import {useReducer} from 'react';

import {
  FIVE_BY_FOUR_CROPPED_IMAGE_ONLY,
  ONE_BY_ONE_CROPPED_IMAGE_ONLY,
  TWO_BY_ONE_CROPPED_IMAGE_ONLY,
  TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL,
  VIDEO_CLOUDINARY,
  VIDEO_YOUTUBE,
} from './mediaTypes';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_IMAGE_URL':
      return {
        ...state,
        imageUrl: action.url,
        imageMeta: {
          fileName: action.fileName,
          width: action.width,
          height: action.height,
        },
      };
    case 'SET_CROPPED_IMAGE_URL':
      return {
        ...state,
        croppedImageUrl: action.url,
        croppedImageMeta: {
          fileName: action.fileName,
          width: action.width,
          height: action.height,
        },
      };
    case 'SET_VIDEO_URL':
      return {
        ...state,
        videoUrl: action.url,
        videoMeta: {fileName: action.fileName},
      };
    case 'SET_EMBED_URL':
      return {...state, embedUrl: action.url};
    case 'SET_MEDIA':
      return {
        ...action.media,
        mediaType: action.mediaType,
        initial: action.media,
      };
    case 'REMOVE_MEDIA':
      return {
        mediaType: state.mediaType,
        initial: state.initial,
      };
    case 'CANCEL_CHANGES':
      return {...state.initial};
    default:
      throw Error('Unknown action type', action.type);
  }
};

// converts a media-slice of an eson object to UploadModal state
// eslint-disable-next-line complexity
const init = (mediaType, media) => {
  // not every client is able to give us a video source. For that reason, we
  // will try guess it on our own.
  // TODO: move to videoUtils in bento
  const CLOUDINARY = 'cloudinary';
  const YOUTUBE = 'youtube';
  const getVideoSource = (url) => {
    if (!url) {
      return null;
    }
    return url.includes('youtube') || url.includes('youtu.be')
      ? YOUTUBE
      : CLOUDINARY;
  };

  switch (mediaType) {
    case FIVE_BY_FOUR_CROPPED_IMAGE_ONLY:
    case TWO_BY_ONE_CROPPED_IMAGE_ONLY:
    case ONE_BY_ONE_CROPPED_IMAGE_ONLY:
      return {
        croppedImageUrl: media.url,
        croppedImageMeta: {
          fileName: media.fileName,
          width: media.width,
          height: media.height,
        },
      };
    case TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL:
      return {
        imageUrl: media.url,
        imageMeta: {
          fileName: media.fileName,
          width: media.width,
          height: media.height,
        },
        croppedImageUrl: media.thumbnailUrl || media.url, // old lessons might not have a thumbnailUrl
        croppedImageMeta: {
          fileName: media.fileName,
          width: media.thumbnailWidth,
          height: media.thumbnailHeight,
        },
      };
    case VIDEO_CLOUDINARY: {
      return {
        videoUrl: getVideoSource(media.url) === CLOUDINARY ? media.url : '',
        videoMeta: {fileName: media.fileName},
      };
    }
    case VIDEO_YOUTUBE: {
      return {embedUrl: getVideoSource(media.url) === YOUTUBE ? media.url : ''};
    }
    default:
      throw Error('unknown media type', mediaType);
  }
};

const useUploadModal = (onReturnMedia, onOpenModal, onCloseModal) => {
  const [state, dispatch] = useReducer(reducer, {});

  const setImage = (url, {fileName, width, height}) => {
    dispatch({type: 'SET_IMAGE_URL', url, fileName, width, height});
  };

  const setCroppedImage = (url, {fileName, width, height}) => {
    dispatch({type: 'SET_CROPPED_IMAGE_URL', url, fileName, width, height});
  };

  const setVideo = (url, {fileName}) => {
    dispatch({type: 'SET_VIDEO_URL', url, fileName});
  };

  const setEmbed = (url) => {
    dispatch({type: 'SET_EMBED_URL', url});
  };

  const removeMedia = () => {
    dispatch({type: 'REMOVE_MEDIA'});
  };

  const openUploadModal = (media, mediaType) => {
    onOpenModal();
    dispatch({type: 'SET_MEDIA', mediaType, media: init(mediaType, media)});
  };

  const closeUploadModal = () => {
    dispatch({type: 'SET_MEDIA', mediaType: null, media: {}});
    onCloseModal();
  };

  const cancelChanges = () => {
    dispatch({type: 'CANCEL_CHANGES'});
  };

  const returnMedia = (mediaType, media) => {
    switch (mediaType) {
      case FIVE_BY_FOUR_CROPPED_IMAGE_ONLY:
      case TWO_BY_ONE_CROPPED_IMAGE_ONLY:
      case ONE_BY_ONE_CROPPED_IMAGE_ONLY:
        return onReturnMedia({
          type: 'image',
          url: media.croppedImageUrl,
          width: media.croppedImageMeta?.width,
          height: media.croppedImageMeta?.height,
          fileName: media.croppedImageMeta?.fileName,
        });
      case TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL:
        return onReturnMedia(
          {
            type: 'image',
            url: media.imageUrl,
            width: media.imageMeta?.width,
            height: media.imageMeta?.height,
            fileName: media.imageMeta?.fileName,
          },
          {
            type: 'image',
            url: media.croppedImageUrl,
            width: media.croppedImageMeta?.width,
            height: media.croppedImageMeta?.height,
            fileName: media.croppedImageMeta?.fileName,
          }
        );
      case VIDEO_CLOUDINARY:
        return onReturnMedia({
          type: 'video',
          url: media.videoUrl,
          fileName: media.videoMeta?.fileName,
          source: 'cloudinary',
        });
      case VIDEO_YOUTUBE:
        return onReturnMedia({
          type: 'video',
          url: media.embedUrl,
          source: 'youtube',
        });
      default:
        throw Error('unknown media type', mediaType);
    }
  };

  const saveMediaAndClose = () => {
    returnMedia(state.mediaType, state);
    closeUploadModal();
  };

  const removeMediaAndClose = () => {
    removeMedia();
    // We can't rely on the current state since it will be stale until the next render
    // state.mediaType doesn't change when removeMedia is called so it should be OK to use
    returnMedia(state.mediaType, {});
    closeUploadModal();
  };

  const cancelChangesAndClose = () => {
    cancelChanges();
    closeUploadModal();
  };

  return {
    ...state,
    onSetImage: setImage,
    onSetCroppedImage: setCroppedImage,
    onSetVideo: setVideo,
    onSetEmbed: setEmbed,

    onSaveChanges: saveMediaAndClose,
    onRemove: removeMediaAndClose,
    onClose: cancelChangesAndClose,

    openUploadModal,
    closeUploadModal,
  };
};

export default useUploadModal;

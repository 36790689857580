import {COURSE, GUIDE, KNOWLEDGE_HUB} from './contentTypes';
import {BEEKEEPER, MS_TEAMS, SPEAKAP} from './integrations';

export const MAXIMUM_USER_COUNT = 100;
export const MAXIMUM_CUSTOM_SMS_LENGTH = 140;

export const SUPPORTED_INTEGRATIONS_WITH_NOTIFICATION = [
  MS_TEAMS,
  SPEAKAP,
  BEEKEEPER,
];

export const SUPPORTED_CONTENT_TYPE_BY_INTEGRATION = {
  [MS_TEAMS]: [COURSE],
  [SPEAKAP]: [COURSE],
  [BEEKEEPER]: [COURSE, GUIDE, KNOWLEDGE_HUB],
};

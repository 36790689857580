import {push} from 'connected-react-router';

import * as contentTypes from '../../enums/contentTypes';
import * as courseActionCreators from '../course/courseActionCreators';
import * as courseActions from '../course/courseActions';
import {CONTENT_FILTERS, SIDEBAR_TABS} from '../course/courseActionTypes';
import * as guideActions from '../guide/guideActions';
import * as notificationActions from '../notification/notificationActions';
import * as teamActions from '../team/teamActions';
import {getSelectedGroupId} from '../team/teamSelectors';
import * as contentActionCreators from './contentActionCreators';
import {OPEN_SCHEDULED_NOTIFICATIONS_SLIDE_PANEL} from './contentActionTypes';

const CONTENT_TYPE_ACTIONS = {
  [contentTypes.COURSE]: courseActions.updateTeamsInCourse,
  [contentTypes.GUIDE]: guideActions.updateTeamsInGuide,
};

/**
 *
 * @param {string|number} contentId
 * @param {contentTypes.COURSE | contentTypes.GUIDE} contentType
 * @param {number[]} teamIdsToAdd
 * @param {number[]} teamIdsToRemove
 * @param {any[]} externalTeamIntegrations
 * @returns
 */
export const updateTeamsInContentForIntegrations =
  (
    contentType,
    contentId,
    teamIdsToAdd,
    teamIdsToRemove,
    externalTeamIntegrations
  ) =>
  async (dispatch, getState) => {
    const updateTeamsInContent = CONTENT_TYPE_ACTIONS[contentType];
    const parentGroupId = getSelectedGroupId(getState());

    if (externalTeamIntegrations && externalTeamIntegrations.length > 0) {
      try {
        const {payload} = await dispatch(
          courseActionCreators.updateExternalTeams(
            parentGroupId,
            externalTeamIntegrations
          )
        );
        dispatch(
          teamActions.loadTeams({
            otherTabsRequireUpdate: false,
            fetchExternalTeams: true,
          })
        );
        const newTeamIds = payload.data;
        updateTeamsInContent(
          contentId,
          [...teamIdsToAdd, ...newTeamIds],
          teamIdsToRemove
        )(dispatch, getState);
      } catch (err) {
        notificationActions.createErrorNotificationFromResponse(err)(dispatch);
      }
    } else {
      updateTeamsInContent(
        contentId,
        teamIdsToAdd,
        teamIdsToRemove
      )(dispatch, getState);
    }
  };

export const loadContents = () => async (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());
  if (selectedGroupId) {
    await dispatch(contentActionCreators.getContents(selectedGroupId)).catch(
      (error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      }
    );
  }
};

export const goToContent = (content, source) => async (dispatch) => {
  switch (content.contentType) {
    case contentTypes.COURSE:
      await dispatch(
        push(`/learning/course/${content.id}${location.search}`, source)
      );
      break;
    case contentTypes.GUIDE:
      await dispatch(push(`/guides/${content.id}${location.search}`, source));
      break;
    default:
      break;
  }
};

export const copyContent =
  ({
    content,
    groupIds,
    currentGroupId,
    copiedFrom,
    shouldGoToContent = false,
    source,
  }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      const duplicatedContent = await dispatch(
        contentActionCreators.copyContent(
          content.contentType,
          content.courseKey || content.id,
          groupIds,
          currentGroupId,
          copiedFrom,
          source
        )
      );

      const isOnResourceLibraryTab =
        state.getIn(['course', 'activeSidebarItem']) ===
        SIDEBAR_TABS.RESOURCE_LIBRARY;
      if (!isOnResourceLibraryTab) {
        await dispatch(
          courseActions.setActiveSidebarItem(SIDEBAR_TABS.STANDARD_CONTENT)
        );
      }

      const activeContentFilter = state.getIn([
        'course',
        'activeContentFilter',
      ]);
      if (activeContentFilter !== CONTENT_FILTERS.ALL) {
        await dispatch(
          courseActions.setActiveContentFilter(CONTENT_FILTERS.ALL)
        );
      }

      if (shouldGoToContent) {
        const {
          payload: {data},
        } = duplicatedContent;
        const newContent = {...data[0], contentType: content.contentType};
        await dispatch(goToContent(newContent));
      }

      await dispatch(loadContents());

      // Show success message if content copied to only currentGroup
      // else it is already showing success modal.
      if (groupIds[0] === currentGroupId) {
        dispatch(
          notificationActions.createSuccessNotification(
            'Learning.copyContentSuccess'
          )
        );
      }
    } catch (error) {
      console.log('error', error);
      dispatch(notificationActions.createErrorNotificationFromResponse(error));
    }
  };

export const getScheduledNotifications =
  (contentType, contentId) => (dispatch) => {
    dispatch(
      contentActionCreators.getScheduledNotifications({contentType, contentId})
    );
  };

export const openScheduledNotificationsSlidePanel =
  (contentType, source) => async (dispatch) => {
    dispatch({
      type: OPEN_SCHEDULED_NOTIFICATIONS_SLIDE_PANEL,
      payload: {contentType, source},
    });
  };

export const ADD_LEARNING_CARD = 'ADD_LEARNING_CARD';
export const ADD_LEARNING_CARD_FAIL = 'ADD_LEARNING_CARD_FAIL';
export const ADD_LEARNING_CARD_SUCCESS = 'ADD_LEARNING_CARD_SUCCESS';
export const DELETE_LEARNING_CARD = 'DELETE_LEARNING_CARD';
export const DELETE_LEARNING_CARD_FAIL = 'DELETE_LEARNING_CARD_FAIL';
export const DELETE_LEARNING_CARD_SUCCESS = 'DELETE_LEARNING_CARD_SUCCESS';
export const EDIT_LEARNING_CARD = 'EDIT_LEARNING_CARD';
export const EDIT_LEARNING_CARD_FAIL = 'EDIT_LEARNING_CARD_FAIL';
export const EDIT_LEARNING_CARD_SUCCESS = 'EDIT_LEARNING_CARD_SUCCESS';
export const GET_LEARNING_CARDS = 'GET_LEARNING_CARDS';
export const GET_LEARNING_CARDS_SUCCESS = 'GET_LEARNING_CARDS_SUCCESS';

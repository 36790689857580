// This is only used by Messages - bubbles use the function in markdownUtils.ts in Magnificent
export const legacyFixMarkdown = (str) => {
  /* both RN / android native app render lists strangely if:
   * a) they're not the very first thing in the text, and
   * b) there's only a single new line before them
   *
   * works consistently across DS/both apps if two new lines though, so ensure two when saving
   */

  //i.e. an asterisk, dash, or number+fullstop (optionally preceded by spaces)
  //also needs to not-detect lines that begin with bold (hence the [^\*])
  //this DOESN'T work for lines beginning in italics (single *) but we're ok with that

  const isListItem = (line) => line.match(/^ *(\d\.|\*[^*]|-)/);

  if (!str) {
    return str;
  }
  const lines = str.split('\n');
  if (lines.length <= 1) {
    return str;
  }

  let prevLineIsListItem = isListItem(lines[0]);
  let prevLineIsEmpty = lines[0] === '';

  const fixedLines = [lines[0]]; //will be returned

  lines.slice(1).forEach((line) => {
    const thisLineIsListItem = isListItem(line);

    if (thisLineIsListItem && !prevLineIsListItem && !prevLineIsEmpty) {
      // last line isn't a list (or empty), want to insert a new line before this first list item
      fixedLines.push('');
    }

    fixedLines.push(line);

    prevLineIsListItem = thisLineIsListItem;
    prevLineIsEmpty = line === '';
  });

  return fixedLines.join('\n');
};

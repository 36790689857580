import {ReactElement, ReactNode} from 'react';

import CSSModules from 'react-css-modules';

import {
  renderText,
  TextColours,
  TextTransforms,
  TextWeights,
} from '../textUtils';

import styles from './styles.module.scss';

export const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
export type HeadingTag = (typeof headingTags)[number];

export const headingSizes = [
  'elephant',
  'rhino',
  'leopard',
  'sheep',
  'cat',
  'hamster',
  'snail',
  'ant',
] as const;
export type HeadingSizes = (typeof headingSizes)[number];

type HeadingProps = {
  children: ReactNode;
  /** HTML tag */
  as: HeadingTag;
  /** elephant: 48px, rhino: 36px, leopard: 28px, sheep: 24px, cat: 20px, hamster: 18px, snail: 16px, ant: 14px */
  size: HeadingSizes;
  /** Text colour
   * @default textPrimary
   */
  colour?: TextColours;
  /** Font weight
   * @default regular
   * */
  /** regular: 400, medium: 500, semiBold: 600, bold: 700, extraBold: 800 */
  weight?: TextWeights;
  /** Max number of lines.
   * Overflow text is truncated with an ellipsis ...
   */
  maxLines?: number;
  /** @default none */
  textTransform?: TextTransforms;
  /** HTML ID */
  id?: string;
  /** Any valid CSS colour value */
  overrideColour?: string;
  dataAutomation?: string;
};

const Heading = ({
  children,
  as,
  size,
  colour = 'textPrimary',
  weight = 'regular',
  maxLines,
  textTransform = 'none',
  id,
  overrideColour,
  dataAutomation,
}: HeadingProps): ReactElement => {
  const styleName = `heading ${size} ${colour} ${weight} ${textTransform}`;
  const style = {...(overrideColour && {color: overrideColour})};

  return renderText({
    children,
    tag: as,
    styleName,
    maxLines,
    id,
    style,
    dataAutomation,
  });
};

export default CSSModules(Heading, styles, {allowMultiple: true});

import React from 'react';

import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import {Link} from '@edume/magnificent';

const SelectUnselectAllLink = ({
  selectedIdsBySource,
  sourceIndex,
  hasSearchResults,
  allSearchedTeamsIds,
  allTeamIds,
  isSelectAll,
  updateSelectedTeams,
  intl,
}) => {
  const unselectedSearchResultIds = allSearchedTeamsIds.filter(
    (id) => !selectedIdsBySource[sourceIndex].includes(id)
  );

  const toggleSelectAll = (selectedIndex, teamIds, isAllSelected, isSearch) => {
    const teamIdsToSave = isSearch ? [...teamIds[0], ...teamIds[1]] : teamIds;
    const unselectSearchedTeams = isSearch
      ? teamIds[0].filter((id) => !teamIds[1].includes(id))
      : [];
    if (isAllSelected) {
      return updateSelectedTeams(selectedIndex, teamIdsToSave);
    } else {
      return updateSelectedTeams(selectedIndex, unselectSearchedTeams);
    }
  };

  return (
    <Link
      linkSize='snail'
      text={
        isSelectAll
          ? intl.formatMessage({id: 'Link.selectAll'})
          : intl.formatMessage({id: 'Link.unselectAll'})
      }
      onClick={() =>
        toggleSelectAll(
          sourceIndex,
          hasSearchResults
            ? [
                selectedIdsBySource[sourceIndex],
                isSelectAll ? unselectedSearchResultIds : allSearchedTeamsIds,
              ]
            : allTeamIds,
          isSelectAll,
          hasSearchResults
        )
      }
    />
  );
};

SelectUnselectAllLink.propTypes = {
  selectedIdsBySource: PropTypes.array.isRequired,
  sourceIndex: PropTypes.number.isRequired,
  hasSearchResults: PropTypes.bool.isRequired,
  allSearchedTeamsIds: PropTypes.array.isRequired,
  allTeamIds: PropTypes.array.isRequired,
  isSelectAll: PropTypes.bool.isRequired,
  updateSelectedTeams: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(SelectUnselectAllLink);

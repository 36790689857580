import React from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import {SlidePanel} from '@edume/magnificent';

const SlidePanelWithWarning = (props) => {
  const intl = useIntl();

  const warningInfo = {
    title: intl.formatMessage({id: 'SlidePanel.warning.title'}),
    text: intl.formatMessage({id: 'SlidePanel.warning.text'}),
    closeButton: {
      dataAutomation: 'warning-close-button',
      text: intl.formatMessage({id: 'SlidePanel.warning.closeButton'}),
    },
    continueButton: {
      dataAutomation: 'warning-continue-button',
      text: intl.formatMessage({id: 'SlidePanel.warning.continueButton'}),
    },
  };

  const {children, ...rest} = props;

  return (
    <SlidePanel {...rest} warningInfo={warningInfo}>
      {children}
    </SlidePanel>
  );
};

SlidePanelWithWarning.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SlidePanelWithWarning;

import {addMeta, buildRequest} from '../stateUtil';
import {
  GET_ACTIVE_USERS_BY_GROUP,
  GET_ACTIVE_USERS_BY_MONTH,
  GET_ACTIVITY_BREAKDOWN,
  GET_ACTIVITY_STATS_FOR_GROUP,
  GET_ADMIN_COUNT,
  GET_COURSE_ACTIVITY_GRAPH,
  GET_COURSE_OVERVIEWS,
  GET_GUIDE_ACTIVITY_GRAPH,
  GET_GUIDE_STEP_BREAKDOWN,
  GET_GUIDE_TEAM_BREAKDOWN,
  GET_STATS_FOR_GROUP,
  GET_TEAM_ACTIVITY_STATS,
  GET_TEAM_USERS,
} from './reportingActionTypes';
import {toCourseGroupKey} from './reportingSelector';

export const getStatsForGroup = (groupId) =>
  buildRequest(
    GET_STATS_FOR_GROUP,
    'GET',
    `/stats/group/${groupId}`,
    null,
    'reports'
  );

export const getActivityStatsForGroup = (groupId) =>
  buildRequest(
    GET_ACTIVITY_STATS_FOR_GROUP,
    'GET',
    `/stats/group/${groupId}/activity`,
    null,
    'reports'
  );

export const getCourseActivityGraph = (courseId, groupId) =>
  addMeta(
    buildRequest(
      GET_COURSE_ACTIVITY_GRAPH,
      'GET',
      `/stats/course/${courseId}/group/${groupId}/activeGraph`,
      null,
      'reports'
    ),
    {courseGroupKey: toCourseGroupKey(courseId, groupId)}
  );

export const getGuideActivityGraph = (guideId, groupId) =>
  addMeta(
    buildRequest(
      GET_GUIDE_ACTIVITY_GRAPH,
      'GET',
      `/stats/guide/${guideId}/group/${groupId}/activeGraph`,
      null,
      'reports'
    ),
    {guideId}
  );

export const getGuideStepBreakdown = (guideId) =>
  addMeta(
    buildRequest(
      GET_GUIDE_STEP_BREAKDOWN,
      'GET',
      `/stats/guide/${guideId}/stepBreakdown`,
      null,
      'reports'
    ),
    {guideId}
  );

export const getGuideTeamBreakdown = (guideId) =>
  addMeta(
    buildRequest(
      GET_GUIDE_TEAM_BREAKDOWN,
      'GET',
      `/stats/guide/${guideId}/teamBreakdown`,
      null,
      'reports'
    ),
    {guideId}
  );

export const getTeamActivityStats = (groupId) =>
  buildRequest(
    GET_TEAM_ACTIVITY_STATS,
    'GET',
    `/stats/group/${groupId}/teams`,
    null,
    'reports'
  );

export const getActivityBreakdown = (courseId) =>
  buildRequest(
    GET_ACTIVITY_BREAKDOWN,
    'GET',
    `/stats/course/${courseId}/activityBreakdown`,
    null,
    'reports'
  );

export const getTeamUsers = (teamId, pageIndex) =>
  buildRequest(
    GET_TEAM_USERS,
    'GET',
    `/stats/team/${teamId}/users?page=${pageIndex}`,
    null,
    'reports'
  );

export const getAdminCount = () =>
  buildRequest(GET_ADMIN_COUNT, 'GET', '/stats/adminCount');

export const getCoursesOverviewData = (courseIds, from, to) =>
  buildRequest(
    GET_COURSE_OVERVIEWS,
    'GET',
    `/stats/courseCompletionOverview?courseIds=${courseIds.join(
      ','
    )}&from=${from}&to=${to}`,
    null,
    'reports'
  );

const pad = (x) => (x < 10 ? '0' + x : x);

export const getActiveUsersByMonth = (startDate, endDate) =>
  buildRequest(
    GET_ACTIVE_USERS_BY_MONTH,
    'GET',
    `/stats/activeUsersByMonth?from=${startDate.getFullYear()}-${pad(
      startDate.getMonth() + 1
    )}&to=${endDate.getFullYear()}-${pad(endDate.getMonth() + 1)}`,
    null,
    'reports'
  );

export const getActiveUsersByGroup = (startDate, endDate) =>
  buildRequest(
    GET_ACTIVE_USERS_BY_GROUP,
    'GET',
    `/stats/activeUsersByGroup?from=${startDate.getFullYear()}-${pad(
      startDate.getMonth() + 1
    )}&to=${endDate.getFullYear()}-${pad(endDate.getMonth() + 1)}`,
    null,
    'reports'
  );

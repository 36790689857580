import {fromJS} from 'immutable';
import {v4 as uuidv4} from 'uuid';

import {
  ADD_LEARNING_CARD,
  ADD_LEARNING_CARD_FAIL,
  ADD_LEARNING_CARD_SUCCESS,
  DELETE_LEARNING_CARD,
  DELETE_LEARNING_CARD_FAIL,
  DELETE_LEARNING_CARD_SUCCESS,
  EDIT_LEARNING_CARD,
  EDIT_LEARNING_CARD_FAIL,
  EDIT_LEARNING_CARD_SUCCESS,
  GET_LEARNING_CARDS_SUCCESS,
} from './learningCardActionTypes';

export const initialState = fromJS({
  learningCards: [],
  editLearningCardInProgress: false,
});

const updateLearningCard = (learningCardIndex, newLearningCard, state) => {
  const {id, title, text, mediaUrl, mediaType} = newLearningCard;

  const learningCards = state.get('learningCards');

  return state.set(
    'learningCards',
    learningCards
      .setIn([learningCardIndex, 'addingInProgress'], false)
      .setIn([learningCardIndex, 'id'], id)
      .setIn([learningCardIndex, 'title'], title)
      .setIn([learningCardIndex, 'text'], text)
      .setIn([learningCardIndex, 'mediaUrl'], mediaUrl)
      .setIn([learningCardIndex, 'mediaType'], mediaType)
  );
};

//eslint-disable-next-line complexity, max-statements
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEARNING_CARDS_SUCCESS: {
      const learningCards = fromJS(action.payload.data);
      return state.set('learningCards', learningCards);
    }

    case ADD_LEARNING_CARD: {
      const learningCards = state.get('learningCards');

      return state.set('editLearningCardInProgress', true).set(
        'learningCards',
        learningCards.unshift(
          fromJS({
            id: uuidv4(), //temp id
            title: '',
            text: '',
            mediaUrl: '',
            mediaType: '',
            addingInProgress: true,
          })
        )
      );
    }

    case ADD_LEARNING_CARD_SUCCESS: {
      const learningCard = action.payload.data;

      return updateLearningCard(
        0,
        {...learningCard, addingInProgress: false},
        state
      ).set('editLearningCardInProgress', false);
    }

    case ADD_LEARNING_CARD_FAIL:
    case DELETE_LEARNING_CARD_FAIL:
    case EDIT_LEARNING_CARD_FAIL: {
      return state.set('editLearningCardInProgress', false);
    }

    case DELETE_LEARNING_CARD:
    case EDIT_LEARNING_CARD: {
      return state.set('editLearningCardInProgress', true);
    }

    case EDIT_LEARNING_CARD_SUCCESS: {
      const learningCard = action.payload.data;
      const {learningCardId} = action.meta;

      const learningCards = state.get('learningCards');
      const learningCardIndex = learningCards.findIndex(
        (item) => item.get('id') === learningCardId
      );

      return updateLearningCard(learningCardIndex, learningCard, state).set(
        'editLearningCardInProgress',
        false
      );
    }

    case DELETE_LEARNING_CARD_SUCCESS: {
      const {learningCardId} = action.meta;

      const learningCards = state.get('learningCards');
      return state.set('editLearningCardInProgress', false).set(
        'learningCards',
        learningCards.filter(
          (learningCard) => learningCard.get('id') !== learningCardId
        )
      );
    }

    default: {
      return state;
    }
  }
};

import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

export type EyeIconType = 'open' | 'closed';

type EyeIconProps = IconProps & {
  type?: EyeIconType;
};

const EyeIcon = ({
  size = 'small',
  colour = 'textPrimary',
  type,
}: EyeIconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d={
        type === 'open'
          ? 'M12 6c5.523 0 10 2.686 10 6s-4.477 6-10 6-10-2.686-10-6 4.477-6 10-6Zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z'
          : 'm19.148 4 1.178 1.178L5.11 20.394l-1.226-1.225 2.288-2.273C3.646 15.807 2 14.03 2 12.02c0-3.314 4.477-6 10-6 1.61 0 3.132.228 4.479.634L19.149 4ZM22 12.02c0 3.313-4.477 6-10 6-.647 0-1.28-.037-1.892-.108L12 16.02a4 4 0 0 0 4-4l3.774-3.774C21.166 9.276 22 10.59 22 12.02Zm-10-4a4 4 0 0 0-3.257 6.322l1.457-1.449a2 2 0 0 1 2.684-2.668l1.458-1.448A3.982 3.982 0 0 0 12 8.02Z'
      }
      fill={colourMap[colour]}
    />
  </svg>
);

export default EyeIcon;

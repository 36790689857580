export const getKnowledgeHubById = (state, knowledgeHubId) => {
  const knowledgeHub = state
    .getIn(['knowledgeHub', 'knowledgeHubs'])
    .find((kh) => kh.get('id') === knowledgeHubId);
  return knowledgeHub ? knowledgeHub.toJS() : null;
};

export const getSectionById = (state, sectionId) => {
  const section = state.getIn(['knowledgeHub', 'sectionsById', sectionId]);
  return section ? section.toJS() : null;
};

export const getHasSingleV2KnowledgeHub = (state) => {
  const knowledgeHubs = state.getIn(['knowledgeHub', 'knowledgeHubs']);
  if (knowledgeHubs.size !== 1) {
    return false;
  }

  const isManualAssignment =
    knowledgeHubs.get(0).get('contentAssignment') === 'manual';
  const isManagementViewEnabled = state.getIn([
    'featureFlags',
    'customerFlags',
  ]).knowledgeHubsManagementEnabled;

  return isManualAssignment && isManagementViewEnabled;
};

export const getContentBadgeColour = (badgeType) => {
  switch (badgeType) {
    case 'published':
      return 'purple';
    case 'template':
      return 'brandPrimaryDark';
    case 'demo':
    case 'sample':
      return 'blue';
    case 'bestPractices':
      return 'yellow';
    case 'readyToUse':
      return 'blue';
    case 'unpublished':
    case 'draft':
      return 'red';
    case 'review':
    default:
      return 'green';
  }
};

import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const SmartTeamIcon = ({
  size = 'small',
  colour = 'textPrimary',
}: IconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9 22a13 13 0 0 0 4-.6l1.3-3.3A10.4 10.4 0 0 1 3.5 18v-.5a5.5 5.5 0 0 1 8.1-4.8L14 7.4A5 5 0 0 0 9 2a5 5 0 0 0-3.7 8.4 8 8 0 0 0-4 9A13 13 0 0 0 9 22Zm2.5-15a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z'
      fill={colourMap[colour]}
      fillRule='evenodd'
    />
    <path
      d='m19.4 7.6-2.8-1.2-3.9 9.1h5l-2 5 2.7 1 3.9-9h-5l2-5Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default SmartTeamIcon;

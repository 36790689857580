import {useEffect, useState} from 'react';

import throttle from 'lodash/throttle';

const useBreakpoint = (breakPoint = 800) => {
  const [isSmallScreen, setIsSmallScreen] = useState(
    () => window.innerWidth < breakPoint
  );

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setIsSmallScreen(window.innerWidth < breakPoint);
    }, 200);

    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, [breakPoint]);

  return {
    isSmallScreen,
    isDesktop: !isSmallScreen,
  };
};
export default useBreakpoint;

import axios from 'axios';

import config from './config';
import {trackingActionTypes} from './state/tracking/trackingActions';
import {
  COMPLETE_TRIAL_FORM_STEP_ONE,
  COMPLETE_TRIAL_FORM_STEP_TWO,
  SET_PREFERRED_EMAIL,
  START_MS_TEAMS_TRIAL,
  START_TRIAL_SUCCESS,
} from './state/trial/trialActionTypes';

const hubspot = (window._hsq = window._hsq || []);
hubspot.push(['doNotTrack']);

/**
 * @typedef HubspotFormData
 * @property {string} [email]
 * @property {string} [firstName]
 * @property {string} [lastName]
 * @property {string} [companyName]
 * @property {string} [estimatedLearners]
 * @property {string} [integrations]
 * @property {string} [industry]
 * @property {string} [resourceType]
 * @property {string} [resourceTitle]
 * @property {string} [preferredEmail]
 * @property {boolean} [isMsTeams]
 */

const hubspotFieldMap = {
  email: 'email',
  firstName: 'firstname',
  lastName: 'lastname',
  companyName: 'company',
  estimatedLearners: 'estimatedlearnercount__c',
  integrations: 'integrationsused',
  industry: 'industry',
  resourceType: 'resourceType',
  resourceTitle: 'resourceTitle',
  preferredEmail: 'preferred_email',
  isMsTeams: 'msteams',
  isBeekeeper: 'beekeeper',
};

/**
 * @param {HubspotFormData} data
 * @param {any} state
 */
const submitHubspotForm = (data, state) => {
  if (!config.hubspotPortalId) {
    return;
  }

  const PORTAL_ID = config.hubspotPortalId;
  const FORM_ID = config.hubspotFormId;

  const HS_COOKIE_REGEX = /hubspotutk=(.+?);/g;
  const match = HS_COOKIE_REGEX.exec(document.cookie);
  const hutk = match ? match[1] : null;

  const fields = Object.keys(data).map((key) => ({
    name: hubspotFieldMap[key],
    value: data[key],
  }));

  if (!data.email) {
    const email = state.getIn(['auth', 'email']);
    fields.push({
      name: hubspotFieldMap.email,
      value: email,
    });
  }

  const body = {
    fields,
    context: {hutk},
    skipValidation: true,
  };

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`;

  axios.post(url, JSON.stringify(body), {
    headers: {'Content-Type': 'application/json'},
  });
};

const logHubspotIdentity = (email) => {
  if (!config.hubspotPortalId) {
    return;
  }
  hubspot.push(['doNotTrack', {track: true}]);
  hubspot.push(['identify', {email}]);
  hubspot.push(['doNotTrack']);
};

const logHubspotPageView = (path) => {
  if (!config.hubspotPortalId) {
    return;
  }
  hubspot.push(['doNotTrack', {track: true}]);
  hubspot.push(['setPath', path]);
  hubspot.push(['trackPageView']);
  hubspot.push(['doNotTrack']);
};

/* eslint-disable complexity */
export const logReduxActionToAnalytics = (action, state) => {
  switch (action.type) {
    case trackingActionTypes.TRIAL_SIGNUP_START:
      return logHubspotPageView('/trial');
    case trackingActionTypes.GO_TO_TRIAL_FORM_COMPANY:
      logHubspotIdentity(action.payload.email);
      return logHubspotPageView('/trial/form');

    case START_TRIAL_SUCCESS: {
      const {email, firstResource} = action.payload.data;
      const resourceKey = state.getIn(['trial', 'resourceKey']);
      const isBeekeeper = state.getIn(['trial', 'isBeekeeper']);

      let baseFields = {email, isMsTeams: false};

      if (isBeekeeper) {
        baseFields = {
          ...baseFields,
          isBeekeeper: true,
        };
      }

      if (resourceKey && !firstResource.isResourceNotFound) {
        baseFields = {
          ...baseFields,
          resourceType: firstResource.resourceType,
          resourceTitle: firstResource.resourceTitle,
        };
      }

      return submitHubspotForm(baseFields, state);
    }

    case START_MS_TEAMS_TRIAL:
      return submitHubspotForm(
        {
          isMsTeams: true,
        },
        state
      );

    case `${SET_PREFERRED_EMAIL}_SUCCESS`:
      return submitHubspotForm({preferredEmail: action.payload.data}, state);

    case COMPLETE_TRIAL_FORM_STEP_ONE: {
      const firstName = state.getIn(['auth', 'firstName']);
      const lastName = state.getIn(['auth', 'lastName']);
      const {officialName: companyName} =
        state.getIn(['auth', 'company']) || {};

      return submitHubspotForm(
        {
          firstName,
          lastName,
          companyName,
        },
        state
      );
    }

    case COMPLETE_TRIAL_FORM_STEP_TWO: {
      const integrationsList = state.getIn(['trial', 'integrationsUsed']);
      const integrations =
        integrationsList && integrationsList.map(({value}) => value).join(', ');
      const estimatedLearners = state.getIn(['trial', 'estimatedLearners']);
      const industry = state.getIn(['trial', 'industry']);

      return submitHubspotForm(
        {
          integrations,
          estimatedLearners,
          industry,
        },
        state
      );
    }

    default:
      return null;
  }
};

import React from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

type ReadyIconProps = {
  isValid: boolean;
  isDisabled?: boolean;
  size?: 'large' | 'small';
  dataAutomation?: string;
};

const ReadyIcon = ({
  isValid,
  isDisabled = false,
  size = 'large',
  dataAutomation,
}: ReadyIconProps): JSX.Element => (
  <div
    data-automation={dataAutomation}
    styleName={classNames('iconContainer', {
      valid: isValid,
      small: size === 'small',
      disabled: isDisabled,
    })}
  />
);

export default CSSModules(ReadyIcon, styles, {allowMultiple: true});

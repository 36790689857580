import React from 'react';

import classnames from 'classnames/bind';
import CSSModules from 'react-css-modules';

import {IconProps} from '../types';
import {colourMap, OrientationOption, sizesMap} from '../utils/iconProperties';

import styles from './styles.module.scss';

const cx = classnames.bind(styles);

type ChevronProps = IconProps & {
  orientation?: OrientationOption;
  withTransition?: boolean;
  customColour?: string;
};

const ChevronIcon = ({
  size = 'small',
  colour = 'textPrimary',
  orientation = 'down',
  withTransition = false,
  customColour,
}: ChevronProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={cx(orientation, {withTransition})}
  >
    <path
      d='M12 13 5.7 6.8 3.2 9.2 12 18l8.8-8.8-2.5-2.5-6.3 6.4Z'
      fill={customColour || colourMap[colour]}
    />
  </svg>
);

export default CSSModules(ChevronIcon, styles);

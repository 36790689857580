export const sortOptions = {
  mostRecent: 'mostRecent',
  aToZ: 'aToZ',
};

export const filterOptions = {
  all: 'all',
  admin: 'admin',
  nonAdmin: 'nonAdmin',
  activated: 'activated',
  notActivated: 'notActivated',
};

export const defaultFilterAndSortOptions = {
  sortOption: sortOptions.mostRecent,
  filterOption: filterOptions.all,
};

import {sortContentByCreationDate} from '../../utils/courseUtil';

export const getContents = (state) => {
  const courses = state.getIn(['course', 'courses']).toJS();
  const guides = state.getIn(['guide', 'guides']).toJS();
  // For now this is just for copy content placeholder.
  const contents = state.getIn(['content', 'contents']).toJS();

  return sortContentByCreationDate(
    [...contents, ...courses, ...guides],
    'title'
  );
};

import React from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {RECENT} from '../../../enums/overview';
import {goToContent} from '../../../state/content/contentActions';
import {setActiveSidebarItem} from '../../../state/course/courseActions';
import {SIDEBAR_TABS} from '../../../state/course/courseActionTypes';
import {injectFeatureFlags} from '../../../utils/featureFlags';
import OverviewCourseList from '../OverviewCourseList';

const OverviewRecentCourses = ({courses = []}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  if (courses.length === 0) {
    return null;
  }

  const coursesToShow = courses.slice(0, 4);

  const onLinkClick = () => {
    dispatch(setActiveSidebarItem(SIDEBAR_TABS.STANDARD_CONTENT));
    history.push('/learning' + location.search, RECENT);
  };
  const onCourseCardClick = (course) => dispatch(goToContent(course, RECENT));

  return (
    <OverviewCourseList
      courses={coursesToShow}
      type={RECENT}
      onLinkClick={onLinkClick}
      onCourseCardClick={onCourseCardClick}
    />
  );
};

OverviewRecentCourses.propTypes = {
  courses: PropTypes.array,
};

export default injectFeatureFlags(OverviewRecentCourses);

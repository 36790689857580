import React, {useState} from 'react';

import CSSModules from 'react-css-modules';
import ReactTooltip from 'react-tooltip';

import {MinusIcon, TickIcon} from '../icons';

import styles from './styles.module.scss';

export const colours = {
  green: 'green',
  blue: 'blue',
  red: 'red',
} as const;
type Colours = (typeof colours)[keyof typeof colours];

type CheckboxTooltipProps =
  | {
      checkboxKey: string;
      tooltipText?: string;
    }
  | {
      checkboxKey?: never;
      tooltipText?: never;
    };

type CheckboxProps = {
  checked: boolean;
  indeterminate?: boolean;
  onToggleCheck: (checked: boolean) => void;
  colour?: Colours;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  label?: string;
  weight?: 'regular' | 'medium' | 'bold';
  tabIndex?: number;
  disabled?: boolean;
  isPropagationDisabled?: boolean;
  dataAutomation?: string;
} & CheckboxTooltipProps;

// eslint-disable-next-line complexity
const Checkbox = ({
  checked,
  indeterminate,
  colour = 'blue',
  checkboxKey,
  tooltipText,
  tooltipPosition,
  label,
  weight = 'medium',
  onToggleCheck,
  disabled = false,
  isPropagationDisabled = false,
  dataAutomation,
  tabIndex = 0,
}: CheckboxProps): JSX.Element => {
  const [fieldId] = useState(
    () => 'heading-' + Math.random().toString(16).slice(2)
  );

  const tooltip = tooltipText && (
    <ReactTooltip
      id={checkboxKey}
      className='hintTooltip'
      place={tooltipPosition || 'top'}
      effect='solid'
    >
      {tooltipText}
    </ReactTooltip>
  );

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    // Stop click events from propagating if desired, but handle actions on the 'change' event
    if (isPropagationDisabled) {
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (isPropagationDisabled) {
      event.nativeEvent.stopImmediatePropagation();
      event.stopPropagation();
    }
    if (!disabled) {
      onToggleCheck(event.target.checked);
    }
  };

  return (
    <>
      <label
        htmlFor={fieldId}
        data-tip
        data-for={checkboxKey}
        data-tip-disable={checked}
        styleName={`labelElement ${weight} ${disabled ? 'disabled' : ''}`}
        data-automation='label-text'
      >
        <input
          type='checkbox'
          id={fieldId}
          checked={checked}
          disabled={disabled}
          data-automation={dataAutomation}
          styleName={`checkboxEl ${indeterminate ? 'indeterminate' : ''}`}
          onChange={handleChange}
          onClick={handleClick}
          tabIndex={tabIndex}
        />
        <span
          styleName={'checkbox-label' + (checked ? ` ${colour}` : '')}
          onClick={handleClick}
        >
          {label}
        </span>
        <div
          styleName={'checkbox' + (checked ? ` ${colour}` : '')}
          onClick={handleClick}
        >
          {checked && !indeterminate && (
            <TickIcon size='xsmall' colour='white' />
          )}
          {indeterminate && <MinusIcon size='xsmall' colour='white' />}
        </div>
        {tooltip}
      </label>
    </>
  );
};

export default CSSModules(Checkbox, styles, {allowMultiple: true});

import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const ProfileIcon = ({
  size = 'small',
  colour = 'textPrimary',
}: IconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={colourMap[colour]}
      fillRule='evenodd'
      d='M15.7 10.4A5 5 0 0 0 12 2a5 5 0 0 0-3.7 8.4 8 8 0 0 0-4 9 13 13 0 0 0 15.5 0 8 8 0 0 0-4.1-9ZM14.5 7a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM12 12a5.5 5.5 0 0 0-5.5 6 10.4 10.4 0 0 0 11 0v-.5c0-3-2.5-5.5-5.5-5.5Z'
    />
  </svg>
);

export default ProfileIcon;

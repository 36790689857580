import PropTypes from 'prop-types';

const linkShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  callback: PropTypes.func.isRequired,
});

export default linkShape;

import React from 'react';

import CSSModules from 'react-css-modules';

import {NonGenericIndicatorSize} from '..';

import colourStyles from '../../../resources/styles/colours.module.scss';
import styles from './styles.module.scss';

type SparklesProps = {
  dataAutomation: string;
  size?: NonGenericIndicatorSize;
  color: 'light' | 'dark';
  containerStyle?: 'centerFlex' | 'centerAbsolute' | '';
};

const SIZE_LIST = {
  small: 40,
  normal: 60,
  large: 80,
};

const COLOUR_LIST = {
  light: {
    left: colourStyles.purple500,
    bottom: colourStyles.aqua500,
    top: colourStyles.blue500,
  },
  dark: {
    left: colourStyles.textTertiary,
    bottom: colourStyles.textSecondary,
    top: colourStyles.textPrimary,
  },
};

const Sparkles = ({
  size = 'normal',
  color,
  containerStyle,
  dataAutomation,
}: SparklesProps): JSX.Element => {
  // eslint-disable-next-line no-nested-ternary
  const svgHeightAndWidth = typeof size === 'number' ? size : SIZE_LIST[size];
  const colours = COLOUR_LIST[color];
  let containerCss = '';
  if (containerStyle) {
    containerCss = containerStyle;
  }

  return (
    <div styleName={`${containerCss}`} data-automation={dataAutomation}>
      <svg
        width={svgHeightAndWidth}
        height={svgHeightAndWidth}
        viewBox='0 0 400 400'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <mask
            styleName='leftMask'
            id='leftMask'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width={400}
            height={400}
          >
            <path
              width={400}
              height={400}
              d='M8.5248 8.05081L7.38347 8.62148C7.04098 8.79273 6.7633 9.0704 6.59206 9.41289L6.02139 10.5542C5.93988 10.7172 5.70718 10.7172 5.62567 10.5542L5.055 9.41289C4.88375 9.0704 4.60608 8.79272 4.26359 8.62148L3.12226 8.05081C2.95925 7.9693 2.95925 7.7366 3.12226 7.65509L4.26359 7.08442C4.60608 6.91317 4.88375 6.63549 5.055 6.29301L5.62567 5.15168C5.70718 4.98867 5.93988 4.98867 6.02139 5.15168L6.59206 6.29301C6.76331 6.63549 7.04098 6.91317 7.38347 7.08442L8.5248 7.65509C8.68781 7.7366 8.68781 7.9693 8.5248 8.05081Z'
              fill='#ffffff'
              transform='translate(0 0) scale(20.0)'
            />
          </mask>
          <mask
            styleName='bottomMask'
            id='bottomMask'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width={400}
            height={400}
          >
            <path
              width={400}
              height={400}
              d='M17.5637 13.9204L15.1384 15.1331C14.4106 15.497 13.8205 16.0871 13.4566 16.8149L12.2439 19.2402C12.0707 19.5866 11.5762 19.5866 11.403 19.2402L10.1904 16.8149C9.82646 16.0871 9.2364 15.497 8.50861 15.1331L6.08328 13.9204C5.73689 13.7473 5.73689 13.2528 6.08328 13.0796L8.50861 11.8669C9.2364 11.503 9.82646 10.9129 10.1904 10.1851L11.403 7.7598C11.5762 7.4134 12.0707 7.4134 12.2439 7.7598L13.4566 10.1851C13.8205 10.9129 14.4106 11.503 15.1384 11.8669L17.5637 13.0796C17.9101 13.2527 17.9101 13.7472 17.5637 13.9204Z'
              fill='#ffffff'
              transform='translate(0 0) scale(20.0)'
            />
          </mask>
          <mask
            styleName='topMask'
            id='topMask'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width={400}
            height={400}
          >
            <path
              width={400}
              height={400}
              d='M11.0565 3.19953L10.4858 3.52053C10.3146 3.61686 10.1757 3.77305 10.0901 3.9657L9.80476 4.6077C9.76401 4.69939 9.64766 4.69939 9.6069 4.6077L9.32157 3.9657C9.23594 3.77305 9.09711 3.61686 8.92586 3.52053L8.3552 3.19953C8.27369 3.15368 8.27369 3.02279 8.3552 2.97694L8.92586 2.65594C9.09711 2.55961 9.23594 2.40342 9.32157 2.21077L9.6069 1.56877C9.64766 1.47708 9.76401 1.47708 9.80476 1.56877L10.0901 2.21077C10.1757 2.40342 10.3146 2.55961 10.4858 2.65594L11.0565 2.97694C11.138 3.02279 11.138 3.15368 11.0565 3.19953Z'
              transform='translate(0 0) scale(20.0)'
              fill='#ffffff'
            />
          </mask>
        </defs>
        <g mask='url(#leftMask)'>
          <rect width={400} height={400} fill={colours.left} />
        </g>
        <g mask='url(#bottomMask)'>
          <rect width={400} height={400} fill={colours.bottom} />
        </g>
        <g mask='url(#topMask)'>
          <rect width={400} height={400} fill={colours.top} />
        </g>
      </svg>
    </div>
  );
};

export default CSSModules(Sparkles, styles, {allowMultiple: true});

import {useSelector} from 'react-redux';

import {
  getCustomerFeatureFlags,
  getGroupFeatureFlags,
} from '../state/featureFlags/featureFlagsSelector';

const useFeatureFlag = (flag) => {
  const customerFeatureFlags = useSelector(getCustomerFeatureFlags);
  const groupFeatureFlags = useSelector(getGroupFeatureFlags);
  const featureFlags = {
    ...customerFeatureFlags,
    ...groupFeatureFlags,
  };
  return featureFlags[`${flag}Enabled`] === true;
};

export default useFeatureFlag;

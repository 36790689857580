import * as imageLibraryActionCreators from './imageLibraryActionCreators';
import {RESET_IMAGE_LIBRARY_LOADING} from './imageLibraryActionTypes';

export const addImageToLibrary =
  (url, filename, groupId, width, height) => (dispatch) => {
    dispatch(
      imageLibraryActionCreators.addImageToLibrary(
        url,
        filename,
        groupId,
        width,
        height
      )
    );
  };

export const getImagesFromLibrary = (groupId) => async (dispatch) => {
  await dispatch(imageLibraryActionCreators.getImagesFromLibrary(groupId));
};

export const resetImageLibraryIsLoading = () => (dispatch) => {
  dispatch({type: RESET_IMAGE_LIBRARY_LOADING});
};

export const deleteImageFromLibrary = (groupId, id) => (dispatch) => {
  dispatch(imageLibraryActionCreators.deleteImageFromLibrary(groupId, id));
};

export const trackImageUse = (groupId, imageId) => (dispatch) => {
  dispatch(imageLibraryActionCreators.trackImageUse(groupId, imageId));
};

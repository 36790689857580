import {fromJS} from 'immutable';

import {
  GET_GROUP_OVERVIEW,
  GET_GROUP_OVERVIEW_SUCCESS,
  RESET_GROUP_OVERVIEW,
} from './overviewActionTypes';

export const initialState = fromJS({
  loaded: false,
  loading: false,
  lessonsCompleted: 0,
  activeUsersMonth: 0,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_OVERVIEW_SUCCESS: {
      return state
        .set('loaded', true)
        .set('loading', false)
        .set('lessonsCompleted', action.payload.data.lessonsCompleted)
        .set('activeUsersMonth', action.payload.data.activeUsersMonth)
        .set('cacheUpdatedAt', action.payload.data.cacheUpdatedAt);
    }
    case GET_GROUP_OVERVIEW: {
      return state.set('loaded', false).set('loading', true);
    }

    case RESET_GROUP_OVERVIEW:
      return initialState;

    default:
      return state;
  }
};

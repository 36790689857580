import {removeMultipleWhitespaces} from './textUtil';
import commonPasswords from './wordlists/common-passwords.json';

export {isValidEmbeddedUrl} from './lessonValidation';

export const isUsername = (username: string) => {
  const usernameRegex = /[a-zA-Z0-9._\\-]{5,40}/;
  return usernameRegex.test(username);
};

export const isEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const isEmployeeId = (employeeId: string, minLength = 1) =>
  (employeeId === null && minLength === 0) ||
  (employeeId.length >= minLength && employeeId.length <= 132);

const isCommonPassword = (password: string) =>
  commonPasswords.includes(Buffer.from(password).toString('base64'));

export const isValidNewPassword = (password: string) => {
  const regex = /^(?=.*[a-zA-Z])(?=.*\d).{12,}$/; // 12 characters, at least one letter and one number
  return regex.test(password) && !isCommonPassword(password);
};

const emojiRegex =
  /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{23E9}-\u{23FA}\u{2B50}\u{2000}-\u{3300}\u{1F000}-\u{1F9E0}]/gu;
export const containsEmojis = (text: string) => emojiRegex.test(text);
export const removeEmojis = (text: string) => text.replace(emojiRegex, '');

export const cleanContentTitleForSmsNotifications = (text: string) =>
  removeMultipleWhitespaces(removeEmojis(text).trim());

import React, {ReactNode, useEffect, useRef, useState} from 'react';

import CSSModules from 'react-css-modules';

import Button from '../Button';
import Heading from '../TextComponents/Heading';

import styles from './styles.module.scss';

export const COOKIE_DISMISSED_KEY = 'cookieDismissed';

type CookiePopupProps = {
  headerText: string;
  bodyText: string;
  buttonText: string;
  padRight: boolean;
};

const CookiePopup = ({
  headerText,
  bodyText,
  buttonText,
  padRight,
}: CookiePopupProps): ReactNode => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const hasAlreadyDismissed =
    localStorage.getItem(COOKIE_DISMISSED_KEY) !== null;

  useEffect(() => {
    setIsDisplayed(true);
  }, []);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onDismiss = () => {
    setIsDisplayed(false);
    localStorage.setItem(COOKIE_DISMISSED_KEY, 'true');
  };

  if (hasAlreadyDismissed) {
    return null;
  }

  const height = wrapperRef?.current ? wrapperRef.current.clientHeight : 0;

  return (
    <div
      styleName='popupWrapper'
      style={{
        transform: isDisplayed ? 'none' : `translateY(${height}px)`,
        opacity: isDisplayed ? 1 : 0,
      }}
      ref={wrapperRef}
    >
      <div styleName='popup'>
        <div styleName={`textWrapper${padRight ? ' pad-right' : ''}`}>
          <Heading size='cat' as='h4' colour='white' weight='medium'>
            {headerText}
          </Heading>
          <p>{bodyText}</p>
        </div>
        <div styleName={`buttonWrapper${padRight ? ' pad-right' : ''}`}>
          <Button
            dataAutomation={'cookie-button'}
            type='tooltip'
            onClick={onDismiss}
            noMarginTop={true}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CSSModules(CookiePopup, styles, {allowMultiple: true});

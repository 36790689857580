import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as formActions from '../state/form/formActions';

export default (component) =>
  connect(
    (state, ownProps) => ({
      ...ownProps,
      isFormOpen: state.getIn(['form', 'isFormOpen']),
      formError: state.getIn(['form', 'errorCode']),
    }),
    (dispatch) => ({...bindActionCreators(formActions, dispatch)})
  )(component);

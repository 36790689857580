import React from 'react';

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  page: {
    paddingTop: 64,
    paddingBottom: 96,
    paddingHorizontal: 48,
  },
  qrSection: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamNameSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: '20%',
    aspectRatio: '3/2',
    objectFit: 'contain',
  },
  qrImage: {
    width: 288,
    height: 288,
  },
  guideTitle: {
    marginBottom: 8,
    fontFamily: 'Inter',
    fontSize: 48,
    fontWeight: 800,
  },
  teamIcon: {
    marginRight: 8,
    width: 24,
    height: 24,
  },
  teamName: {
    fontFamily: 'Inter',
    fontSize: 32,
    fontWeight: 300,
    color: '#666',
  },
});

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hjp-Ek-_EeA.woff',
      fontWeight: 800,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfAZ9hjp-Ek-_EeA.woff',
      fontWeight: 300,
    },
  ],
});

const QRCodePDF = ({QRCodeDataUrl, contentTitle, teamName, logo}) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.header}>
        {logo && <Image style={styles.logo} src={logo} />}
      </View>

      <View style={styles.qrSection}>
        <Image style={styles.qrImage} src={QRCodeDataUrl} />
      </View>

      <View style={styles.guideTitleSection}>
        <Text style={styles.guideTitle}>{contentTitle}</Text>
      </View>

      <View style={styles.teamNameSection}>
        <Image style={styles.teamIcon} src='/resources/img/team.png' />
        <Text style={styles.teamName}>{teamName}</Text>
      </View>
    </Page>
  </Document>
);

QRCodePDF.propTypes = {
  QRCodeDataUrl: PropTypes.string,
  contentTitle: PropTypes.string,
  teamName: PropTypes.string,
  logo: PropTypes.string,
};

export default QRCodePDF;

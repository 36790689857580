import {buildRequest} from '../stateUtil';
import {
  CREATE_SURVEY,
  DELETE_SURVEY,
  GET_GROUP_SURVEYS,
  STOP_SURVEY,
} from './surveyActionTypes';

export const getGroupSurveys = (groupId) =>
  buildRequest(GET_GROUP_SURVEYS, 'GET', `/groups/${groupId}/surveys`);

export const stopSurvey = (surveyId) =>
  buildRequest(STOP_SURVEY, 'PUT', `/survey/${surveyId}/finish`);

export const createGroupSurvey = (groupId, survey) =>
  buildRequest(CREATE_SURVEY, 'POST', `/groups/${groupId}/surveys`, survey);

export const deleteSurvey = (surveyId) =>
  buildRequest(DELETE_SURVEY, 'DELETE', `/survey/${surveyId}`);

import PropTypes from 'prop-types';

const lessonAnalytics = PropTypes.shape({
  lessonKey: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired, //-1 if N/A
  totalUsersEnrolled: PropTypes.number.isRequired,
  numUsersCompleted: PropTypes.number.isRequired,
  totalAttempts: PropTypes.number.isRequired,
  quizActivities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      quizType: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          stats: PropTypes.shape({
            totalAttempts: PropTypes.number.isRequired,
            correct: PropTypes.number.isRequired,
          }),
        })
      ).isRequired,
    })
  ).isRequired,
});

export default lessonAnalytics;

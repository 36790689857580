import {fromJS} from 'immutable';

import {UNSUBSCRIBE} from './unsubscribeActionTypes';

export const initialState = fromJS({
  unsubscribeSuccess: false,
  unsubscribeError: false,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UNSUBSCRIBE}`:
      return initialState;
    case `${UNSUBSCRIBE}_SUCCESS`:
      return state.set('unsubscribeSuccess', true);
    case `${UNSUBSCRIBE}_FAIL`:
      return state.set('unsubscribeError', true);
    default:
      return state;
  }
};

import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import ReactTooltip from 'react-tooltip';

import {PenIcon, TickIcon} from '../icons';
import {type HeadingSizes} from '../TextComponents/Heading';
import {TextColours, TextWeights} from '../TextComponents/textUtils';
import {IconProps} from '../types/commonProps';
import {getTooltipDataKey} from '../utils/tooltipUtils';

import styles from './styles.module.scss';

export type TooltipProps = {
  text: string;
  effect: 'float' | 'solid';
  place: 'top' | 'right' | 'bottom' | 'left';
  backgroundColor?: string;
};

type InlineInputProps = {
  value: string;
  onSave: (value: string) => void;
  onEdit?: (isEditing: boolean) => void;
  size: HeadingSizes;
  iconSize?: IconProps['size'];
  colour?: TextColours;
  weight?: TextWeights;
  maxLength?: number;
  tooltipProps?: TooltipProps;
  disabled?: boolean;
  dataAutomationIcon?: string;
  dataAutomationInput?: string;
};

const getIconSize = (size: HeadingSizes): IconProps['size'] => {
  if (['xxl', 'xl', 'l', 'm'].includes(size)) {
    return 'small';
  }
  return 'xsmall';
};

const InlineInput = ({
  value,
  onSave,
  onEdit,
  size,
  iconSize,
  colour = 'textPrimary',
  weight = 'regular',
  maxLength,
  disabled = false,
  tooltipProps,
  dataAutomationIcon,
  dataAutomationInput,
}: InlineInputProps): JSX.Element => {
  const [newValue, setNewValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  // There is no way to use width: min-content or similar with an input element, and the
  // native size prop only works with monospace fonts. So we use a hidden canvas element to
  // calculate the width of the input text (including white space), and then set the width
  // of the input element to that value.
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useLayoutEffect(() => {
    const getTextWidth = (
      canvas: HTMLCanvasElement,
      text: string,
      font: string
    ) => {
      const context = canvas.getContext('2d');
      if (context) {
        context.font = font;
        const {width} = context.measureText(text);
        return Math.ceil(width);
      }
      return null;
    };

    const getCssStyle = (element: HTMLElement, prop: string) =>
      window.getComputedStyle(element, null).getPropertyValue(prop);

    const getCanvasFont = (element: HTMLElement) => {
      const fontWeight = getCssStyle(element, 'font-weight');
      const fontSize = getCssStyle(element, 'font-size');
      const fontFamily = getCssStyle(element, 'font-family');
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    };

    if (canvasRef.current) {
      const styling = getCanvasFont(canvasRef.current);
      const width = getTextWidth(canvasRef.current, newValue, styling);
      if (width) {
        setInputWidth(width);
      }
    }
  }, [setInputWidth, newValue]);

  const onChangeValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (maxLength && e.target.value.length > maxLength) {
      return;
    }
    setNewValue(e.target.value);
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      inputRef.current?.blur();
    }
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const inputValue = e.target.value;
    inputRef.current?.setSelectionRange(inputValue.length, inputValue.length);

    if (inputValue.trim().length) {
      onSave(inputValue);
    } else {
      // Reset to original value if input is empty
      setNewValue(value);
      onSave(value);
    }
    setIsEditing(false);
  };

  const onIconClick = () => {
    if (onEdit) {
      onEdit(isEditing);
    }

    setIsEditing(!isEditing);
    if (!isEditing) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  };

  const tooltip = tooltipProps && (
    <ReactTooltip
      className='iconButtonTooltip'
      delayShow={300}
      id={tooltipProps && getTooltipDataKey(tooltipProps.text)}
      {...tooltipProps}
    >
      {tooltipProps && tooltipProps.text}
    </ReactTooltip>
  );
  const targetTooltipAttributes = tooltipProps
    ? {
        'data-tip': true,
        'data-for': getTooltipDataKey(tooltipProps.text),
      }
    : {};

  const inputStyleNames = classNames(size, colour, weight, {
    editing: isEditing,
  });
  const iconProps: IconProps = {
    size: iconSize || getIconSize(size),
    colour: 'brandPrimary',
  };

  return (
    <div styleName='container'>
      <canvas styleName={`size-calculator ${inputStyleNames}`} ref={canvasRef}>
        {newValue}
      </canvas>
      <input
        ref={inputRef}
        style={{width: `${inputWidth * 1.02}px`}} // Need to avoid text being cut off with large font sizes
        styleName={inputStyleNames}
        type='text'
        value={newValue}
        onChange={onChangeValue}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        dir='inherit'
        data-automation={dataAutomationInput}
        spellCheck={false}
      />
      <div
        styleName={classNames('icon-container', {disabled})}
        onClick={onIconClick}
        data-automation={dataAutomationIcon}
        {...targetTooltipAttributes}
      >
        {isEditing ? <TickIcon {...iconProps} /> : <PenIcon {...iconProps} />}
      </div>
      {tooltip}
    </div>
  );
};

export default CSSModules(InlineInput, styles, {allowMultiple: true});

import React from 'react';

import {useSelector} from 'react-redux';

import {UploadMediaWithThumbnail} from '..';
import {getIsBasicEditingEnabled} from '../../../utils/editingContent';

const UploadCourseMediaWithThumbnail = ({...props}) => {
  const courseId = useSelector((state) =>
    state.getIn(['moduleActivity', 'courseId'])
  );
  const courses = useSelector((state) => state.getIn(['course', 'courses']));
  const course = courses.find((c) => c.get('id') === courseId).toJS();

  const isBasicEditingEnabled = getIsBasicEditingEnabled({course});

  return (
    <UploadMediaWithThumbnail
      isAddingOrRemovingEnabled={isBasicEditingEnabled}
      {...props}
    />
  );
};

export default UploadCourseMediaWithThumbnail;

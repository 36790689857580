// used for Amplitude user props on DS and LFE
const COMPANY = 'company';
const GROUP = 'group';
const TEAM = 'team';

type Roles = {
  isManager: boolean;
  isEditor: boolean;
  isAnalyst: boolean;
  isLead: boolean;
};

type CompanyPermissions = {
  company: Roles;
};

type GroupOrTeamPermissions = {
  group?: {
    roles: Roles;
  }[];
  team?: {
    roles: Roles;
  }[];
};

export const getAdminLevel = (
  permissions: CompanyPermissions | GroupOrTeamPermissions
) => {
  if (COMPANY in permissions && permissions.company) {
    return COMPANY;
  } else if (GROUP in permissions && permissions.group) {
    return GROUP;
  } else {
    return TEAM;
  }
};

export const getRolesFromPermissions = (
  permissions: CompanyPermissions | GroupOrTeamPermissions
) => {
  if (COMPANY in permissions && permissions.company) {
    return permissions.company;
  } else if (GROUP in permissions && permissions.group) {
    return permissions.group[0].roles;
  } else if (TEAM in permissions && permissions.team) {
    return permissions.team[0].roles;
  }
  return {};
};

export const getPermissionsList = (roles: Roles) =>
  Object.entries(roles)
    .filter((permission) => permission[1])
    .map((permission) => permission[0])
    .sort();

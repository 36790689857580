import * as notificationActions from '../notification/notificationActions';
import * as surveyActionCreators from './surveyActionCreators';
import {VIEW_SURVEYS} from './surveyActionTypes';

export const getGroupSurveys = () => (dispatch, getState) => {
  const selectedGroupId = getState().getIn(['team', 'groupId']);
  if (selectedGroupId) {
    dispatch(surveyActionCreators.getGroupSurveys(selectedGroupId)).catch(
      (error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
  }
};

export const stopSurvey = (surveyId) => (dispatch, getState) => {
  dispatch(surveyActionCreators.stopSurvey(surveyId))
    .then(() => getGroupSurveys()(dispatch, getState))
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const createSurvey = (survey) => async (dispatch, getState) => {
  const messageKey = 'Survey.createSurveySuccess';
  try {
    const selectedGroupId = getState().getIn(['team', 'groupId']);
    const {payload} = await dispatch(
      surveyActionCreators.createGroupSurvey(selectedGroupId, survey)
    );
    await getGroupSurveys()(dispatch, getState);
    await notificationActions.createSuccessNotification(messageKey)(dispatch);
    return payload.data.id;
  } catch (error) {
    notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    return null;
  }
};

export const deleteSurvey = (surveyId) => (dispatch, getState) => {
  dispatch(surveyActionCreators.deleteSurvey(surveyId))
    .then(() => getGroupSurveys()(dispatch, getState))
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const viewSurveys = (index) => (dispatch) => {
  dispatch({
    type: VIEW_SURVEYS,
    payload: index,
  });
};

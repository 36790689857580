import React from 'react';

import {FormattedMessage} from 'react-intl';

const NoMatch = () => (
  <div>
    <FormattedMessage id='NoMatch.pageNotFound' />
  </div>
);

export default NoMatch;

import {addMeta, buildRequest} from '../stateUtil';
import {
  ACTIVATE_USER,
  CHECK_EMAIL,
  DISMISS_HINT,
  LOAD_HINTS,
  LOAD_INVITE,
  LOAD_WHOAMI,
  LOGIN,
  LOGIN_WITH_MS_TEAMS,
  LOGIN_WITH_SSO,
  REQUEST_SIGNOUT,
  RESET_PASSWORD_DETAILS,
  SEND_PASSWORD,
  SEND_PASSWORD_RESET,
  UNDISMISS_HINT,
  UPDATE_CUSTOMER,
  UPDATE_PASSWORD,
  UPDATE_USER,
} from './authActionTypes';

export const checkEmail = (email) =>
  buildRequest(CHECK_EMAIL, 'GET', `/auth/checkAdminEmail/${email}`);

export const loginUser = (email, password) =>
  buildRequest(LOGIN, 'POST', '/auth/admin/login', {email, password});

export const loginWithMsTeamsToken = (token) =>
  buildRequest(LOGIN_WITH_MS_TEAMS, 'POST', '/auth/admin/msTeamsLogin', {
    token,
  });

export const loginWithSSO = (redirectUri, authCode, ssoConfigId) =>
  buildRequest(LOGIN_WITH_SSO, 'POST', '/auth/sso/loginAdmin', {
    redirectUri,
    authCode,
    ssoConfigId,
  });

export const whoAmI = () =>
  buildRequest(LOAD_WHOAMI, 'GET', '/auth/admin/whoami');

// Activation
export const getUserFromInvite = (invite, signature, signedUrl) =>
  buildRequest(LOAD_INVITE, 'POST', '/auth/invite/activation', {
    invite,
    signature,
    signedUrl,
  });

export const setPassword = (password, companyAndTokenMeta) =>
  addMeta(
    buildRequest(ACTIVATE_USER, 'PUT', '/user/password', {password}),
    companyAndTokenMeta
  );

export const setNameAndPassword = (
  firstName,
  lastName,
  password,
  companyAndTokenMeta
) =>
  addMeta(
    buildRequest(ACTIVATE_USER, 'PUT', '/user/password', {
      firstName,
      lastName,
      password,
    }),
    companyAndTokenMeta
  );

// Password Reset
export const resetPassword = (resetToken, password) =>
  buildRequest(SEND_PASSWORD, 'PUT', `/user/password/reset/${resetToken}`, {
    password,
  });

export const sendPasswordReset = (inputValue) => {
  const body = {email: inputValue};
  return buildRequest(
    SEND_PASSWORD_RESET,
    'POST',
    '/user/password/reset',
    body
  );
};

export const getUserFromResetToken = (resetToken) =>
  buildRequest(RESET_PASSWORD_DETAILS, 'GET', `/user/reset/${resetToken}`);

export const loadHints = () => buildRequest(LOAD_HINTS, 'GET', '/user/hints');

export const dismissHint = (hintId) =>
  buildRequest(DISMISS_HINT, 'POST', '/user/hints/dismiss', {hintId});

export const undismissHint = (hintId) =>
  buildRequest(UNDISMISS_HINT, 'POST', '/user/hints/undismiss', {hintId});

export const logout = () =>
  buildRequest(REQUEST_SIGNOUT, 'POST', '/auth/logout');

export const updateCustomer = (customerId, updates) =>
  addMeta(
    buildRequest(UPDATE_CUSTOMER, 'PUT', `/customers/${customerId}`, updates),
    {updates}
  );

export const updateUser = (userId, updates) =>
  buildRequest(UPDATE_USER, 'PUT', `/users/${userId}?patch=true`, updates);

export const updatePassword = (password) =>
  buildRequest(UPDATE_PASSWORD, 'PUT', '/user/updatePassword', {password});

/*wraps LegacyHeading, adding a textKey attribute for i18n*/
import React from 'react';

import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import {LegacyHeading} from '@edume/magnificent';

const LegacyIntlHeading = ({text, textKey, values, intl, ...props}) => {
  const displayText = textKey
    ? intl.formatMessage({id: textKey}, values)
    : text;

  return <LegacyHeading {...props}>{displayText}</LegacyHeading>;
};

LegacyIntlHeading.propTypes = {
  text: PropTypes.string,
  textKey: PropTypes.string,
  values: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LegacyIntlHeading);

import React, {ReactNode} from 'react';

import CSSModules from 'react-css-modules';

import {TextColours} from '../textUtils';

import styles from './styles.module.scss';

export const headingTextSizes = [
  'mini-light',
  'mini-dark',
  'mini',
  'micro',
  'tinier',
  'tiny',
  'small',
  'medium',
  'large',
  'xlarge',
] as const;

const textWeights = {
  regular: '',
  medium: 'medium-font',
  bold: 'bold-font',
  extraBold: 'extra-bold-font',
};

export type HeadingTextSizes = (typeof headingTextSizes)[number];
export type HeadingTextWeights = keyof typeof textWeights;

type LegacyHeadingProps = {
  size: HeadingTextSizes;
  children?: ReactNode;
  text?: string;
  weight?: HeadingTextWeights;
  maxLines?: number;
  colour?: TextColours;
  inline?: boolean;
  dataAutomation?: string;
  id?: string;
  overrideColour?: string;
};

const LegacyHeading = ({
  text,
  children,
  size,
  colour = 'textPrimary',
  weight = 'regular',
  maxLines,
  inline,
  dataAutomation,
  id,
  overrideColour,
}: LegacyHeadingProps) => {
  const styleName = `heading ${colour} ${textWeights[weight]} ${size}${
    inline ? ' inline' : ''
  }`;

  const HeadingTag = (() => {
    switch (size) {
      case 'micro':
        return 'h4';
      case 'tiny':
        return 'h3';
      case 'tinier':
        return 'h3';
      case 'small':
        return 'h2';
      case 'medium':
        return 'h1';
      case 'large':
        return 'h1';
      case 'xlarge':
        return 'h1';
      default:
        return 'span';
    }
  })();

  const content = children || text;

  const style: React.CSSProperties = {};
  if (maxLines) {
    style.WebkitLineClamp = maxLines;
    style.WebkitBoxOrient = 'vertical';
    style.display = '-webkit-box';
    style.overflow = 'hidden';
  }
  if (overrideColour) {
    style.color = overrideColour;
  }

  return (
    <HeadingTag
      data-automation={dataAutomation}
      styleName={styleName}
      id={id}
      style={style}
    >
      {content}
    </HeadingTag>
  );
};

export default CSSModules(LegacyHeading, styles, {allowMultiple: true});

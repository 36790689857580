import axios from 'axios';

const isInIframe = window.self !== window.top;

const guidesClient = axios.create({
  baseURL: '/guides-api',
  headers: {
    platform: 'admin-guides',
    'is-in-iframe': isInIframe,
  },
});

export default guidesClient;

import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {areEqual} from 'react-window';

const Cell = memo(({columnIndex, rowIndex, style, data}) => {
  const {content, columnCount} = data;
  const singleColumnIndex = columnIndex + rowIndex * columnCount;
  const image = content[singleColumnIndex];

  return <div style={style}>{image && image}</div>;
}, areEqual);

Cell.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

Cell.displayName = 'Cell';

export default Cell;

// SSU / OTP login field definitions
export const EMAIL_BOTH = 'both'; // email as default
export const TEL_BOTH = 'both-tel'; // telephone as default
export const EMAIL = 'email';
export const TELEPHONE = 'tel';

export const OTP = 'otp';
export const SSU = 'ssu';
export const SSO = 'sso';

export const CONTACT_METHOD_QUERY_PARAM = 'm';
export const COUNTRY_CODE_QUERY_PARAM = 'c';

export const providers = [
  {
    name: 'Figma', //name is used by locale, eg Deathstar LessonCanvas.EditThirdParty.hintText.Figma
    title: 'Figma', //title is used to populate the Deathstar/EditThirdParty/dropdownOptions. Not necessarily the same as title
    urlSubset: 'figma',
    placeholder: () => 'https://www.figma.com/embed?embed_host=share&url=...',
    //add parameter to hide Figma UI
    getUrlInInput: (u: string | null) => `${u}%26hide-ui%3D1`,
    getUrlInIframe: (u: string | null) => u,
  },
  {
    name: 'Matterport',
    title: 'Matterport',
    urlSubset: 'matterport',
    placeholder: () => 'https://my.matterport.com/show/?m=...',
    getUrlInInput: (u: string | null) => u,
    getUrlInIframe: (u: string | null) => u,
  },
  {
    name: 'Kaltura',
    title: 'Kaltura',
    urlSubset: 'kaltura',
    placeholder: () => 'https://cdnapisec.kaltura.com/p/...',
    getUrlInInput: (u: string | null) => u,
    getUrlInIframe: (u: string | null) => u,
  },
  {
    name: 'Mindstamp',
    title: 'Mindstamp',
    urlSubset: 'mindstamp',
    placeholder: () => 'https://embed.mindstamp.io/embed/...',
    getUrlInInput: (u: string | null) => u,
    getUrlInIframe: (u: string | null) => u,
  },
  {
    name: 'HTML5',
    title: 'HTML5 - Ask Customer Success',
    urlSubset: '/lessons/customerActivityContent/',
    placeholder: (windowLocationOrigin: string) =>
      `${windowLocationOrigin}/api/lessons/customerActivityContent/...`,
    //remove url and just leave path that can be used both by DS and LFE via /api proxy
    getUrlInInput: (u: string | null, windowLocationOrigin: string) =>
      u && u.replace(`${windowLocationOrigin}/`, ''),
    getUrlInIframe: (
      u: string | null,
      windowLocationOrigin: string,
      previewToken: string | null
    ) => {
      const attachOrigin = (url: string) => `${windowLocationOrigin}/${url}`;

      const attachToken = (url: string) =>
        previewToken
          ? url?.replace(
              '/lessons/customerActivityContent/',
              `/lessons/customerActivityContent/previewToken/${previewToken}/`
            )
          : url;

      return u && u.includes('/lessons/customerActivityContent/')
        ? attachOrigin(attachToken(u))
        : u;
    },
  },
];

export const getProviderIndex = (u: string | null) =>
  providers.findIndex((p) => u && u.includes(p.urlSubset));

export const getProviderByUrl = (u: string | null) =>
  providers.find((p) => u && u.includes(p.urlSubset));

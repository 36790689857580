export const GET_API_KEYS = 'GET_API_KEYS';
export const GET_API_KEYS_SUCCESS = 'GET_API_KEYS_SUCCESS';
export const GET_API_KEYS_FAIL = 'GET_API_KEYS_FAIL';

export const GENERATE_API_KEY = 'GENERATE_API_KEY';
export const GENERATE_API_KEY_SUCCESS = 'GENERATE_API_KEY_SUCCESS';
export const GENERATE_API_KEY_FAIL = 'GENERATE_API_KEY_FAIL';

export const DELETE_API_KEY = 'DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAIL = 'DELETE_API_KEY_FAIL';

export const GET_SIGNING_SECRETS = 'GET_SIGNING_SECRETS';
export const GET_SIGNING_SECRETS_SUCCESS = 'GET_SIGNING_SECRETS_SUCCESS';
export const GET_SIGNING_SECRETS_FAIL = 'GET_SIGNING_SECRETS_FAIL';

export const GENERATE_SIGNING_SECRET = 'GENERATE_SIGNING_SECRET';
export const GENERATE_SIGNING_SECRET_SUCCESS =
  'GENERATE_SIGNING_SECRET_SUCCESS';
export const GENERATE_SIGNING_SECRET_FAIL = 'GENERATE_SIGNING_SECRET_FAIL';

export const GET_WEBHOOK_CONFIGURATIONS = 'GET_WEBHOOK_CONFIGURATIONS';
export const GET_WEBHOOK_CONFIGURATIONS_FAIL =
  'GET_WEBHOOK_CONFIGURATIONS_FAIL';
export const GET_WEBHOOK_CONFIGURATIONS_SUCCESS =
  'GET_WEBHOOK_CONFIGURATIONS_SUCCESS';

export const GET_WEBHOOK_EVENTS = 'GET_WEBHOOK_EVENTS';
export const GET_WEBHOOK_EVENTS_FAIL = 'GET_WEBHOOK_EVENTS_FAIL';
export const GET_WEBHOOK_EVENTS_SUCCESS = 'GET_WEBHOOK_EVENTS_SUCCESS';

export const CREATE_WEBHOOK = 'CREATE_WEBHOOK';
export const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK';
export const UPDATE_WEBHOOK_EVENTS = 'UPDATE_WEBHOOK_EVENTS';
export const DELETE_WEBHOOK = 'DELETE_WEBHOOK';
export const TEST_WEBHOOK = 'TEST_WEBHOOK';
export const COPY_WEBHOOK_SECRET = 'COPY_WEBHOOK_SECRET';

export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const CREATE_INTEGRATION_SUCCESS = 'CREATE_INTEGRATION_SUCCESS';
export const CREATE_INTEGRATION_FAIL = 'CREATE_INTEGRATION_FAIL';

export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const UPDATE_INTEGRATION_SUCCESS = 'UPDATE_INTEGRATION_SUCCESS';
export const UPDATE_INTEGRATION_FAIL = 'UPDATE_INTEGRATION_FAIL';

export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS';
export const DELETE_INTEGRATION_FAIL = 'DELETE_INTEGRATION_FAIL';

export const GET_INTEGRATION_CREDENTIALS = 'GET_INTEGRATION_CREDENTIALS';
export const GET_INTEGRATION_CREDENTIALS_SUCCESS =
  'GET_INTEGRATION_CREDENTIALS_SUCCESS';
export const GET_INTEGRATION_CREDENTIALS_FAIL =
  'GET_INTEGRATION_CREDENTIALS_FAIL';

export const OPEN_MS_TEAMS_MODAL = 'OPEN_MS_TEAMS_MODAL';
export const CLOSE_MS_TEAMS_MODAL = 'CLOSE_MS_TEAMS_MODAL';

export const GENERATE_FOUNTAIN_KEYS = 'GENERATE_FOUNTAIN_KEYS';
export const GENERATE_FOUNTAIN_KEYS_SUCCESS = 'GENERATE_FOUNTAIN_KEYS_SUCCESS';
export const GENERATE_FOUNTAIN_KEYS_FAIL = 'GENERATE_FOUNTAIN_KEYS_FAIL';

export const UPDATE_FOUNTAIN_KEYS = 'UPDATE_FOUNTAIN_KEYS';
export const UPDATE_FOUNTAIN_KEYS_SUCCESS = 'UPDATE_FOUNTAIN_KEYS_SUCCESS';
export const UPDATE_FOUNTAIN_KEYS_FAIL = 'UPDATE_FOUNTAIN_KEYS_FAIL';

export const GET_CUSTOMER_BRANDING = 'GET_CUSTOMER_BRANDING';
export const RESET_CUSTOMER_BRANDING = 'RESET_CUSTOMER_BRANDING';
export const UPDATE_CUSTOMER_BRANDING = 'UPDATE_CUSTOMER_BRANDING';
export const UPDATE_CUSTOMER_BRANDING_SUCCESS =
  'UPDATE_CUSTOMER_BRANDING_SUCCESS';
export const UPDATE_GROUP_BRANDING = 'UPDATE_GROUP_BRANDING';
export const UPDATE_GROUP_BRANDING_SUCCESS = 'UPDATE_GROUP_BRANDING_SUCCESS';
export const GET_GROUP_BRANDING = 'GET_GROUP_BRANDING';

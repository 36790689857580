/* eslint-disable max-params */
import {addMeta, buildRequest} from '../stateUtil';
import {
  CHECK_COMPANY_NAME,
  CHECK_TRIAL_EMAIL,
  SET_PREFERRED_EMAIL,
  START_TRIAL,
} from './trialActionTypes';

export const startTrial = ({
  companyName,
  email,
  password,
  resourceType,
  resourceKey,
  integrationsUsed,
}) =>
  addMeta(
    buildRequest(START_TRIAL, 'POST', '/trial', {
      companyName,
      email,
      password,
      resourceType,
      resourceKey,
    }),
    {integrationsUsed: integrationsUsed?.map(({value}) => value) || []}
  );

export const setPreferredEmail = ({preferredEmail}) =>
  buildRequest(SET_PREFERRED_EMAIL, 'PUT', '/msTeams/preferredEmail', {
    preferredEmail,
  });

export const checkTrialUserEmail = (email) =>
  buildRequest(CHECK_TRIAL_EMAIL, 'GET', `/trial/checkEmail/${email}`);

export const checkCompanyName = (companyName) =>
  buildRequest(
    CHECK_COMPANY_NAME,
    'GET',
    `/trial/checkCompanyName/${companyName}`
  );

import {fromJS, OrderedMap} from 'immutable';

import {ERROR, types} from '../../utils/notificationTypes';
import {ADD_NOTIFICATION, DELETE_NOTIFICATION} from './notificationActionTypes';

export const initialState = fromJS({
  notifications: OrderedMap([]),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const notification = {
        ...action.payload,
      };
      if (types.indexOf(notification.type) === -1) {
        console.warn(`Requested notification type ${notification.type} which is invalid.
          Using 'ERROR' instead.`);
        notification.type = ERROR;
      }
      const oldNotifications = state.get('notifications');
      return state.set(
        'notifications',
        oldNotifications.set(notification.id, notification)
      );
    }
    case DELETE_NOTIFICATION: {
      const id = action.payload;
      const oldNotifications = state.get('notifications');
      return state.set('notifications', oldNotifications.delete(id));
    }
    default:
      return state;
  }
};

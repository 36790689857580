import React from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import {INSPIRATION} from '../../../enums/overview';
import {copyContent} from '../../../state/content/contentActions';
import {goToResources} from '../../../state/course/courseActions';
import {injectFeatureFlags} from '../../../utils/featureFlags';
import OverviewCourseList from '../OverviewCourseList';

const OverviewInspiration = ({resourceLibraryCourses = [], groupId}) => {
  const dispatch = useDispatch();
  const shuffledCourses = resourceLibraryCourses.sort(
    () => 0.5 - Math.random()
  );
  const coursesToShow = shuffledCourses.slice(0, 2);

  const onLinkClick = () => {
    dispatch(goToResources(INSPIRATION));
  };

  const handleCopyAndGoToCourse = (course) => {
    dispatch(
      copyContent({
        content: course,
        groupIds: [groupId],
        currentGroupId: groupId,
        copiedFrom: course.label,
        shouldGoToContent: true,
        source: INSPIRATION,
      })
    );
  };

  return (
    <OverviewCourseList
      courses={coursesToShow}
      type={INSPIRATION}
      onLinkClick={onLinkClick}
      onCourseCardClick={handleCopyAndGoToCourse}
    />
  );
};

OverviewInspiration.propTypes = {
  resourceLibraryCourses: PropTypes.array,
  groupId: PropTypes.number.isRequired,
};

export default injectFeatureFlags(OverviewInspiration);

import {connect} from 'react-redux';

import {getSelectedGroupId} from '../state/team/teamSelectors';

export const injectFeatureFlags = (component) => {
  const mapStateToProps = (state) => {
    const groupId = getSelectedGroupId(state);
    const customerFlags = state.getIn(['featureFlags', 'customerFlags']);
    const groupFlags = state.getIn(['featureFlags', 'groupFlags', groupId]);
    return {
      featureFlags: {...customerFlags, ...groupFlags},
    };
  };

  return connect(mapStateToProps)(component);
};

import {
  ACTIVATE_USER_SUCCESS,
  LOGGED_IN,
  LOGGED_IN_MS_TEAMS,
} from '../state/auth/authActionTypes';
import {SET_AMPLITUDE_TEST_PROPS} from '../state/multivariantTests/multivariantTestActionTypes';
import {SELECT_GROUP} from '../state/people/peopleActionTypes';
import {LOAD_TEAMS_SUCCESS} from '../state/team/teamActionTypes';
import {LOG_VIEWPORT_SIZE_CHANGE} from '../state/tracking/trackingActions';
import {
  sendUserTests,
  setAppcuesUserProps,
  setInitialUserProps,
  setUserGroup,
  setUserLoginType,
  setUserViewportSize,
} from './trackingFunctions';
import {DIRECT, MS_TEAMS} from './trackingProps';

let isSetUserGroupRequired = false;

// eslint-disable-next-line complexity
export const sendTrackingUserProps = (action, state) => {
  switch (action.type) {
    case LOGGED_IN:
    case ACTIVATE_USER_SUCCESS:
      isSetUserGroupRequired = true;
      setAppcuesUserProps(state);
      setUserLoginType(DIRECT);
      return setInitialUserProps(state);

    case SELECT_GROUP:
      return setUserGroup(action.payload, state);

    case LOAD_TEAMS_SUCCESS:
      if (isSetUserGroupRequired) {
        setUserGroup(null, state);
        isSetUserGroupRequired = false;
      }
      return null;

    case SET_AMPLITUDE_TEST_PROPS: {
      const tests = state.getIn(['multivariantTests', 'testNames']).toJS();
      const variants = state.getIn(['multivariantTests', 'variantIds']).toJS();
      return sendUserTests(tests, variants);
    }

    case LOG_VIEWPORT_SIZE_CHANGE:
      return setUserViewportSize();

    case LOGGED_IN_MS_TEAMS:
      return setUserLoginType(MS_TEAMS);

    default:
      return null;
  }
};

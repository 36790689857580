import {
  DATE_RANGE_SELECTED,
  MODIFIED_COMPANY_LEVEL_REPORT,
} from '../../tracking/trackingProps';

export const trackingActionTypes = {
  TRIAL_SIGNUP_START: 'TRIAL_SIGNUP_START',
  TRIAL_EMAIL_ALREADY_EXISTS: 'TRIAL_EMAIL_ALREADY_EXIST',
  GO_TO_TRIAL_FORM_COMPANY: 'GO_TO_TRIAL_FORM_COMPANY',
  TRIAL_ONBOARD: 'TRIAL_ONBOARD',
  TRIAL_ONBOARD_END: 'TRIAL_ONBOARD_END',
  TRIAL_ONBOARD_SKIP: 'TRIAL_ONBOARD_SKIP',
  TRIAL_CREATE_SAMPLE_COURSE_MODAL_YES: 'TRIAL_CREATE_SAMPLE_COURSE_MODAL_YES',
  TRIAL_CREATE_SAMPLE_COURSE_MODAL_NO: 'TRIAL_CREATE_SAMPLE_COURSE_MODAL_NO',
  VIEWED_CONTENT_CREATION_GUIDE: 'VIEWED_CONTENT_CREATION_GUIDE',
};

export const LOG_VIEWPORT_SIZE_CHANGE = 'LOG_VIEWPORT_SIZE_CHANGE';
export const ID_COPIED = 'ID_COPIED';

export const track = (actionType, payload) => (dispatch) => {
  if (Object.values(trackingActionTypes).includes(actionType)) {
    dispatch({type: actionType, payload});
  } else {
    throw new Error('Invalid tracking action');
  }
};

export const logViewportSizeChange = () => (dispatch) =>
  dispatch({type: LOG_VIEWPORT_SIZE_CHANGE});

export const trackIdCopied = (payload) => (dispatch) =>
  dispatch({type: ID_COPIED, payload});

export const trackDateRangeSelected = (payload) => (dispatch) => {
  dispatch({type: DATE_RANGE_SELECTED, payload});
};

export const trackModifiedCompanyLevelReport = (payload) => (dispatch) => {
  dispatch({type: MODIFIED_COMPANY_LEVEL_REPORT, payload});
};

import {fromJS} from 'immutable';

import {
  ADD_REMOVE_USER_FROM_TEAMS,
  ADD_REMOVE_USER_FROM_TEAMS_FAIL,
  ADD_REMOVE_USER_FROM_TEAMS_SUCCESS,
  ADD_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_FAIL,
  EDIT_USER_SUCCESS,
  LOAD_USER_DETAILS,
  LOAD_USER_DETAILS_SUCCESS,
  LOAD_USERS,
  LOAD_USERS_FAIL,
  LOAD_USERS_SEARCH,
  LOAD_USERS_SEARCH_FAIL,
  LOAD_USERS_SEARCH_SUCCESS,
  LOAD_USERS_SUCCESS,
  REMOVE_USER_FROM_TEAM_SUCCESS,
  RESET_SEARCH,
  RESET_SELECTED_TEAM,
  RESET_SELECTED_USER,
  RESET_USERS_STATE,
  SELECT_GROUP,
  SET_SELECTED_TEAM,
  SET_SELECTED_USER,
  UPDATE_SEARCH_QUERY,
} from './peopleActionTypes';

export const initialState = fromJS({
  groupUsers: {}, //TODO thoroughly check the effect on the *teams* tab of searching, deleting, etc
  groupUsersLoading: false,
  updateUserInProgress: false,
  isAssigningUser: false,
  numUsersMatchingQuery: 0,
  searchQuery: '',
  selectedTeamId: null,
  selectedUserId: null,
  selectedUserDetails: null,
  selectedUserDetailsLoading: false,
  hasRequestedNewUser: false,
});

const processLoadedUsersAction = (state) => (meta, users, totalUsers) => {
  const {groupId, pageIndex, pageFilters, silent} = meta;
  const newState = state
    .set('groupUsersLoading', false)
    .set('numUsersMatchingQuery', totalUsers)
    .setIn(['groupUsers', groupId, 'users', pageIndex], users)
    .setIn(['groupUsers', groupId, 'usersPageFilters'], pageFilters);
  if (silent) {
    return newState;
  } else {
    return newState.setIn(['groupUsers', groupId, 'usersPageIndex'], pageIndex);
  }
};

// eslint-disable-next-line complexity
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SELECTED_USER: {
      return state.set('selectedUserId', null);
    }

    case RESET_SELECTED_TEAM: {
      return state.set('selectedTeamId', null);
    }

    case LOAD_USER_DETAILS: {
      return state.set('selectedUserDetailsLoading', true);
    }

    case LOAD_USER_DETAILS_SUCCESS: {
      return state
        .set('selectedUserDetails', action.payload.data)
        .set('selectedUserDetailsLoading', false);
    }

    case SET_SELECTED_TEAM: {
      return state.set('selectedTeamId', action.payload.id);
    }

    case SET_SELECTED_USER: {
      return state.set('selectedUserId', action.payload.id);
    }

    case UPDATE_SEARCH_QUERY: {
      return state.set('searchQuery', action.payload);
    }
    case RESET_SEARCH: {
      const {groupId} = action.payload;
      return state
        .set('searchQuery', '')
        .set('numUsersMatchingQuery', 0)
        .deleteIn(['groupUsers', groupId]);
    }

    case LOAD_USERS:
    case LOAD_USERS_SEARCH: {
      return state
        .set('groupUsersLoading', true)
        .set('numUsersMatchingQuery', 0);
    }

    case LOAD_USERS_SUCCESS: {
      return processLoadedUsersAction(state)(
        action.meta,
        action.payload.data,
        0
      );
    }

    case LOAD_USERS_SEARCH_SUCCESS: {
      const {users, totalUsers} = action.payload.data;
      return processLoadedUsersAction(state)(action.meta, users, totalUsers);
    }

    case LOAD_USERS_FAIL:
    case LOAD_USERS_SEARCH_FAIL: {
      return state.set('groupUsersLoading', false);
    }
    case RESET_USERS_STATE: {
      const {groupId} = action.payload;
      return state.deleteIn(['groupUsers', groupId]);
    }

    case SELECT_GROUP: {
      return state
        .set('usersPageIndex', 0)
        .set('searchQuery', '')
        .set('numUsersMatchingQuery', 0);
    }

    case ADD_USER:
    case EDIT_USER: {
      return state.set('updateUserInProgress', true);
    }
    case ADD_USER_SUCCESS:
    case ADD_USER_FAIL:
    case EDIT_USER_SUCCESS:
    case EDIT_USER_FAIL: {
      return state.set('updateUserInProgress', false);
    }

    case ADD_REMOVE_USER_FROM_TEAMS:
      return state.set('isAssigningUser', true);

    case ADD_REMOVE_USER_FROM_TEAMS_SUCCESS:
    case ADD_REMOVE_USER_FROM_TEAMS_FAIL:
      return state.set('isAssigningUser', false);

    case REMOVE_USER_FROM_TEAM_SUCCESS: {
      const {userId, teamId} = action.meta;
      const teamUsersPages = state.getIn(['groupUsers', teamId, 'users']);
      const updatedPages = teamUsersPages.map((page) =>
        page.filter((user) => user.id !== userId)
      );

      return state.setIn(['groupUsers', teamId, 'users'], updatedPages);
    }

    case DELETE_USER_SUCCESS: {
      let newState = state;

      const filterUsers = (user) => user.id !== action.meta.userId;
      const groupUsers = state.get('groupUsers');

      groupUsers.forEach((group, groupId) => {
        const teamUsersPages = group.getIn(['users']);
        const updatedPages = teamUsersPages.map((page) =>
          page.filter(filterUsers)
        );
        newState = newState.setIn(
          ['groupUsers', groupId, 'users'],
          updatedPages
        );
      });

      return newState;
    }

    default: {
      return state;
    }
  }
};

import {ReactElement, ReactNode} from 'react';

import CSSModules from 'react-css-modules';

import {
  renderText,
  TextColours,
  TextTransforms,
  TextWeights,
} from '../textUtils';

import styles from './styles.module.scss';

export const textSizes = ['xs', 's', 'm', 'l', 'xl'] as const;
export type TextSizes = (typeof textSizes)[number];

export type LineHeight = 'standard' | 'tight' | 'loose';

type TextProps = {
  children: ReactNode;
  /** xl: 20px, l: 18px, m: 16px, s: 14px, xs: 12px */
  size: TextSizes;
  /** Text colour
   * @default textPrimary
   */
  colour?: TextColours;
  /** Font weight
   * @default regular
   * */
  weight?: TextWeights;
  /** <p> used by default. Set to true for <span>
   * @default false
   */
  inline?: boolean;
  /** @default standard */
  lineHeight?: LineHeight;
  /** Max number of lines.
   * Overflow text is truncated with an ellipsis ...
   */
  maxLines?: number;
  /** @default none */
  textTransform?: TextTransforms;
  /** HTML ID */
  id?: string;
  /** Any valid CSS colour value */
  overrideColour?: string;
  dataAutomation?: string;
};

const Text = ({
  children,
  size,
  colour = 'textPrimary',
  weight = 'regular',
  inline = false,
  lineHeight = 'standard',
  maxLines,
  textTransform = 'none',
  id,
  overrideColour,
  dataAutomation,
}: TextProps): ReactElement => {
  const styleName = `text ${size} ${colour} ${weight} ${lineHeight} ${textTransform}`;
  const style = {...(overrideColour && {color: overrideColour})};

  const tag = inline ? 'span' : 'p';

  return renderText({
    children,
    tag,
    styleName,
    maxLines,
    id,
    style,
    dataAutomation,
  });
};

export default CSSModules(Text, styles, {allowMultiple: true});

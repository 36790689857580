import {fromJS} from 'immutable';

import {COLLECTION_PREFIX} from '../../components/LearningTab';
import {COURSE} from '../../enums/contentTypes';
import {sortContentByCreationDate} from '../../utils/courseUtil';
import {getLessonTypeByVersion} from '../../utils/lesson';
import {CREATE_COLLECTION_SUCCESS} from '../collections/collectionsActionTypes';
import {
  COMMAND_PALETTE_CLEAR_NEW_ACTIVITY,
  COMMAND_PALETTE_NEW_ACTIVITY,
} from '../commandPalette/commandPaletteActionTypes';
import {
  COPY_CONTENT,
  COPY_CONTENT_FAIL,
  COPY_CONTENT_SUCCESS,
  GET_SCHEDULED_NOTIFICATIONS_SUCCESS,
  LOAD_CONTENTS,
  LOAD_CONTENTS_SUCCESS,
} from '../content/contentActionTypes';
import {
  RESET_COURSE_NOTIFICATIONS_NEW,
  SEND_COURSE_NOTIFICATIONS_NEW,
  SEND_COURSE_NOTIFICATIONS_NEW_FAIL,
  SEND_COURSE_NOTIFICATIONS_NEW_SUCCESS,
} from '../learnerNotification/learnerNotificationActionTypes';
import {
  ADD_LEARNING_CARD_SUCCESS,
  DELETE_LEARNING_CARD_SUCCESS,
} from '../learningCard/learningCardActionTypes';
import {GENERATE_LESSONS_FROM_TOPICS_SUCCESS} from '../moduleActivity/moduleActivityActionTypes';
import {
  ADD_COURSE,
  ADD_COURSE_FAIL,
  ADD_COURSE_SUCCESS,
  ADD_LESSON_SUCCESS,
  ASSIGN_COURSE_TO_GROUPS,
  ASSIGN_COURSE_TO_GROUPS_SUCCESS,
  CHANGE_COURSE_TAB,
  CLEAR_IMPORT_ERROR,
  CONTENT_FILTERS,
  COPY_DEMO_COURSE_TO_CUSTOMER,
  COPY_DEMO_COURSE_TO_CUSTOMER_SUCCESS,
  COPY_LESSON_SUCCESS,
  COURSE_TABS,
  CREATE_MODULE_SURVEY_SUCCESS,
  CREATE_TEMPLATE_FROM_COURSE,
  CREATE_TEMPLATE_FROM_COURSE_SUCCESS,
  DELETE_COURSE,
  DELETE_COURSE_FAIL,
  DELETE_COURSE_SUCCESS,
  DELETE_LESSON_SUCCESS,
  DELETE_MODULE_SURVEY_SUCCESS,
  DELETE_TRANSLATION_SUCCESS,
  DISMISS_REASSIGN_COURSE_CSV_UPLOAD_ERROR,
  EXPORT_COURSE_TO_GSHEET,
  EXPORT_COURSE_TO_GSHEET_SUCCESS,
  GET_BAMBOO_TRAININGS_FAIL,
  GET_BAMBOO_TRAININGS_SUCCESS,
  IMPORT_COURSE_FROM_GSHEET,
  IMPORT_COURSE_FROM_GSHEET_SUCCESS,
  LOAD_COURSE,
  LOAD_COURSE_SUCCESS,
  LOAD_DEMO_CONTENT_COURSES,
  LOAD_DEMO_CONTENT_COURSES_FAIL,
  LOAD_DEMO_CONTENT_COURSES_SUCCESS,
  LOAD_RESOURCE_LIBRARY_COURSES,
  LOAD_RESOURCE_LIBRARY_COURSES_SUCCESS,
  PUBLISH_COURSE_SUCCESS,
  REASSIGN_COURSE,
  REASSIGN_COURSE_FAIL,
  REASSIGN_COURSE_SUCCESS,
  REDIRECT_COMPLETED,
  REDIRECT_IN_PROGRESS,
  RESET_COURSE_LOADED,
  SET_ACTIVE_SIDEBAR_ITEM,
  SET_CONTENT_FILTER,
  SET_IS_USER_ADDING_CONTENT,
  SET_IS_USER_ADDING_COURSE,
  SET_PAGINATION_TAB,
  SIDEBAR_TABS,
  TOGGLE_COURSE_NOTIFICATIONS_SLIDEPANEL,
  TOGGLE_COURSE_OTP_SLIDEPANEL,
  TOGGLE_SIDEBAR_CREATION_EXPANSION,
  TRANSLATE_COURSE_FAIL,
  TRANSLATE_COURSE_SUCCESS,
  UNPUBLISH_COURSE_SUCCESS,
  UPDATE_COURSE,
  UPDATE_COURSE_FAIL,
  UPDATE_COURSE_HIDDEN_TITLE_SLIDE,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_TIMESTAMPS,
  UPDATE_DEFAULT_LANGUAGE_SUCCESS,
} from './courseActionTypes';

export const initialState = fromJS({
  courses: [],
  coursesLoaded: false,
  editCourseInProgress: false,
  hasCopyCourseSuccessMessageVisible: false,
  hasCourseDuplicationFailed: false,
  deleteCourseInProgress: false,
  reassignCourseInProgress: false,
  isAssigning: false,
  reassignCourseUploadCsvError: null,
  reassignCourseResults: [],
  selectedCourseTab: COURSE_TABS.LESSONS,
  redirectInProgress: false,
  exportCourseInProgress: false,
  importCourseInProgress: false,
  importCourseError: null,
  courseLoaded: false,
  createTemplateInProgress: false,
  resourceLibraryCourses: [],
  resourceLibraryCoursesLoaded: false,
  demoContentCourses: [],
  demoContentCoursesLoaded: false,
  lastCopiedCourse: null,
  activeSidebarItem: SIDEBAR_TABS.STANDARD_CONTENT,
  activeContentFilter: CONTENT_FILTERS.ALL,
  isSidebarCreationExpanded: true,
  isUserAddingContent: false,
  isUserAddingCourse: false,
  isNotificationsSlidePanelVisible: false,
  isContentLinksSlidePanelVisible: false,
  learnerNotificationsSending: false,
  learnerNotificationsSent: false,
  sentLearnerNotifications: {
    email: null,
    sms: null,
    msTeams: null,
    speakap: null,
  },
  learnerNotificationsError: false,
  scheduledNotifications: {},
});

const updateCourse = (courseKey, newCourse, state) => {
  const {
    courseImageUrl,
    title,
    icon,
    colour,
    type,
    requiresEnrolment,
    isFountainOnly,
    waitSendCompletionData,
    isAutostartEnabled,
    isSequentialCompletionsEnabled,
  } = newCourse;

  const courses = state.get('courses');
  const courseIndex = courses.findIndex(
    (course) => course.get('courseKey') === courseKey
  );

  return state.set(
    'courses',
    courses
      .setIn([courseIndex, 'updatedAt'], new Date())
      .setIn([courseIndex, 'courseImageUrl'], courseImageUrl)
      .setIn([courseIndex, 'requiresEnrolment'], requiresEnrolment)
      .setIn([courseIndex, 'waitSendCompletionData'], waitSendCompletionData)
      .setIn([courseIndex, 'title'], title)
      .setIn([courseIndex, 'icon'], icon)
      .setIn([courseIndex, 'colour'], colour)
      .setIn([courseIndex, 'type'], type)
      .setIn([courseIndex, 'isFountainOnly'], isFountainOnly)
      .setIn([courseIndex, 'isAutostartEnabled'], isAutostartEnabled)
      .setIn(
        [courseIndex, 'isSequentialCompletionsEnabled'],
        isSequentialCompletionsEnabled
      )
  );
};

const updateDefaultLanguage = (
  courseId,
  defaultLanguage,
  previousDefaultLanguage,
  state
) => {
  const courses = state.get('courses');
  const courseIndex = courses.findIndex(
    (course) => course.get('id') === courseId
  );
  const courseLanguages = courses
    .getIn([courseIndex, 'languages'])
    .filter((language) => language !== previousDefaultLanguage)
    .push(defaultLanguage);

  return state.set(
    'courses',
    courses
      .setIn([courseIndex, 'defaultLanguage'], defaultLanguage)
      .setIn([courseIndex, 'languages'], courseLanguages)
  );
};

const translateCourse = (courseId, language, state) => {
  const courses = state.get('courses');
  const courseIndex = courses.findIndex(
    (course) => course.get('id') === courseId
  );
  const course = courses.get(courseIndex);

  const languages = course.get('languages').toJS();
  languages.push(language);

  return state
    .setIn(['courses', courseIndex, 'languages'], fromJS(languages))
    .setIn(['courses', courseIndex, 'isMultiLanguage'], true);
};

const getCourseIndexFromModuleId = (courses, moduleId) =>
  courses.findIndex((course) =>
    course.get('modules').find((module) => module.get('id') === moduleId)
  );

//eslint-disable-next-line complexity, max-statements
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COURSE: {
      return state.set('editCourseInProgress', true);
    }
    case COPY_CONTENT: {
      const {contentType, currentGroupId, groupIds} = action.meta;
      if (contentType === COURSE) {
        if (groupIds.length === 1 && groupIds.includes(currentGroupId)) {
          //This means we are copying to the same group, using the Make a copy option
          return state
            .set('editCourseInProgress', true)
            .set('hasCourseDuplicationFailed', false);
        }
        return state.set('editCourseInProgress', true);
      }
      return state;
    }
    case ADD_COURSE_SUCCESS: {
      const courses = state.get('courses');
      return state
        .set('editCourseInProgress', false)
        .set('courses', courses.insert(0, fromJS(action.payload.data)));
    }
    case COPY_CONTENT_SUCCESS: {
      const {contentType, currentGroupId, groupIds} = action.meta;
      if (contentType === COURSE) {
        if (groupIds.length === 1 && groupIds.includes(currentGroupId)) {
          //This means we are copying to the same group, using the Make a copy option
          const courses = state.get('courses');
          const insertedCourses = fromJS(action.payload.data);
          return state
            .set('editCourseInProgress', false)
            .set('lastCopiedCourse', insertedCourses.get(0).toJS())
            .set('courses', courses.insert(0, insertedCourses.get(0)));
        }
        return state.set('editCourseInProgress', false);
      }
      return state;
    }
    case COPY_DEMO_COURSE_TO_CUSTOMER: {
      return state.set('editCourseInProgress', true);
    }
    case COPY_DEMO_COURSE_TO_CUSTOMER_SUCCESS: {
      const duplicatedCourse = action.payload.data;
      return state
        .set('editCourseInProgress', false)
        .set('lastCopiedCourse', duplicatedCourse);
    }
    case ADD_COURSE_FAIL: {
      return state.set('editCourseInProgress', false);
    }
    case COPY_CONTENT_FAIL: {
      const {contentType} = action.meta;
      if (contentType === COURSE) {
        return state
          .set('editCourseInProgress', false)
          .set('hasCourseDuplicationFailed', true);
      }
      return state;
    }
    case LOAD_COURSE: {
      return state.set('courseLoaded', false);
    }
    case LOAD_CONTENTS: {
      return state.set('coursesLoaded', false);
    }

    case CHANGE_COURSE_TAB: {
      return state.set('selectedCourseTab', action.payload.tab);
    }

    case LOAD_COURSE_SUCCESS: {
      const returnedCourse = action.payload.data;

      const courses = state.get('courses');
      const courseIndex = courses.findIndex(
        (course) => course.get('id') === returnedCourse.id
      );

      const hasTitle = courses.getIn([courseIndex, 'title']);
      if (hasTitle) {
        delete returnedCourse.title;
      }

      return state
        .set('courses', courses.mergeIn([courseIndex], fromJS(returnedCourse)))
        .set('courseLoaded', true);
    }

    case RESET_COURSE_LOADED: {
      return state.set('courseLoaded', false);
    }

    case LOAD_CONTENTS_SUCCESS: {
      const {courses} = action.payload.data;
      const sorted = sortContentByCreationDate(courses, 'title');
      return state.set('courses', fromJS(sorted)).set('coursesLoaded', true);
    }

    case DELETE_COURSE: {
      return state.set('deleteCourseInProgress', true);
    }

    case DELETE_COURSE_SUCCESS: {
      const deletedKey = action.meta.courseKey;
      return state.set('deleteCourseInProgress', false).set(
        'courses',
        state
          .get('courses')
          .filter((course) => course.get('courseKey') !== deletedKey)
      );
    }

    case DELETE_COURSE_FAIL: {
      return state.set('deleteCourseInProgress', false);
    }

    case UPDATE_COURSE: {
      const stateWithInProgress = state.set('editCourseInProgress', true);
      return action.meta.earlyUpdate
        ? updateCourse(
            action.meta.courseKey,
            action.meta.course,
            stateWithInProgress
          )
        : stateWithInProgress;
    }
    case UPDATE_COURSE_FAIL: {
      const innerAction = action.payload.action;
      const stateWithInProgress = state.set('editCourseInProgress', false);
      return innerAction.meta.earlyUpdate
        ? updateCourse(
            innerAction.meta.courseKey,
            innerAction.meta.previousDetails,
            stateWithInProgress
          )
        : stateWithInProgress;
    }
    case UPDATE_COURSE_SUCCESS: {
      return updateCourse(
        action.meta.courseKey,
        action.meta.course,
        state.set('editCourseInProgress', false)
      );
    }
    case UPDATE_COURSE_HIDDEN_TITLE_SLIDE: {
      const {courseKey, hideTitleSlide} = action.meta;
      const courses = state.get('courses');
      const courseIndex = courses.findIndex(
        (course) => course.get('courseKey') === courseKey
      );
      return state.set(
        'courses',
        courses.setIn([courseIndex, 'hideTitleSlide'], hideTitleSlide)
      );
    }

    case UPDATE_DEFAULT_LANGUAGE_SUCCESS: {
      return updateDefaultLanguage(
        action.meta.courseId,
        action.meta.defaultLanguage,
        action.meta.previousDefaultLanguage,
        state
      );
    }
    case TRANSLATE_COURSE_FAIL: {
      const innerAction = action.payload.action;
      return translateCourse(
        innerAction.meta.courseId,
        innerAction.meta.language,
        state
      );
    }
    case TRANSLATE_COURSE_SUCCESS: {
      return translateCourse(action.meta.courseId, action.meta.language, state);
    }

    case DELETE_TRANSLATION_SUCCESS: {
      const courseId = action.meta.courseId;
      const languageCode = action.meta.language;

      const courses = state.get('courses');
      const courseIndex = courses.findIndex(
        (course) => course.get('id') === courseId
      );

      const remainingLanguages = courses
        .getIn([courseIndex, 'languages'])
        .filter((code) => code !== languageCode);

      const newCourses =
        remainingLanguages.size === 1
          ? courses.setIn([courseIndex, 'isMultiLanguage'], false)
          : courses;

      return state.set(
        'courses',
        newCourses.setIn([courseIndex, 'languages'], remainingLanguages)
      );
    }

    case COPY_LESSON_SUCCESS:
    case ADD_LESSON_SUCCESS: {
      const {lessonKey} = action.payload.data;
      const {courseId, lessonType} = action.meta;
      const courses = state.get('courses');
      const courseIndex = courses.findIndex(
        (course) => course.get('id') === courseId
      );

      return state.set(
        'courses',
        courses.update(courseIndex, (course) =>
          course
            .update('lessonKeys', (list) => list.push(lessonKey))
            .update('numModuleActivities', (n) => n + 1)
            .set('lessonsType', lessonType)
            .set('updatedAt', new Date())
        )
      );
    }

    case GENERATE_LESSONS_FROM_TOPICS_SUCCESS: {
      const {moduleId} = action.meta;
      const courses = state.get('courses');
      const courseIndex = getCourseIndexFromModuleId(courses, moduleId);

      const lessonKeys = action.payload.data.map((lesson) => lesson.lessonKey);
      const lessonType = getLessonTypeByVersion(
        action.payload.data[0]?.content?.version
      );

      return state.set(
        'courses',
        courses.update(courseIndex, (course) =>
          course
            .update('lessonKeys', (list) => list.push(...lessonKeys))
            .update('numModuleActivities', (n) => n + lessonKeys.length)
            .set('lessonsType', lessonType)
            .set('updatedAt', new Date())
        )
      );
    }

    case CREATE_MODULE_SURVEY_SUCCESS: {
      const {moduleId} = action.meta;
      const courses = state.get('courses');
      const courseIndex = getCourseIndexFromModuleId(courses, moduleId);

      return state.set(
        'courses',
        courses.update(courseIndex, (course) =>
          course
            .update('numModuleActivities', (n) => n + 1)
            .set('updatedAt', new Date())
        )
      );
    }

    case DELETE_LESSON_SUCCESS: {
      const {lessonKey} = action.meta;
      const courses = state.get('courses');
      const courseIndex = courses.findIndex((course) =>
        course.get('lessonKeys').includes(lessonKey)
      );

      return state.set(
        'courses',
        courses.update(courseIndex, (course) =>
          course
            .update('lessonKeys', (list) =>
              //eslint-disable-next-line max-nested-callbacks
              list.filter((key) => key !== lessonKey)
            )
            .update('numModuleActivities', (n) => n - 1)
            .set('updatedAt', new Date())
        )
      );
    }

    case DELETE_MODULE_SURVEY_SUCCESS: {
      const {moduleId} = action.meta;
      const courses = state.get('courses');
      const courseIndex = getCourseIndexFromModuleId(courses, moduleId);

      return state.set(
        'courses',
        courses.update(courseIndex, (course) =>
          course
            .update('numModuleActivities', (n) => n - 1)
            .set('updatedAt', new Date())
        )
      );
    }

    case PUBLISH_COURSE_SUCCESS:
    case UNPUBLISH_COURSE_SUCCESS: {
      const {courseKey} = action.meta;
      const published = action.type === 'PUBLISH_COURSE_SUCCESS';

      const courses = state.get('courses');
      const courseIndex = courses.findIndex(
        (course) => course.get('courseKey') === courseKey
      );

      return state
        .set(
          'courses',
          courses
            .setIn([courseIndex, 'published'], published)
            .setIn([courseIndex, 'publishedAt'], published ? new Date() : null)
        )
        .set('demoContentCoursesLoaded', false);
    }

    case ASSIGN_COURSE_TO_GROUPS:
      return state.set('isAssigning', true);
    case ASSIGN_COURSE_TO_GROUPS_SUCCESS:
      return state.set('isAssigning', false);

    case ADD_LEARNING_CARD_SUCCESS: {
      const {courseId, id: learningCardId} = action.payload.data;
      const courses = state.get('courses');
      const courseIndex = courses.findIndex(
        (course) => course.get('id') === courseId
      );

      return state.set(
        'courses',
        courses
          .updateIn([courseIndex, 'learningCards'], (list) =>
            list.push(learningCardId)
          )
          .setIn([courseIndex, 'updatedAt'], new Date())
      );
    }

    case DELETE_LEARNING_CARD_SUCCESS: {
      const {learningCardId} = action.meta;

      const courses = state.get('courses');
      return state.set(
        'courses',
        courses.map((course) =>
          course
            .update('learningCards', (list) =>
              //eslint-disable-next-line max-nested-callbacks
              list.filter((id) => id !== learningCardId)
            )
            .set('updatedAt', new Date())
        )
      );
    }

    case DISMISS_REASSIGN_COURSE_CSV_UPLOAD_ERROR: {
      return state
        .set('reassignCourseUploadCsvError', null)
        .set('reassignCourseResults', []);
    }

    case REASSIGN_COURSE: {
      return state.set('reassignCourseInProgress', true);
    }

    case REASSIGN_COURSE_FAIL: {
      return state
        .set('reassignCourseInProgress', false)
        .set(
          'reassignCourseUploadCsvError',
          action.payload.error.response.data
        );
    }

    case REASSIGN_COURSE_SUCCESS: {
      return state
        .set('reassignCourseInProgress', false)
        .set('reassignCourseResults', action.payload.data.results);
    }

    case GET_BAMBOO_TRAININGS_SUCCESS: {
      return state
        .set('bambooTrainingList', action.payload.data)
        .set('getBambooTrainingsError', false);
    }

    case GET_BAMBOO_TRAININGS_FAIL: {
      return state
        .set('bambooTrainingList', [])
        .set('getBambooTrainingsError', true);
    }

    case REDIRECT_IN_PROGRESS: {
      return state.set('redirectInProgress', true);
    }

    case REDIRECT_COMPLETED: {
      return state.set('redirectInProgress', false);
    }

    case EXPORT_COURSE_TO_GSHEET:
      return state.set('exportCourseInProgress', true);

    case EXPORT_COURSE_TO_GSHEET_SUCCESS: {
      const {courseId} = action.meta;
      const {googleSheetsId} = action.payload.data;

      const courses = state.get('courses');
      const courseIndex = courses.findIndex(
        (course) => course.get('id') === courseId
      );

      return state
        .set('exportCourseInProgress', false)
        .setIn(['courses', courseIndex, 'googleSheetsId'], googleSheetsId)
        .setIn(
          ['courses', courseIndex, 'lastGsheetExportDate'],
          new Date().toISOString()
        );
    }

    case `${EXPORT_COURSE_TO_GSHEET}_FAIL`:
      return state.set('exportCourseInProgress', false);

    case IMPORT_COURSE_FROM_GSHEET:
      return state.set('importCourseInProgress', true);

    case IMPORT_COURSE_FROM_GSHEET_SUCCESS: {
      const {courseId} = action.meta;
      const languages = action.payload.data.languages;

      const courses = state.get('courses');
      const courseIndex = courses.findIndex(
        (course) => course.get('id') === courseId
      );

      return state
        .set('importCourseInProgress', false)
        .setIn(
          ['courses', courseIndex, 'lastGsheetImportDate'],
          new Date().toISOString()
        )
        .set('importCourseError', null)
        .setIn(['courses', courseIndex, 'languages'], fromJS(languages));
    }

    case `${IMPORT_COURSE_FROM_GSHEET}_FAIL`: {
      const errorKey = action.payload.error.response.data.message;
      const keysWithErrors = action.payload.error.response.data?.keys;

      return state
        .set('importCourseInProgress', false)
        .set('importCourseError', {errorKey, keysWithErrors});
    }

    case SET_PAGINATION_TAB:
      return state.set('paginationTab', action.payload);

    case LOAD_RESOURCE_LIBRARY_COURSES: {
      return state.set('resourceLibraryCoursesLoaded', false);
    }

    case LOAD_RESOURCE_LIBRARY_COURSES_SUCCESS: {
      const courses = action.payload.data;

      const sorted = sortContentByCreationDate(courses, 'title');

      return state
        .set('resourceLibraryCourses', fromJS(sorted))
        .set('resourceLibraryCoursesLoaded', true);
    }

    case LOAD_DEMO_CONTENT_COURSES: {
      return state.set('demoContentCoursesLoaded', false);
    }

    case LOAD_DEMO_CONTENT_COURSES_SUCCESS: {
      const courses = action.payload.data;

      const sorted = sortContentByCreationDate(courses, 'title');

      return state
        .set('demoContentCourses', fromJS(sorted))
        .set('demoContentCoursesLoaded', true);
    }

    case LOAD_DEMO_CONTENT_COURSES_FAIL: {
      return state
        .set('demoContentCourses', fromJS([]))
        .set('demoContentCoursesLoaded', true);
    }

    case UPDATE_COURSE_TIMESTAMPS: {
      const {moduleId} = action.payload;
      const courses = state.get('courses');
      const courseIndex = getCourseIndexFromModuleId(courses, moduleId);

      return state.set(
        'courses',
        courses.setIn([courseIndex, 'updatedAt'], new Date())
      );
    }

    case CREATE_TEMPLATE_FROM_COURSE: {
      return state.set('createTemplateInProgress', true);
    }

    case CREATE_TEMPLATE_FROM_COURSE_SUCCESS: {
      return state.set('createTemplateInProgress', false);
    }

    case SET_ACTIVE_SIDEBAR_ITEM: {
      return state.set('activeSidebarItem', action.payload);
    }

    case SET_CONTENT_FILTER: {
      return state.set('activeContentFilter', action.payload);
    }

    case TOGGLE_SIDEBAR_CREATION_EXPANSION: {
      return state.set(
        'isSidebarCreationExpanded',
        !state.get('isSidebarCreationExpanded')
      );
    }

    case SET_IS_USER_ADDING_CONTENT: {
      return state.set('isUserAddingContent', action.payload);
    }

    case SET_IS_USER_ADDING_COURSE: {
      return state.set('isUserAddingCourse', action.payload);
    }

    case TOGGLE_COURSE_NOTIFICATIONS_SLIDEPANEL: {
      const {visible} = action.payload;
      return state.set('isNotificationsSlidePanelVisible', visible);
    }

    case TOGGLE_COURSE_OTP_SLIDEPANEL: {
      const {visible} = action.payload;
      return state.set('isContentLinksSlidePanelVisible', visible);
    }

    case SEND_COURSE_NOTIFICATIONS_NEW: {
      return state.set('learnerNotificationsSending', true);
    }

    case SEND_COURSE_NOTIFICATIONS_NEW_SUCCESS: {
      return state
        .set('learnerNotificationsSent', true)
        .set('learnerNotificationsSending', false)
        .set('sentLearnerNotifications', action.payload.data);
    }

    case SEND_COURSE_NOTIFICATIONS_NEW_FAIL: {
      return state
        .set('learnerNotificationsSending', false)
        .set('learnerNotificationsError', true);
    }

    case RESET_COURSE_NOTIFICATIONS_NEW: {
      return state
        .set('learnerNotificationsSending', false)
        .set('learnerNotificationsSent', false)
        .set('sentLearnerNotifications', {
          email: null,
          sms: null,
          msTeams: null,
          speakap: null,
        });
    }

    case COMMAND_PALETTE_NEW_ACTIVITY: {
      return state.set('hasRequestedNewActivity', true);
    }

    case COMMAND_PALETTE_CLEAR_NEW_ACTIVITY: {
      return state.set('hasRequestedNewActivity', false);
    }

    case CREATE_COLLECTION_SUCCESS: {
      const {id} = action.payload.data;
      return state.set('activeSidebarItem', `${COLLECTION_PREFIX}${id}`);
    }

    case CLEAR_IMPORT_ERROR:
      return state.set('importCourseError', null);

    case GET_SCHEDULED_NOTIFICATIONS_SUCCESS: {
      if (action.meta.contentType === COURSE) {
        const courseId = action.meta.contentId;
        const scheduledNotifications = state
          .get('scheduledNotifications')
          .toJS();
        scheduledNotifications[courseId] = action.payload.data;
        return state.set(
          'scheduledNotifications',
          fromJS(scheduledNotifications)
        );
      }
      return state;
    }

    default: {
      return state;
    }
  }
};

export const LOAD_CONTENT_COLLECTIONS = 'LOAD_CONTENT_COLLECTIONS';
export const LOAD_CONTENT_COLLECTIONS_SUCCESS =
  'LOAD_CONTENT_COLLECTIONS_SUCCESS';
export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const ASSIGN_COLLECTION_TO_CONTENT = 'ASSIGN_COLLECTION_TO_CONTENT';
export const ASSIGN_COLLECTION_TO_CONTENT_SUCCESS =
  'ASSIGN_COLLECTION_TO_CONTENT_SUCCESS';
export const UPDATE_CONTENT_IN_COLLECTION = 'UPDATE_CONTENT_IN_COLLECTION';
export const UPDATE_CONTENT_IN_COLLECTION_SUCCESS =
  'UPDATE_CONTENT_IN_COLLECTION_SUCCESS';

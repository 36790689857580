import React, {useEffect} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';

import {CrossIcon, PeopleIcon, RefreshIcon} from '../icons';
import Link from '../Link';

import styles from './styles.module.scss';

export type NotificationProps = {
  headerText: string;
  message: string;
  type: 'SUCCESS' | 'ERROR' | 'WARNING';
  onDelete: () => void;
  ctaOptions?: CtaOptions;
  dataAutomation?: string;
  isAutoDismissed?: boolean;
  duration?: number;
};

export type CtaOptions = {
  icon: keyof typeof ctaActionIcons;
  text: string;
  onClick: () => void;
};

const ctaActionIcons = {
  team: <PeopleIcon colour='primary' size='xsmall' />,
  retry: <RefreshIcon colour='primary' size='xsmall' direction='left' />,
};

const Notification = ({
  headerText,
  message,
  type,
  onDelete: close,
  ctaOptions,
  dataAutomation,
  isAutoDismissed = true,
  duration = 4000,
}: NotificationProps): JSX.Element => {
  useEffect(() => {
    if (!isAutoDismissed) {
      return undefined;
    }
    const timer = setTimeout(close, duration);
    return () => {
      clearTimeout(timer);
    };
  }, [isAutoDismissed, close, duration]);

  return (
    <div data-automation={dataAutomation} styleName='animation-wrapper'>
      <div styleName={'notification'}>
        <div styleName={classNames('notification-header', type.toLowerCase())}>
          {headerText}
          <div
            styleName={'close-icon'}
            onClick={close}
            data-automation='notification-close-button'
          >
            <CrossIcon
              size='small'
              colour={type === 'WARNING' ? 'black' : 'white'}
            />
          </div>
        </div>
        {isAutoDismissed && (
          <div styleName='progress-container'>
            <div
              styleName={`progress-bar ${type.toLowerCase()}`}
              style={{animationDuration: `${duration / 1000}s`}}
            />
          </div>
        )}
        <div styleName='notification-body'>
          {message}
          {ctaOptions && (
            <div styleName='cta-link'>
              <Link
                linkSize='snail'
                text={ctaOptions.text}
                onClick={() => {
                  ctaOptions.onClick();
                  close();
                }}
                icon={ctaActionIcons[ctaOptions.icon]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CSSModules(Notification, styles, {allowMultiple: true});

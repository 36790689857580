import {logReduxActionToAnalytics} from '../analytics';

export const analytics =
  ({getState}) =>
  (next) =>
  (action) => {
    const returnValue = next(action);

    if (action) {
      logReduxActionToAnalytics(action, getState());
    }

    return returnValue;
  };

export default analytics;

import React from 'react';

import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';

import AuthenticatedPage from './AuthenticatedPage';

export const createAuthenticatedPage = (
  component,
  routeProps,
  appProps,
  noHeader,
  noFooter
) => (
  <AuthenticatedPage {...appProps} noHeader={noHeader} noFooter={noFooter}>
    {React.createElement(component, routeProps)}
  </AuthenticatedPage>
);
const AuthenticatedRoute = ({
  component: Component,
  props,
  extraRouteProps = {},
  noHeader = false,
  noFooter = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={(routeProps) =>
      createAuthenticatedPage(
        Component,
        {...routeProps, ...extraRouteProps},
        props,
        noHeader,
        noFooter
      )
    }
  />
);
AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  props: PropTypes.object,
  extraRouteProps: PropTypes.object,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
};

export default AuthenticatedRoute;

import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const SearchIcon = ({
  size = 'small',
  colour = 'textPrimary',
}: IconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.6 16.8a7.5 7.5 0 1 1 2.1-2.1l5.3 5.2-2.1 2.1-5.3-5.2Zm.9-6.3a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z'
      fillRule='evenodd'
      fill={colourMap[colour]}
    />
  </svg>
);

export default SearchIcon;

import {fromJS} from 'immutable';

import {ADD_LESSON} from '../course/courseActionTypes';
import {
  CLOSE_EDITOR,
  OPEN_EDITOR,
  RESET_AI_EDITOR_STATE,
  REWORD_TEXT,
  SET_REWORD_PARAMS,
  SET_TEXT_TO_REWORD,
} from './aiActionTypes';

export const initialState = fromJS({
  textToReword: null,
  rewordedText: null,
  rewordAction: null,
  rewordTone: null,
  showingAiEditor: false,
  aiLoading: false,
  aiCharacterLimit: null,
});

// eslint-disable-next-line complexity
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEXT_TO_REWORD: {
      const {text, limit} = action.payload;

      return state
        .set('textToReword', text)
        .set('aiCharacterLimit', limit)
        .set('rewordedText', null);
    }

    case ADD_LESSON:
    case CLOSE_EDITOR: {
      return state.set('showingAiEditor', false);
    }

    case RESET_AI_EDITOR_STATE: {
      return initialState;
    }

    case SET_REWORD_PARAMS: {
      const rewordAction = action.payload.action;
      const rewordTone = action.payload.tone;

      return state
        .set('rewordAction', rewordAction)
        .set('rewordTone', rewordTone);
    }

    case OPEN_EDITOR: {
      return state.set('showingAiEditor', true);
    }

    case REWORD_TEXT: {
      const {word} = action.payload;
      return state.set('rewordedText', word).set('aiLoading', true);
    }

    case `${REWORD_TEXT}_SUCCESS`: {
      const payload = action.payload;

      return state.set('rewordedText', payload.data).set('aiLoading', false);
    }

    case `${REWORD_TEXT}_FAIL`: {
      return state.set('aiLoading', false).set('rewordedText', null);
    }

    default:
      return state;
  }
};

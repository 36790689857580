import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

type InfoIconProps = IconProps & {
  dataTip?: boolean;
  dataFor?: string;
};

const InfoIcon = ({
  size = 'small',
  colour = 'textPrimary',
  dataTip = false,
  dataFor = '',
}: InfoIconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-tip={dataTip}
    data-for={dataFor}
  >
    <path d='M13.5 11v7h-3v-7h3ZM13.5 9V6h-3v3h3Z' fill={colourMap[colour]} />
    <path
      fillRule='evenodd'
      d='M12 23a11 11 0 1 0 0-22 11 11 0 0 0 0 22Zm0-3a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default InfoIcon;

import {buildRequest} from '../stateUtil';
import {
  ADD_ONBOARDING_ITEM,
  DELETE_ONBOARDING_ITEM,
  LOAD_ONBOARDING,
} from './onboardingActionTypes';

export const addOnboardingItem = (teamId, order, title, text, type, url) => {
  const media = type !== '' ? {type, url} : null;
  const content = {title, text, media, order};
  return buildRequest(
    ADD_ONBOARDING_ITEM,
    'POST',
    `/onboardingItem/${teamId}`,
    content
  );
};

export const deleteOnboardingItem = (id) =>
  buildRequest(DELETE_ONBOARDING_ITEM, 'DELETE', `/onboardingItem/${id}`, id);

export const getOnboarding = (teamId) =>
  buildRequest(LOAD_ONBOARDING, 'GET', `/onboarding/${teamId}`);

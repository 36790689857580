import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import * as formActions from '../../state/form/formActions';

const FormWrapper = ({onClose, onSubmit, children}) => {
  const dispatch = useDispatch();
  const [isInitialising, setIsInitialising] = useState(true);
  const isFormOpen = useSelector((state) =>
    state.getIn(['form', 'isFormOpen'])
  );

  useEffect(() => {
    dispatch(formActions.openForm());
    setIsInitialising(false);
    return () => {
      dispatch(formActions.closeForm());
    };
  }, []);

  useEffect(() => {
    if (!isInitialising && !isFormOpen && onClose) {
      onClose();
    }
  }, [isInitialising, isFormOpen]);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <form noValidate onSubmit={handleOnSubmit}>
      {children}
    </form>
  );
};

FormWrapper.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.any.isRequired,
};

export default FormWrapper;

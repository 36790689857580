import {push} from 'connected-react-router';

import {VIEW_COMPANY_SETTINGS, VIEW_HELP_CENTER} from './headerActionTypes';

export const viewHelpCenter = () => (dispatch) => {
  dispatch({
    type: VIEW_HELP_CENTER,
  });
};

export const viewCompanySettings = () => (dispatch) => {
  dispatch({
    type: VIEW_COMPANY_SETTINGS,
  });
  dispatch(push('/settings' + location.search));
};

import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const MinusIcon = ({
  size = 'small',
  colour = 'textPrimary',
}: IconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M4 10.5h16v3H4v-3Z' fill={colourMap[colour]} />
  </svg>
);

export default MinusIcon;

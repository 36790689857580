// sort-imports-ignore
import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

import Article from './Article';
import HeroLink from './HeroLink';
import {LegacyHeading} from '@edume/magnificent'; // eslint-disable-line workspaces/require-dependency

const HelpArticles = ({
  withIcon,
  articles,
  intl,
  breakpoint = 'regular',
  logReduxAction,
}) => (
  <div styleName={`container ${breakpoint}`}>
    <LegacyHeading size='tinier' weight='medium' colour='grey700'>
      {intl.formatMessage({id: 'Overview.articles.heading'})}
    </LegacyHeading>
    <div styleName='content'>
      <HeroLink
        withIcon={withIcon}
        intl={intl}
        breakpoint={breakpoint}
        logReduxAction={logReduxAction}
      />
      <div styleName='articles'>
        {articles.map((article, index) => (
          <Article
            key={index}
            article={article}
            logReduxAction={logReduxAction}
          />
        ))}
      </div>
    </div>
  </div>
);

HelpArticles.propTypes = {
  withIcon: PropTypes.bool,
  articles: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  breakpoint: PropTypes.oneOf(['regular', 'wide']),
  logReduxAction: PropTypes.func,
};

export default CSSModules(HelpArticles, styles, {allowMultiple: true});

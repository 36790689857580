/* eslint-disable complexity */
import * as contentTypes from '../enums/contentTypes';
import {
  ADD_REMOVE_ADMIN_FROM_TEAMS_SUCCESS,
  ADMIN_CREATED,
  DELETE_ADMIN_SUCCESS,
  UPDATE_ADMIN_LEVEL_SUCCESS,
  UPDATE_ADMIN_SUCCESS,
} from '../state/admin/adminActionTypes';
import {UPDATE_LANGUAGE_SUCCESS} from '../state/auth/authActionTypes';
import {
  ASSIGN_COLLECTION_TO_CONTENT_SUCCESS,
  CREATE_COLLECTION_SUCCESS,
  UPDATE_CONTENT_IN_COLLECTION_SUCCESS,
} from '../state/collections/collectionsActionTypes';
import {
  COPY_CONTENT_SUCCESS,
  EDIT_CONTENT_EVENT_INTEGRATION_NAME,
  OPEN_SCHEDULED_NOTIFICATIONS_SLIDE_PANEL,
  TOGGLE_CONTENT_EVENT_INTEGRATION,
  TOGGLE_CONTENT_INTEGRATION,
} from '../state/content/contentActionTypes';
import {
  ACCESSED_COURSE_REPORT_LINK,
  ADD_AI_LESSON_SUCCESS,
  ADD_COURSE_SUCCESS,
  ADD_LESSON_SUCCESS,
  ADD_MODULE_SUCCESS,
  ASSIGN_COURSE_TO_GROUPS_SUCCESS,
  CHANGE_COURSE_TAB,
  CREATE_MODULE_SURVEY_SUCCESS,
  CREATE_TEMPLATE_FROM_COURSE_SUCCESS,
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  EDIT_COURSE_COMPLETION,
  EXPORT_COURSE_TO_GSHEET_SUCCESS,
  ID_COPIED,
  LINK_INTEGRATED_COURSE_SUCCESS,
  PUBLISH_COURSE_SUCCESS,
  SEARCH_COURSE,
  SELECTED_LEARNING_TAB_FILTER,
  SET_COURSE_CARD_DESIGN,
  TOGGLE_COURSE_NOTIFICATIONS_SLIDEPANEL,
  UPDATE_COURSE_HIDDEN_TITLE_SLIDE_SUCCESS,
  UPDATE_COURSE_PRIORITY_SUCCESS,
  UPDATE_COURSE_SUCCESS,
  UPDATE_INTEGRATED_COURSE_SUCCESS,
  VIEWED_PREVIEW_LINK,
} from '../state/course/courseActionTypes';
import {
  ADD_FEED_ITEM_SUCCESS,
  DELETE_FEED_ITEM_SUCCESS,
  VIEW_MESSAGES,
} from '../state/feed/feedActionTypes';
import {
  ASSIGN_GUIDE_TO_GROUPS_SUCCESS,
  DELETE_GUIDE_SUCCESS,
  LINK_INTEGRATED_GUIDE_SUCCESS,
  PUBLISH_GUIDE_SUCCESS,
  RECORD_GUIDE_CREATION,
  TOGGLE_GUIDE_NOTIFICATIONS_SLIDEPANEL,
  UNPUBLISH_GUIDE_SUCCESS,
  UPDATE_GUIDE_SUCCESS,
  UPDATE_INTEGRATED_GUIDE_SUCCESS,
} from '../state/guide/guideActionTypes';
import {
  VIEW_COMPANY_SETTINGS,
  VIEW_HELP_CENTER,
} from '../state/header/headerActionTypes';
import {
  ADD_KNOWLEDGE_HUB_SUCCESS,
  TOGGLE_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL,
} from '../state/knowledgeHub/knowledgeHubActionTypes';
import {
  SEND_COURSE_NOTIFICATIONS_NEW_SUCCESS,
  SEND_GUIDE_NOTIFICATIONS_SUCCESS,
  SEND_KNOWLEDGE_HUB_NOTIFICATIONS_SUCCESS,
} from '../state/learnerNotification/learnerNotificationActionTypes';
import {
  GENERATE_LESSONS_FROM_TOPICS_SUCCESS,
  UPDATE_LESSON,
  UPDATE_LESSON_SUCCESS,
} from '../state/moduleActivity/moduleActivityActionTypes';
import {ADD_NOTIFICATION} from '../state/notification/notificationActionTypes';
import {
  CLICK_CREATE_CONTENT_BUTTON,
  VIEW_HELP_CENTER_ARTICLE,
} from '../state/overview/overviewActionTypes';
import {
  ADD_REMOVE_USER_FROM_TEAMS_SUCCESS,
  ADD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  LOAD_USERS_SEARCH_SUCCESS,
  LOAD_USERS_SUCCESS,
  REMOVE_USER_FROM_TEAM_SUCCESS,
  VIEW_TEAMS_OR_USERS,
} from '../state/people/peopleActionTypes';
import {
  REPORT_DOWNLOADED,
  TOGGLE_REPORT_VIEW,
  TOGGLE_USER_VIEW,
  USER_REPORT_DOWNLOADED,
} from '../state/reporting/reportingActionTypes';
import {
  COPY_WEBHOOK_SECRET,
  CREATE_INTEGRATION_SUCCESS,
  DELETE_API_KEY_SUCCESS,
  DELETE_INTEGRATION_SUCCESS,
  GENERATE_API_KEY_SUCCESS,
  GENERATE_SIGNING_SECRET_SUCCESS,
  UPDATE_CUSTOMER_BRANDING_SUCCESS,
  UPDATE_GROUP_BRANDING_SUCCESS,
  UPDATE_INTEGRATION_SUCCESS,
  UPDATE_WEBHOOK,
  UPDATE_WEBHOOK_EVENTS,
} from '../state/settings/settingsActionTypes';
import {
  CREATE_SURVEY_SUCCESS,
  DELETE_SURVEY_SUCCESS,
  VIEW_SURVEYS,
} from '../state/survey/surveyActionTypes';
import {
  ADD_GROUP_SUCCESS,
  ADD_TEAM_SUCCESS,
  CHANGED_STATIC_TEAM_NAME,
  CLICKED_CREATE_TEAM,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_TEAM_SUCCESS,
  SAVED_TEAM_UPDATE,
  SELECTED_TEAM_TYPE,
  SHOW_USERS_IN_TEAM,
  UPLOAD_CSV_SUCCESS,
  VIEWED_SMART_TEAM_DETAILS,
  VIEWED_TEAM_ACTION,
} from '../state/team/teamActionTypes';
import {trackingActionTypes} from '../state/tracking/trackingActions';
import {
  COPIED_PUBLISHED_LINK,
  START_TRIAL_SUCCESS,
  VIEWED_ACCOUNT_UPGRADE,
} from '../state/trial/trialActionTypes';
import * as trackingFunctions from './trackingFunctions';
import {
  DATE_RANGE_SELECTED,
  MODIFIED_COMPANY_LEVEL_REPORT,
} from './trackingProps';

const {VIEWED_CONTENT_CREATION_GUIDE} = trackingActionTypes;

// eslint-disable-next-line complexity, max-statements
export const sendTrackingEvents = (action, state) => {
  switch (action.type) {
    case PUBLISH_COURSE_SUCCESS:
      return trackingFunctions.sendPublishCourseEvent(
        action.meta.courseKey,
        state
      );

    case ASSIGN_COURSE_TO_GROUPS_SUCCESS:
      return trackingFunctions.sendAssignCourseToTeamEvent(action);

    case UPDATE_LESSON: {
      const lastLessonCreatedType = state.getIn([
        'moduleActivity',
        'lastLessonCreatedType',
      ]);
      if (lastLessonCreatedType === 'ai') {
        return trackingFunctions.sendCreateAICourseEvent();
      }
      return null;
    }

    case UPDATE_LESSON_SUCCESS:
      return trackingFunctions.sendUpdateLessonEvent(action, state);

    case DELETE_GROUP:
      return trackingFunctions.saveDeletedGroupData(state);

    case DELETE_GROUP_SUCCESS:
      return trackingFunctions.sendDeleteGroupEvent(action);

    case DELETE_FEED_ITEM_SUCCESS:
      return trackingFunctions.sendDeleteMessageEvent(action);

    case '@@router/LOCATION_CHANGE': {
      trackingFunctions.setAppcuesUserProps(state);
      const urlPath = window.location.pathname;
      const urlElements = urlPath.split('/').slice(1);
      const [path] = urlElements.slice(-1);
      const [pathRoot] = urlElements.slice(0, 1);
      const hasQueryParams = !!window.location.search;

      if (pathRoot === 'reports' && hasQueryParams) {
        if (urlElements.includes('compare-courses')) {
          return trackingFunctions.sendViewedCompanyLevelReportEvent();
        }
        const source = action.payload.location?.state;
        return trackingFunctions.sendViewReportEvent(action, state, source);
      }
      if (path === 'api-keys') {
        return trackingFunctions.sendViewApiKeysEvent();
      }
      if (path === 'signing-secrets') {
        return trackingFunctions.sendViewedSigningSecretsEvent();
      }
      if (
        urlElements[2] === 'webhooks' &&
        urlElements.length === 4 // exactly 4 to avoid firing on settings/developer-tools/webhooks page
      ) {
        return trackingFunctions.sendViewedWebhookConfigEvent(
          parseInt(urlElements[3])
        );
      }
      if (urlPath === '/learning') {
        return trackingFunctions.sendViewedLearningPageEvent(action);
      }
      if (pathRoot === 'settings' && path === 'integrations') {
        return trackingFunctions.sendViewedIntegrationsEvent();
      }
      if (
        urlElements[0] === 'learning' &&
        urlElements[1] === 'course' &&
        /^\d+$/.test(urlElements[2]) &&
        urlElements.length === 3 // exactly 3 to avoid firing on lesson/assessment pages
      ) {
        return trackingFunctions.sendViewedCourseScreenEvent(
          urlElements[2],
          action
        );
      }
      if (urlPath === '/') {
        const {isAuthenticated} = state.get('auth').toJS();
        return isAuthenticated
          ? trackingFunctions.sendViewedDashboardEvent()
          : null;
      }
      if (
        urlElements[0] === 'settings' &&
        urlElements[1] === 'integrations' &&
        urlElements.length === 3 // exactly 3 to avoid firing on main 3rd party integrations page
      ) {
        return trackingFunctions.sendViewedIntegrationPageEvent(urlElements[2]);
      }
      if (pathRoot === 'settings' && path === 'usage' && hasQueryParams) {
        return trackingFunctions.sendViewedUsagePageEvent();
      }
      if (
        pathRoot === 'people' &&
        urlElements.includes('smart-teams') &&
        hasQueryParams
      ) {
        return trackingFunctions.sendEditSmartTeamEvent({
          teamId: urlElements[2],
          state,
        });
      }
      return null;
    }
    case VIEW_SURVEYS:
      return trackingFunctions.sendViewSurveyListEvent(action.payload, state);

    case UPLOAD_CSV_SUCCESS:
    case ADD_USER_SUCCESS:
      return trackingFunctions.sendAddUserEvent(action);

    case EDIT_USER_SUCCESS:
      return trackingFunctions.sendEditUserEvent(action, state);

    case DELETE_USER_SUCCESS:
      return trackingFunctions.sendDeleteUserEvent(action);
    case ADD_LESSON_SUCCESS:
    case ADD_AI_LESSON_SUCCESS:
      return trackingFunctions.sendCreateLessonEvent(action);
    case ADD_COURSE_SUCCESS:
      return trackingFunctions.sendCreateCourseEvent(action, state);
    case COPY_CONTENT_SUCCESS: {
      const {contentType} = action.meta;
      if (contentType === contentTypes.COURSE) {
        return trackingFunctions.sendCreateCourseEvent(action, state);
      }
      return null;
    }

    case ADD_TEAM_SUCCESS:
      return trackingFunctions.sendCreateTeamEvent(action);

    case ADD_GROUP_SUCCESS:
      return trackingFunctions.sendAddGroupEvent(action);

    case DELETE_TEAM_SUCCESS:
      return trackingFunctions.sendDeleteTeamEvent(
        action.meta.previousAction.payload.request.url,
        state
      );

    case ADMIN_CREATED:
      return trackingFunctions.sendAddAdminEvent(action.payload);

    case DELETE_ADMIN_SUCCESS:
      return trackingFunctions.sendDeleteAdminEvent(
        action.meta.previousAction.payload.request.url,
        state
      );

    case VIEW_TEAMS_OR_USERS:
      return trackingFunctions.handleViewTeamOrUserList(action, state);

    case LOAD_USERS_SUCCESS:
    case LOAD_USERS_SEARCH_SUCCESS:
      return trackingFunctions.sendViewUserListEvent(action, state);

    case ADD_MODULE_SUCCESS:
      return trackingFunctions.sendCreateModuleEvent(action);

    case ADD_REMOVE_USER_FROM_TEAMS_SUCCESS:
      return trackingFunctions.sendUpdateUserTeamsEvent(action, state);

    case REMOVE_USER_FROM_TEAM_SUCCESS:
      return trackingFunctions.sendRemoveUserTeamsEvent(action);

    case ADD_NOTIFICATION:
      if (action.payload.messageKey === 'People.downloadCsvSuccess') {
        return trackingFunctions.sendDownloadUsersInGroupEvent(action, state);
      }
      return null;

    case VIEW_MESSAGES:
      return trackingFunctions.sendViewMessagesEvent(action);

    case DELETE_SURVEY_SUCCESS:
      return trackingFunctions.sendDeleteSurveyEvent(action, state);

    case CREATE_SURVEY_SUCCESS:
      return trackingFunctions.sendCreateSurveyEvent(action);

    case CREATE_MODULE_SURVEY_SUCCESS:
      return trackingFunctions.sendCreateModuleSurveyEvent(action);

    case REPORT_DOWNLOADED:
      return trackingFunctions.sendDownloadReportEvent(action);

    case TOGGLE_REPORT_VIEW:
      return trackingFunctions.handleReportToggle(action);

    case TOGGLE_USER_VIEW:
      return trackingFunctions.handleUserToggle(action);

    case ADD_FEED_ITEM_SUCCESS:
      return trackingFunctions.sendCreateMessageEvent(action);

    case VIEW_HELP_CENTER:
      return trackingFunctions.sendViewHelpCenterEvent();

    case DELETE_COURSE:
      return trackingFunctions.saveDeleteCourseData(action, state);

    case DELETE_COURSE_SUCCESS:
      return trackingFunctions.sendDeleteCourseEvent();

    case EDIT_COURSE_COMPLETION:
      return trackingFunctions.sendEditedEndScreenEvent(action, state);

    case UPDATE_COURSE_HIDDEN_TITLE_SLIDE_SUCCESS:
      return trackingFunctions.sendEditCourseTitleSlideEvent(action, state);

    case UPDATE_COURSE_SUCCESS:
      return trackingFunctions.sendEditCourseSettingsEvent(action, state);

    case EXPORT_COURSE_TO_GSHEET_SUCCESS:
      return trackingFunctions.sendGenerateMLSSheetEvent(action, state);

    case LINK_INTEGRATED_COURSE_SUCCESS:
    case UPDATE_INTEGRATED_COURSE_SUCCESS:
    case LINK_INTEGRATED_GUIDE_SUCCESS:
    case UPDATE_INTEGRATED_GUIDE_SUCCESS:
      switch (action.meta.previousAction.payload.request.data.eventType) {
        case TOGGLE_CONTENT_EVENT_INTEGRATION:
          return trackingFunctions.toggleContentEventIntegration(action, state);
        case TOGGLE_CONTENT_INTEGRATION:
          return trackingFunctions.toggleContentIntegration(action, state);
        case EDIT_CONTENT_EVENT_INTEGRATION_NAME:
          return trackingFunctions.editContentEventIntegrationName(
            action,
            state
          );
        default:
          return null;
      }

    case ACCESSED_COURSE_REPORT_LINK:
      return trackingFunctions.accessedCourseReportLink(action);

    case SEARCH_COURSE:
      return trackingFunctions.sendCourseSearchEvent(action);

    case GENERATE_API_KEY_SUCCESS:
      return trackingFunctions.sendCreateApiKeyEvent(action);

    case DELETE_API_KEY_SUCCESS:
      return trackingFunctions.sendDeleteApiKeyEvent(action);

    case GENERATE_SIGNING_SECRET_SUCCESS:
      return trackingFunctions.sendCreatedSigningSecretEvent(action);

    case COPY_WEBHOOK_SECRET:
      return trackingFunctions.sendCopiedWebhookSecretEvent(action);

    case UPDATE_WEBHOOK:
      return trackingFunctions.sendUpdatedWebhookConfigEvent(action);

    case UPDATE_WEBHOOK_EVENTS:
      return trackingFunctions.sendUpdatedWebhookSubscribedEventsEvent(action);

    case USER_REPORT_DOWNLOADED:
      return trackingFunctions.sendUserReportDownloadedEvent(action);

    case SELECTED_LEARNING_TAB_FILTER:
      return trackingFunctions.selectedLearningTabFilter(action);

    case VIEWED_PREVIEW_LINK:
      return trackingFunctions.sendViewedPreviewLink(action);

    case ID_COPIED:
      return trackingFunctions.sendIdCopiedEvent(action);

    case CHANGE_COURSE_TAB: {
      if (action.payload.clicked) {
        return trackingFunctions.selectedCourseTab(action);
      }
      return null;
    }

    case UPDATE_LANGUAGE_SUCCESS:
      return trackingFunctions.sendUpdatedLanguageEvent(action);

    case UPDATE_COURSE_PRIORITY_SUCCESS:
      return trackingFunctions.sendUpdateCoursePriorityEvent(action);

    case VIEW_COMPANY_SETTINGS:
      return trackingFunctions.sendViewedCompanySettingsEvent();

    case VIEWED_CONTENT_CREATION_GUIDE:
      return trackingFunctions.sendViewedContentCreationGuide();

    case UPDATE_INTEGRATION_SUCCESS:
      return trackingFunctions.sendIntegrationUpdatedEvent(action);

    case DELETE_INTEGRATION_SUCCESS:
      return trackingFunctions.sendIntegrationDeletedEvent(action);

    case CREATE_INTEGRATION_SUCCESS:
      return trackingFunctions.sendIntegrationCreatedEvent(action);

    case SET_COURSE_CARD_DESIGN:
      return trackingFunctions.sendSetCourseCardDesignEvent(action, state);

    case ADD_KNOWLEDGE_HUB_SUCCESS:
      return trackingFunctions.sendKnowledgeHubCreatedEvent(action);

    case START_TRIAL_SUCCESS:
      return trackingFunctions.sendCreatedAccountEvent(state);

    case CREATE_TEMPLATE_FROM_COURSE_SUCCESS:
      return trackingFunctions.sendCreateTemplateFromCourseEvent(action, state);

    case UPDATE_CUSTOMER_BRANDING_SUCCESS:
      return trackingFunctions.sendUpdateBrandingEvent(action);

    case UPDATE_GROUP_BRANDING_SUCCESS:
      return trackingFunctions.sendUpdateBrandingEvent(action);

    case VIEWED_ACCOUNT_UPGRADE:
      return trackingFunctions.sendTalkToSalesEvent(action);

    case COPIED_PUBLISHED_LINK:
      return trackingFunctions.sendCopyAnonCourseLinkEvent(action);

    case TOGGLE_COURSE_NOTIFICATIONS_SLIDEPANEL:
      return trackingFunctions.sendOpenedCourseNotificationsSlidepanelEvent(
        action
      );

    case TOGGLE_GUIDE_NOTIFICATIONS_SLIDEPANEL:
      return trackingFunctions.sendOpenedGuideNotificationsSlidepanelEvent(
        action
      );

    case TOGGLE_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL:
      return trackingFunctions.sendOpenedKnowledgeHubNotificationsSlidepanelEvent(
        action
      );

    case SEND_COURSE_NOTIFICATIONS_NEW_SUCCESS:
    case SEND_KNOWLEDGE_HUB_NOTIFICATIONS_SUCCESS:
    case SEND_GUIDE_NOTIFICATIONS_SUCCESS:
      return trackingFunctions.sendLearnerNotificationsEvents(action, state);

    case DATE_RANGE_SELECTED:
      return trackingFunctions.sendDateRangeSelectedEvent(action);

    case MODIFIED_COMPANY_LEVEL_REPORT:
      return trackingFunctions.sendModifiedCompanyLevelReportEvent(action);

    case ADD_REMOVE_ADMIN_FROM_TEAMS_SUCCESS:
      return trackingFunctions.sendUpdateAdminTeamsEvent(action);

    case CREATE_COLLECTION_SUCCESS:
      return trackingFunctions.sendCollectionCreatedEvent(action);

    case UPDATE_CONTENT_IN_COLLECTION_SUCCESS:
      return trackingFunctions.sendCollectionContentAddedEvent(action);

    case ASSIGN_COLLECTION_TO_CONTENT_SUCCESS:
      return trackingFunctions.sendCollectionContentAddedEvent(action);

    case RECORD_GUIDE_CREATION:
      return trackingFunctions.sendGuideCreated(action, state);
    case UPDATE_GUIDE_SUCCESS:
      return trackingFunctions.sendGuideUpdated(action, state);
    case PUBLISH_GUIDE_SUCCESS:
    case UNPUBLISH_GUIDE_SUCCESS:
      return trackingFunctions.sendGuidePublishEvent(action, state);
    case DELETE_GUIDE_SUCCESS:
      return trackingFunctions.sendGuideDeleted(action);
    case ASSIGN_GUIDE_TO_GROUPS_SUCCESS:
      return trackingFunctions.sendGuideGroupAssigned(action, state);

    case UPDATE_ADMIN_SUCCESS:
      return trackingFunctions.sendAdminPermissionsRevokedEvent(action, state);

    case UPDATE_ADMIN_LEVEL_SUCCESS:
      return trackingFunctions.sendLearnerUpgradedToAdminEvent(action, state);

    case CLICK_CREATE_CONTENT_BUTTON:
      return trackingFunctions.sendClickedCreateContentButtonEvent(action);

    case CLICKED_CREATE_TEAM:
      return trackingFunctions.sendClickedCreateTeamEvent(action);

    case VIEW_HELP_CENTER_ARTICLE:
      return trackingFunctions.sendViewedHelpCenterArticleEvent(action);

    case GENERATE_LESSONS_FROM_TOPICS_SUCCESS:
      return trackingFunctions.sendCreateAILessonsEvent(action);

    case OPEN_SCHEDULED_NOTIFICATIONS_SLIDE_PANEL:
      return trackingFunctions.sendViewedScheduledNotificationsEvent(action);

    case SELECTED_TEAM_TYPE:
      return trackingFunctions.sendSelectedTeamTypeEvent(action);

    case VIEWED_SMART_TEAM_DETAILS:
      return trackingFunctions.sendViewedSmartTeamDetailsEvent(action);

    case VIEWED_TEAM_ACTION:
      return trackingFunctions.sendViewedTeamActionEvent(action);

    case SHOW_USERS_IN_TEAM:
      return trackingFunctions.sendShowUsersInTeamEvent(action);

    case CHANGED_STATIC_TEAM_NAME:
      return trackingFunctions.sendChangeStaticTeamNameEvent(action);

    case SAVED_TEAM_UPDATE:
      return trackingFunctions.sendSavedTeamUpdateEvent(action);

    default:
      return null;
  }
};

//cloudinary
export const getVideoUrl = (name: string) =>
  'https://d13dt4xa7zzqg.cloudfront.net/video/upload/' + name;

export const getVideoThumb = (name: string) => {
  const youTubeVideoThumbFromUrl = getYouTubeVideoThumbFromUrl(name);
  return youTubeVideoThumbFromUrl
    ? youTubeVideoThumbFromUrl
    : getVideoUrl(name).replace(/\.((mp4)|(mov))/, '.png');
};

//youtube
export const getYouTubeIdFromUrl = (url: string) => {
  // eslint-disable-next-line max-len
  const idRegex =
    /^(?:https?:\/\/)?(?:(?:www\.)?(?:youtube(?:-nocookie)?|youtube.googleapis)\.com.*(?:v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/gi;
  const matches = idRegex.exec(url);
  return matches?.[1];
};

export const getYouTubeVideoThumbFromUrl = (url: string) => {
  const youTubeVideoId = getYouTubeIdFromUrl(url);
  return youTubeVideoId
    ? `https://img.youtube.com/vi/${youTubeVideoId}/hqdefault.jpg` //note maxresdefault.jpg would remove bars but it's not always available
    : false;
};

const hubspot = () => (next) => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    if ('HubSpotConversations' in window) {
      window.HubSpotConversations.widget.refresh();
    }
  }
  return next(action);
};

export default hubspot;

export const ADD_REMOVE_USER_FROM_TEAMS = 'ADD_REMOVE_USER_FROM_TEAMS';
export const ADD_REMOVE_USER_FROM_TEAMS_FAIL =
  'ADD_REMOVE_USER_FROM_TEAMS_FAIL';
export const ADD_REMOVE_USER_FROM_TEAMS_SUCCESS =
  'ADD_REMOVE_USER_FROM_TEAMS_SUCCESS';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USER_DETAILS = 'LOAD_USER_DETAILS';
export const LOAD_USER_DETAILS_FAIL = 'LOAD_USER_DETAILS_FAIL';
export const LOAD_USER_DETAILS_SUCCESS = 'LOAD_USER_DETAILS_SUCCESS';
export const LOAD_USERS_SEARCH = 'LOAD_USERS_SEARCH';
export const LOAD_USERS_SEARCH_FAIL = 'LOAD_USERS_SEARCH_FAIL';
export const LOAD_USERS_SEARCH_SUCCESS = 'LOAD_USERS_SEARCH_SUCCESS';
export const REMOVE_USER_FROM_TEAM = 'REMOVE_USER_FROM_TEAM';
export const REMOVE_USER_FROM_TEAM_SUCCESS = 'REMOVE_USER_FROM_TEAM_SUCCESS';
export const RESET_SEARCH = 'RESET_SEARCH';
export const RESET_SELECTED_USER = 'RESET_SELECTED_USER';
export const RESET_SELECTED_TEAM = 'RESET_SELECTED_TEAM';
export const RESET_USERS_STATE = 'RESET_USERS_STATE';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export const VIEW_TEAMS_OR_USERS = 'VIEW_TEAMS_OR_USERS';
export const UPDATE_ADMIN_LEVEL = 'UPDATE_ADMIN_LEVEL';

/*wraps LegacyHeading, adding a textKey attribute for i18n*/
import React from 'react';

import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import {Heading} from '@edume/magnificent';

const IntlHeading = ({text, textKey, values, intl, ...props}) => {
  const displayText = textKey
    ? intl.formatMessage({id: textKey}, values)
    : text;

  return <Heading {...props}>{displayText}</Heading>;
};

IntlHeading.propTypes = {
  text: PropTypes.string,
  textKey: PropTypes.string,
  values: PropTypes.object,
  intl: PropTypes.object.isRequired,
  as: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default injectIntl(IntlHeading);

import crypto from 'crypto';

export const getUrlFriendlyTitle = (title: string) =>
  title
    .replace(/[^a-zA-Z0-9-_ ]/g, '')
    .replace(/ /g, '-')
    .toLowerCase() || 'u'; //'u' stands for unsupported name

export const appendParamsToUrl = (url: string, paramsString: string) => {
  if (!url || typeof url !== 'string') {
    return url;
  }
  const queryStringSeparator = url.includes('?') ? '&' : '?';
  return `${url}${queryStringSeparator}${paramsString}`;
};

// The following code can be used to non-securely obfuscate strings to deter casual inspection
const characterDomain =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$&`:<>[]{}"+.-@=\''.split(
    ''
  );
export const VERSION_IDENTIFIER = 'vsn=';

const encrypt = (string: string, secret: string) =>
  crypto.createHmac('sha256', secret).update(string).digest('hex');

export const encryptionTable: Record<string, string> = {};
export const decryptionTable: Record<string, string> = {};

const generateEncryptionTables = (secret: string) => {
  const randomlySortedDomain = [...characterDomain].sort((a, b) =>
    encrypt(a, secret).localeCompare(encrypt(b, secret))
  );

  characterDomain.forEach((_char, index) => {
    encryptionTable[characterDomain[index]] = randomlySortedDomain[index];
    decryptionTable[randomlySortedDomain[index]] = characterDomain[index];
  });
};

/** Obfuscates and URI-encodes a string */
export const obfuscateUrl = (string: string, secret: string) => {
  generateEncryptionTables(secret);

  const obfuscatedString = string
    .split('')
    .map((char) => {
      const encodedChar = encryptionTable[char];
      if (!encodedChar) {
        console.warn(`Character ${char} not found in character domain`);
      }
      return encodeURIComponent(encodedChar || char);
    }) // need to encode unmapped characters to handle special characters (e.g. é)
    .join('');

  // Adding version number to track changes to secret or character domain
  const VERSION_1 = '1';
  const versionProperty = `${VERSION_IDENTIFIER}${VERSION_1}`;
  return `${obfuscatedString}${versionProperty}`;
};

export const deobfuscateUrl = (string: string, secret: string) => {
  generateEncryptionTables(secret);
  const obfuscatedString = string.split(VERSION_IDENTIFIER)[0]; // remove version number

  // If we start using multiple versions via the `vsr` query param, this logic will need to change with the version number
  return obfuscatedString
    .split('')
    .map((char) => decryptionTable[char] || char) // need to decode unmapped characters to handle special characters (e.g. é)
    .join('');
};

export const reverseLookupFromMap = (
  map: Record<string, string>,
  value: string
) => Object.keys(map).find((key) => map[key] === value);

// Used for seamless link user info obfuscation
export const userInfoKeyMap: Record<string, string> = {
  firstName: 'fn',
  lastName: 'ln',
  email: 'e',
  phone: 'p',
};

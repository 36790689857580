import React, {
  MouseEvent,
  MutableRefObject,
  ReactNode,
  useLayoutEffect,
  useRef,
} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

export type PopoverCustomWidth =
  | number
  | 'max-content'
  | 'min-content'
  | 'fit-content';

// 'container' sets the width to 100% of the container
// 'mobile-only' sets the width to 100% of the screen on mobile, and 'max-content' on desktop
export type PopoverFullWidth = 'container' | 'mobile-only';

export type CommonPopoverProps = ConditionalWidthProps & {
  close: (e: MouseEvent<HTMLElement>) => void;
  title?: string;
  forceLeft?: boolean;
  narrowPadding?: boolean;
  nested?: boolean;
};

type ConditionalWidthProps =
  | {
      fullWidth?: PopoverFullWidth;
      fixedWidth?: never;
      customWidth?: never;
      allowOffsetTop?: never;
    }
  | {
      fullWidth?: never;
      fixedWidth?: boolean;
      customWidth?: PopoverCustomWidth;
      allowOffsetTop?: boolean;
    };

type PopoverProps = CommonPopoverProps &
  ConditionalWidthProps & {
    children: ReactNode;
    fixedTopContent?: ReactNode;
    fixedBottomContent?: ReactNode;
    shouldIncludePopoverBackdrop?: boolean;
    forceScroll?: boolean;
  };

const Popover = ({
  title,
  fixedTopContent,
  fixedBottomContent,
  children,
  close,
  allowOffsetTop = true,
  forceLeft,
  forceScroll = true,
  shouldIncludePopoverBackdrop = false,
  fixedWidth,
  fullWidth,
  customWidth,
  narrowPadding = false,
  nested,
}: PopoverProps) => {
  const containerDiv = useRef() as MutableRefObject<HTMLDivElement>;

  // eslint-disable-next-line complexity
  useLayoutEffect(() => {
    const boundingRect = containerDiv.current.getBoundingClientRect();
    const offsetLeft =
      window.innerWidth -
      (containerDiv.current.clientWidth + boundingRect.left);
    const offsetTop =
      window.innerHeight -
      (containerDiv.current.clientHeight + boundingRect.top);
    //TODO verify this; if the dropdown's too far right/down then it exceeds page + scroll bars appear
    //so may want to correct this to < -X px or something rather than 0?
    if (allowOffsetTop) {
      if (offsetTop < 0) {
        containerDiv.current.style.top = offsetTop - 21 + 'px';
      }
    }

    if (!nested) {
      if (forceLeft) {
        containerDiv.current.style.right = '0px';
      } else if (offsetLeft <= 0 && !fullWidth) {
        containerDiv.current.style.left = offsetLeft - 21 + 'px';
      } else {
        containerDiv.current.style.left = '0px';
      }
    }

    if (customWidth) {
      containerDiv.current.style.width =
        typeof customWidth === 'number' ? customWidth + 'px' : customWidth;
    }
  }, []);

  const preventDragging = (e: MouseEvent<HTMLDivElement>) => {
    /*
      clickContainer on the popover will take entire screen so can interfere with dragging events which are used in deathstar places like the Lesson listing.
      To workaround it, we capture the drag start event and prevent the default dragging behaviour and we stop propagation
    */
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      styleName={classNames('popover', {
        'container-width': fullWidth === 'container',
        'full-width-mobile': fullWidth === 'mobile-only',
      })}
      draggable={true}
      onDragStart={preventDragging}
      onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      {!nested && (
        <div
          styleName={classNames('click-container', {
            overlay: shouldIncludePopoverBackdrop,
          })}
          onClick={close}
        />
      )}
      <div
        styleName={classNames('container', {
          'fixed-width': fixedWidth,
          'narrow-padding': narrowPadding,
        })}
        ref={containerDiv}
      >
        {title && <p styleName='title'>{title}</p>}
        {fixedTopContent}
        <div styleName={classNames({'scroll-container': forceScroll})}>
          {children}
        </div>

        {fixedBottomContent}
      </div>
    </div>
  );
};

export default CSSModules(Popover, styles, {allowMultiple: true});

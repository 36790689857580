import {buildRequest} from '../stateUtil';
import {
  ADD_FEED_ITEM,
  DELETE_FEED_ITEM,
  EDIT_FEED_ITEM,
  LOAD_FEED_ITEMS,
} from './feedActionTypes';

/* eslint-disable max-params, max-len */
export const addFeedItem = (
  publishDate,
  title,
  text,
  isImportant,
  type,
  url,
  uploadFilename,
  groupIds,
  expiryDate
) => {
  const media = type !== '' && url ? {type, url, uploadFilename} : null;
  const content = {title, text, media};
  const expiry = expiryDate ? {expiryDate} : {};
  const body = {groupIds, publishDate, content, pinned: isImportant, ...expiry};
  return buildRequest(ADD_FEED_ITEM, 'POST', '/newsFeedItem', body);
};

/* eslint-disable max-params, max-len */
export const editFeedItem = (
  id,
  publishDate,
  title,
  text,
  isImportant,
  type,
  url,
  uploadFilename,
  expiryDate
) => {
  const media = type !== '' ? {type, url, uploadFilename} : null;
  const expiry = expiryDate ? {expiryDate} : {};
  const content = {
    title,
    text,
    pinned: isImportant,
    media,
    publishDate,
    ...expiry,
  };
  return buildRequest(EDIT_FEED_ITEM, 'PUT', `/newsFeedItem/${id}`, content);
};

export const deleteFeedItem = (id) =>
  buildRequest(DELETE_FEED_ITEM, 'DELETE', `/newsFeedItem/${id}`, id);

export const getFeed = (groupId) =>
  buildRequest(LOAD_FEED_ITEMS, 'GET', `/newsFeed/${groupId}`);

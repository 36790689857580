import {fromJS} from 'immutable';
import pickBy from 'lodash/pickBy';

import {LOAD_INVITE_SUCCESS, LOGGED_IN} from '../auth/authActionTypes';
import {SET_GROUP_FEATURE_FLAGS} from './featureFlagsActions';

export const initialState = fromJS({
  customerFlags: {},
  groupFlags: {},
});

export const reducer = (state, action) => {
  switch (action.type) {
    case LOGGED_IN:
      return state.set(
        'customerFlags',
        pickBy(action.payload.company, (value, key) => key.endsWith('Enabled'))
      );

    case LOAD_INVITE_SUCCESS:
      return state.set(
        'customerFlags',
        pickBy(action.payload.data.company, (value, key) =>
          key.endsWith('Enabled')
        )
      );

    case SET_GROUP_FEATURE_FLAGS: {
      const {flags, groupId} = action.payload;
      return state.setIn(['groupFlags', groupId], flags);
    }
    default:
      return state;
  }
};

import {fromJS} from 'immutable';

import {GUIDE} from '../../enums/contentTypes';
import {sortContentByCreationDate} from '../../utils/courseUtil';
import {
  COPY_CONTENT,
  COPY_CONTENT_FAIL,
  COPY_CONTENT_SUCCESS,
  GET_SCHEDULED_NOTIFICATIONS_SUCCESS,
  LOAD_CONTENTS,
  LOAD_CONTENTS_FAIL,
  LOAD_CONTENTS_SUCCESS,
} from '../content/contentActionTypes';
import {
  RESET_GUIDE_NOTIFICATIONS,
  SEND_GUIDE_NOTIFICATIONS,
  SEND_GUIDE_NOTIFICATIONS_FAIL,
  SEND_GUIDE_NOTIFICATIONS_SUCCESS,
} from '../learnerNotification/learnerNotificationActionTypes';
import {
  ASSIGN_GUIDE_TO_GROUPS,
  ASSIGN_GUIDE_TO_GROUPS_SUCCESS,
  CHANGE_GUIDE_TAB,
  DELETE_GUIDE,
  DELETE_GUIDE_FAIL,
  DELETE_GUIDE_SUCCESS,
  GET_GUIDE_ASSIGNED_TEAMS,
  GET_GUIDE_ASSIGNED_TEAMS_SUCCESS,
  GET_GUIDES_NOTIFICATION_DATA_SUCCESS,
  GET_PREVIEW_TOKEN_SUCCESS,
  GUIDE_TABS,
  LOAD_GUIDE,
  LOAD_GUIDE_SUCCESS,
  PUBLISH_GUIDE_SUCCESS,
  RESET_GUIDE_ASSIGNED_TEAMS,
  RESET_PREVIEW_TOKEN,
  SET_IS_USER_ADDING_GUIDE,
  TOGGLE_GUIDE_NOTIFICATIONS_SLIDEPANEL,
  TOGGLE_GUIDE_OTP_SLIDEPANEL,
  UNPUBLISH_GUIDE_SUCCESS,
  UPDATE_GUIDE_SUCCESS,
} from './guideActionTypes';

export const initialState = fromJS({
  guides: [],
  guidesLoading: false,
  guidesLoaded: false,
  guideLoaded: false,
  isAssigning: false,
  editGuideInProgress: false,
  hasGuideDuplicationFailed: false,
  deleteGuideInProgress: false,
  selectedGuideTab: GUIDE_TABS.PREVIEW,
  previewToken: null,
  guideAssignedTeamsLoaded: false,
  guideAssignedTeamIds: [],
  guideTotalLearnersCount: 0,

  paginationPageIndex: 0,
  paginationPageSize: 1000,
  totalGuideCount: null,
  redirectInProgress: false,

  isNotificationsSlidePanelVisible: false,
  isContentLinksSlidePanelVisible: false,
  learnerNotificationsSending: false,
  learnerNotificationsSent: false,
  sentLearnerNotifications: {
    email: null,
    sms: null,
  },
  learnerNotificationsError: false,
  scheduledNotifications: {},
});

//eslint-disable-next-line complexity, max-statements
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONTENTS: {
      return state.set('guidesLoading', true).set('guidesLoaded', false);
    }
    case LOAD_CONTENTS_FAIL: {
      return state.set('guidesLoading', false).set('guidesLoaded', true);
    }
    case LOAD_CONTENTS_SUCCESS: {
      const {guides} = action.payload.data;

      const sorted = sortContentByCreationDate(guides, 'title');

      return state
        .set('guides', fromJS(sorted))
        .set('guidesLoading', false)
        .set('guidesLoaded', true);
    }

    case LOAD_GUIDE: {
      return state.set('guideLoaded', false);
    }
    case PUBLISH_GUIDE_SUCCESS:
    case UNPUBLISH_GUIDE_SUCCESS:
    case UPDATE_GUIDE_SUCCESS:
    case LOAD_GUIDE_SUCCESS: {
      const returnedGuide = action.payload.data;

      const guides = state.get('guides');
      const guideIndex = guides.findIndex(
        (guide) => guide.get('id') === returnedGuide.id
      );

      return state
        .set('guides', guides.mergeIn([guideIndex], fromJS(returnedGuide)))
        .set('guideLoaded', true);
    }

    case DELETE_GUIDE: {
      return state.set('deleteGuideInProgress', true);
    }

    case DELETE_GUIDE_SUCCESS: {
      const guideId = action.meta.guideId;
      return state.set('deleteGuideInProgress', false).set(
        'guides',
        state.get('guides').filter((guide) => guide.get('id') !== guideId)
      );
    }

    case DELETE_GUIDE_FAIL: {
      return state.set('deleteGuideInProgress', false);
    }

    case GET_PREVIEW_TOKEN_SUCCESS: {
      const {token} = action.payload.data;
      return state.set('previewToken', token);
    }
    case RESET_PREVIEW_TOKEN: {
      return state.set('previewToken', null);
    }

    case GET_GUIDE_ASSIGNED_TEAMS: {
      return state.set('guideAssignedTeamsLoaded', false);
    }
    case GET_GUIDE_ASSIGNED_TEAMS_SUCCESS: {
      return state
        .set(
          'guideAssignedTeamIds',
          fromJS(action.payload.data.assignedTeamIds)
        )
        .set('guideTotalLearnersCount', action.payload.data.totalLearnersCount)
        .set('guideAssignedTeamsLoaded', true);
    }
    case RESET_GUIDE_ASSIGNED_TEAMS: {
      return state.set('guideAssignedTeamIds', fromJS([]));
    }

    case ASSIGN_GUIDE_TO_GROUPS:
      return state.set('isAssigning', true);

    case ASSIGN_GUIDE_TO_GROUPS_SUCCESS: {
      return state.set('isAssigning', false);
    }

    case CHANGE_GUIDE_TAB: {
      return state.set('selectedGuideTab', action.payload.tab);
    }

    case TOGGLE_GUIDE_NOTIFICATIONS_SLIDEPANEL: {
      const {visible} = action.payload;
      return state.set('isNotificationsSlidePanelVisible', visible);
    }

    case TOGGLE_GUIDE_OTP_SLIDEPANEL: {
      const {visible} = action.payload;
      return state.set('isContentLinksSlidePanelVisible', visible);
    }

    case SEND_GUIDE_NOTIFICATIONS: {
      return state.set('learnerNotificationsSending', true);
    }

    case SEND_GUIDE_NOTIFICATIONS_SUCCESS: {
      return state
        .set('learnerNotificationsSent', true)
        .set('learnerNotificationsSending', false)
        .set('sentLearnerNotifications', action.payload.data);
    }

    case SEND_GUIDE_NOTIFICATIONS_FAIL: {
      return state
        .set('learnerNotificationsSending', false)
        .set('learnerNotificationsError', true);
    }

    case RESET_GUIDE_NOTIFICATIONS: {
      return state
        .set('learnerNotificationsSending', false)
        .set('learnerNotificationsSent', false)
        .set('sentLearnerNotifications', {
          email: null,
          sms: null,
        });
    }

    case GET_GUIDES_NOTIFICATION_DATA_SUCCESS: {
      const {data} = action.payload;
      const guides = state.get('guides');
      const guidesWithNotificationHistory = guides.toJS().map((guide) => {
        const guideData = data.find((d) => d.guideId === guide.id);
        const teamNotificationHistory =
          (guideData && guideData.teamNotificationHistory) || [];
        const deliveryPreference =
          (guideData && guideData.deliveryPreference) || null;
        return {...guide, teamNotificationHistory, deliveryPreference};
      });

      return state.set('guides', fromJS(guidesWithNotificationHistory));
    }

    case SET_IS_USER_ADDING_GUIDE: {
      return state.set('isUserAddingGuide', action.payload);
    }

    case COPY_CONTENT: {
      const {contentType} = action.meta;
      if (contentType === GUIDE) {
        return state
          .set('editGuideInProgress', true)
          .set('hasGuideDuplicationFailed', false);
      }
      return state;
    }

    case COPY_CONTENT_SUCCESS: {
      const {contentType} = action.meta;
      if (contentType === GUIDE) {
        return state
          .set('editGuideInProgress', false)
          .set('hasGuideDuplicationFailed', false);
      }
      return state;
    }

    case COPY_CONTENT_FAIL: {
      const {contentType} = action.meta;
      if (contentType === GUIDE) {
        return state
          .set('editGuideInProgress', false)
          .set('hasGuideDuplicationFailed', true);
      }
      return state;
    }

    case GET_SCHEDULED_NOTIFICATIONS_SUCCESS: {
      if (action.meta.contentType === GUIDE) {
        const guideId = action.meta.contentId;
        const scheduledNotifications = state
          .get('scheduledNotifications')
          .toJS();
        scheduledNotifications[guideId] = action.payload.data;
        return state.set(
          'scheduledNotifications',
          fromJS(scheduledNotifications)
        );
      }
      return state;
    }

    default: {
      return state;
    }
  }
};

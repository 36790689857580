import {push} from 'connected-react-router';

import {integrationDisplayNames, MS_TEAMS} from '../../enums/integrations';
import {isValidEmail} from '../../utils/validation';
import * as reportingActions from '../reporting/reportingActions';
import * as teamActions from '../team/teamActions';
import {setIntegrationsUsed, setIsTrialSignup} from '../trial/trialActions';
import {START_MS_TEAMS_TRIAL} from '../trial/trialActionTypes';
import * as authActionCreators from './authActionCreators';
import {
  CHANGED_CREDENTIALS,
  CLOSE_ONBOARDING,
  CONTACT_INPUT_CHANGED,
  DELETED_USER,
  ENABLE_ONBOARDING,
  EXPIRED_TOKEN_USED,
  INTEGRATION_CREATED,
  INTEGRATION_DELETED,
  INTEGRATION_UPDATED,
  INVALID_TOKEN_USED,
  INVITE_NOT_FOUND,
  LOGGED_IN,
  LOGGED_IN_MS_TEAMS,
  LOGGED_OUT,
  LOGIN_FAIL,
  LOGIN_FAILED_NOT_ADMIN,
  OLD_TOKEN_VERSION,
  RECORD_CREATE_COURSE,
  RECORD_INVITE_ADMIN,
  RESET_ENTERED_EMAIL,
  RESET_LOGIN_FORM,
  RESET_PASSWORD_DETAILS_REQUEST,
  RESET_PASSWORD_ERROR,
  SEND_PASSWORD_RESET_ERROR,
  START_LOGIN_FROM_COOKIE,
} from './authActionTypes';
import {getVisibleTabs} from './authSelector';

export const resetLoginForm = () => (dispatch) => {
  dispatch({
    type: RESET_LOGIN_FORM,
  });
};

export const loginFromResponse =
  (authResponse, loadTeams, shouldRedirect) => (dispatch, getState) => {
    const {
      id,
      isAdmin,
      name,
      firstName,
      lastName,
      email,
      phone,
      company,
      permissions,
      language,
      hidden,
      hasCreatedCourse,
      hasInvitedAdmins,
      hasSignedInAsLearner,
      isCompanyOwner,
      activationDate,
      createdAt,
      employeeId,
      integrations,
      learnerGroups,
    } = authResponse.data;

    if (!isAdmin) {
      dispatch({
        type: LOGIN_FAILED_NOT_ADMIN,
      });
    } else {
      dispatch({
        type: LOGGED_IN,
        payload: {
          id,
          name,
          firstName,
          lastName,
          email,
          phone,
          employeeId,
          company,
          permissions,
          isAdmin,
          language,
          hidden,
          isCompanyOwner,
          hasCreatedCourse,
          hasInvitedAdmins,
          hasSignedInAsLearner,
          activationDate,
          createdAt,
          integrations,
          learnerGroups,
        },
      });
      if (shouldRedirect) {
        if (
          sessionStorage.getItem('accessMethod') === MS_TEAMS &&
          !company.estimatedLearners &&
          company.isTrial
        ) {
          dispatch(
            setIntegrationsUsed([
              {
                value: integrationDisplayNames[MS_TEAMS],
                label: integrationDisplayNames[MS_TEAMS],
              },
            ])
          );
          dispatch({type: START_MS_TEAMS_TRIAL});
          dispatch(setIsTrialSignup(true));
        }

        redirectToVisibleTab('/', dispatch, getState());
      }
      if (loadTeams) {
        teamActions.loadTeams({fetchExternalTeams: true})(dispatch, getState);
      }
      if (company.isTrial) {
        dispatch(reportingActions.getAdminCount());
      }
    }
  };

export const loginWithLocalStorage =
  ({shouldRedirect = false} = {}) =>
  (dispatch, getState) => {
    dispatch({type: START_LOGIN_FROM_COOKIE});
    dispatch(authActionCreators.whoAmI())
      .then((successAction) =>
        loginFromResponse(
          successAction.payload,
          false,
          shouldRedirect
        )(dispatch, getState)
      )
      .catch((err) => {
        console.error(err);
        if (!err.errorCode === 'invalid_token') {
          //invalid token handled elsewhere; if reset here, overwrites the reason in the UI
          dispatch({type: LOGIN_FAIL});
        }
      });
  };

export const loginWithSSO =
  (redirectUri, authCode, ssoConfigId) => (dispatch) =>
    dispatch(
      authActionCreators.loginWithSSO(redirectUri, authCode, ssoConfigId)
    );

export const loginWithMsTeamsToken = (token) => async (dispatch, getState) => {
  try {
    const response = await dispatch(
      authActionCreators.loginWithMsTeamsToken(token)
    );

    loginFromResponse(response.payload, false, true)(dispatch, getState);

    dispatch({type: LOGGED_IN_MS_TEAMS});
  } catch (err) {
    console.error(err);
    if (!err.errorCode === 'invalid_token') {
      //invalid token handled elsewhere; if reset here, overwrites the reason in the UI
      dispatch({type: LOGIN_FAIL});
    }
  }
};

export const login = (email, password) => (dispatch, getState) => {
  dispatch(authActionCreators.loginUser(email, password)).then(
    (successAction) => {
      const response = successAction.payload;
      loginFromResponse(response)(dispatch, getState);
    }
  );
};

export const checkEmail = (email) => (dispatch) => {
  dispatch(authActionCreators.checkEmail(email));
};

export const handleInvalidToken = () => (dispatch) => {
  logout()(dispatch);
  dispatch({type: INVALID_TOKEN_USED});
};

export const handleExpiredToken = () => (dispatch) => {
  logout()(dispatch);
  dispatch({type: EXPIRED_TOKEN_USED});
};

export const handleOldTokenVersion = () => (dispatch) => {
  logout()(dispatch);
  dispatch({type: OLD_TOKEN_VERSION});
};

export const handleDeletedUser = () => (dispatch) => {
  logout()(dispatch);
  dispatch({type: DELETED_USER});
};

export const logout = () => async (dispatch) => {
  await dispatch(authActionCreators.logout());

  dispatch({type: LOGGED_OUT});
  dispatch(push(''));
};

export const getUserFromInvite =
  (invite, signature, signedUrl) => (dispatch) => {
    const dispatchError = (errorCode) =>
      dispatch({
        type: INVITE_NOT_FOUND,
        payload: errorCode,
      });
    if (!invite) {
      dispatchError('invite_not_found');
    }

    dispatch(
      authActionCreators.getUserFromInvite(invite, signature, signedUrl)
    ).catch((err) => {
      console.error(err);
      dispatchError(err.response.data.errorCode);
    });
  };

export const setPassword = (password) => (dispatch, getState) => {
  const company = getState().getIn(['auth', 'company']);
  dispatch(authActionCreators.setPassword(password, {company}))
    .then(() => {
      redirectToVisibleTab('/', dispatch, getState());
      teamActions.loadTeams({fetchExternalTeams: true})(dispatch, getState);
    })
    .catch((err) => {
      console.error(err);
    });
};

export const setNameAndPassword =
  (firstName, lastName, password) => (dispatch, getState) => {
    const company = getState().getIn(['auth', 'company']);
    dispatch(
      authActionCreators.setNameAndPassword(firstName, lastName, password, {
        company,
      })
    )
      .then(() => {
        redirectToVisibleTab('/', dispatch, getState());
        teamActions.loadTeams({fetchExternalTeams: true})(dispatch, getState);
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const updatePassword = (password) => async (dispatch) => {
  await dispatch(authActionCreators.updatePassword(password));
};

export const enableOnboarding = () => (dispatch) => {
  dispatch({type: ENABLE_ONBOARDING});
};

export const closeOnboarding = () => (dispatch) => {
  dispatch({type: CLOSE_ONBOARDING});
};

export const onChangeCredentials = () => (dispatch) => {
  dispatch({
    type: CHANGED_CREDENTIALS,
  });
};

const redirectToVisibleTab = (pathname, dispatch, state) => {
  const basePath = pathname.split('/')[1];
  //root path is the overview tab
  let permissionName = basePath === '' ? 'overview' : basePath;

  const tabs = getVisibleTabs(state);

  if (tabs.indexOf(permissionName) > -1) {
    dispatch(
      push(
        '/' + (permissionName === 'overview' ? '' : basePath) + location.search
      )
    );
  } else {
    dispatch(push('/' + location.search));
  }
};

export const onChangeContactInput = (input) => (dispatch) => {
  const isInvalid = !isValidEmail(input);
  dispatch({type: CONTACT_INPUT_CHANGED, payload: {input, isInvalid}});
};

export const onFocusContactInput = () => (dispatch, getState) => {
  const input = getState().getIn(['auth', 'resetContactInput']);
  const isInvalid = !isValidEmail(input) && input !== '';
  dispatch({type: CONTACT_INPUT_CHANGED, payload: {input, isInvalid}});
};

export const goToForgotPasswordCompleteScreen = () => (dispatch) => {
  dispatch(push('/forgotPassword/complete'));
};

export const sendPasswordReset = (input) => (dispatch) => {
  const dispatchSendPasswordResetError = (payload) =>
    dispatch({
      type: SEND_PASSWORD_RESET_ERROR,
      payload,
    });
  if (!input) {
    return dispatchSendPasswordResetError('email_empty');
  }
  if (!isValidEmail(input)) {
    return dispatchSendPasswordResetError();
  }

  return dispatch(authActionCreators.sendPasswordReset(input))
    .then(() => goToForgotPasswordCompleteScreen()(dispatch))
    .catch((err) => {
      dispatchSendPasswordResetError(err.response.data.errorCode);
      console.warn(err);
    });
};

export const getUserFromResetToken = (resetToken) => (dispatch) => {
  dispatch({type: RESET_PASSWORD_DETAILS_REQUEST});
  const dispatchResetPasswordError = (payload) =>
    dispatch({
      type: RESET_PASSWORD_ERROR,
      payload,
    });
  if (!resetToken) {
    return dispatchResetPasswordError('reset_token_not_found');
  }

  return dispatch(authActionCreators.getUserFromResetToken(resetToken)).catch(
    (err) => {
      dispatchResetPasswordError(err.response.data.errorCode);
      console.warn(err);
    }
  );
};

export const resetPassword = (resetToken, newPassword) => (dispatch, get) => {
  const dispatchResetPasswordError = (payload) =>
    dispatch({
      type: RESET_PASSWORD_ERROR,
      payload,
    });
  if (!resetToken) {
    return dispatchResetPasswordError('reset_token_not_found');
  }

  return dispatch(authActionCreators.resetPassword(resetToken, newPassword))
    .then((successAction) => {
      const response = successAction.payload;
      loginFromResponse(response, true, true)(dispatch, get);
    })
    .catch((err) => {
      dispatchResetPasswordError(err.response.data.errorCode);
      console.warn(err);
    });
};

export const resetEnteredEmail = () => (dispatch) => {
  dispatch({type: RESET_ENTERED_EMAIL});
};

export const recordInviteAdmin = () => (dispatch) => {
  dispatch({type: RECORD_INVITE_ADMIN});
};

export const recordCreateCourse = () => (dispatch) => {
  dispatch({type: RECORD_CREATE_COURSE});
};

export const loadHints = () => (dispatch) =>
  dispatch(authActionCreators.loadHints()).catch((error) => {
    console.warn(error);
  });

export const dismissHint = (hintId) => (dispatch) => {
  dispatch(authActionCreators.dismissHint(hintId)).catch((error) => {
    console.warn(error);
  });
};

export const undismissHint = (hintId) => (dispatch) => {
  dispatch(authActionCreators.undismissHint(hintId)).catch((error) => {
    console.warn(error);
  });
};

export const addIntegrationToState = (payload) => (dispatch) => {
  dispatch({type: INTEGRATION_CREATED, payload});
};

export const updateIntegrationInState = (payload) => (dispatch) => {
  dispatch({type: INTEGRATION_UPDATED, payload});
};

export const deleteIntegrationFromState = (id) => (dispatch) => {
  dispatch({type: INTEGRATION_DELETED, payload: {id}});
};

export const updateCustomer = (updates) => (dispatch, getState) => {
  const {id} = getState().getIn(['auth', 'company']);
  dispatch(authActionCreators.updateCustomer(id, updates));
};

export const updateUser = (updates) => async (dispatch, getState) => {
  const userId = getState().getIn(['auth', 'id']);

  await dispatch(authActionCreators.updateUser(userId, updates));
};

import {fromJS} from 'immutable';

import {
  ADD_FEED_ITEM,
  ADD_FEED_ITEM_FAIL,
  ADD_FEED_ITEM_SUCCESS,
  EDIT_FEED_ITEM,
  EDIT_FEED_ITEM_FAIL,
  EDIT_FEED_ITEM_SUCCESS,
  LOAD_FEED_ITEMS_SUCCESS,
} from './feedActionTypes';

export const initialState = fromJS({
  items: [],
  editNewsInProgress: false,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_FEED_ITEM:
    case ADD_FEED_ITEM: {
      return state.set('editNewsInProgress', true);
    }
    case ADD_FEED_ITEM_FAIL:
    case ADD_FEED_ITEM_SUCCESS:
    case EDIT_FEED_ITEM_FAIL:
    case EDIT_FEED_ITEM_SUCCESS: {
      return state.set('editNewsInProgress', false);
    }
    case LOAD_FEED_ITEMS_SUCCESS:
      return state.set('items', fromJS(action.payload.data));
    default:
      return state;
  }
};

export const NOT_STARTED = 'notStarted';
export const IN_PROGRESS = 'inProgress';
export const COMPLETED = 'completed';

export const GROUP = 'group';
export const TEAM = 'team';

export const BEST_PRACTICES = 'bestPractices';
export const READY_TO_USE = 'readyToUse';
export const ACCEPTED_LABELS = [BEST_PRACTICES, READY_TO_USE];

export const READY_TO_PUBLISH = 'readyToPublish';
export const UNPUBLISHED = 'unpublished';
export const PUBLISHED = 'published';
export const REVIEW = 'review';

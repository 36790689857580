import {fromJS} from 'immutable';

import {
  CREATE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_SUCCESS,
  LOAD_CONTENT_COLLECTIONS_SUCCESS,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_CONTENT_IN_COLLECTION_SUCCESS,
} from './collectionsActionTypes';

export const initialState = fromJS({
  collections: [],
  collectionsLoaded: false,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONTENT_COLLECTIONS_SUCCESS: {
      return state
        .set('collectionsLoaded', true)
        .set('collections', fromJS(action.payload.data));
    }
    case CREATE_COLLECTION_SUCCESS: {
      const {data} = action.payload;
      data.contentIds = [];
      const collections = state.get('collections');

      return state.set('collections', collections.unshift(fromJS(data)));
    }

    case UPDATE_COLLECTION_SUCCESS: {
      const {name, collectionId} = action.meta;
      const collections = state.get('collections');
      const collectionIndex = collections.findIndex(
        (collection) => collection.get('id') === collectionId
      );
      return state.setIn(['collections', collectionIndex, 'name'], name);
    }

    case DELETE_COLLECTION_SUCCESS: {
      const {collectionId} = action.meta;
      const collections = state.get('collections');

      return state.set(
        'collections',
        collections.filter(
          (collection) => collection.get('id') !== collectionId
        )
      );
    }

    case UPDATE_CONTENT_IN_COLLECTION_SUCCESS: {
      const {collectionId, contentToAdd, contentToRemove} = action.meta;
      const collections = state.get('collections');
      const collectionIndex = collections.findIndex(
        (collection) => collection.get('id') === collectionId
      );
      const collection = collections.get(collectionIndex);

      const updatedCollection = collection.update(
        'contentIds',
        (contentIds) => {
          if (contentToAdd) {
            return [...contentIds, contentToAdd.contentId];
          } else if (contentToRemove) {
            return contentIds.filter(
              (contentId) => contentId !== contentToRemove.contentId
            );
          } else {
            return contentIds;
          }
        }
      );

      return state.setIn(['collections', collectionIndex], updatedCollection);
    }
    default:
      return state;
  }
};

// sort-imports-ignore
import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {ExternalLinkIcon, LegacyHeading} from '@edume/magnificent'; // eslint-disable-line workspaces/require-dependency

import image from '../../../../resources/img/promo-guide.svg';

import styles from './styles.module.scss';

const HeroLink = ({withIcon, intl, breakpoint = 'regular', logReduxAction}) => (
  <div
    styleName={`container ${breakpoint}`}
    onClick={() => {
      logReduxAction('Inspiration library');
      window.open('https://help.edume.com/inspiration-library', '_blank');
    }}
  >
    <img
      alt={intl.formatMessage({id: 'Overview.promo.image.alt'})}
      styleName='icon'
      src={image}
    />
    <div styleName='text'>
      <LegacyHeading size='micro' weight='medium' inline>
        {intl.formatMessage({id: 'Overview.promo.heading'})}
      </LegacyHeading>
      {withIcon && (
        <span styleName='link-icon'>
          <ExternalLinkIcon size='tiny' colour='brandPrimary' />
        </span>
      )}
      <LegacyHeading size='mini'>
        {intl.formatMessage({id: 'Overview.promo.body'})}
      </LegacyHeading>
    </div>
  </div>
);

HeroLink.propTypes = {
  withIcon: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  breakpoint: PropTypes.oneOf(['regular', 'wide']),
  logReduxAction: PropTypes.func,
};

export default CSSModules(HeroLink, styles, {allowMultiple: true});

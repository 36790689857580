import _ from 'lodash';
// we should remove lodash
import {z} from 'zod';

import {isLengthInRange} from './surveyValidation';

export const EMPTY_AI_LESSON_DESCRIPTION = 'AI error';

const validHexColourRegex = /^#([A-F0-9]{3})(?:[A-F0-9]{3})?$/i;

//validation methods
const isString = (val: any) => typeof val === 'string';
const isNonEmptyString = (val: any) =>
  typeof val === 'string' && val.length > 0;
const isNumber = (val: any) => typeof val === 'number';
const isBoolean = (val: any) => typeof val === 'boolean';
const isArrayOfStrings = (val: any) =>
  Array.isArray(val) && _.every(val, (v: any) => typeof v === 'string');
const isArrayOfStringsOrNull = (val: any) =>
  Array.isArray(val) &&
  _.every(val, (v: any) => typeof v === 'string' || v === null);
const isNonEmptyArrayOfNonEmptyStrings = (val: any) =>
  Array.isArray(val) &&
  val.length > 0 &&
  _.every(val, (v: any) => typeof v === 'string' && v.length > 0);
const isValidQuizType = (val: any) =>
  ['singleChoice', 'multipleChoice', 'mixed'].includes(val);
const isValidMultipleChoiceQuestionType = (val: string) =>
  ['singleResponse', 'multipleResponse'].includes(val);
const isValidOrderingQuestionType = (val: string) => val === 'ordering';
const isValidQuestionType = (val: string) =>
  isValidOrderingQuestionType(val) || isValidMultipleChoiceQuestionType(val);
const isValidOrderingType = (val: any) => ['text'].includes(val);
const isValidVideoSource = (val: any) =>
  ['cloudinary', 'youtube'].includes(val);
// 3 or 6 char hex color with # at start
const isValidHexColor = (val: any) =>
  typeof val === 'string' && validHexColourRegex.test(val);
export const isValidCustomisedButtonText = (val: any) =>
  typeof val === 'string' && val.length > 0;
const isValidCustomisedTitleText = (val: any) =>
  typeof val === 'string' && val.length > 0 && val.length <= 35;
const isValidCustomisedSubtitleText = (val: any) =>
  typeof val === 'string' && val.length >= 0 && val.length <= 200;

const isValidMinTime = (val: any) =>
  val === null || (isNumber(val) && val >= 0 && val <= 120);
const isValidOverrideProgressButton = (val: any) =>
  typeof val === 'undefined' || val === null || isBoolean(val);
const isValidZoomToFit = (val: any) =>
  typeof val === 'undefined' || val === null || isBoolean(val);
const isValidIframeHeightOffset = (val: any) =>
  typeof val === 'undefined' || val === null || isNumber(val);
const isValidHideIntroSlide = (val: any) =>
  typeof val === 'undefined' || val === null || isBoolean(val);

export const isValidEmbeddedUrl = (val: any) => {
  if (!isNonEmptyString(val)) {
    return false;
  }
  if (val.includes('/lessons/customerActivityContent/')) {
    return true;
  }
  return /^(?:https:\/\/)?[^/]*(?:(figma)|(matterport)|(kaltura)|(mindstamp))[^/]*\/\S+$/.test(
    val
  );
};

const maxOrderingAnswerLength = 60;

const validHexColourSchema = z.string().regex(validHexColourRegex);

const withCustomisationsSchema = z.object({
  background: validHexColourSchema,
  fontColour: validHexColourSchema.optional(),
  isLightButtonStyle: z.boolean().optional(),
});

// eslint-disable-next-line complexity
const validUrlOrUrls = (imageUrlSpec: any, mandatory = true) => {
  if (!mandatory) {
    return imageUrlSpec.urls
      ? isArrayOfStringsOrNull(imageUrlSpec.urls)
      : isString(imageUrlSpec.url) ||
          imageUrlSpec.url === null ||
          typeof imageUrlSpec.url === 'undefined';
  }
  if (!imageUrlSpec.urls) {
    return isString(imageUrlSpec.url);
  } else {
    const areUrlsValid =
      imageUrlSpec.urls.length === imageUrlSpec.questions.length &&
      imageUrlSpec.urls.every(
        (url: any) =>
          url === null || typeof url === 'undefined' || isString(url)
      );
    const areThumbnailUrlsValid = imageUrlSpec.thumbnailUrls
      ? imageUrlSpec.thumbnailUrls.length === imageUrlSpec.urls.length &&
        imageUrlSpec.thumbnailUrls.every(
          (val: any) =>
            val === null || typeof val === 'undefined' || isString(val)
        )
      : imageUrlSpec.thumbnailUrl === null ||
        typeof imageUrlSpec.thumbnailUrl === 'undefined' ||
        isString(imageUrlSpec.thumbnailUrl);
    return areUrlsValid && areThumbnailUrlsValid;
  }
};

// eslint-disable-next-line complexity
export const areValidOptions = (options: any, mandatory = true) => {
  for (const option of options) {
    const validText = mandatory
      ? isNonEmptyString(option.text)
      : isString(option.text);

    const invalidFeedbackSelection =
      mandatory &&
      ((option.tip === '' && !isNonEmptyString(option.videoFeedback?.url)) ||
        (option.videoFeedback?.url === '' && !isNonEmptyString(option.tip)));

    if (
      !validText ||
      invalidFeedbackSelection ||
      typeof option.isCorrect !== 'boolean' ||
      (typeof option.tip !== 'undefined' && typeof option.tip !== 'string') ||
      (typeof option.videoFeedback?.url !== 'undefined' &&
        typeof option.videoFeedback?.url !== 'string') ||
      (typeof option.videoFeedback?.title !== 'undefined' &&
        (mandatory
          ? !(
              typeof option.videoFeedback?.title === 'string' &&
              isLengthInRange(option.videoFeedback?.title, 0, 30, true)
            )
          : typeof option.videoFeedback?.title !== 'string'))
    ) {
      return false;
    }
  }
  return hasCorrectOptionSet(options, mandatory);
};

const hasCorrectOptionSet = (options: any, mandatory = true) =>
  !mandatory || options.some((option: any) => option.isCorrect);

//boilerplate
const validatorWrapper = (
  validator: any,
  allowIncompleteActivities: boolean
) => {
  const env = {
    context: [],
  };
  const validateParam = (param: any, validate: any, mandatory = true) => {
    const paramName = Object.keys(param)[0];
    const paramValue = param[paramName];
    const validateName = Object.keys(validate)[0];
    const validateFn = validate[validateName];

    if (mandatory === true) {
      if (!validateFn(paramValue, mandatory)) {
        (
          env as any
        ).reason = `Mandatory param ${paramName} failed validation criteria of ${validateName}; value was ${JSON.stringify(
          paramValue
        )}`;
        return false;
      }
      // Allow fields to be invalid while editing
    } else if (!allowIncompleteActivities) {
      /* eslint-disable no-lonely-if */
      if (
        typeof paramValue !== 'undefined' &&
        paramValue !== '' &&
        !validateFn(paramValue, mandatory)
      ) {
        (
          env as any
        ).reason = `Optional param ${paramName} failed validation criteria of ${validateName}; value was ${JSON.stringify(
          paramValue
        )}`;
        return false;
      }
      /* eslint-enable no-lonely-if */
    }
    return true;
  };
  const validateEson = (eson: any) => {
    const {params, type} = eson;
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    env.context.push(type);
    if (!(type in validatorClosure)) {
      (env as any).reason = `eson type '${type}' not available`;
      return false;
    }
    if (params === null || typeof params !== 'object') {
      (env as any).reason = `params for eson of type '${type}' not supplied`;
      return false;
    }
    const result = validatorClosure[type](params);
    if (!(env as any).reason) {
      env.context.pop();
    }
    return result;
  };
  const validateEsons = (esonsObject: any, atLeastOne = true) => {
    const esonsName = Object.keys(esonsObject)[0];
    const esons = esonsObject[esonsName];

    if (atLeastOne) {
      if (Array.isArray(esons) && esons.length > 0) {
        return _.every(esons, (eson: any) => validateEson(eson));
      } else {
        (
          env as any
        ).reason = `At least one of ${esonsName} need to be specified`;
        return false;
      }
    } else {
      if (!esons) {
        //allowing empty children (*)
        return true;
      }
      return (
        Array.isArray(esons) &&
        _.every(esons, (eson: any) => validateEson(eson))
      );
    }
  };

  const parseResult = (result: any) => {
    if (result === true) {
      return {
        result,
      };
    } else {
      return {
        result,
        failedReason: (env as any).reason,
        failedEsonContext: env.context,
      };
    }
  };

  const validatorClosure = validator(
    validateParam,
    validateEson,
    validateEsons
  );

  //returned function can validate any level of eson - from full lessons to smaller activities
  return (eson: any) => {
    const result = validateEson(eson);
    return parseResult(result);
  };
};

const optionalCustomisedButtonParamSchema = z
  .object({
    text: z.string().min(1),
  })
  .optional();

const validateCustomisedButtonParam = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  if (!params) {
    return true;
  }
  const {text} = params;
  return validateParam(
    {text},
    {isValidCustomisedButtonText},
    !allowIncompleteActivities
  );
};

const validateLearningOutcomes = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  if (allowIncompleteActivities && !params) {
    return true;
  }
  const {title, outcomes, cheer, button} = params;
  const testOutcomes = allowIncompleteActivities
    ? isArrayOfStrings
    : isNonEmptyArrayOfNonEmptyStrings;
  return (
    validateParam({title}, {isNonEmptyString}, !allowIncompleteActivities) &&
    validateParam({outcomes}, {testOutcomes}, !allowIncompleteActivities) &&
    validateParam({cheer}, {isNonEmptyString}, false) &&
    validateCustomisedButtonParam(
      validateParam,
      button,
      allowIncompleteActivities
    )
  );
};

const imageParamSchema = z
  .object({
    url: z.string().min(1),
    position: z.enum(['back', 'top', 'bottom']),
    width: z.number().optional(),
    height: z.number().optional(),
  })
  .nullish();

const learningOutcomesSchema = withCustomisationsSchema.extend({
  title: z.string().min(1),
  outcomes: z.array(z.string().min(1)).min(1),
  cheer: z.string().optional(),
  image: imageParamSchema,
  button: optionalCustomisedButtonParamSchema,
});

export type V4LearningOutcomes = z.infer<typeof learningOutcomesSchema>;

const validateIntro = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  if (allowIncompleteActivities && !params) {
    return true;
  }
  const {greeting, description, welcomeImageUrl, button} = params;
  const testString = allowIncompleteActivities
    ? {isString}
    : {isNonEmptyString};
  return (
    validateParam({welcomeImageUrl}, testString, false) &&
    validateParam({greeting}, testString, !allowIncompleteActivities) &&
    validateParam({description}, testString, !allowIncompleteActivities) &&
    validateCustomisedButtonParam(
      validateParam,
      button,
      allowIncompleteActivities
    )
  );
};

//note: there are no checks for the numbers of (in)correct answers are sane based on quizType or anything
const validateMultipleChoice = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {question, prompt, options} = params;
  return (
    validateParam({question}, {isNonEmptyString}, !allowIncompleteActivities) &&
    validateParam({prompt}, {isNonEmptyString}, false) &&
    validateParam({options}, {areValidOptions}, !allowIncompleteActivities)
  );
};
const validateMixedQuizMultipleChoiceQuestion = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {type} = params;
  return (
    validateMultipleChoice(validateParam, params, allowIncompleteActivities) &&
    validateParam(
      {type},
      {isValidMultipleChoiceQuestionType},
      !allowIncompleteActivities
    )
  );
};

const validateMixedQuizOrderingQuestion = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {question, prompt, options, type} = params;
  return (
    validateParam(
      {question},
      {isValidMixedQuizOrderingQuestion},
      !allowIncompleteActivities
    ) &&
    validateParam(
      {prompt},
      {isValidMixedQuizOrderingPrompt},
      !allowIncompleteActivities
    ) &&
    validateParam(
      {options},
      {areValidOrderingOptions},
      !allowIncompleteActivities
    ) &&
    validateParam(
      {type},
      {isValidOrderingQuestionType},
      !allowIncompleteActivities
    )
  );
};

const validateMixedQuizQuestion = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {type} = params;
  const validationFunc =
    type === 'ordering'
      ? validateMixedQuizOrderingQuestion
      : validateMixedQuizMultipleChoiceQuestion;
  return (
    validationFunc(validateParam, params, allowIncompleteActivities) &&
    validateParam({type}, {isValidQuestionType}, !allowIncompleteActivities)
  );
};

const validateVideo = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  if (!params) {
    return true;
  }
  const {url, source} = params;
  return (
    validateParam({url}, {isNonEmptyString}, !allowIncompleteActivities) &&
    validateParam({source}, {isValidVideoSource}, !allowIncompleteActivities)
  );
};

const validateQuestions = (
  validateParam: any,
  params = [],
  allowIncompleteActivities: any
) =>
  params.every((question) =>
    validateMultipleChoice(validateParam, question, allowIncompleteActivities)
  );

const validateMixedQuizQuestions = (
  validateParam: any,
  params = [],
  allowIncompleteActivities: any
) =>
  params.every((question) =>
    validateMixedQuizQuestion(
      validateParam,
      question,
      allowIncompleteActivities
    )
  );

const validateOrdering = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {quizType, prompt, answers, progressButton} = params;
  const testAnswers = allowIncompleteActivities
    ? isArrayOfStrings
    : isNonEmptyArrayOfNonEmptyStrings;

  return (
    validateParam(
      {quizType},
      {isValidOrderingType},
      !allowIncompleteActivities
    ) &&
    validateParam({prompt}, {isValidOrderingPrompt}, false) &&
    validateParam({answers}, {testAnswers}, !allowIncompleteActivities) &&
    validateParam(
      {answers},
      {
        areValidAnswers: (as: any) =>
          allowIncompleteActivities || areValidOrderingAnswers(as),
      },
      !allowIncompleteActivities
    ) &&
    validateCustomisedButtonParam(
      validateParam,
      progressButton,
      allowIncompleteActivities
    )
  );
};

const validateQuiz = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any,
  isMixedQuizType = false
) => {
  const {quizType, shuffleAnswers, prompt, progressButton} = params;

  return (
    validateParam({quizType}, {isValidQuizType}, !allowIncompleteActivities) &&
    validateParam({shuffleAnswers}, {isBoolean}, !allowIncompleteActivities) &&
    (isMixedQuizType || validateParam({prompt}, {isNonEmptyString}, false)) &&
    validateCustomisedButtonParam(
      validateParam,
      progressButton,
      allowIncompleteActivities
    )
  );
};

const validateStandaloneQuiz = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {questions, quizType} = params;
  const isMixedQuizType = quizType === 'mixed';
  const validateQuestionsFunc = isMixedQuizType
    ? validateMixedQuizQuestions
    : validateQuestions;

  return (
    validateQuiz(
      validateParam,
      params,
      allowIncompleteActivities,
      isMixedQuizType
    ) &&
    validateQuestionsFunc(validateParam, questions, allowIncompleteActivities)
  );
};

const validateV3dot1ImageQuiz = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {url, urls, thumbnailUrl, thumbnailUrls, questions} = params;
  return (
    validateParam(
      {imageUrlSpec: {url, urls, thumbnailUrl, thumbnailUrls, questions}},
      {validUrlOrUrls},
      !allowIncompleteActivities
    ) &&
    validateStandaloneQuiz(validateParam, params, allowIncompleteActivities)
  );
};

const validateV3dot1InteractiveActivity = (
  validateParam: any,
  validateEson: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {
    background,
    taskBoxTitle,
    taskBoxText,
    video,
    interaction,
    taskBoxButton,
  } = params;
  return (
    validateParam(
      {background},
      {isValidHexColor},
      !allowIncompleteActivities
    ) &&
    validateParam({taskBoxTitle}, {isNonEmptyString}, false) &&
    validateParam(
      {taskBoxText},
      {isNonEmptyString},
      !allowIncompleteActivities
    ) &&
    validateVideo(validateParam, video, allowIncompleteActivities) &&
    validateEson(interaction) &&
    validateCustomisedButtonParam(
      validateParam,
      taskBoxButton,
      allowIncompleteActivities
    )
  );
};

const validateText = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {title, description} = params;
  return (
    validateParam({title}, {isNonEmptyString}, !allowIncompleteActivities) &&
    validateParam({description}, {isNonEmptyString}, !allowIncompleteActivities)
  );
};

const textSlideSchema = withCustomisationsSchema.extend({
  title: z.string().min(1),
  description: z.string().min(1),
  button: optionalCustomisedButtonParamSchema,
  image: imageParamSchema,
});

export type V4Text = z.infer<typeof textSlideSchema>;

const validateV4Text = createValidationFunctionFromSchema(textSlideSchema);

// eslint-disable-next-line complexity
const validateV4ThirdParty = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: boolean
) => {
  const {
    url,
    title,
    description,
    background,
    fontColour,
    button,
    introButton,
    minTime,
    overrideProgressButton,
    zoomToFit,
    iframeHeightOffset,
    hideIntroSlide,
  } = params;
  return (
    validateParam({url}, {isValidEmbeddedUrl}, !allowIncompleteActivities) &&
    validateParam({title}, {isNonEmptyString}, false) &&
    validateParam(
      {description},
      {isNonEmptyString},
      !allowIncompleteActivities
    ) &&
    validateParam(
      {background},
      {isValidHexColor},
      !allowIncompleteActivities
    ) &&
    validateParam({fontColour}, {isValidHexColor}, false) &&
    validateParam({minTime}, {isValidMinTime}, !allowIncompleteActivities) &&
    validateParam(
      {overrideProgressButton},
      {isValidOverrideProgressButton},
      !allowIncompleteActivities
    ) &&
    validateParam(
      {zoomToFit},
      {isValidZoomToFit},
      !allowIncompleteActivities
    ) &&
    validateParam(
      {iframeHeightOffset},
      {isValidIframeHeightOffset},
      !allowIncompleteActivities
    ) &&
    validateParam(
      {hideIntroSlide},
      {isValidHideIntroSlide},
      !allowIncompleteActivities
    ) &&
    validateCustomisedButtonParam(
      validateParam,
      button,
      allowIncompleteActivities
    ) &&
    validateCustomisedButtonParam(
      validateParam,
      introButton,
      allowIncompleteActivities
    )
  );
};

const validateImage = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {url, caption, width, height, button} = params;
  return (
    validateParam({url}, {isNonEmptyString}, !allowIncompleteActivities) &&
    validateParam({caption}, {isNonEmptyString}, false) &&
    validateParam({width}, {isNumber}, false) &&
    validateParam({height}, {isNumber}, false) &&
    validateCustomisedButtonParam(
      validateParam,
      button,
      allowIncompleteActivities
    )
  );
};

const validateVideoActivity = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {url, source, caption, button} = params;
  return (
    validateParam({url}, {isNonEmptyString}, !allowIncompleteActivities) &&
    validateParam({source}, {isValidVideoSource}, !allowIncompleteActivities) &&
    validateParam({caption}, {isNonEmptyString}, false) &&
    validateCustomisedButtonParam(
      validateParam,
      button,
      allowIncompleteActivities
    )
  );
};

const resultsSchema = z
  .object({
    minimumScore: z.undefined(),
    message: z.string(),
  })
  .or(
    z.object({
      minimumScore: z.number().min(0).max(100).multipleOf(5),
      passMessage: z.string(),
      failMessage: z.string(),
    })
  );

const validateV4Video = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any
) => {
  const {url, source} = params;
  return (
    validateParam({url}, {isNonEmptyString}, !allowIncompleteActivities) &&
    validateParam({source}, {isValidVideoSource}, !allowIncompleteActivities)
  );
};

// eslint-disable-next-line complexity
export const validateV4CustomisedEndOfActivityScreen = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any,
  endOfActivityEnabled = true
) => {
  const {
    title,
    subtitle,
    button,
    background,
    fontColour,
    icon,
    endOfActivityCustomTextOptions,
  } = params;

  if (!endOfActivityEnabled) {
    return true;
  }

  const colourAndImageChecks =
    validateParam(
      {background},
      {isValidHexColor},
      !allowIncompleteActivities
    ) &&
    validateParam(
      {fontColour},
      {isValidHexColor},
      !allowIncompleteActivities
    ) &&
    validateParam({icon: icon?.url}, {isNonEmptyString}, false);

  if (endOfActivityCustomTextOptions) {
    return (
      colourAndImageChecks &&
      validateParam(
        {button: button?.text},
        {isValidCustomisedButtonText},
        !allowIncompleteActivities
      ) &&
      validateParam(
        {title},
        {isValidCustomisedTitleText},
        !allowIncompleteActivities
      ) &&
      validateParam({subtitle}, {isValidCustomisedSubtitleText}, false)
    );
  } else {
    return colourAndImageChecks;
  }
};

export const validateV3dot1CustomisedEndOfActivityScreen = (
  validateParam: any,
  params: any,
  allowIncompleteActivities: any,
  endOfActivityEnabled = true
) => {
  if (!endOfActivityEnabled) {
    return true;
  }

  const {title, subtitle, button, icon, endOfActivityCustomTextOptions} =
    params;

  if (endOfActivityCustomTextOptions) {
    return (
      validateParam(
        {button: button?.text},
        {isValidCustomisedButtonText},
        !allowIncompleteActivities
      ) &&
      validateParam(
        {title},
        {isValidCustomisedTitleText},
        !allowIncompleteActivities
      ) &&
      validateParam({subtitle}, {isValidCustomisedSubtitleText}, false) &&
      validateParam({icon: icon?.url}, {isNonEmptyString}, false)
    );
  } else {
    return validateParam({icon: icon?.url}, {isNonEmptyString}, false);
  }
};

const v3dot1Validator =
  (allowIncompleteActivities: any, endOfActivityEnabled: any) =>
  // @ts-expect-error TS(7006): Parameter 'validateParam' implicitly has an 'any' ... Remove this comment to see the full error message
  (validateParam, validateEson, validateEsons) => ({
    // eslint-disable-next-line complexity
    lesson: (params: any) => {
      const {
        title,
        previewImageUrl,
        completionMessage,
        intro,
        learningOutcomes,
        activities,
        end,
        endOfActivityCustomTextOptions,
      } = params;
      return (
        validateParam(
          {title},
          {isNonEmptyString},
          !allowIncompleteActivities
        ) &&
        validateParam({completionMessage}, {isString}, false) &&
        validateParam({previewImageUrl}, {isString}, false) &&
        (!intro || validateEson({type: 'intro', params: intro})) &&
        (!learningOutcomes ||
          validateEson({type: 'learningOutcomes', params: learningOutcomes})) &&
        validateEsons({activities}, !allowIncompleteActivities) &&
        (!end ||
          validateEson({
            type: 'end',
            params: {...end, endOfActivityCustomTextOptions},
          }))
      );
    },

    intro: (params: any) =>
      _.isEmpty(params) ||
      !params ||
      validateIntro(validateParam, params, allowIncompleteActivities),

    learningOutcomes: (params: any) =>
      _.isEmpty(params) ||
      !params ||
      validateLearningOutcomes(
        validateParam,
        params,
        allowIncompleteActivities
      ),

    end: (params: any) =>
      _.isEmpty(params) ||
      !params ||
      validateV3dot1CustomisedEndOfActivityScreen(
        validateParam,
        params,
        allowIncompleteActivities,
        endOfActivityEnabled
      ),

    text: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateText(validateParam, params, allowIncompleteActivities),

    image: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateImage(validateParam, params, allowIncompleteActivities),

    video: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateVideoActivity(validateParam, params, allowIncompleteActivities),

    interactive: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV3dot1InteractiveActivity(
        validateParam,
        validateEson,
        params,
        allowIncompleteActivities
      ),

    standaloneQuiz: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateStandaloneQuiz(validateParam, params, allowIncompleteActivities),

    imageQuiz: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV3dot1ImageQuiz(validateParam, params, allowIncompleteActivities),

    ordering: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateOrdering(validateParam, params, allowIncompleteActivities),
  });

const v4Validator =
  (allowIncompleteActivities: boolean, endOfActivityEnabled: boolean) =>
  // @ts-expect-error TS(7006): Parameter 'validateParam' implicitly has an 'any' ... Remove this comment to see the full error message
  (validateParam, validateEson, validateEsons) => ({
    // eslint-disable-next-line complexity
    lesson: (params: any) => {
      const {
        title,
        previewImageUrl,
        completionMessage,
        intro,
        learningOutcomes,
        activities,
        end,
        endOfActivityCustomTextOptions,
      } = params;
      return (
        validateParam(
          {title},
          {isNonEmptyString},
          !allowIncompleteActivities
        ) &&
        validateParam({completionMessage}, {isString}, false) &&
        validateParam({previewImageUrl}, {isString}, false) &&
        (!intro ||
          validateIntroSlide(intro, allowIncompleteActivities).result) &&
        (!learningOutcomes ||
          validateLearningOutcomesSlide(
            learningOutcomes,
            allowIncompleteActivities
          ).result) &&
        validateEsons({activities}, !allowIncompleteActivities) &&
        (!end ||
          validateEson({
            type: 'end',
            params: {...end, endOfActivityCustomTextOptions},
          }))
      );
    },

    end: (params: any) =>
      _.isEmpty(params) ||
      !params ||
      validateV4CustomisedEndOfActivityScreen(
        validateParam,
        params,
        allowIncompleteActivities,
        endOfActivityEnabled
      ),

    text: (params: unknown) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV4Text(params, allowIncompleteActivities).result,

    thirdParty: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV4ThirdParty(validateParam, params, allowIncompleteActivities),

    video: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV4Video(validateParam, params, allowIncompleteActivities),

    interactive: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV3dot1InteractiveActivity(
        validateParam,
        validateEson,
        params,
        allowIncompleteActivities
      ),

    standaloneQuiz: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateStandaloneQuiz(validateParam, params, allowIncompleteActivities),

    imageQuiz: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV3dot1ImageQuiz(validateParam, params, allowIncompleteActivities),

    ordering: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateOrdering(validateParam, params, allowIncompleteActivities),
  });

export const v4AssessmentValidator =
  (allowIncompleteActivities: boolean) =>
  (validateParam: any, validateEson: any, validateEsons: any) => ({
    assessment: (params: any) => {
      const {title, previewImageUrl, intro, activities, results, minimumScore} =
        params;
      return (
        validateParam(
          {title},
          {isNonEmptyString},
          !allowIncompleteActivities
        ) &&
        validateParam({previewImageUrl}, {isString}, false) &&
        validateParam({minimumScore}, {isNumber}, false) &&
        (!intro ||
          validateIntroSlide(intro, allowIncompleteActivities).result) &&
        validateEsons({activities}, !allowIncompleteActivities) &&
        (!results ||
          validateResultsSlide(results, allowIncompleteActivities, minimumScore)
            .result) &&
        validateCustomisedButtonParam(
          validateParam,
          intro.button,
          allowIncompleteActivities
        ) &&
        validateCustomisedButtonParam(
          validateParam,
          activities[0].params.interaction.params.progressButton,
          allowIncompleteActivities
        )
      );
    },

    interactive: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV3dot1InteractiveActivity(
        validateParam,
        validateEson,
        params,
        allowIncompleteActivities
      ),

    standaloneQuiz: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateStandaloneQuiz(validateParam, params, allowIncompleteActivities),

    imageQuiz: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateV3dot1ImageQuiz(validateParam, params, allowIncompleteActivities),

    ordering: (params: any) =>
      (allowIncompleteActivities && _.isEmpty(params)) ||
      validateOrdering(validateParam, params, allowIncompleteActivities),
  });

//<Lesson> → {result: <Boolean>, failedReason?: <string>, failedEsonContext?: <[<string>]>}
export const validateLesson = (
  lesson: any,
  allowIncompleteActivities: any,
  endOfActivityEnabled?: any
) => {
  switch (lesson.version) {
    case 4: {
      const validator = validatorWrapper(
        v4Validator(allowIncompleteActivities, endOfActivityEnabled),
        allowIncompleteActivities
      );
      return validator(lesson);
    }
    case 3.1: {
      const validator = validatorWrapper(
        v3dot1Validator(allowIncompleteActivities, endOfActivityEnabled),
        allowIncompleteActivities
      );
      return validator(lesson);
    }
    default: {
      return {
        result: false,
        failedReason: `Invalid lesson version given: ${lesson.version}`,
      };
    }
  }
};

export const validateAssessment = (
  assessment: any,
  allowIncompleteActivities?: any
) => {
  switch (assessment.version) {
    case 4: {
      const validator = validatorWrapper(
        v4AssessmentValidator(allowIncompleteActivities),
        allowIncompleteActivities
      );
      return validator(assessment);
    }
    default: {
      return {
        result: false,
        failedReason: `Invalid assessment version given: ${assessment.version}`,
      };
    }
  }
};

export const validateLessonOrAssessment = (
  content: any,
  allowIncompleteActivities: any,
  endOfActivityEnabled: any
) => {
  const validationFunc =
    content.type === 'assessment' ? validateAssessment : validateLesson;
  return validationFunc(
    content,
    allowIncompleteActivities,
    endOfActivityEnabled
  );
};

// Avoid filtering out ALL outcomes
const filterOutcomes = (outcome: any, i: any, hasAnyOutcomes: any) =>
  (i === 0 && !hasAnyOutcomes) || !!outcome;
const filterOptions = (option: any, i: any) => i < 2 || !!option.text;

export const removeEmptyAnswersFromV3Activity = (activity: any) => {
  if (
    activity.type === 'interactive' &&
    activity.params.interaction?.params.questions
  ) {
    const isNonEmptyQuestion = (question: any) =>
      question.options.some((option: any) => !!option.text) ||
      !!question.question;

    const indexesForRemoval =
      activity.params.interaction.params.questions.reduce(
        (prevVal: any, currVal: any, currIndex: any) => {
          if (!isNonEmptyQuestion(currVal)) {
            prevVal.push(currIndex);
          }
          return prevVal;
        },
        []
      );

    if (
      indexesForRemoval.length ===
      activity.params.interaction.params.questions.length
    ) {
      indexesForRemoval.shift();
    }

    const paramsKeysToFilterByIndex = [
      'questions',
      'urls',
      'urlsCkey',
      'thumbnailUrls',
      'thumbnailUrlsCkey',
    ];
    const isIndexToKeep = (_val: any, index: any) =>
      !indexesForRemoval.includes(index);

    paramsKeysToFilterByIndex.forEach((key) => {
      if (activity.params.interaction.params[key]) {
        activity.params.interaction.params[key] =
          activity.params.interaction.params[key].filter(isIndexToKeep);
      }
    });

    activity.params.interaction.params.questions.forEach(
      (question: any, index: number) => {
        if (question.options) {
          question.options = question.options.filter(filterOptions);
        }
        if (question.type === 'ordering') {
          question.options = question.options.map((option: any) => {
            delete option.isCorrect;
            delete option.tip;
            delete option.videoFeedback;
            return option;
          });
          if (activity.params.interaction.params.urls?.[index]) {
            activity.params.interaction.params.urls[index] = '';
          }
          if (activity.params.interaction.params.thumbnailUrls?.[index]) {
            activity.params.interaction.params.thumbnailUrls[index] = '';
          }
        }
      }
    );
  }
  return activity;
};

export const removeEmptyAnswersFromV4Activity =
  removeEmptyAnswersFromV3Activity;

export const removeEmptyV3Outcomes = (learningOutcomes: any) => {
  if (!learningOutcomes.outcomes) {
    return learningOutcomes;
  }
  const hasAnyOutcomes = learningOutcomes.outcomes.some((o: any) => !!o);
  if (learningOutcomes.outcomesCkey) {
    const outcomesWithCkeys = learningOutcomes.outcomes.map(
      (o: any, i: any) => [o, learningOutcomes.outcomesCkey[i]]
    );
    // @ts-expect-error TS(7031): Binding element 'o' implicitly has an 'any' type.
    const filteredOutcomes = outcomesWithCkeys.filter(([o], i: any) =>
      filterOutcomes(o, i, hasAnyOutcomes)
    );
    // @ts-expect-error TS(7031): Binding element 'o' implicitly has an 'any' type.
    learningOutcomes.outcomes = filteredOutcomes.map(([o]) => o);
    // @ts-expect-error TS(7031): Binding element 'cKey' implicitly has an 'any' typ... Remove this comment to see the full error message
    learningOutcomes.outcomesCkey = filteredOutcomes.map(([, cKey]) => cKey);
    return learningOutcomes;
  }
  learningOutcomes.outcomes = learningOutcomes.outcomes.filter(
    (o: any, i: any) => filterOutcomes(o, i, hasAnyOutcomes)
  );
  return learningOutcomes;
};

export const removeEmptyV4Outcomes = removeEmptyV3Outcomes;

export const validateV3dot1Activity = (
  activityEson: any,
  allowIncompleteActivities: any
) => {
  // @ts-expect-error
  const validator = validatorWrapper(
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    v3dot1Validator(allowIncompleteActivities)
  );
  return validator(activityEson);
};

export const validateV4Activity = (
  activityEson: any,
  allowIncompleteActivities: any
) => {
  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const validator = validatorWrapper(v4Validator(allowIncompleteActivities));
  return validator(activityEson);
};

export const validateV3Intro = (intro: any, allowIncompleteActivities: any) => {
  // @ts-expect-error
  const validator = validatorWrapper(
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    v3dot1Validator(allowIncompleteActivities)
  );
  return validator({type: 'intro', params: intro});
};

export const validateV3LearningOutcomes = (
  learningOutcomes: any,
  allowIncompleteActivities: any
) => {
  // @ts-expect-error
  const validator = validatorWrapper(
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    v3dot1Validator(allowIncompleteActivities)
  );
  return validator({type: 'learningOutcomes', params: learningOutcomes});
};

function createValidationFunctionFromSchema(schema: z.ZodTypeAny) {
  return (data: unknown, allowIncompleteActivities: boolean) =>
    allowIncompleteActivities
      ? isIncompleteSchemaDataValid(schema)(data)
      : isSchemaDataValid(schema)(data);
}

export type V4Intro = V4Text;
export const validateIntroSlide = validateV4Text;

export const validateEndSlide = (end: any, allowIncompleteActivities: any) => {
  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const validator = validatorWrapper(v4Validator(allowIncompleteActivities));
  return validator({type: 'end', params: end});
};

export const validateEndBubble = (end: any, allowIncompleteActivities: any) => {
  // @ts-expect-error
  const validator = validatorWrapper(
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    v3dot1Validator(allowIncompleteActivities)
  );
  return validator({type: 'end', params: end});
};

export const validateLearningOutcomesSlide = createValidationFunctionFromSchema(
  learningOutcomesSchema
);

export const validateResultsSlide = (
  results: object,
  allowIncompleteActivities: boolean,
  minimumScore?: number
) =>
  createValidationFunctionFromSchema(resultsSchema)(
    {...results, minimumScore},
    allowIncompleteActivities
  );

export const isValidTaskBox = (params: any) => {
  const {taskBoxTitle, taskBoxText} = params;
  return isNonEmptyString(taskBoxTitle) && isNonEmptyString(taskBoxText);
};

export const isValidVideo = (params: any) => {
  const {url, source} = params;
  return isNonEmptyString(url) && isValidVideoSource(source);
};

export const isValidQuestion = (params: any) => {
  const {question, prompt, options, type} = params;
  if (type === 'ordering') {
    return (
      isValidMixedQuizOrderingQuestion(question) &&
      isValidMixedQuizOrderingPrompt(prompt) &&
      areValidOrderingOptions(options)
    );
  }
  return isNonEmptyString(question) && areValidOptions(options);
};

export const isValidOrderingPrompt = (val: any) =>
  isNonEmptyString(val) && val.length <= 75;

export const isValidMixedQuizOrderingPrompt = (val: string): boolean =>
  isNonEmptyString(val) && val.length <= 49;

export const isValidMixedQuizOrderingQuestion = (val: string): boolean =>
  isNonEmptyString(val) && val.length <= 59;

export const areValidOrderingAnswers = (answers: any) =>
  isNonEmptyArrayOfNonEmptyStrings(answers) &&
  answers.length > 2 &&
  answers.length < 6 &&
  answers.every((answer: any) => answer.length <= maxOrderingAnswerLength);

export const areValidOrderingOptions = (options: {text: string}[]) =>
  areValidOrderingAnswers(options.map(({text}) => text));

export const isValidOrderingTask = (params: any) => {
  const {prompt, answers} = params;
  return isValidOrderingPrompt(prompt) && areValidOrderingAnswers(answers);
};

function isSchemaDataValid<T extends z.ZodTypeAny>(schema: T) {
  return (data: unknown) =>
    convertZodReturnToLegacyFormat(schema.safeParse(data));
}

function isIncompleteSchemaDataValid<T extends z.SomeZodObject | z.ZodTypeAny>(
  schema: T
) {
  return (data: unknown) => {
    const result = (
      'deepPartial' in schema ? schema.deepPartial() : schema
    ).safeParse(data);

    const nonMandatoryIssueCodes: z.ZodIssueCode[] = [
      z.ZodIssueCode.custom,
      z.ZodIssueCode.too_small,
      z.ZodIssueCode.too_big,
      z.ZodIssueCode.invalid_string,
    ];

    const isIssueCodeNonMandatory = (issue: z.ZodIssue) =>
      nonMandatoryIssueCodes.includes(issue.code);

    if (!result.success) {
      const hasNoMandatoryIssues = result.error.issues.every(
        isIssueCodeNonMandatory
      );

      if (hasNoMandatoryIssues) {
        return {
          result: true,
        };
      }
    }

    return convertZodReturnToLegacyFormat(result);
  };
}
function convertZodReturnToLegacyFormat<
  T extends z.SafeParseReturnType<unknown, unknown>
>(result: T) {
  const {success, ...resultRest} = result;
  return {
    result: success,
    ...resultRest,
  };
}

import {useEffect, useState} from 'react';

import {
  isValidEmail,
  isValidLegacyPassword,
  isValidLessonTitle,
  isValidName,
  isValidPassword,
} from '../utils/validation';

// Should really be all of them, add as needed :)

const validationMethods = {
  email: isValidEmail,
  name: isValidName,
  lessonTitle: isValidLessonTitle,
  password: isValidPassword,
  'password-legacy': isValidLegacyPassword,
};

/**
 * Simple hook to ease input validation
 * Validates a given value, will update on change
 *
 * If the inital value is not empty, will also validate on creation (should it?)
 *
 * Will initially force the valid display state to true
 * So that forms don't error immediately
 * If the value changes we stop forcing the valid display state
 * The parent can also disable the forced valid display state
 *
 * Usage: const [isNameValid, isNameValidDisplay, unsetForceNameValidDisplay] = useValidation(name, 'name');
 */
const useValidation = (value, type) => {
  const validate = typeof type === 'function' ? type : validationMethods[type];

  const [isValid, setIsValid] = useState(true);
  const [initialValue] = useState(value);
  const [forceValidDisplay, setForceValidDisplay] = useState(true);
  const unsetForceValidDisplay = () => setForceValidDisplay(false);

  useEffect(() => {
    setIsValid(validate(value));

    if (forceValidDisplay && value !== initialValue) {
      unsetForceValidDisplay();
    }
  }, [value, validate, initialValue, forceValidDisplay]);

  const isValidDisplay = isValid || forceValidDisplay;

  return [isValid, isValidDisplay, unsetForceValidDisplay];
};

export default useValidation;

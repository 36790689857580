import {fromJS, Map} from 'immutable';

import {KNOWLEDGE_HUB} from '../../enums/contentTypes';
import {GET_SCHEDULED_NOTIFICATIONS_SUCCESS} from '../content/contentActionTypes';
import {
  RESET_KNOWLEDGE_HUB_NOTIFICATIONS,
  SEND_KNOWLEDGE_HUB_NOTIFICATIONS,
  SEND_KNOWLEDGE_HUB_NOTIFICATIONS_FAIL,
  SEND_KNOWLEDGE_HUB_NOTIFICATIONS_SUCCESS,
} from '../learnerNotification/learnerNotificationActionTypes';
import {
  ADD_CONTENT_TO_KNOWLEDGE_HUB_SECTION,
  ADD_KNOWLEDGE_HUB,
  ADD_KNOWLEDGE_HUB_FAIL,
  ADD_KNOWLEDGE_HUB_SECTION_SUCCESS,
  ADD_KNOWLEDGE_HUB_SUCCESS,
  CLEAN_KNOWLEDGE_HUB_SECTION_CONTENT,
  DELETE_KNOWLEDGE_HUB,
  DELETE_KNOWLEDGE_HUB_FAIL,
  DELETE_KNOWLEDGE_HUB_SECTION_SUCCESS,
  DELETE_KNOWLEDGE_HUB_SUCCESS,
  EDIT_KNOWLEDGE_HUB_SECTION_SUCCESS,
  EDIT_KNOWLEDGE_HUB_SUCCESS,
  GET_KNOWLEDGE_HUB_CONTENT_SUCCESS,
  GET_KNOWLEDGE_HUBS,
  GET_KNOWLEDGE_HUBS_SUCCESS,
  REMOVE_CONTENT_FROM_KNOWLEDGE_HUB_SECTION,
  REORDER_KNOWLEDGE_HUB_SECTION_CONTENT,
  REORDER_KNOWLEDGE_HUB_SECTIONS,
  REORDER_KNOWLEDGE_HUB_SECTIONS_FAIL,
  REORDER_KNOWLEDGE_HUB_SECTIONS_SUCCESS,
  SET_KNOWLEDGE_HUB_ID,
  UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT,
  UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT_FAIL,
  UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT_SUCCESS,
  UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS,
  UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS_FAIL,
  UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS_SUCCESS,
} from './knowledgeHubActionTypes';

export const initialState = fromJS({
  editKnowledgeHubInProgress: false,
  knowledgeHubId: null,
  knowledgeHubs: [],
  knowledgeHubsLoaded: false,
  sectionsById: {},
  deleteKnowledgeHubInProgress: false,

  learnerNotificationsSending: false,
  learnerNotificationsSent: false,
  sentLearnerNotifications: {
    email: null,
    sms: null,
  },
  learnerNotificationsError: false,
  scheduledNotifications: {},
});

export const updateSectionPositions = (state, newSectionIdsOrder) =>
  newSectionIdsOrder.reduce((sectionsById, sectionId, index) => {
    const newSectionsById = sectionsById.set(
      sectionId,
      state.get('sectionsById').get(sectionId).set('position', index)
    );
    return newSectionsById;
  }, new Map());

// eslint-disable-next-line complexity, max-statements
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_KNOWLEDGE_HUB: {
      return state.set('editKnowledgeHubInProgress', true);
    }

    case ADD_KNOWLEDGE_HUB_SUCCESS: {
      const knowledgeHub = action.payload.data;
      const knowledgeHubs = state.get('knowledgeHubs');

      return state
        .set('editKnowledgeHubInProgress', false)
        .set('knowledgeHubId', knowledgeHub.id)
        .set('knowledgeHubs', knowledgeHubs.push(fromJS(knowledgeHub)));
    }

    case ADD_KNOWLEDGE_HUB_FAIL: {
      return state.set('editKnowledgeHubInProgress', false);
    }

    case EDIT_KNOWLEDGE_HUB_SUCCESS: {
      const {knowledgeHubId, previousAction} = action.meta;
      const {name, showsExternalContent} = previousAction.payload.request.data;
      const knowledgeHubs = state.get('knowledgeHubs');
      const knowledgeHubIndex = knowledgeHubs.findIndex(
        (hub) => hub.get('id') === knowledgeHubId
      );

      return state
        .set('knowledgeHubId', knowledgeHubId)
        .setIn(['knowledgeHubs', knowledgeHubIndex, 'name'], name)
        .setIn(
          ['knowledgeHubs', knowledgeHubIndex, 'showsExternalContent'],
          showsExternalContent
        )
        .setIn(['knowledgeHubs', knowledgeHubIndex, 'updatedAt'], new Date());
    }

    case GET_KNOWLEDGE_HUBS:
      return state.set('knowledgeHubsLoaded', false);

    case GET_KNOWLEDGE_HUBS_SUCCESS:
      return state
        .set('knowledgeHubs', fromJS(action.payload.data))
        .set('knowledgeHubsLoaded', true);

    case SET_KNOWLEDGE_HUB_ID:
      return state.set('knowledgeHubId', action.payload);

    case GET_KNOWLEDGE_HUB_CONTENT_SUCCESS: {
      const {data} = action.payload;
      const {knowledgeHubId} = action.meta;
      const knowledgeHubs = state.get('knowledgeHubs');
      const knowledgeHubIndex = knowledgeHubs.findIndex(
        (hub) => hub.get('id') === knowledgeHubId
      );

      const [sectionsById, sectionIds] = data.reduce(
        (acc, section) => {
          const [sections, ids] = acc;
          const newSections = sections.set(
            section.id,
            fromJS({
              ...section,
              lastSavedContent: section.content,
              lastSavedTeams: section.teams,
            })
          );
          ids.push(section.id);

          return [newSections, ids];
        },
        [new Map(), []]
      );

      return state
        .setIn(['knowledgeHubs', knowledgeHubIndex, 'sections'], sectionIds)
        .mergeIn(['sectionsById'], sectionsById);
    }

    case UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT: {
      const {sectionId} = action.meta;
      return state.setIn(['sectionsById', sectionId, 'saveInProgress'], true);
    }

    case UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT_SUCCESS: {
      const {sectionId} = action.meta;
      const {content} = action.meta.previousAction.payload.request.data;
      return state
        .setIn(['sectionsById', sectionId, 'lastSavedContent'], fromJS(content))
        .setIn(['sectionsById', sectionId, 'hasLastSaveFailed'], false)
        .setIn(['sectionsById', sectionId, 'saveInProgress'], false);
    }

    case UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT_FAIL: {
      const {sectionId} = action.payload.action.meta;

      const newState = state.setIn(
        ['sectionsById', sectionId, 'saveInProgress'],
        false
      );

      const hasLastSaveFailed = state.getIn([
        'sectionsById',
        sectionId,
        'hasLastSaveFailed',
      ]);
      if (hasLastSaveFailed) {
        return newState;
      }

      const lastSavedContent = state.getIn([
        'sectionsById',
        sectionId,
        'lastSavedContent',
      ]);

      return newState
        .setIn(['sectionsById', sectionId, 'content'], lastSavedContent)
        .setIn(['sectionsById', sectionId, 'hasLastSaveFailed'], true);
    }

    case ADD_CONTENT_TO_KNOWLEDGE_HUB_SECTION: {
      const {sectionId, content, position} = action.payload;
      const {id, contentType} = content;

      return state.updateIn(
        ['sectionsById', sectionId, 'content'],
        (contentList) =>
          contentList
            .splice(position, 0, new Map({id, contentType}))
            .toOrderedSet()
            .toList()
      );
    }

    case REMOVE_CONTENT_FROM_KNOWLEDGE_HUB_SECTION: {
      const {sectionId, content} = action.payload;
      const {id, contentType} = content;

      return state.updateIn(
        ['sectionsById', sectionId, 'content'],
        (contentList) =>
          contentList.filterNot(
            (c) => c.get('id') === id && c.get('contentType') === contentType
          )
      );
    }

    case REORDER_KNOWLEDGE_HUB_SECTION_CONTENT: {
      const {sectionId, oldIndex, newIndex} = action.payload;
      return state.updateIn(
        ['sectionsById', sectionId, 'content'],
        (contentList) => {
          const movedContent = contentList.get(oldIndex);
          return contentList
            .splice(oldIndex, 1)
            .splice(newIndex, 0, movedContent);
        }
      );
    }

    case REORDER_KNOWLEDGE_HUB_SECTIONS: {
      const {sectionIds} = action.payload.request.data;
      return state.set(
        'sectionsById',
        updateSectionPositions(state, sectionIds)
      );
    }
    case REORDER_KNOWLEDGE_HUB_SECTIONS_SUCCESS: {
      //TODO: state.set('saveInProgress', false);
      return state;
    }
    case REORDER_KNOWLEDGE_HUB_SECTIONS_FAIL: {
      const {oldSectionIds} = action.payload.action.meta;
      //TODO: state.set('saveInProgress', false);
      return state.set(
        'sectionsById',
        updateSectionPositions(state, oldSectionIds)
      );
    }

    case DELETE_KNOWLEDGE_HUB: {
      return state.set('deleteKnowledgeHubInProgress', true);
    }

    case DELETE_KNOWLEDGE_HUB_SUCCESS: {
      const deletedId = action.meta.knowledgeHubId;
      return state.set('deleteKnowledgeHubInProgress', false).set(
        'knowledgeHubs',
        state.get('knowledgeHubs').filter((kh) => kh.get('id') !== deletedId)
      );
    }

    case DELETE_KNOWLEDGE_HUB_FAIL: {
      return state.set('deleteKnowledgeHubInProgress', false);
    }

    case CLEAN_KNOWLEDGE_HUB_SECTION_CONTENT: {
      const {sectionId, cleanedContent} = action.payload;
      return state.setIn(
        ['sectionsById', sectionId, 'content'],
        fromJS(cleanedContent)
      );
    }

    case ADD_KNOWLEDGE_HUB_SECTION_SUCCESS: {
      const section = action.payload.data;
      const sectionContent = {...section, content: []};

      return state.setIn(['sectionsById', section.id], fromJS(sectionContent));
    }

    case EDIT_KNOWLEDGE_HUB_SECTION_SUCCESS: {
      const {knowledgeHubSectionId, title} = action.meta;

      return state.setIn(
        ['sectionsById', knowledgeHubSectionId, 'title'],
        title
      );
    }

    case UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS: {
      const {knowledgeHubSectionId, updatedTeamIds} = action.meta;

      return state
        .setIn(['sectionsById', knowledgeHubSectionId, 'teams'], updatedTeamIds)
        .setIn(
          ['sectionsById', knowledgeHubSectionId, 'lastSavedTeams'],
          updatedTeamIds
        )
        .setIn(['sectionsById', knowledgeHubSectionId, 'saveInProgress'], true);
    }

    case UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS_SUCCESS: {
      const {knowledgeHubSectionId} = action.meta;

      return state.setIn(
        ['sectionsById', knowledgeHubSectionId, 'saveInProgress'],
        false
      );
    }

    case UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS_FAIL: {
      const {knowledgeHubSectionId} = action.payload.action.meta;
      const lastSavedTeams = state.getIn([
        'sectionsById',
        knowledgeHubSectionId,
        'lastSavedTeams',
      ]);

      return state
        .setIn(['sectionsById', knowledgeHubSectionId, 'teams'], lastSavedTeams)
        .setIn(
          ['sectionsById', knowledgeHubSectionId, 'saveInProgress'],
          false
        );
    }

    case DELETE_KNOWLEDGE_HUB_SECTION_SUCCESS: {
      const sectionId = action.meta.knowledgeHubSectionId;
      return state.deleteIn(['sectionsById', sectionId]);
    }

    case SEND_KNOWLEDGE_HUB_NOTIFICATIONS: {
      return state.set('learnerNotificationsSending', true);
    }

    case SEND_KNOWLEDGE_HUB_NOTIFICATIONS_SUCCESS: {
      const knowledgeHubs = state.get('knowledgeHubs');
      const knowledgeHubIndex = knowledgeHubs.findIndex(
        (hub) => hub.get('id') === action.meta.knowledgeHubId
      );
      const {deliveryPreference} =
        action.meta.previousAction.payload.request.data;
      const {eduMeTeamIdsToNotify} =
        action.meta.previousAction.payload.request.data;
      const notificationHistory = [
        ...knowledgeHubs
          .getIn([knowledgeHubIndex, 'notificationHistory'])
          .toJS(),
      ];

      for (const teamId of eduMeTeamIdsToNotify) {
        const teamHistoryIndex = notificationHistory.findIndex(
          (data) => data.teamId === teamId
        );
        if (teamHistoryIndex === -1) {
          notificationHistory.push({teamId: teamId, lastNotified: new Date()});
        } else {
          notificationHistory[teamHistoryIndex] = {
            teamId: teamId,
            lastNotified: new Date(),
          };
        }
      }
      return state
        .set('learnerNotificationsSent', true)
        .set('learnerNotificationsSending', false)
        .set('sentLearnerNotifications', action.payload.data)
        .setIn(
          ['knowledgeHubs', knowledgeHubIndex, 'deliveryPreference'],
          deliveryPreference
        )
        .setIn(
          ['knowledgeHubs', knowledgeHubIndex, 'notificationHistory'],
          fromJS(notificationHistory)
        );
    }

    case SEND_KNOWLEDGE_HUB_NOTIFICATIONS_FAIL: {
      return state
        .set('learnerNotificationsSending', false)
        .set('learnerNotificationsError', true);
    }

    case RESET_KNOWLEDGE_HUB_NOTIFICATIONS: {
      return state
        .set('learnerNotificationsSending', false)
        .set('learnerNotificationsSent', false)
        .set('sentLearnerNotifications', {
          email: null,
          sms: null,
        });
    }

    case GET_SCHEDULED_NOTIFICATIONS_SUCCESS: {
      if (action.meta.contentType === KNOWLEDGE_HUB) {
        const knowledgeHubId = action.meta.contentId;
        const scheduledNotifications = state
          .get('scheduledNotifications')
          .toJS();
        scheduledNotifications[knowledgeHubId] = action.payload.data;
        return state.set(
          'scheduledNotifications',
          fromJS(scheduledNotifications)
        );
      }
      return state;
    }

    default: {
      return state;
    }
  }
};

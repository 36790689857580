import {fromJS} from 'immutable';

import {
  COPY_CONTENT,
  COPY_CONTENT_FAIL,
  COPY_CONTENT_SUCCESS,
} from './contentActionTypes';

export const initialState = fromJS({
  contents: [],
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COPY_CONTENT: {
      const {currentGroupId, groupIds} = action.meta;
      if (groupIds.length === 1 && groupIds.includes(currentGroupId)) {
        //This means we are copying to the same group, using the Make a copy option
        //Insert placeholder content to have it on the list, before loading...
        const contents = state.get('contents');
        return state.set(
          'contents',
          contents.insert(
            0,
            fromJS({
              courseKey: '@temp-copy-course@',
              isTempCoursePlaceholder: true,
              // Used to push temporaryCoursePlaceholders to the top of the content list
              createdAt: new Date(),
            })
          )
        );
      }
      return state;
    }
    case COPY_CONTENT_SUCCESS:
    case COPY_CONTENT_FAIL: {
      return state.set(
        'contents',
        state
          .get('contents')
          .filter((content) => !content.get('isTempCoursePlaceholder'))
      );
    }

    default: {
      return state;
    }
  }
};

import PropTypes from 'prop-types';

const surveyAnalytics = PropTypes.shape({
  surveyId: PropTypes.number.isRequired,
  activityType: PropTypes.string.isRequired,
  activityIndex: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  totalUsersEnrolled: PropTypes.number.isRequired,
  totalUsersAnswered: PropTypes.number.isRequired,
  totalUsersOptedOut: PropTypes.number.isRequired,
  userResponses: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string.isRequired,
      responded: PropTypes.number.isRequired,
    })
  ).isRequired,
});

export default surveyAnalytics;

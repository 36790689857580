import React, {CSSProperties, ReactNode} from 'react';

import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

type FixedRatioImageProps = {
  children?: ReactNode;
  url?: string;
  backgroundColor?: string;
  style?: CSSProperties;
  dataAutomation?: string;
  isPlaceholder?: boolean;
};

//ignore empty strings
const isValidString = (str?: string) => str && str.length > 0;

const FixedRatioImage = ({
  url,
  backgroundColor,
  children,
  style,
  dataAutomation,
  isPlaceholder = false,
}: FixedRatioImageProps): JSX.Element => {
  const validUrl = isValidString(url)
    ? url
    : '/resources/img/default_course_image.png';

  const additionalStyles = {
    backgroundColor,
    backgroundImage: isPlaceholder ? 'none' : `url(${validUrl})`,
    ...style,
  };

  return (
    <div
      styleName='fixed-ratio-image'
      style={additionalStyles}
      data-automation={dataAutomation}
    >
      {children}
    </div>
  );
};

export default CSSModules(FixedRatioImage, styles, {allowMultiple: true});

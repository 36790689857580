// sort-imports-ignore
import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './styles.module.scss';
import {Badge, ExternalLinkIcon, LegacyHeading} from '@edume/magnificent'; // eslint-disable-line workspaces/require-dependency

type Article = {
  title: string;
  tag: string;
  url: string;
};

type ArticleProps = {
  article: Article;
  logReduxAction: (a: string) => void;
};

const Article = ({
  article,
  logReduxAction = (a) => {
    Promise.resolve(a);
  },
}: ArticleProps) => {
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    logReduxAction(article.title);
    window.open(article.url, '_blank');
  };

  return (
    <div styleName='container' onClick={onClick}>
      <div styleName='link'>
        <LegacyHeading size='micro' weight='medium' text={article.title} />
        <div styleName='hover-icon'>
          <ExternalLinkIcon colour='brandPrimary' size='tiny' />
        </div>
      </div>
      <div styleName='badge'>
        <Badge
          textContent={article.tag}
          colour='light-grey'
          badgeStyle='solid'
        />
      </div>
    </div>
  );
};

Article.propTypes = {
  article: PropTypes.object.isRequired,
  logReduxAction: PropTypes.func,
};

export default CSSModules(Article, styles);

/* eslint-disable max-len */
import React from 'react';

import CSSModules from 'react-css-modules';

import {NonGenericIndicatorSize} from '..';

import styles from './styles.module.scss';

type HexagonsProps = {
  dataAutomation: string;
  size?: NonGenericIndicatorSize;
  color: 'light' | 'dark';
  containerStyle: 'centerFlex' | 'centerAbsolute' | '';
};

const SIZE_LIST = {
  small: 40,
  normal: 60,
  large: 80,
};

const COLOUR_LIST = {
  light: {
    left: '#00EAF6',
    middle: '#883FFF',
    right: '#1BB4FF',
  },
  dark: {
    left: '#8E89A0',
    middle: '#5A5076',
    right: '#382E5D',
  },
};

const Hexagons = ({
  size = 'normal',
  color,
  containerStyle,
  dataAutomation,
}: HexagonsProps): JSX.Element => {
  // eslint-disable-next-line no-nested-ternary
  const svgHeightAndWidth = typeof size === 'number' ? size : SIZE_LIST[size];
  const colours = COLOUR_LIST[color];
  let containerCss = '';
  if (containerStyle) {
    containerCss = containerStyle;
  }

  return (
    <div styleName={`${containerCss}`} data-automation={dataAutomation}>
      <svg
        width={svgHeightAndWidth}
        height={svgHeightAndWidth}
        viewBox='0 0 400 400'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <mask
            styleName='leftMask'
            id='leftMask'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width={400}
            height={400}
          >
            <path
              width={400}
              height={400}
              d='M34.3779 180.502C35.2276 178.626 38.4573 171.634 39.3092 169.932C46.616 153.901 52.4013 141.625 63.7919 125.937C65.5706 123.495 67.8324 121.447 70.436 119.921C73.0396 118.396 75.929 117.425 78.9237 117.07L83.003 116.559C94.5648 115.017 105.275 114.172 115.985 114C127.804 114.2 139.605 114.996 151.344 116.387C154.368 116.72 157.291 117.68 159.925 119.207C162.559 120.733 164.848 122.793 166.645 125.255C180.916 144.523 187.387 158.847 197.927 182.037C200.817 188.517 200.648 196.02 197.587 202.498C195.888 206.242 179.566 241.375 168.167 257.234C166.388 259.677 164.126 261.725 161.522 263.25C158.919 264.776 156.029 265.747 153.035 266.102C139.943 267.806 128.043 268.83 115.972 269C104.151 268.801 92.3482 268.004 80.6077 266.613C77.5835 266.28 74.6614 265.32 72.0272 263.793C69.393 262.267 67.1044 260.207 65.3068 257.745C51.1959 238.477 44.5653 224.153 34.1935 200.963C31.1351 194.485 31.3063 186.982 34.3648 180.502H34.3779Z'
              transform='translate(0 0) scale(1.0)'
              fill='#ffffff'
            />
          </mask>
          <mask
            styleName='rightMask'
            id='rightMask'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width={400}
            height={400}
          >
            <path
              width={400}
              height={400}
              d='M211.378 180.502C212.228 178.626 215.457 171.634 216.309 169.932C223.616 153.901 229.401 141.625 240.792 125.937C242.571 123.495 244.832 121.447 247.436 119.921C250.04 118.396 252.929 117.425 255.924 117.07L260.003 116.559C271.565 115.017 282.275 114.172 292.985 114C304.804 114.2 316.605 114.996 328.344 116.387C331.368 116.72 334.291 117.68 336.925 119.207C339.559 120.733 341.848 122.793 343.645 125.255C357.916 144.523 364.387 158.847 374.927 182.037C377.817 188.517 377.648 196.02 374.587 202.498C372.888 206.242 356.566 241.375 345.167 257.234C343.388 259.677 341.126 261.725 338.522 263.25C335.919 264.776 333.029 265.747 330.035 266.102C316.943 267.806 305.043 268.83 292.972 269C281.151 268.801 269.348 268.004 257.608 266.613C254.583 266.28 251.661 265.32 249.027 263.793C246.393 262.267 244.104 260.207 242.307 257.745C228.196 238.477 221.565 224.153 211.193 200.963C208.135 194.485 208.306 186.982 211.365 180.502H211.378Z'
              transform='translate(0 0) scale(1.0)'
              fill='#ffffff'
            />
          </mask>
        </defs>
        <g mask='url(#leftMask)'>
          <rect width={400} height={400} fill={colours.left} />
        </g>
        <g mask='url(#rightMask)'>
          <rect width={400} height={400} fill={colours.middle} />
        </g>
        <g mask='url(#leftMask)'>
          <g mask='url(#rightMask)'>
            <rect width={400} height={400} fill={colours.right} />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CSSModules(Hexagons, styles, {allowMultiple: true});

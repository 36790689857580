/**
 * @param {string} subscriptionName
 * @param {string} webhookURL
 * @returns {string}
 */
export const getWebhookConfigurationName = (subscriptionName, webhookURL) => {
  if (subscriptionName) {
    return subscriptionName;
  }

  try {
    const {hostname} = new URL(webhookURL);
    return hostname;
  } catch (e) {
    // if, for some reason, the url isn't valid
    // catch and set as the url
    return webhookURL;
  }
};

import React, {ReactNode, useState} from 'react';

import CSSModules from 'react-css-modules';

import Link from '../Link';
import Modal, {BottomLeftContent, ModalButton} from '../Modal';
import SelectableItem from './SelectableItem';

import styles from './styles.module.scss';

type Item = {
  id: number;
  text: string;
  dataAutomation?: string;
};

type CommonProps = {
  save: (
    add: number[],
    remove?: number[],
    seed?: number[],
    selectAll?: boolean
  ) => void;
  cancel: () => void;
  items: Item[];
  seedSelected?: number[];
  headingText: string;
  buttonText: string;
  secondaryButtonText?: string;
  showSelectAllItem?: boolean;
  selectAllText: string;
  allowNone?: boolean;
  alternateShading?: boolean;
  canClose?: boolean;
  additionalTopContent?: ReactNode;
  additionalBottomContent: ReactNode;
  renderTo: HTMLElement;
  buttonDataAutomation?: string;
  fitContentHeightForDesktop?: boolean;
};

type ConditionalSelectAllProps =
  | {
      selectAllPosition?: 'top' | 'bottom';
      unselectAllText: string;
    }
  | {
      selectAllPosition?: never;
      unselectAllText: never;
    };

type MultiSelectModalProps = CommonProps & ConditionalSelectAllProps;

// eslint-disable-next-line complexity
const MultiSelectModal = ({
  save,
  cancel,
  items,
  seedSelected,
  headingText,
  buttonText,
  secondaryButtonText,
  showSelectAllItem,
  selectAllText,
  allowNone = true,
  alternateShading,
  canClose = true,
  additionalTopContent,
  additionalBottomContent,
  renderTo,
  buttonDataAutomation,
  selectAllPosition = 'bottom',
  unselectAllText,
  fitContentHeightForDesktop = false,
}: MultiSelectModalProps): ReactNode => {
  const [selected, setSelected] = useState<number[]>(seedSelected || []);
  const [isSelectingAll, setIsSelectingAll] = useState(false);

  const onSave = () => {
    if (isSelectingAll) {
      const allItemIds = items.map((item) => item.id);
      save(allItemIds, [], allItemIds, true);
    } else {
      const idsToAdd: number[] = seedSelected
        ? selected.filter((id) => seedSelected.indexOf(id) === -1)
        : selected;
      const idsToRemove: number[] = seedSelected
        ? seedSelected.filter((id) => selected.indexOf(id) === -1)
        : [];
      save(idsToAdd, idsToRemove, selected, isSelectingAll);
    }

    if (canClose) {
      cancel();
    }
  };

  const toggleSelectAll = () => {
    const hasSelectedAll = selected.length === items.length;
    if (hasSelectedAll) {
      setSelected([]);
    } else {
      const allItemIds = items.map((item) => item.id);
      setSelected(allItemIds);
    }
  };

  const selectAll = () => {
    setIsSelectingAll(!isSelectingAll);
    if (isSelectingAll) {
      setSelected([]);
    }
  };

  const toggleItem = (itemId: number) => {
    const selectedItemIndex = selected.indexOf(itemId);

    const selectItem = () => {
      setSelected([...selected, itemId]);
      setIsSelectingAll(false);
    };

    const unselectItem = () => {
      setSelected([
        ...selected.slice(0, selectedItemIndex),
        ...selected.slice(selectedItemIndex + 1),
      ]);
      setIsSelectingAll(false);
    };

    if (selectedItemIndex === -1) {
      selectItem();
    } else {
      unselectItem();
    }
  };

  const hasSelectedAll = selected.length === items.length;

  const body = () => (
    <div>
      {additionalTopContent || selectAllPosition === 'top' ? (
        <div styleName='top-content'>
          {additionalTopContent}

          {!showSelectAllItem && (
            <Link
              onClick={toggleSelectAll}
              text={hasSelectedAll ? unselectAllText : selectAllText}
            />
          )}
        </div>
      ) : null}

      <div styleName={alternateShading ? 'selectable-alternate' : ''}>
        {showSelectAllItem && (
          <SelectableItem
            id={-1}
            key={-1}
            text={selectAllText}
            onClick={selectAll}
            selected={isSelectingAll}
          />
        )}
        {items.map((t) => (
          <SelectableItem
            id={t.id}
            key={t.id}
            text={t.text}
            onClick={() => toggleItem(t.id)}
            selected={selected.includes(t.id)}
            dataAutomation={t.dataAutomation}
          />
        ))}
      </div>

      {additionalBottomContent}
    </div>
  );

  const bottomLeftContent: BottomLeftContent | null =
    showSelectAllItem || selectAllPosition !== 'bottom'
      ? null
      : {
          text: hasSelectedAll ? unselectAllText : selectAllText,
          onClick: toggleSelectAll,
          type: 'link',
        };

  const buttons: ModalButton[] = [
    {
      type: 'primary',
      onClick: onSave,
      text: buttonText,
      disabled:
        (!allowNone && selected.length === 0 && !isSelectingAll) || false,
      dataAutomation: buttonDataAutomation,
    },
  ];

  if (secondaryButtonText) {
    buttons.unshift({
      type: 'secondary',
      onClick: cancel,
      text: secondaryButtonText,
      disabled: false,
    });
  }

  return (
    <Modal
      onClose={cancel}
      compact={false}
      content={{
        title: headingText,
        body: CSSModules(body, styles, {allowMultiple: true})(),
        buttons,
        bottomLeftContent,
      }}
      renderTo={renderTo}
      fitContentHeightForDesktop={fitContentHeightForDesktop}
    />
  );
};

export default MultiSelectModal;

export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAIL = 'ACTIVATE_USER_FAIL';
export const CHANGED_CREDENTIALS = 'CHANGED_CREDENTIALS';
export const CHECK_EMAIL = 'CHECK_EMAIL';
export const CHECK_EMAIL_FAIL = 'CHECK_EMAIL_FAIL';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CLOSE_ONBOARDING = 'CLOSE_ONBOARDING';
export const CONTACT_INPUT_CHANGED = 'CONTACT_INPUT_CHANGED';
export const CONTACT_METHOD_SELECTED = 'CONTACT_METHOD_SELECTED'; // not used??
export const DELETED_USER = 'DELETED_USER';
export const DISMISS_HINT = 'DISMISS_HINT';
export const DISMISS_HINT_SUCCESS = 'DISMISS_HINT_SUCCESS';
export const ENABLE_ONBOARDING = 'ENABLE_ONBOARDING';
export const EXPIRED_TOKEN_USED = 'EXPIRED_TOKEN_USED';
export const INVALID_TOKEN_USED = 'INVALID_TOKEN_USED';
export const INVITE_NOT_FOUND = 'INVITE_NOT_FOUND';
export const LOAD_HINTS = 'LOAD_HINTS';
export const LOAD_HINTS_SUCCESS = 'LOAD_HINTS_SUCCESS';
export const LOAD_INVITE = 'LOAD_INVITE';
export const LOAD_INVITE_SUCCESS = 'LOAD_INVITE_SUCCESS';
export const LOAD_WHOAMI = 'LOAD_WHOAMI';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGIN = 'LOGIN';
export const LOGIN_WITH_MS_TEAMS = 'LOGIN_WITH_MS_TEAMS';
export const LOGGED_IN_MS_TEAMS = 'LOGGED_IN_MS_TEAMS'; // needed for Amplitude
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_FAILED_NOT_ADMIN = 'LOGIN_FAILED_NOT_ADMIN';
export const OLD_TOKEN_VERSION = 'OLD_TOKEN_VERSION';
export const RECORD_CREATE_COURSE = 'RECORD_CREATE_COURSE';
export const RECORD_INVITE_ADMIN = 'RECORD_INVITE_ADMIN';
export const REQUEST_SIGNOUT = 'REQUEST_SIGNOUT';
export const RESET_ENTERED_EMAIL = 'RESET_ENTERED_EMAIL';
export const RESET_LOGIN_FORM = 'RESET_LOGIN_FORM';
export const RESET_PASSWORD_DETAILS = 'RESET_PASSWORD_DETAILS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_DETAILS_REQUEST = 'RESET_PASSWORD_DETAILS_REQUEST';
export const RESET_PASSWORD_DETAILS_SUCCESS = 'RESET_PASSWORD_DETAILS_SUCCESS';
export const SEND_PASSWORD = 'SEND_PASSWORD';
export const SEND_PASSWORD_RESET = 'SEND_PASSWORD_RESET';
export const SEND_PASSWORD_RESET_ERROR = 'SEND_PASSWORD_RESET_ERROR';
export const SEND_PASSWORD_RESET_SUCCESS = 'SEND_PASSWORD_RESET_SUCCESS'; // is this used??
export const START_LOGIN_FROM_COOKIE = 'START_LOGIN_FROM_COOKIE';
export const UNDISMISS_HINT = 'UNDISMISS_HINT';
export const UNDISMISS_HINT_SUCCESS = 'UNDISMISS_HINT_SUCCESS';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const INTEGRATION_CREATED = 'INTEGRATION_CREATED';
export const INTEGRATION_UPDATED = 'INTEGRATION_UPDATED';
export const INTEGRATION_DELETED = 'INTEGRATION_DELETED';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const LOGIN_WITH_SSO = 'LOGIN_WITH_SSO';

import marked from 'marked';

import {BAD, GOOD, OKAY} from '../enums/gradeIndicators.js';
import {QUESTION_TYPES} from '../enums/lessons.js';

export const DEFAULT_END_ICON =
  'https://edume-res.cloudinary.com/image/upload/v1658916428/img_thumbs_up_pagbxh.svg';

//`breaks` causes single line breaks to render as new lines, which isn't normal markdown (it's gfm-only)
export const renderBlockMarkdown = (contents) =>
  marked(contents, {gfm: true, breaks: true});

export const renderInlineMarkdown = (contents) =>
  marked.inlineLexer(contents, []);

export const getGradeIndicator = (percent) => {
  if (percent >= 70) {
    return GOOD;
  } else if (percent >= 40) {
    return OKAY;
  } else {
    return BAD;
  }
};

export const getLessonType = (lesson) =>
  getLessonTypeByVersion(lesson.eson.version);

export const getLessonTypeByVersion = (version) =>
  version === 3.1 ? 'conversational' : 'slideshow';

export const getCourseLessonsType = (lessons) => {
  const firstNonAssessment = lessons.find((l) => l.eson.type === 'lesson');
  if (firstNonAssessment && firstNonAssessment.eson.version) {
    return getLessonType(firstNonAssessment);
  } else {
    return null;
  }
};

const quizTypeMap = {
  singleChoice: QUESTION_TYPES.SINGLE_RESPONSE,
  multipleChoice: QUESTION_TYPES.MULTIPLE_RESPONSE,
};

export const getQuestionTypeFromQuizType = (quizType) => quizTypeMap[quizType];

export const CHECK_COMPANY_NAME = 'CHECK_COMPANY_NAME';
export const CHECK_COMPANY_NAME_FAIL = 'CHECK_COMPANY_NAME_FAIL';
export const CHECK_COMPANY_NAME_SUCCESS = 'CHECK_COMPANY_NAME_SUCCESS';
export const CHECK_PHONE_NUMBER = 'CHECK_PHONE_NUMBER';
export const CHECK_PHONE_NUMBER_FAIL = 'CHECK_PHONE_NUMBER_FAIL';
export const CHECK_PHONE_NUMBER_SUCCESS = 'CHECK_PHONE_NUMBER_SUCCESS';
export const CHECK_TRIAL_EMAIL = 'CHECK_TRIAL_EMAIL';
export const CHECK_TRIAL_EMAIL_FAIL = 'CHECK_TRIAL_EMAIL_FAIL';
export const CHECK_TRIAL_EMAIL_SUCCESS = 'CHECK_TRIAL_EMAIL_SUCCESS';
export const INVITE_ADMINS = 'INVITE_ADMINS';
export const INVITE_ADMINS_SUCCESS = 'INVITE_ADMINS_SUCCESS';
export const INVITE_ADMINS_FAIL = 'INVITE_ADMINS_FAIL';
export const SEND_APP_LINK = 'SEND_APP_LINK';
export const SEND_APP_LINK_FAIL = 'SEND_APP_LINK_FAIL';
export const SEND_APP_LINK_SUCCESS = 'SEND_APP_LINK_SUCCESS';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_RESOURCE_TYPE = 'SET_RESOURCE_TYPE';
export const SET_RESOURCE_KEY = 'SET_RESOURCE_KEY';
export const SET_IS_BEEKEEPER = 'SET_IS_BEEKEEPER';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_ESTIMATED_LEARNERS = 'SET_ESTIMATED_LEARNERS';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_INDUSTRY = 'SET_INDUSTRY';
export const SET_INTEGRATIONS_USED = 'SET_INTEGRATIONS_USED';
export const SET_PHONE = 'SET_PHONE';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const START_TRIAL = 'START_TRIAL';
export const START_TRIAL_SUCCESS = 'START_TRIAL_SUCCESS';
export const START_TRIAL_FAIL = 'START_TRIAL_FAIL';
export const START_MS_TEAMS_TRIAL = 'START_MS_TEAMS_TRIAL';
export const VIEWED_ACCOUNT_UPGRADE = 'VIEWED_ACCOUNT_UPGRADE';
export const COPIED_PUBLISHED_LINK = 'COPIED_PUBLISHED_LINK';
export const SET_PREFERRED_EMAIL = 'SET_PREFERRED_EMAIL';
export const SET_IS_TRIAL_SIGNUP = 'SET_IS_TRIAL_SIGNUP';
export const COMPLETE_TRIAL_FORM_STEP_ONE = 'COMPLETE_TRIAL_FORM_STEP_ONE';
export const COMPLETE_TRIAL_FORM_STEP_TWO = 'COMPLETE_TRIAL_FORM_STEP_TWO';

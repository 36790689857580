import {addMeta, buildRequest} from '../stateUtil';
import {
  ADD_ADMIN,
  ADD_REMOVE_ADMIN_FROM_TEAMS,
  DELETE_ADMIN,
  GET_COMPANY_ADMINS,
  UPDATE_ADMIN,
} from './adminActionTypes';

export const getCompanyAdmins = () =>
  buildRequest(GET_COMPANY_ADMINS, 'GET', '/admin/company');

export const getGroupAdmins = (actionType, groupId) =>
  buildRequest(actionType, 'GET', `/admin/group/${groupId}`);

export const addAdmin = (admin) =>
  buildRequest(ADD_ADMIN, 'POST', '/admin', admin);

export const updateAdmin = (adminId, admin) =>
  buildRequest(UPDATE_ADMIN, 'PUT', `/users/${adminId}`, admin);

export const deleteAdmin = (adminId) =>
  buildRequest(DELETE_ADMIN, 'DELETE', `/users/${adminId}`);

export const addAndRemoveAdminFromTeams = (
  user,
  groupIdsToAdd,
  groupIdsToRemove
) =>
  addMeta(
    buildRequest(
      ADD_REMOVE_ADMIN_FROM_TEAMS,
      'PUT',
      `/users/${user.id}/groups`,
      {
        groupIdsToAdd,
        groupIdsToRemove,
      }
    ),
    {user}
  );

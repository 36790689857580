import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {StyledMultiSelectModal} from '../../../containers/StyledModals';
import ConfirmCopyContent from './ConfirmCopyContent';

const DuplicateContent = ({
  content,
  groups,
  currentGroupId,
  copyContent,
  close,
  editContentInProgress,
  hasContentDuplicationFailed,
  intl,
  selectGroup,
  isResourceLibraryCourse = false,
  duplicatedContent,
  goToContent,
}) => {
  const [isConfirmStep, setIsConfirmStep] = useState(isResourceLibraryCourse);
  const [copiedGroupIds, setCopiedGroupIds] = useState([]);

  const onSave = async (groupIds) => {
    await copyContent({content, groupIds, currentGroupId});
    setIsConfirmStep(true);
    setCopiedGroupIds(groupIds);
  };

  const copyContentTitle = intl.formatMessage({
    id: 'Learning.copyContentTitle',
  });
  const copyContentConfirm = intl.formatMessage({
    id: 'Learning.copyContentConfirm',
  });

  const selectAllText = intl.formatMessage({id: 'Link.selectAll'});
  const unselectAllText = intl.formatMessage({id: 'Link.unselectAll'});

  return isConfirmStep ? (
    <ConfirmCopyContent
      close={close}
      contentType={content.contentType}
      sourceContentTitle={content.title}
      copiedGroups={groups.filter((group) => copiedGroupIds.includes(group.id))}
      selectGroup={selectGroup}
      isLoading={editContentInProgress}
      isFailed={hasContentDuplicationFailed}
      isResourceLibraryCourse={isResourceLibraryCourse}
      duplicatedContent={duplicatedContent}
      goToContent={goToContent}
      intl={intl}
    />
  ) : (
    <StyledMultiSelectModal
      allowNone={false}
      save={onSave}
      canClose={false}
      cancel={close}
      items={groups.map((group) => ({
        id: group.id,
        text: group.name,
        dataAutomation: 'group-option',
      }))}
      headingText={copyContentTitle}
      buttonText={copyContentConfirm}
      secondaryButtonText={intl.formatMessage({id: 'Button.cancel'})}
      selectAllText={selectAllText}
      unselectAllText={unselectAllText}
      buttonDataAutomation={`confirm-duplicate-${content.contentType}-button`}
      fitContentHeightForDesktop
    />
  );
};

DuplicateContent.propTypes = {
  content: PropTypes.object.isRequired,
  duplicatedContent: PropTypes.object,
  groups: PropTypes.array.isRequired,
  currentGroupId: PropTypes.number.isRequired,
  copyContent: PropTypes.func.isRequired,
  editContentInProgress: PropTypes.bool.isRequired,
  hasContentDuplicationFailed: PropTypes.bool.isRequired,
  isResourceLibraryCourse: PropTypes.bool,
  close: PropTypes.func.isRequired,
  selectGroup: PropTypes.func.isRequired,
  goToContent: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default DuplicateContent;

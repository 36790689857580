import {COMPANY, GROUP, TEAM} from '../enums/adminLevels';

export const getAdminLevel = (permissions) => {
  if (permissions.company) {
    return COMPANY;
  } else if (permissions.group) {
    return GROUP;
  } else {
    return TEAM;
  }
};

export const getHasLeadRightsOnCompany = (permissions) =>
  permissions.company ? permissions.company.isLead : false;

export const getHasLeadRightsOnGroup = (permissions, group) => {
  const isGroupLead =
    group && permissions.group
      ? permissions.group.find((g) => g.id === group.id).roles.isLead
      : false;
  return getHasLeadRightsOnCompany(permissions) || isGroupLead;
};

export const getHasLeadRightsOnTeam = (permissions, group, team) =>
  getHasLeadRightsOnCompany(permissions) ||
  getHasLeadRightsOnGroup(permissions, group) ||
  (permissions.team &&
    permissions.team.find((t) => t.id === team.id).roles.isLead);

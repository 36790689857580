import React from 'react';

import Badge from '../Badge';

type Level = 'company' | 'group' | 'team';

type Role = 'lead' | 'analyst' | 'editor' | 'manager';

type AdminBadgeProps = {
  level: Level;
  role: Role;
  solid?: boolean;
  text?: string;
  dataFor?: string;
  dataTip?: string;
  key?: string;
};

const AdminBadge = ({
  level,
  role,
  solid,
  text,
  dataFor,
  dataTip,
  key,
}: AdminBadgeProps): JSX.Element => {
  const colour = (() => {
    if (role === 'lead') {
      return 'green';
    } else if (role === 'analyst') {
      return 'amber';
    } else if (role === 'editor') {
      return 'purple';
    } else if (role === 'manager') {
      return 'blue';
    } else {
      throw new Error(`Unknown role: ${role}`);
    }
  })();

  const badgeText =
    text ??
    level[0].toUpperCase() +
      level.slice(1) +
      ' ' +
      role[0].toUpperCase() +
      role.slice(1);

  return (
    <Badge
      dataFor={dataFor}
      dataTip={dataTip}
      dataAutomation={`${level}-${role}-admin-badge`}
      colour={colour}
      textContent={badgeText}
      badgeStyle={solid ? 'solid' : 'default'}
      key={key}
    />
  );
};

export default AdminBadge;

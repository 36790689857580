import {getSelectedGroupId} from '../team/teamSelectors';
import * as featureFlagsActionCreators from './featureFlagsActionCreators';

export const SET_GROUP_FEATURE_FLAGS = 'SET_GROUP_FEATURE_FLAGS';

const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;

export const loadGroupFeatureFlags = () => async (dispatch, getState) => {
  const state = getState();
  const groupId = getSelectedGroupId(getState());
  if (!isEmptyObject(state.getIn(['featureFlags', 'groupFlags', groupId]))) {
    return;
  }

  const {
    payload: {data},
  } = await dispatch(
    featureFlagsActionCreators.getFeatureFlagsForGroup(groupId)
  );
  dispatch({
    type: SET_GROUP_FEATURE_FLAGS,
    payload: {
      groupId,
      flags: data,
    },
  });
};

import axios from 'axios';

let config = {};

export const getConfig = () =>
  axios.get('/config').then((response) => {
    Object.assign(config, response.data);
    return config;
  });

export default config;

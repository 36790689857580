import PropTypes from 'prop-types';

const imageParams = PropTypes.shape({
  url: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'bottom']).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
});

export default imageParams;

import React from 'react';

export const formatRed = (source) => {
  const match =
    /([\s\S]*?)<red>([\s\S]+?)<\/red>([\s\S]*?)(?:<red>([\s\S]+)<\/red>([\s\S]*))?$/.exec(
      source
    );
  // JS RegEx doesn't support repeated groups, so you need more (?:<red>([\s\S]+)<\/red>([\s\S]*)) for more matches...
  if (!match) {
    return source;
  }

  // TODO: insert key to elements in the array and remove eslint-disable-next-line
  // eslint-disable-next-line react/jsx-key
  let formatted = [<span>{match[1]}</span>];
  for (let i = 2; i < match.length; i += 2) {
    formatted.push(
      <span>
        <span styleName='red'>{match[i]}</span>
        {match[i + 1]}
      </span>
    );
  }
  return formatted;
};

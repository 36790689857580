import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const DownloadIcon = ({
  size = 'small',
  colour = 'textPrimary',
}: IconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='m10.5 12.4-4-4-2 2.2L12 18l7.5-7.5-2-2.1-4 3.9V2h-3v10.4ZM3 19h18v3H3v-3Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default DownloadIcon;

import {COMPANY, GROUP} from '../../enums/adminLevels';
import * as authActions from '../auth/authActions';
import * as formActions from '../form/formActions';
import * as notificationActions from '../notification/notificationActions';
import * as peopleActions from '../people/peopleActions';
import * as teamActions from '../team/teamActions';
import * as adminActionCreators from './adminActionCreators';
import {
  ADMIN_CREATED,
  ADMIN_DELETED,
  ADMIN_UPDATED,
  LOAD_GROUP_ADMINS,
  LOAD_TEAM_ADMINS,
} from './adminActionTypes';

export const getCompanyAdmins = () => (dispatch) =>
  dispatch(adminActionCreators.getCompanyAdmins()).catch((error) =>
    notificationActions.createErrorNotificationFromResponse(error)(dispatch)
  );

export const getGroupAdmins = (groupId) => (dispatch) =>
  dispatch(
    adminActionCreators.getGroupAdmins(LOAD_GROUP_ADMINS, groupId)
  ).catch((error) =>
    notificationActions.createErrorNotificationFromResponse(error)(dispatch)
  );

export const getTeamAdmins = (groupId) => (dispatch) =>
  dispatch(adminActionCreators.getGroupAdmins(LOAD_TEAM_ADMINS, groupId)).catch(
    (error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
  );

export const addAdmin =
  (admin, isTeamAdmin, source, isAddingFirstUser = false) =>
  (dispatch, getState) => {
    dispatch(adminActionCreators.addAdmin(admin))
      .then((response) => {
        dispatch({
          type: ADMIN_CREATED,
          payload: {
            ...response.payload.data,
            isTeamAdmin,
            source,
          },
        });
      })
      .then(() =>
        isAddingFirstUser
          ? teamActions.loadTeams({otherTabsRequireUpdate: false})(
              dispatch,
              getState
            )
          : null
      )
      .then(() => {
        peopleActions.loadUsersInVisibleTeams(dispatch, getState, {
          resetState: true,
          resetSearch: true,
        });
        const messageKey = 'Admin.add.addAdminSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
      })
      .then(() => formActions.closeForm()(dispatch))
      .then(() => authActions.recordInviteAdmin()(dispatch))
      .catch((error) => {
        formActions.addFormErrorFromResponse(error)(dispatch);
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });
  };

export const deleteAdmin = (admin) => (dispatch) => {
  dispatch(adminActionCreators.deleteAdmin(admin.id))
    .then(() => {
      dispatch({
        type: ADMIN_DELETED,
        payload: {
          adminId: admin.id,
          isCompanyAdmin: admin.isCompanyAdmin,
          isTeamAdmin: admin.isTeamAdmin,
        },
      });
      const messageKey = 'Admin.delete.success';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const updateAdmin =
  (
    existingAdmin,
    updatedAdmin,
    isTeamAdmin,
    learnerGroupsToAddRemove,
    adminLevel
  ) =>
  async (dispatch) => {
    try {
      const {groupIdsToAdd, groupIdsToRemove} = learnerGroupsToAddRemove;
      if (groupIdsToAdd.length > 0 || groupIdsToRemove.length > 0) {
        await dispatch(
          addAndRemoveAdminFromTeams(
            existingAdmin,
            groupIdsToAdd,
            groupIdsToRemove,
            adminLevel
          )
        );
      }
      const updateAction = await dispatch(
        adminActionCreators.updateAdmin(existingAdmin.id, updatedAdmin)
      );
      await dispatch({
        type: ADMIN_UPDATED,
        payload: {
          ...updateAction.payload.data,
          isTeamAdmin,
        },
      });
      const messageKey = 'Admin.edit.updateAdminSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
      await formActions.closeForm()(dispatch);
    } catch (error) {
      formActions.addFormErrorFromResponse(error)(dispatch);
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const addAndRemoveAdminFromTeams =
  (existingAdmin, groupIdsToAdd, groupIdsToRemove, adminLevel) =>
  async (dispatch, getState) => {
    try {
      await dispatch(
        adminActionCreators.addAndRemoveAdminFromTeams(
          existingAdmin,
          groupIdsToAdd,
          groupIdsToRemove
        )
      );
      if (adminLevel === COMPANY) {
        return dispatch(getCompanyAdmins());
      } else if (adminLevel === GROUP) {
        const groupId = getState().getIn(['team', 'groupId']);
        return dispatch(getGroupAdmins(groupId));
      }
      return null;
    } catch (error) {
      return notificationActions.createErrorNotificationFromResponse(error)(
        dispatch
      );
    }
  };

import React, {MouseEvent, MouseEventHandler, ReactNode, useState} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';

import {ChevronIcon} from '../../icons';
import Input from '../../Input';
import Link, {LinkColours} from '../../Link';
import Text from '../../TextComponents/Text';
import Popover, {CommonPopoverProps} from '../Popover';

import popoverStyles from '../Popover/styles.module.scss';
import styles from './styles.module.scss';

export type ListPopoverOption =
  | {
      title: string;
      callback: MouseEventHandler<HTMLElement>;
      icon?: string;
      iconComponent?: ReactNode;
      iconPosition?: string;
      colour?: LinkColours;
      dataAutomation?: string;
      disabled?: boolean;
      label?: string;
      tooltipProps?: {
        text: string;
        effect: 'float' | 'solid';
        place: 'top' | 'right' | 'bottom' | 'left';
        backgroundColor?: string;
      };
      subtitle?: never;
      rightComponent?: ReactNode;
      nestedPopoverOptions?: never;
      nestedSide?: never;
    }
  | {
      // subtitle prop overrides any option (become a label and not a Link)
      subtitle: string;
      title?: never;
      callback?: never;
      icon?: never;
      iconComponent?: never;
      iconPosition?: never;
      colour?: never;
      dataAutomation?: never;
      label?: never;
      disabled?: never;
      tooltipProps?: never;
      rightComponent?: never;
      nestedPopoverOptions?: never;
      nestedSide?: never;
    }
  | {
      // nested popover option on hover
      title: string;
      callback: never;
      icon?: string;
      iconComponent?: ReactNode;
      iconPosition?: string;
      colour?: LinkColours;
      dataAutomation?: string;
      disabled?: boolean;
      label?: string;
      tooltipProps?: {
        text: string;
        effect: 'float' | 'solid';
        place: 'top' | 'right' | 'bottom' | 'left';
        backgroundColor?: string;
      };
      subtitle?: never;
      rightComponent?: ReactNode;
      nestedPopoverOptions: ListPopoverOption[];
      nestedSide: 'left' | 'right';
    }
  | 'divider';

type ListPopoverProps = CommonPopoverProps & {
  options: ListPopoverOption[];
  withSearch?: boolean;
  searchPlaceholder?: string;
  searchClearText?: string;
  searchNoResultsMessage?: string;
  fixedBottomContent?: ReactNode;
  dataAutomation?: string;
  clearInputType?: 'icon' | 'button' | 'none';
  minWidth?: string;
};

const ListPopover = ({
  title,
  close,
  forceLeft,
  options,
  withSearch,
  searchPlaceholder,
  searchClearText,
  searchNoResultsMessage,
  fixedBottomContent,
  fullWidth,
  dataAutomation,
  customWidth,
  clearInputType,
  minWidth,
}: ListPopoverProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [visibleNestedPopoverIndex, setVisibleNestedPopoverIndex] = useState<
    number | null
  >();

  const optionsRows = (optionsToRender = options) =>
    optionsToRender.map(
      // eslint-disable-next-line complexity
      (option, index: number) => {
        if (option === 'divider') {
          return <hr styleName='divider' key={index} />;
        } else if ('subtitle' in option && option.subtitle) {
          return index > 0 ? (
            <div styleName='subtitleWrapper' key={index}>
              <p styleName='title'>{option.subtitle}</p>
            </div>
          ) : (
            <p styleName='title' key={index}>
              {option.subtitle}
            </p>
          );
        } else if ('nestedPopoverOptions' in option) {
          return (
            <button
              key={index}
              styleName={classNames('container', {
                withLabel: option.label,
                link: !option.label,
                rightComponent: option.rightComponent,
                [`${option.nestedSide}`]: true,
              })}
              style={{minWidth: minWidth}}
              onClick={() =>
                setVisibleNestedPopoverIndex(
                  visibleNestedPopoverIndex === index ? null : index
                )
              }
              data-automation={option.dataAutomation}
            >
              <div styleName='nested-option'>
                <ChevronIcon
                  orientation={option.nestedSide}
                  colour='brandPrimary'
                  size='tiny'
                />
                <Text size='s' colour='primary' weight='semiBold'>
                  {option.title}
                </Text>
              </div>
              {visibleNestedPopoverIndex === index && (
                <Popover
                  close={() => setVisibleNestedPopoverIndex(null)}
                  nested={true}
                >
                  {optionsRows(option.nestedPopoverOptions)}
                </Popover>
              )}
            </button>
          );
        } else if ('title' in option) {
          let linkTitle = option.title as string;

          if (option.title && withSearch && searchValue.length > 0) {
            if (
              !option.title.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return null;
            } else {
              linkTitle = option.icon
                ? `<div style='display:flex;align-items:center;'><img style='height:24px;max-height: 30px;margin-right: 10px;padding-right:10px;' src=${option.icon} /><span>`
                : '<span>';
              const titleCopy: string = option.title;
              const titleCopyLowered: string = option.title.toLowerCase();
              let currentPosition = 0;
              const underlinedIndexesResult: number[] = [];

              while (
                titleCopyLowered.indexOf(searchValue, currentPosition) !== -1
              ) {
                underlinedIndexesResult.push(
                  titleCopyLowered.indexOf(searchValue, currentPosition)
                );
                currentPosition =
                  titleCopyLowered.indexOf(searchValue, currentPosition) + 1;
              }

              let currentBuilderPosition = 0;
              for (const [
                loopIndex,
                underlinedIndex,
              ] of underlinedIndexesResult.entries()) {
                // edge case - when a search term appears in a value more than once and it overlaps with the previous case
                if (
                  underlinedIndexesResult[loopIndex + 1] <
                  underlinedIndex + searchValue.length
                ) {
                  continue;
                }
                linkTitle += `${titleCopy.slice(
                  currentBuilderPosition,
                  underlinedIndex
                )}<u>${titleCopy.slice(
                  underlinedIndex,
                  underlinedIndex + searchValue.length
                )}</u>`;
                currentBuilderPosition = underlinedIndex + searchValue.length;
              }
              linkTitle += `${titleCopy.slice(
                currentBuilderPosition,
                titleCopyLowered.length
              )}</span>`;

              if (option.icon) {
                linkTitle += '</div>';
              }
            }
          }

          return (
            <div
              key={index}
              styleName={classNames('container', {
                withLabel: option.label,
                link: !option.label,
                rightComponent: option.rightComponent,
              })}
              style={{minWidth: minWidth}}
            >
              {option.label && <span styleName='label'>{option.label}</span>}
              <Link
                dataAutomation={option.dataAutomation}
                text={linkTitle}
                linkColour={option.colour}
                iconLink={option.icon}
                icon={option.iconComponent}
                iconPosition={option.iconPosition}
                onClick={(e: MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  // if nestedOption in all options array set visibleNestedPopoverIndex to undefined
                  if (visibleNestedPopoverIndex) {
                    setVisibleNestedPopoverIndex(null);
                  }
                  close(e);
                  if (option.callback) {
                    option.callback(e);
                  }
                }}
                disabled={option.disabled}
                shouldDangerouslySetInnerHtml={
                  withSearch && searchValue.length > 0
                }
                tooltipProps={option.tooltipProps}
              />
              {option.rightComponent && <div>{option.rightComponent}</div>}
            </div>
          );
        } else {
          return null;
        }
      }
    );

  const widthProps = fullWidth
    ? {fullWidth}
    : {customWidth, fixedWidth: withSearch};

  return (
    <Popover
      title={title}
      close={close}
      fixedTopContent={
        withSearch && (
          <Input
            type='search'
            value={searchValue}
            onChangeValue={setSearchValue}
            placeholder={searchPlaceholder || ''}
            removeSpellcheck
            autoFocus={true}
            validationStyles='hide'
            searchClearText={searchClearText}
            dataAutomation={dataAutomation}
            clearInputType={clearInputType}
          />
        )
      }
      forceLeft={forceLeft}
      fixedBottomContent={fixedBottomContent}
      narrowPadding={true}
      {...widthProps}
    >
      {withSearch && optionsRows().filter(Boolean).length === 0 ? (
        <span styleName='searchNoResultsMessage'>{searchNoResultsMessage}</span>
      ) : (
        optionsRows()
      )}
    </Popover>
  );
};

// This is a workaround for a TS issue only seen in X-Wing, related to the spreading of the styles object
export default CSSModules(
  ListPopover,
  {
    ...popoverStyles,
    ...styles,
  },
  {allowMultiple: true}
) as typeof ListPopover;

export const COURSE = 'course';
export const GUIDE = 'guide';
export const KNOWLEDGE_HUB = 'knowledgeHub';
export const LESSON = 'lesson';

export const NEWS = 'news';
export const ONBOARDING = 'onboarding';

export const AI = 'ai';

export const STATUSES = {
  PUBLISHED: 'published',
};

export const removeMultipleWhitespaces = (string: string) =>
  string.replace(/\s\s+/g, ' ');

export const adjustFontSizeToFit = ({
  containerElement,
  maxPxWidth,
  contentElement,
  startingRemFontSize,
  minimumRemFontSize,
}: {
  containerElement: HTMLElement;
  contentElement: HTMLElement | null;
  maxPxWidth: number;
  startingRemFontSize: number;
  minimumRemFontSize: number;
}) => {
  if (!contentElement) {
    return;
  }

  let currentFontSize = startingRemFontSize;
  contentElement.style.fontSize = `${currentFontSize}rem`;

  const isOverflowing = (element: HTMLElement) =>
    element.scrollWidth > maxPxWidth - 1;

  // Reduce the font size until the content fits
  while (
    currentFontSize > minimumRemFontSize &&
    isOverflowing(containerElement)
  ) {
    currentFontSize -= 0.0625; // 1 pixel
    contentElement.style.fontSize = `${currentFontSize}rem`;
  }
};

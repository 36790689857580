import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import CheckEmailPage from '../../components/Trial/CheckEmailPage';
import * as trackingActions from '../../state/tracking/trackingActions';
import * as trialActions from '../../state/trial/trialActions';

const mapStateToProps = (state) => ({
  email: state.getIn(['trial', 'email']),
  emailError: state.getIn(['trial', 'emailError']),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(trialActions, dispatch),
  ...bindActionCreators(trackingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckEmailPage);

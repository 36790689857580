import React, {
  KeyboardEventHandler,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import ReactTooltip from 'react-tooltip';

import ListPopover, {ListPopoverOption} from '../Popover/ListPopover';
import Text from '../TextComponents/Text';
import {getTooltipDataKey} from '../utils/tooltipUtils';

import styles from './styles.module.scss';

type CommonIconButtonProps = {
  tooltipProps?: {
    text: string;
    effect: 'float' | 'solid';
    place: 'top' | 'right' | 'bottom' | 'left';
    backgroundColor?: string;
  };
  showBorder?: boolean;
  onOpenEdit?: () => void;
  onCloseEdit?: () => void;
  isSuccessShown?: boolean;
  handleClick?: () => void;
  isExpandable?: boolean;
  isAlwaysExpanded?: boolean;
  isBgTransparent?: boolean;
  disabled?: boolean;
  displayedText?: string;
  icon?: ReactNode;
  iconUrl?: string;
  hoverIconUrl?: string;
  successIcon?: ReactNode;
  successIconLink?: string;
  noMarginStart?: boolean;
  dataAutomation?: string;
};

type ConditionalPopoverProps =
  | {
      handleClick: () => void;
      onOpenEdit?: never;
      onCloseEdit?: never;
      popoverProps?: never;
    }
  | {
      handleClick?: never;
      onOpenEdit: () => void;
      onCloseEdit: () => void;
      popoverProps: {
        title: string;
        options: ListPopoverOption[];
        forceLeft?: boolean;
      };
    };

type IconButtonProps = CommonIconButtonProps & ConditionalPopoverProps;

// eslint-disable-next-line complexity
const IconButton = (
  /* prettier-ignore */ {
    onOpenEdit, onCloseEdit, isSuccessShown, handleClick, icon, iconUrl, hoverIconUrl, isExpandable = false, 
    isAlwaysExpanded = false, disabled = false, showBorder = true, isBgTransparent = false, noMarginStart = false, tooltipProps,
    popoverProps, displayedText, successIcon, successIconLink, dataAutomation
  }: IconButtonProps
): JSX.Element => {
  const [popoverShown, setPopoverShown] = useState(false);
  const [headingId, setHeadingId] = useState('');

  useEffect(() => {
    const randomID = 'heading-' + Math.random().toString(16).slice(2);
    setHeadingId(randomID);
  }, []);

  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Escape') {
      closeEdit();
    }
  };

  const closeEdit = (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    if (onCloseEdit) {
      onCloseEdit();
    }
    setPopoverShown(false);
  };

  const openEdit = (e: MouseEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation();
    }
    if (onOpenEdit) {
      onOpenEdit();
    }
    setPopoverShown(true);
  };

  const popover = !handleClick && popoverShown && (
    <ListPopover {...popoverProps} close={closeEdit} />
  );
  const displayedIcon = isSuccessShown ? successIcon : icon;
  const displayedIconType = icon ? 'vector' : 'imageUrl';

  const onClick = handleClick ? handleClick : openEdit;

  const buttonStyleName = classNames('button', {
    border: showBorder,
    transparent: isBgTransparent,
  });

  const tooltip = tooltipProps && (
    <ReactTooltip
      className='iconButtonTooltip'
      delayShow={300}
      id={tooltipProps && getTooltipDataKey(tooltipProps.text)}
      {...tooltipProps}
    >
      {tooltipProps && tooltipProps.text}
    </ReactTooltip>
  );

  const containerStyleName = classNames('icon-button-wrapper', {
    expandable: isExpandable,
    'always-expanded': isAlwaysExpanded,
  });

  let buttonLabel = '';

  if (displayedText) {
    buttonLabel = headingId;
  } else if (tooltipProps && tooltipProps.text) {
    buttonLabel = getTooltipDataKey(tooltipProps.text);
  } else {
    buttonLabel = '';
  }

  return (
    // keeping prop 'type' to accomadate for old versions of the icon button
    <span styleName={containerStyleName}>
      <div
        styleName={classNames('spacingWrapper', {
          disabled,
          success: isSuccessShown,
          noMarginStart: noMarginStart,
        })}
      >
        <span styleName='fakeText' aria-hidden>
          {displayedText && (
            <Text size='m' weight='medium'>
              {displayedText}
            </Text>
          )}
        </span>
        <button
          {...(tooltipProps && {'data-tip': true})}
          data-for={tooltipProps && getTooltipDataKey(tooltipProps.text)}
          styleName={buttonStyleName}
          onClick={onClick}
          onKeyDown={handleKeyPress}
          data-automation={dataAutomation}
          aria-labelledby={buttonLabel}
          disabled={disabled}
        >
          <div styleName='linkWrapper'>
            {displayedIconType === 'vector' ? (
              displayedIcon
            ) : (
              <>
                {hoverIconUrl && (
                  <img styleName='icon-hover' src={hoverIconUrl} alt='' />
                )}
                {iconUrl && (
                  <img styleName='icon-default' src={iconUrl} alt='' />
                )}
                {successIconLink && (
                  <img styleName='icon-success' src={successIconLink} alt='' />
                )}
              </>
            )}
            <span styleName='headingWrapper'>
              {displayedText && (
                <Text
                  size='m'
                  weight='medium'
                  colour={'inherit'}
                  id={headingId}
                >
                  {isSuccessShown ? '' : displayedText}
                </Text>
              )}
            </span>
          </div>
        </button>
        {tooltip}
        {popover}
      </div>
      <div styleName='clearFix' />
    </span>
  );
};

export default CSSModules(IconButton, styles, {allowMultiple: true});

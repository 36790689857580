import {addMeta, buildRequest} from '../stateUtil';
import {
  COPY_CONTENT,
  GET_SCHEDULED_NOTIFICATIONS,
  LOAD_CONTENTS,
} from './contentActionTypes';

export const getContents = (groupId) =>
  buildRequest(LOAD_CONTENTS, 'GET', `/groups/${groupId}/contents`);

export const copyContent = (
  contentType,
  contentId,
  groupIds,
  currentGroupId,
  copiedFrom = 'duplicated', // we pass the course label if copied from an eduMe resources course or template if from template course
  source
) =>
  addMeta(
    buildRequest(
      COPY_CONTENT,
      'POST',
      `/content/${contentType}/${contentId}/duplicate`,
      {
        groupIds,
      }
    ),
    {
      contentType,
      contentId,
      courseKey: contentId,
      groupIds,
      currentGroupId,
      copiedFrom,
      source,
    }
  );

export const getScheduledNotifications = ({contentType, contentId}) =>
  addMeta(
    buildRequest(
      GET_SCHEDULED_NOTIFICATIONS,
      'GET',
      `/notifications/scheduled/${contentType}?contentId=${contentId}`
    ),
    {
      contentType,
      contentId,
    }
  );

/**
 * Add event listeners to toggle the provided action when `option` + the provided key code are pressed
 * @param {() => void} action function to be called
 * @param {string} [keyCode='KeyD'] this is the keycode, not the key
 * @returns {{event: string, listener: () => void} | null}
 */
export const addDevActionToggle = (action, keyCode = 'KeyD') => {
  if (process.env.NODE_ENV !== 'production') {
    const handleKeyDown = (e) => {
      if (e.altKey && e.code === keyCode) {
        action();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return {
      event: 'keydown',
      listener: handleKeyDown,
    };
  }

  return null;
};

type Extension = 'scss' | 'css';

type Paths = Array<string | RegExp>;

export const getStylingConfig = (
  extension: Extension,
  paths: Paths,
  isUsingThreadLoader = true
) => {
  const options =
    extension === 'scss'
      ? {
          sourceMap: true,
          modules: {
            localIdentName: '[name]__[local]__[hash:base64:5]',
          },
        }
      : {sourceMap: true};

  const test = extension === 'scss' ? /\.scss$/ : /\.css$/;

  const includeList = paths.filter((path) => path);

  const baseLoaders = isUsingThreadLoader
    ? ['thread-loader', 'style-loader']
    : ['style-loader'];
  const loaders = [
    ...baseLoaders,
    {
      loader: 'css-loader',
      options: options,
    },
    {loader: 'postcss-loader', options: {sourceMap: true}},
    {
      loader: 'sass-loader',
      options: {
        sourceMap: true,
      },
    },
  ];

  return {
    test: test,
    include: includeList,
    use: loaders,
  };
};

export const getFontConfig = (paths: Paths) => ({
  test: /\.(woff|woff2|eot|ttf|otf)$/,
  include: paths,
  type: 'asset/resource',
  generator: {
    filename: 'fonts/[name].[contenthash][ext]',
  },
});

import * as notificationActions from '../notification/notificationActions';
import {getSelectedTeamId} from '../team/teamSelectors';
import * as onboardingActionCreators from './onboardingActionCreators';

export const addOnboardingItem =
  (title, text, type, url, order) => (dispatch, getState) => {
    const teamId = getSelectedTeamId(getState());

    dispatch(
      onboardingActionCreators.addOnboardingItem(
        teamId,
        order,
        title,
        text,
        type,
        url
      )
    )
      .then(() => loadOnboardingItems()(dispatch, getState))
      .then(() =>
        notificationActions.createSuccessNotification(
          'OnboardingTab.addSuccess'
        )(dispatch)
      )
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const deleteOnboardingItem = (id) => (dispatch, getState) => {
  dispatch(onboardingActionCreators.deleteOnboardingItem(id))
    .then(() => loadOnboardingItems()(dispatch, getState))
    .then(() =>
      notificationActions.createSuccessNotification(
        'OnboardingTab.deleteSuccess'
      )(dispatch)
    )
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const loadOnboardingItems = () => (dispatch, getState) => {
  const teamId = getSelectedTeamId(getState());

  if (teamId) {
    dispatch(onboardingActionCreators.getOnboarding(teamId)).catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
  }
};

import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

type QuestionMarkIconProps = IconProps & {
  dataTip?: boolean;
  dataFor?: string;
};

const QuestionMarkIcon = ({
  size = 'small',
  colour = 'textPrimary',
  dataTip = false,
  dataFor = '',
}: QuestionMarkIconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-tip={dataTip}
    data-for={dataFor}
  >
    <path
      d='m10.5 11.7-.2 1v1H13v-1.2c0-.2 0-.4.3-.5l.6-.5.9-.7.8-1c.2-.4.3-.9.3-1.4 0-.6-.1-1-.4-1.5-.2-.3-.6-.6-1.1-.8L12.5 6a7.3 7.3 0 0 0-1.8.2 10.4 10.4 0 0 0-1.4.4v2.6a5.5 5.5 0 0 1 2.5-.7c.4 0 .7 0 .9.2.2.1.2.3.2.5l-.1.5a2 2 0 0 1-.4.4l-.5.4-.8.6-.6.7ZM10.6 18.3l1 .2c.5 0 1-.2 1.2-.5.4-.3.5-.7.5-1.2 0-.4 0-.7-.2-1l-.6-.5a2 2 0 0 0-1-.2c-.5 0-1 .1-1.3.5-.3.3-.5.7-.5 1.2l.2.9.7.6Z'
      fill={colourMap[colour]}
    />
    <path
      fillRule='evenodd'
      d='M12 23a11 11 0 1 0 0-22 11 11 0 0 0 0 22Zm8.5-11a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default QuestionMarkIcon;

import React, {useEffect, useRef, useState} from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {StyledModal} from '../../../containers/StyledModals';
import {createErrorNotification} from '../../../state/notification/notificationActions';
import {
  CANCELLED_COMPONENT_UNMOUNTED,
  handleVideoUpload,
  shouldErrorFileTooBig,
  shouldWarnFileTooBig,
} from '../../../utils/fileUpload.js';
import LegacyIntlHeading from '../LegacyIntlHeading';
import MediaContainer from '../MediaContainer';
import FilePicker from './FilePicker';
import ToolbarButtons from './ToolbarButtons';

const UploadVideoModal = ({
  videoUrl,
  hintTextKey,
  onSetVideo,
  onSaveChanges,
  onRemove,
  onClose,
  isRemovingEnabled,
}) => {
  const intl = useIntl();
  const [step, setStep] = useState(() =>
    videoUrl ? 'initial-seeded' : 'initial'
  );
  const [lastPickedFile, setLastPickedFile] = useState(null);
  const axiosCancelSource = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      axiosCancelSource.current.cancel(CANCELLED_COMPONENT_UNMOUNTED);
    };
  }, []);

  const startUpload = (files, {ignoreWarning = false} = {}) => {
    if (files.length) {
      setStep('uploading');
      const file = files[0];
      const fileName = file.name;
      setLastPickedFile(file);
      if (shouldErrorFileTooBig(file)) {
        setStep('file-size-error');
      } else if (shouldWarnFileTooBig(file) && !ignoreWarning) {
        setStep('file-size-warning');
      } else {
        handleVideoUpload(file, axiosCancelSource.current)
          .then((uploadedVideo) => {
            setStep('success');
            onSetVideo(uploadedVideo.url, {fileName});
          })
          .catch((err) => {
            if (err.message !== CANCELLED_COMPONENT_UNMOUNTED) {
              setStep('upload-error');
              throw err;
            }
          });
      }
    } else {
      dispatch(createErrorNotification('UploadModal.error.fileType'));
    }
  };

  const retryUpload = () => {
    startUpload([lastPickedFile], {ignoreWarning: true});
  };

  const goToStart = () => {
    setStep(videoUrl ? 'initial-seeded' : 'initial');
  };

  const steps = {
    initial: () => ({
      title: intl.formatMessage({id: 'UploadModal.title'}, {type: 'video'}),
      body: (
        <FilePicker
          type='video'
          onFilePick={startUpload}
          isUploading={false}
          hintTextKey={hintTextKey}
        />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.cancel'}),
        },
      ],
    }),
    'initial-seeded': () => ({
      title: intl.formatMessage(
        {id: 'UploadModal.changeMediaTitle'},
        {type: 'video'}
      ),
      body: <MediaContainer type='video' mediaUrl={videoUrl} />,
      buttons: [
        {
          type: 'primary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.okay'}),
        },
      ],
      bottomLeftContent: {
        type: 'element',
        element: (
          <ToolbarButtons
            type='video'
            onReplace={startUpload}
            onRemove={onRemove}
            isRemovingEnabled={isRemovingEnabled}
          />
        ),
      },
    }),
    'file-size-warning': () => ({
      iconUrl: '/resources/img/38_alert.svg',
      title: intl.formatMessage(
        {id: 'UploadModal.warningTitle'},
        {type: 'video'}
      ),
      body: (
        <LegacyIntlHeading size='mini' textKey='UploadModal.videoWarningHint' />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: retryUpload,
          text: intl.formatMessage({id: 'UploadModal.warningProceed'}),
        },
        {
          type: 'primary',
          onClick: goToStart,
          text: intl.formatMessage({id: 'UploadModal.warningStop'}),
        },
      ],
    }),
    'file-size-error': () => ({
      iconUrl: '/resources/img/38_cross.svg',
      title: intl.formatMessage(
        {id: 'UploadModal.errorTitle'},
        {type: 'video'}
      ),
      body: (
        <LegacyIntlHeading size='mini' textKey='UploadModal.videoErrorHint' />
      ),
      buttons: [
        {
          type: 'primary',
          onClick: goToStart,
          text: intl.formatMessage({id: 'Button.goBack'}),
        },
      ],
    }),
    uploading: () => ({
      title: intl.formatMessage({id: 'UploadModal.title'}, {type: 'video'}),
      body: (
        <FilePicker
          type='video'
          onFilePick={() => {}}
          isUploading={true}
          hintTextKey={hintTextKey}
        />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.cancel'}),
        },
      ],
    }),
    'upload-error': () => ({
      iconUrl: '/resources/img/38_cross.svg',
      title: intl.formatMessage({id: 'UploadModal.uploadErrorTitle'}),
      body: (
        <LegacyIntlHeading size='mini' textKey='UploadModal.uploadErrorHint' />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: onClose,
          text: intl.formatMessage({id: 'UploadModal.cancelUploadButton'}),
        },
        {
          type: 'primary',
          onClick: retryUpload,
          text: intl.formatMessage({id: 'Button.tryAgain'}),
        },
      ],
    }),
    success: () => ({
      iconUrl: '/resources/img/38_tick.svg',
      title: intl.formatMessage(
        {id: 'UploadModal.successTitle'},
        {type: 'video'}
      ),
      body: <MediaContainer type='video' mediaUrl={videoUrl} />,
      buttons: [
        {
          type: 'primary',
          onClick: onSaveChanges,
          dataAutomation: 'upload-done-button',
          text: intl.formatMessage({id: 'Button.okay'}),
        },
      ],
      bottomLeftContent: {
        type: 'element',
        element: (
          <ToolbarButtons
            type='video'
            onReplace={startUpload}
            onRemove={onRemove}
            isRemovingEnabled={isRemovingEnabled}
          />
        ),
      },
    }),
  };

  return <StyledModal onClose={onClose} content={steps[step]()} compact />;
};

UploadVideoModal.propTypes = {
  videoUrl: PropTypes.string,
  hintTextKey: PropTypes.string.isRequired,
  onSetVideo: PropTypes.func.isRequired,
  onSaveChanges: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isRemovingEnabled: PropTypes.bool,
};

export default UploadVideoModal;

import {addMeta, buildRequest} from '../stateUtil';
import {UPDATE_LANGUAGE} from './authActionTypes';

export const updateLanguage = (userId, languageCode) =>
  addMeta(
    buildRequest(UPDATE_LANGUAGE, 'PUT', `/users/${userId}?patch=true`, {
      language: languageCode,
    }),
    {languageCode}
  );

import {addMeta, buildRequest} from '../stateUtil';
import {GET_GROUP_OVERVIEW} from './overviewActionTypes';

export const getOverviewStats = (parentGroupId) =>
  addMeta(
    buildRequest(
      GET_GROUP_OVERVIEW,
      'GET',
      `/stats/group/${parentGroupId}/overview`,
      null,
      'reports'
    ),
    {parentGroupId}
  );

import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const AddFolderIcon = ({
  size = 'small',
  colour = 'textPrimary',
}: IconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M4.4 4 2 8v12h12v-3H5v-6h14v1h3V8h-8l-2.4-4H4.4Zm1.7 3h3.8l1.2 2H5v-.2L6.1 7Z'
      fill={colourMap[colour]}
    />
    <path d='M21 14v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2Z' fill={colourMap[colour]} />
  </svg>
);

export default AddFolderIcon;

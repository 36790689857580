import {fromJS, Set} from 'immutable';

import {
  CLEAR_VARIANT_OVERRIDE,
  SET_TEST_VARIANT,
  SET_VARIANT_OVERRIDE,
} from './multivariantTestActionTypes';

export const initialState = fromJS({
  testNames: Set(),
  variantIds: Set(),
  variantIdOverride: null,
});

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_TEST_VARIANT: {
      const {testName, variantId} = action.payload;
      const testNames = state.get('testNames');
      const variantIds = state.get('variantIds');
      return state
        .set('testNames', testNames.add(testName))
        .set('variantIds', variantIds.add(`${variantId}-${testName}`));
    }

    case SET_VARIANT_OVERRIDE:
      return state.set('variantIdOverride', action.payload);

    case CLEAR_VARIANT_OVERRIDE:
      return state.set('variantIdOverride', null);

    default:
      return state;
  }
};

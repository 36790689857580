import React from 'react';

import colourStyles from '../../resources/styles/colours.module.scss';

export {isLightColour} from '@edume/magnificent';

export const svgColorMap = [
  'successPrimary',
  'errorPrimary',
  'brandPrimary',
  'brandPrimaryDark',
  'purple700',
  'purple300',
  'grey700',
  'grey300',
  'blue700',
  'white',
  'textPrimary',
];

export const colourMap = {
  grey700: colourStyles.grey700,
  white: colourStyles.white,
  text: colourStyles.textPrimary,
  grey050: colourStyles.grey050,
};

export const fontColourHexMap = {
  [normaliseHexMapKey(colourStyles.white)]: 'white',
  [normaliseHexMapKey(colourStyles.textPrimary)]: 'textPrimary',
};

export const lookupMagnificentFontColour = (hex, fallback) =>
  fontColourHexMap[hex] ?? fallback;

function normaliseHexMapKey(hex) {
  const extendedHexKey = hex.length === 7 ? hex : `#${hex.slice(1).repeat(2)}`;
  return extendedHexKey.toUpperCase();
}

export const buildColor = (color, selected, onSelectColour, dataAutomation) => {
  const styleName = selected ? 'color-circle selected' : 'color-circle';

  return (
    <button
      type='button'
      styleName={styleName}
      key={color}
      onClick={() => onSelectColour(color)}
      data-automation={dataAutomation}
    >
      <div style={{background: color}} />
    </button>
  );
};

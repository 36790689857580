import {fromJS} from 'immutable';

import {
  ADD_FORM_ERROR,
  CLOSE_FORM,
  OPEN_FORM,
  RESET_FORM_ERROR,
} from './formActionTypes';

export const initialState = fromJS({
  isFormOpen: false,
  errorCode: null,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_FORM:
      return state.set('isFormOpen', true);
    case CLOSE_FORM:
      return initialState;
    case ADD_FORM_ERROR:
      return state.set('errorCode', action.payload);
    case RESET_FORM_ERROR:
      return state.set('errorCode', initialState.get('errorCode'));
    default:
      return state;
  }
};

import {localesMyAppSupports} from '../../utils/i18n';

const params = new URLSearchParams(window.location.search);
const langParam = params.get('lang');

export const getAdminLanguage = (state) => {
  const adminLanguage = state.getIn(['auth', 'language']);

  return (
    (localesMyAppSupports.includes(langParam) && langParam) ||
    (localesMyAppSupports.includes(adminLanguage) && adminLanguage) ||
    'en'
  );
};

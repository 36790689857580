import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import {Input} from '@edume/magnificent';

const UserName = ({
  value,
  placeholder,
  onSave,
  setIsFieldValid,
  isForcingValidation,
  dataAutomation,
}) => {
  const [name, setName] = useState(value);
  useEffect(() => {
    setName(value);
  }, [value]);

  const [isNameValid, setIsNameValid] = useState(true);
  const getIsNameValid = (n) => n?.length > 1;

  useEffect(() => {
    if (isForcingValidation) {
      const isValid = getIsNameValid(name);
      setIsNameValid(isValid);
    }
  }, [isForcingValidation, isNameValid, name]);

  const updateValidity = (status) => {
    setIsNameValid(status);
    setIsFieldValid(status);
  };

  const onSaveName = () => {
    if (name === value) {
      return;
    }

    if (!getIsNameValid(name)) {
      updateValidity(false);
      return;
    }

    updateValidity(true);
    onSave(name);
  };

  const getValidationStyles = () => {
    if (isForcingValidation) {
      return 'normal';
    }
    // need to override the ignorePrefilled prop for an empty string
    return name?.length ? 'normal' : 'hide';
  };

  const intl = useIntl();
  const errorText = intl.formatMessage({id: 'Trial.invalidName'});

  return (
    <Input
      type='text'
      errorText={errorText}
      placeholder={placeholder}
      dataAutomation={dataAutomation}
      value={name}
      isInvalid={!isNameValid}
      onChangeValue={setName}
      onBlur={onSaveName}
      onEnterKey={onSaveName}
      includeBottomMargin={false}
      validationEmotes={false}
      ignorePrefilled={true}
      validationStyles={getValidationStyles()}
    />
  );
};

UserName.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  setIsFieldValid: PropTypes.func.isRequired,
  isForcingValidation: PropTypes.bool.isRequired,
  dataAutomation: PropTypes.string,
};

export default UserName;

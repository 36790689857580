import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {Input} from '@edume/magnificent';

import {updatePassword} from '../../../state/auth/authActions';
import {isValidPassword} from '../../../utils/validation';

const Password = ({setIsFieldValid, isForcingValidation}) => {
  const [password, setPassword] = useState('');

  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const updateValidity = (status) => {
    setIsPasswordValid(status);
    setIsFieldValid(status);
  };

  useEffect(() => {
    if (isForcingValidation) {
      const isValid = isValidPassword(password);
      setIsPasswordValid(isValid);
    }
  }, [isForcingValidation, isPasswordValid, password]);

  const dispatch = useDispatch();
  const onBlur = () => {
    if (!isValidPassword(password)) {
      updateValidity(false);
      return;
    }
    updateValidity(true);

    dispatch(updatePassword(password));
  };

  const intl = useIntl();
  const formatKey = (id) => intl.formatMessage({id});
  const errorText = formatKey('Password.errorText');
  const placeholder = formatKey('Trial.password.placeholder');
  const showPasswordText = formatKey('Password.show');
  const hidePasswordText = formatKey('Password.hide');

  return (
    <Input
      type='password'
      errorText={errorText}
      placeholder={placeholder}
      dataAutomation='trial-password-input'
      value={password}
      isInvalid={!isPasswordValid}
      onChangeValue={setPassword}
      onBlur={onBlur}
      includeBottomMargin={false}
      validationEmotes={false}
      enableVisibilityToggle={true}
      showText={showPasswordText}
      hideText={hidePasswordText}
      typeIcons={false}
    />
  );
};

Password.propTypes = {
  setIsFieldValid: PropTypes.func.isRequired,
  isForcingValidation: PropTypes.bool.isRequired,
};

export default Password;

/* Super simple hook for a toggleable boolean variable */
import {useCallback, useState} from 'react';

const useToggle = (initial = false) => {
  const [state, setState] = useState(initial);
  const toggle = useCallback(() => setState((prev) => !prev), []);

  return [state, toggle, setState];
};

export default useToggle;

import Promise from 'bluebird';

import * as notificationActions from '../notification/notificationActions';
import {buildRequest} from '../stateUtil';
import * as reportingActionCreators from './reportingActionCreators';
import {
  CLEAR_COURSE_OVERVIEWS_CACHE,
  CLEAR_PENDING_DOWNLOADS,
  GROUP_STATS_LOADED,
  REPORT_DOWNLOADED,
  RESET_TEAM_USERS,
  SET_PENDING_DOWNLOADS,
  TOGGLE_REPORT_VIEW,
  TOGGLE_USER_VIEW,
  USER_REPORT_DOWNLOADED,
} from './reportingActionTypes';
import {
  getSelectedGroupCourseStats,
  getSelectedGroupStats,
  getSelectedGroupTeamStats,
} from './reportingSelector';

export const setPendingDownload =
  (groupOrTeamId, range, isPending) => (dispatch) => {
    dispatch({
      type: SET_PENDING_DOWNLOADS,
      payload: {groupOrTeamId, isPending, range},
    });
  };

export const clearPendingDownload = (groupOrTeamId) => (dispatch) => {
  dispatch({
    type: CLEAR_PENDING_DOWNLOADS,
    payload: {groupOrTeamId},
  });
};

export const getStatsForGroup = (groupId) => (dispatch, getState) => {
  const cachedStats = getSelectedGroupCourseStats(getState());
  if (cachedStats) {
    return Promise.resolve();
  } else {
    return dispatch(reportingActionCreators.getStatsForGroup(groupId)).catch(
      (error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      }
    );
  }
};

export const getActivityStatsForGroup = (groupId) => (dispatch, getState) => {
  const cachedStats = getSelectedGroupStats(getState());
  if (cachedStats) {
    return Promise.resolve();
  } else {
    return dispatch(reportingActionCreators.getActivityStatsForGroup(groupId))
      .then((results) => {
        dispatch(groupStatsLoaded(results.payload.data));
      })
      .catch((error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });
  }
};

export const groupStatsLoaded = (stats) => ({
  type: GROUP_STATS_LOADED,
  payload: stats,
});

export const getCourseGraphAndActivityBreakdown =
  (courseId, groupId) => (dispatch) =>
    Promise.all([
      dispatch(
        reportingActionCreators.getCourseActivityGraph(courseId, groupId)
      ),
      dispatch(reportingActionCreators.getActivityBreakdown(courseId)),
    ]).catch((error) => {
      console.log(error);
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });

export const getGuideGraphAndActivityAndTeamBreakdown =
  (guideId, groupId) => (dispatch) =>
    Promise.all([
      dispatch(reportingActionCreators.getGuideTeamBreakdown(guideId)),
      dispatch(reportingActionCreators.getGuideActivityGraph(guideId, groupId)),
      dispatch(reportingActionCreators.getGuideStepBreakdown(guideId)),
    ]).catch((error) => {
      console.log(error);
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });

export const getTeamActivityStats = (groupId) => (dispatch, getState) => {
  const cachedStats = getSelectedGroupTeamStats(getState());
  if (cachedStats) {
    return Promise.resolve();
  } else {
    return dispatch(
      reportingActionCreators.getTeamActivityStats(groupId)
    ).catch((error) => {
      console.log(error);
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });
  }
};

export const getTeamUsersStats =
  (teamId, pageIndex, reset = false) =>
  async (dispatch) => {
    try {
      if (reset) {
        dispatch({type: RESET_TEAM_USERS});
      }
      dispatch(reportingActionCreators.getTeamUsers(teamId, pageIndex));
    } catch (error) {
      console.log(error);
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const getCourseOverviews =
  (coursesIds, from, to) => async (dispatch) => {
    try {
      dispatch(
        reportingActionCreators.getCoursesOverviewData(coursesIds, from, to)
      );
    } catch (error) {
      console.log(error);
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const clearCourseOverviewsCache = () => async (dispatch) => {
  dispatch(buildRequest(CLEAR_COURSE_OVERVIEWS_CACHE));
};

// needed for Amplitude
export const downloadReport = (payload) => (dispatch) => {
  dispatch({
    type: REPORT_DOWNLOADED,
    payload: payload,
  });
};

export const toggleReportView = (payload) => (dispatch) => {
  dispatch({
    type: TOGGLE_REPORT_VIEW,
    payload: payload,
  });
};

export const toggleUserView = (payload) => (dispatch) => {
  dispatch({
    type: TOGGLE_USER_VIEW,
    payload: payload,
  });
};

export const trackDownloadUserReport = (payload) => (dispatch) => {
  dispatch({
    type: USER_REPORT_DOWNLOADED,
    payload: payload,
  });
};

export const getAdminCount = () => (dispatch) =>
  dispatch(reportingActionCreators.getAdminCount());

export const getActiveUsersByMonth =
  (startDate, endDate) => async (dispatch) => {
    try {
      await dispatch(
        reportingActionCreators.getActiveUsersByMonth(startDate, endDate)
      );
    } catch (error) {
      console.log(error);
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const getActiveUsersByGroup =
  (startDate, endDate) => async (dispatch) => {
    try {
      await dispatch(
        reportingActionCreators.getActiveUsersByGroup(startDate, endDate)
      );
    } catch (error) {
      console.log(error);
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

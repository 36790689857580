import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const LanguageIcon = ({
  size = 'small',
  colour = 'textPrimary',
}: IconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.4 8a1 1 0 0 1-.7-.2c-.2-.1-.2-.4-.2-.7 0-.2 0-.5.2-.6.2-.2.4-.3.7-.3.3 0 .5.1.6.3.2.1.3.4.3.6 0 .3-.1.6-.3.7a1 1 0 0 1-.6.2Z'
      fill={colourMap[colour]}
    />
    <path
      fillRule='evenodd'
      d='M15 14.8c-.3 0-.6-.1-.8-.3-.2 0-.4-.3-.6-.5l-.2-1c0-.3 0-.5.2-.7l.4-.6.6-.3.7-.2 1.6-.2c0-.2 0-.4-.2-.5l-.4-.3h-1a4 4 0 0 0-1 .2l-.5.2-.1-1.4a9.6 9.6 0 0 1 1.7-.4h.8l1.2.1.8.7c.2.3.3.7.3 1.2v3.8H17l-.1-.7h-.2l-.3.4a2 2 0 0 1-1.3.5Zm1.1-1.5a1 1 0 0 1-.4.1l-.4-.1-.2-.5v-.3l.3-.3.4-.1a29.3 29.3 0 0 0 1-.3v.8c0 .2-.2.3-.3.5l-.4.2Z'
      fill={colourMap[colour]}
    />
    <path
      d='M14.8 8a1 1 0 0 1-.7-.2c-.2-.1-.3-.4-.3-.7 0-.2.1-.5.3-.6.2-.2.4-.3.7-.3.2 0 .5.1.6.3.2.1.3.4.3.6 0 .3-.1.6-.3.7a1 1 0 0 1-.6.2Z'
      fill={colourMap[colour]}
    />
    <path
      fillRule='evenodd'
      d='M5.1 14.6a213.7 213.7 0 0 0 1-2.8L6.8 9a233.6 233.6 0 0 1 .9-2.6h2.4a2740.1 2740.1 0 0 1 .9 2.6l.9 2.8a156.8 156.8 0 0 1 .9 2.8h-2l-.3-1.3-.2-.4H7.5l-.1.4-.4 1.3H5.1Zm4-6.6 1 3.5H7.8L9 8h.2Z'
      fill={colourMap[colour]}
    />
    <path
      fillRule='evenodd'
      d='M0 19V2h24v17H9l-5 5v-5H0ZM2.5 4.5h19v12h-13L6 19v-2.5H2.5v-12Z'
      fill={colourMap[colour]}
    />
  </svg>
);

export default LanguageIcon;

import React from 'react';

import PropTypes from 'prop-types';

import EmbedYouTubeLinkModal from './EmbedYouTubeLinkModal';
import {
  FIVE_BY_FOUR_CROPPED_IMAGE_ONLY,
  ONE_BY_ONE_CROPPED_IMAGE_ONLY,
  TWO_BY_ONE_CROPPED_IMAGE_ONLY,
  TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL,
  VIDEO_CLOUDINARY,
  VIDEO_YOUTUBE,
} from './mediaTypes';
import UploadImageModal from './UploadImageModal';
import UploadVideoModal from './UploadVideoModal';

const UploadModal = ({
  mediaType,
  imageUrl,
  croppedImageUrl,
  videoUrl,
  embedUrl,
  hintTextKey,
  onSetImage,
  onSetCroppedImage,
  onSetVideo,
  onSetEmbed,
  onSaveChanges,
  onRemove,
  onClose,
  isRemovingEnabled = true,
}) => {
  switch (mediaType) {
    case FIVE_BY_FOUR_CROPPED_IMAGE_ONLY:
    case TWO_BY_ONE_CROPPED_IMAGE_ONLY:
    case ONE_BY_ONE_CROPPED_IMAGE_ONLY:
    case TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL: {
      const aspectRatios = {
        [FIVE_BY_FOUR_CROPPED_IMAGE_ONLY]: 5 / 4,
        [TWO_BY_ONE_CROPPED_IMAGE_ONLY]: 2,
        [TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL]: 2,
        [ONE_BY_ONE_CROPPED_IMAGE_ONLY]: 1,
      };

      const aspectRatio = aspectRatios[mediaType];

      return (
        <UploadImageModal
          imageUrl={imageUrl}
          croppedImageUrl={croppedImageUrl}
          cropAspectRatio={aspectRatio}
          showOriginalImage={
            mediaType === TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL
          }
          hintTextKey={hintTextKey}
          onSetImage={onSetImage}
          onSetCroppedImage={onSetCroppedImage}
          onSaveChanges={onSaveChanges}
          onRemove={onRemove}
          onClose={onClose}
          isRemovingEnabled={isRemovingEnabled}
        />
      );
    }
    case VIDEO_CLOUDINARY:
      return (
        <UploadVideoModal
          videoUrl={videoUrl}
          hintTextKey={hintTextKey}
          onSetVideo={onSetVideo}
          onSaveChanges={onSaveChanges}
          onRemove={onRemove}
          onClose={onClose}
          isRemovingEnabled={isRemovingEnabled}
        />
      );
    case VIDEO_YOUTUBE:
      return (
        <EmbedYouTubeLinkModal
          embedUrl={embedUrl}
          onSetEmbedUrl={onSetEmbed}
          onSaveChanges={onSaveChanges}
          onRemove={onRemove}
          onClose={onClose}
          isRemovingEnabled={isRemovingEnabled}
        />
      );
    default:
      return null;
  }
};

UploadModal.propTypes = {
  mediaType: PropTypes.oneOf([
    FIVE_BY_FOUR_CROPPED_IMAGE_ONLY,
    TWO_BY_ONE_CROPPED_IMAGE_ONLY,
    TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL,
    VIDEO_CLOUDINARY,
    VIDEO_YOUTUBE,
  ]),
  imageUrl: PropTypes.string,
  croppedImageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  embedUrl: PropTypes.string,
  onSetImage: PropTypes.func,
  onSetCroppedImage: PropTypes.func,
  onSetVideo: PropTypes.func,
  onSetEmbed: PropTypes.func,
  onSaveChanges: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hintTextKey: PropTypes.string.isRequired,
  isRemovingEnabled: PropTypes.bool,
};

export default UploadModal;

import {fromJS} from 'immutable';

import {
  DELETE_IMAGE,
  GET_IMAGE_LIBRARY,
  RESET_IMAGE_LIBRARY_LOADING,
} from './imageLibraryActionTypes';

export const initialState = fromJS({
  groupImages: [],
  edumeImages: [],
  isLibraryLoading: true,
  suggestedImages: [],
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_IMAGE_LIBRARY_LOADING:
    case GET_IMAGE_LIBRARY: {
      return state.set('isLibraryLoading', true);
    }
    case `${GET_IMAGE_LIBRARY}_SUCCESS`: {
      const {groupImages, edumeImages} = action.payload.data;

      return state
        .set('groupImages', fromJS(groupImages))
        .set('edumeImages', fromJS(edumeImages))
        .set('isLibraryLoading', false);
    }
    case `${GET_IMAGE_LIBRARY}_FAIL`: {
      // TODO set errors
      return state.set('isLibraryLoading', false);
    }
    case `${DELETE_IMAGE}_SUCCESS`: {
      const {data} = action.payload;

      return state.update('groupImages', (images) =>
        images.filter((image) => image.get('id') !== data)
      );
    }

    default: {
      return state;
    }
  }
};

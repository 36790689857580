//jwt auth
export const INVALID_TOKEN = 'invalid_token';
export const OLD_TOKEN_VERSION = 'old_token_version';

//authController
export const LOGIN_NO_PHONE = 'error_login_no_phone';
export const LOGIN_NO_CODE = 'error_login_no_code';
export const LOGIN_NO_EMAIL = 'error_login_no_email';
export const INVALID_CREDENTIALS = 'invalid_credentials';
export const INVALID_USERNAME = 'invalid_username';
//The number of failed attempts being appended to this error code string in ../controllers/authController.js
export const INVALID_PASSWORD = 'invalid_password';
export const ACCOUNT_LOCKED = 'account_locked';

//utils/CsvValidationUtil.js
export const CSV_INSUFFICIENT_ROWS = 'error_csv_insufficient_rows';
export const CSV_INCONSISTENT_ROW_LENGTHS =
  'error_csv_inconsistent_row_lengths';
export const CSV_INVALID_HEADER_NAME = 'error_csv_invalid_header_name';
export const CSV_INVALID_EMAIL = 'error_csv_invalid_email';
export const CSV_INVALID_PHONE = 'error_csv_invalid_phone';
export const CSV_NO_NAME_HEADER = 'error_csv_no_name_header';
export const CSV_NO_NAME_VALUE = 'error_csv_no_name_value';
export const CSV_EMAIL_IN_USE = 'error_csv_email_in_use';
export const CSV_PHONE_IN_USE = 'error_csv_phone_in_use';
export const CSV_DUPLICATE_EMAIL = 'error_csv_duplicate_email';
export const CSV_DUPLICATE_PHONE = 'error_csv_duplicate_phone';
export const CSV_TOO_MANY_CONTACT_DETAILS =
  'error_csv_too_many_contact_details';

// user validation
export const USER_ALREADY_ACTIVATED = 'user_already_activated';
export const USER_NOT_ACTIVATED = 'user_not_activated';
export const USER_IS_ANONYMOUS = 'user_is_anonymous';
export const INVITE_CODE_NOT_FOUND = 'invite_code_not_found';
export const USERNAME_IN_USE = 'username_in_use';
export const PHONE_IN_USE = 'phone_in_use';
export const EMAIL_IN_USE = 'email_in_use';
export const PASSWORD_INVALID = 'password_invalid';
export const USERNAME_INVALID = 'username_invalid';
export const PHONE_INVALID = 'phone_invalid';
export const EMAIL_INVALID = 'email_invalid';

// course validation
export const COURSE_NOT_FOUND = 'course_not_found';

// password validation
export const RESET_TOKEN_NOT_FOUND = 'reset_token_not_found';
export const RESET_TOKEN_EXPIRED = 'reset_token_expired';
export const USERNAME_NOT_FOUND = 'username_not_found';
export const PHONE_NOT_FOUND = 'phone_not_found';
export const EMAIL_NOT_FOUND = 'email_not_found';
export const USER_HAS_NO_PASSWORD = 'user_has_no_password';
export const USER_ID_NOT_FOUND = 'user_id_not_found';
export const USER_NOT_FOUND = 'user_not_found';

//customer validation
export const COMPANY_REJECTING_PUBLIC_SIGNUPS =
  'company_rejecting_public_signups';

//group validation
export const GROUP_NOT_FOUND = 'group_not_found';
export const GROUPS_NOT_FOUND = 'groups_not_found';
export const GROUP_IS_SUB_GROUP = 'group_is_sub_group';
export const GROUP_IS_PARENT_GROUP = 'group_is_parent_group';
export const GROUPS_ARE_PARENT_GROUPS = 'groups_are_parent_groups';
export const GROUP_NAME_ALREADY_EXISTS = 'group_name_already_exists';
export const CHILD_GROUP_NAME_ALREADY_EXISTS =
  'child_group_name_already_exists';
export const GROUP_NAME_INVALID = 'group_name_invalid';
export const GROUPS_ARE_NOT_CHILDREN_OF_PARENT =
  'groups_are_not_children_of_parent';

//permission validation
export const NO_ACCESS_GROUP = 'no_access_group';
export const NO_ACCESS_GROUPS = 'no_access_groups';
export const NO_ACCESS_USER = 'no_access_user';
export const INSUFFICIENT_PERMISSIONS = 'insufficient_permissions';

// trial validation
export const MAX_USERS_REACHED = 'max_users_reached';

// OTP validation
export const EXPIRED_TOKEN = 'expired_token';

// misc
export const RELOAD_REQUIRED = 'reload_required';

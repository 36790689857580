export const ADD_KNOWLEDGE_HUB = 'ADD_KNOWLEDGE_HUB';
export const ADD_KNOWLEDGE_HUB_FAIL = 'ADD_KNOWLEDGE_HUB_FAIL';
export const ADD_KNOWLEDGE_HUB_SUCCESS = 'ADD_KNOWLEDGE_HUB_SUCCESS';
export const EDIT_KNOWLEDGE_HUB = 'EDIT_KNOWLEDGE_HUB';
export const EDIT_KNOWLEDGE_HUB_SUCCESS = 'EDIT_KNOWLEDGE_HUB_SUCCESS';
export const SET_KNOWLEDGE_HUB_ID = 'SET_KNOWLEDGE_HUB_ID';
export const GET_KNOWLEDGE_HUBS = 'GET_KNOWLEDGE_HUBS';
export const GET_KNOWLEDGE_HUBS_FAIL = 'GET_KNOWLEDGE_HUBS_FAIL';
export const GET_KNOWLEDGE_HUBS_SUCCESS = 'GET_KNOWLEDGE_HUBS_SUCCESS';
export const STORE_KNOWLEDGE_HUB_PREVIEW_TOKEN =
  'STORE_KNOWLEDGE_HUB_PREVIEW_TOKEN';
export const GET_KNOWLEDGE_HUB_CONTENT = 'GET_KNOWLEDGE_HUB_CONTENT';
export const GET_KNOWLEDGE_HUB_CONTENT_SUCCESS =
  'GET_KNOWLEDGE_HUB_CONTENT_SUCCESS';
export const ADD_CONTENT_TO_KNOWLEDGE_HUB_SECTION =
  'ADD_CONTENT_TO_KNOWLEDGE_HUB_SECTION';
export const REMOVE_CONTENT_FROM_KNOWLEDGE_HUB_SECTION =
  'REMOVE_CONTENT_FROM_KNOWLEDGE_HUB_SECTION';
export const REORDER_KNOWLEDGE_HUB_SECTION_CONTENT =
  'REORDER_KNOWLEDGE_HUB_SECTION_CONTENT';
export const UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT =
  'UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT';
export const UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT_FAIL =
  'UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT_FAIL';
export const UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT_SUCCESS =
  'UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT_SUCCESS';
export const DELETE_KNOWLEDGE_HUB = 'DELETE_KNOWLEDGE_HUB';
export const DELETE_KNOWLEDGE_HUB_FAIL = 'DELETE_KNOWLEDGE_HUB_FAIL';
export const DELETE_KNOWLEDGE_HUB_SUCCESS = 'DELETE_KNOWLEDGE_HUB_SUCCESS';
export const CLEAN_KNOWLEDGE_HUB_SECTION_CONTENT =
  'CLEAN_KNOWLEDGE_HUB_SECTION_CONTENT';
export const ADD_KNOWLEDGE_HUB_SECTION = 'ADD_KNOWLEDGE_HUB_SECTION';
export const ADD_KNOWLEDGE_HUB_SECTION_SUCCESS =
  'ADD_KNOWLEDGE_HUB_SECTION_SUCCESS';
export const EDIT_KNOWLEDGE_HUB_SECTION = 'EDIT_KNOWLEDGE_HUB_SECTION';
export const EDIT_KNOWLEDGE_HUB_SECTION_SUCCESS =
  'EDIT_KNOWLEDGE_HUB_SECTION_SUCCESS';
export const REORDER_KNOWLEDGE_HUB_SECTIONS = 'REORDER_KNOWLEDGE_HUB_SECTIONS';
export const REORDER_KNOWLEDGE_HUB_SECTIONS_SUCCESS =
  'REORDER_KNOWLEDGE_HUB_SECTIONS_SUCCESS';
export const REORDER_KNOWLEDGE_HUB_SECTIONS_FAIL =
  'REORDER_KNOWLEDGE_HUB_SECTIONS_FAIL';
export const UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS =
  'UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS';
export const UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS_SUCCESS =
  'UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS_SUCCESS';
export const UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS_FAIL =
  'UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS_FAIL';
export const DELETE_KNOWLEDGE_HUB_SECTION = 'DELETE_KNOWLEDGE_HUB_SECTION';
export const DELETE_KNOWLEDGE_HUB_SECTION_SUCCESS =
  'DELETE_KNOWLEDGE_HUB_SECTION_SUCCESS';
export const TOGGLE_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL =
  'TOGGLE_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL';

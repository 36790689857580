import PropTypes from 'prop-types';

const rolesShape = PropTypes.shape({
  isAnalyst: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  isLead: PropTypes.bool.isRequired,
});

const arrayOfRolesShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    roles: rolesShape.isRequired,
  })
);

const permissions = PropTypes.shape({
  //company perms can either be 'false' or array of roles
  company: PropTypes.oneOfType([rolesShape, PropTypes.bool]).isRequired,
  //group perms are optional (only required if you're not a company admin); can be 'false' or array of roles
  group: PropTypes.oneOfType([arrayOfRolesShape, PropTypes.bool]),
  //team perms are optional (only required if you're not a company or group admin); false or array of roles
  team: PropTypes.oneOfType([arrayOfRolesShape, PropTypes.bool]),
});

export default permissions;

import React from 'react';

import CSSModules from 'react-css-modules';

import Button from '../Button';
import {AlertIcon, DeleteIcon} from '../icons';
import Heading from '../TextComponents/Heading';

import styles from './styles.module.scss';

export type WarningButton = {
  text: string;
  disabled?: boolean;
  dataAutomation?: string;
};

type UnsavedChangesWarningProps = {
  title: string;
  text: string;
  closeButton: WarningButton;
  continueButton: WarningButton;
  onClose: () => void;
  onContinue: () => void;
};

const UnsavedChangesWarning = ({
  title,
  text,
  closeButton,
  continueButton,
  onClose,
  onContinue,
}: UnsavedChangesWarningProps) => (
  <div styleName='warning-container'>
    <div styleName='warning-icon-wrapper'>
      <AlertIcon size='big' />
    </div>
    <Heading as='h4' size='leopard' weight='medium'>
      {title}
    </Heading>
    <div styleName='text-wrapper'>
      {text.split('\n').map((item, key) => (
        <div styleName={key > 0 ? 'next-line' : ''} key={key}>
          {item}
          <br />
        </div>
      ))}
    </div>
    <div styleName='button-container'>
      <Button
        type='danger'
        noMarginTop
        noMarginLeft
        icon={<DeleteIcon />}
        iconPosition='start'
        onClick={onClose}
        disabled={closeButton.disabled}
        dataAutomation={closeButton.dataAutomation}
      >
        {closeButton.text}
      </Button>
      <Button
        type='thirdary'
        noMarginTop
        noMarginLeft
        onClick={onContinue}
        disabled={continueButton.disabled}
        dataAutomation={continueButton.dataAutomation}
      >
        {continueButton.text}
      </Button>
    </div>
  </div>
);

export default CSSModules(UnsavedChangesWarning, styles, {allowMultiple: true});

import {addMeta, buildRequest} from '../stateUtil';
import {
  ASSIGN_COLLECTION_TO_CONTENT,
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  LOAD_CONTENT_COLLECTIONS,
  UPDATE_COLLECTION,
  UPDATE_CONTENT_IN_COLLECTION,
} from './collectionsActionTypes';

export const getCollectionsForGroup = (groupId) =>
  buildRequest(
    LOAD_CONTENT_COLLECTIONS,
    'GET',
    `groups/${groupId}/collections`
  );

export const createCollection = (name, groupId) =>
  buildRequest(CREATE_COLLECTION, 'POST', `/groups/${groupId}/collections`, {
    name,
  });

export const renameCollection = (groupId, collectionId, name) =>
  addMeta(
    buildRequest(
      UPDATE_COLLECTION,
      'PATCH',
      `/groups/${groupId}/collections/${collectionId}`,
      {
        name,
      }
    ),
    {name, collectionId}
  );

export const deleteCollection = (collectionId, groupId) =>
  addMeta(
    buildRequest(
      DELETE_COLLECTION,
      'DELETE',
      `/groups/${groupId}/collections/${collectionId}`
    ),
    {collectionId}
  );

export const assignCollectionsToContent = (
  groupId,
  contentId,
  contentType,
  collectionIds
) =>
  addMeta(
    buildRequest(
      ASSIGN_COLLECTION_TO_CONTENT,
      'POST',
      `groups/${groupId}/collections/assignCollections`,
      {
        contentId,
        contentType,
        collectionIds,
      }
    ),
    {
      contentId,
      contentType,
      collectionIds,
      source: 'course',
    }
  );

export const updateContentInCollection = (
  groupId,
  collectionId,
  contentToAdd,
  contentToRemove
) =>
  addMeta(
    buildRequest(
      UPDATE_CONTENT_IN_COLLECTION,
      'POST',
      `/groups/${groupId}/collections/${collectionId}/content`,
      {
        contentToAdd,
        contentToRemove,
      }
    ),
    {collectionId, contentToAdd, contentToRemove, source: 'collection'}
  );

export const LOAD_CONTENTS = 'LOAD_CONTENTS';
export const LOAD_CONTENTS_SUCCESS = 'LOAD_CONTENTS_SUCCESS';
export const LOAD_CONTENTS_FAIL = 'LOAD_CONTENTS_FAIL';

export const COPY_CONTENT = 'COPY_CONTENT';
export const COPY_CONTENT_SUCCESS = 'COPY_CONTENT_SUCCESS';
export const COPY_CONTENT_FAIL = 'COPY_CONTENT_FAIL';

export const GET_SCHEDULED_NOTIFICATIONS = 'GET_SCHEDULED_NOTIFICATIONS';
export const GET_SCHEDULED_NOTIFICATIONS_SUCCESS =
  'GET_SCHEDULED_NOTIFICATIONS_SUCCESS';

export const OPEN_SCHEDULED_NOTIFICATIONS_SLIDE_PANEL =
  'OPEN_SCHEDULED_NOTIFICATIONS_SLIDE_PANEL';

export const TOGGLE_CONTENT_INTEGRATION = 'TOGGLE_COURSE_INTEGRATION';
export const TOGGLE_CONTENT_EVENT_INTEGRATION =
  'TOGGLE_COURSE_EVENT_INTEGRATION';
export const EDIT_CONTENT_EVENT_INTEGRATION_NAME =
  'EDIT_COURSE_EVENT_INTEGRATION_NAME';

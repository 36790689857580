import {legacyFixMarkdown} from '../../utils/markdown';
import * as formActions from '../form/formActions';
import * as notificationActions from '../notification/notificationActions';
import {getSelectedTeamId} from '../team/teamSelectors';
import * as feedActionCreators from './feedActionCreators';
import {VIEW_MESSAGES} from './feedActionTypes';

/* eslint-disable max-params, max-len */
export const addFeedItem =
  (
    title,
    text,
    isImportant,
    type,
    url,
    publishDate,
    uploadFilename,
    groupIds,
    expiryDate
  ) =>
  (dispatch, getState) => {
    dispatch(
      feedActionCreators.addFeedItem(
        publishDate,
        title,
        legacyFixMarkdown(text),
        isImportant,
        type,
        url,
        uploadFilename,
        groupIds,
        expiryDate
      )
    )
      .then(() => loadFeedItems()(dispatch, getState))
      .then(() =>
        notificationActions.createSuccessNotification('Updates.addSuccess')(
          dispatch
        )
      )
      .then(() => formActions.closeForm()(dispatch))
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

/* eslint-disable max-params */
export const editFeedItem =
  (
    id,
    publishDate,
    title,
    text,
    isImportant,
    type,
    url,
    uploadFilename,
    expiryDate
  ) =>
  (dispatch, getState) => {
    dispatch(
      feedActionCreators.editFeedItem(
        id,
        publishDate,
        title,
        legacyFixMarkdown(text),
        isImportant,
        type,
        url,
        uploadFilename,
        expiryDate
      )
    )
      .then(() => loadFeedItems()(dispatch, getState))
      .then(() =>
        notificationActions.createSuccessNotification('Updates.editSuccess')(
          dispatch
        )
      )
      .then(() => formActions.closeForm()(dispatch))
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const deleteFeedItem = (id) => (dispatch, getState) => {
  dispatch(feedActionCreators.deleteFeedItem(id))
    .then(() => loadFeedItems()(dispatch, getState))
    .then(() =>
      notificationActions.createSuccessNotification('Updates.deleteSuccess')(
        dispatch
      )
    )
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const loadFeedItems = () => (dispatch, getState) => {
  const teamId = getSelectedTeamId(getState());
  if (teamId) {
    dispatch(feedActionCreators.getFeed(teamId)).catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
  }
};

export const viewMessagesEvent = (teamId, teamName) => (dispatch) => {
  dispatch({
    type: VIEW_MESSAGES,
    payload: {
      teamId,
      teamName,
    },
  });
};

export const sortObjectsAlphabeticallyByKey = (propName) => (a, b) => {
  const nameA = a[propName].toLowerCase();
  const nameB = b[propName].toLowerCase();
  if (nameA === nameB) {
    return 0;
  }
  return nameA < nameB ? -1 : 1;
};

export const sortObjectsAscendingByKey = (propName) => (a, b) => {
  const keyA = a[propName];
  const keyB = b[propName];
  if (keyA === keyB) {
    return 0;
  }
  return keyA < keyB ? -1 : 1;
};

export const sortObjectsDescendingByKey = (propName) => (a, b) => {
  const keyA = a[propName];
  const keyB = b[propName];
  if (keyA === keyB) {
    return 0;
  }
  return keyA > keyB ? -1 : 1;
};

import {fromJS} from 'immutable';

import {
  TRIAL_EMAIL_STORAGE_KEY,
  TRIAL_IS_BEEKEEPER,
  TRIAL_RESOURCE_KEY_STORAGE_KEY,
  TRIAL_RESOURCE_TYPE_STORAGE_KEY,
} from '../../enums/trial';
import {
  CHECK_COMPANY_NAME,
  CHECK_COMPANY_NAME_FAIL,
  CHECK_COMPANY_NAME_SUCCESS,
  CHECK_TRIAL_EMAIL,
  CHECK_TRIAL_EMAIL_FAIL,
  CHECK_TRIAL_EMAIL_SUCCESS,
  SET_COMPANY_NAME,
  SET_EMAIL,
  SET_ESTIMATED_LEARNERS,
  SET_FIRST_NAME,
  SET_INDUSTRY,
  SET_INTEGRATIONS_USED,
  SET_IS_BEEKEEPER,
  SET_IS_TRIAL_SIGNUP,
  SET_LAST_NAME,
  SET_PREFERRED_EMAIL,
  SET_RESOURCE_KEY,
  SET_RESOURCE_TYPE,
  START_TRIAL,
  START_TRIAL_FAIL,
  START_TRIAL_SUCCESS,
} from './trialActionTypes';

export const initialState = fromJS({
  loadingTrialStart: false,
  trialStartSuccess: false,
  emailCheckInProgress: false,
  emailError: null,
  companyNameCheck: 'notFound',
  email: sessionStorage.getItem(TRIAL_EMAIL_STORAGE_KEY) || '',
  resourceType: sessionStorage.getItem(TRIAL_RESOURCE_TYPE_STORAGE_KEY) || null,
  resourceKey: sessionStorage.getItem(TRIAL_RESOURCE_KEY_STORAGE_KEY) || null,
  isBeekeeper: sessionStorage.getItem(TRIAL_IS_BEEKEEPER) === 'true' || false,
  companyName: '',
  estimatedLearners: null,
  hasSetLearners: false,
  firstName: '',
  lastName: '',
  industry: '',
  integrationsUsed: null,
  preferredEmail: '',
  firstResource: {
    type: null,
    id: null,
    lessonKey: '',
    isResourceNotFound: false,
  },
  userId: null,
  isTrialSignup: false,
});

// eslint-disable-next-line complexity
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_TRIAL:
      return state
        .set('loadingTrialStart', true)
        .set('trialStartSuccess', false);
    case START_TRIAL_SUCCESS:
      return state
        .set('loadingTrialStart', false)
        .set('trialStartSuccess', true)
        .set('firstResource', {
          type: action.payload.data.firstResource.resourceType,
          id: action.payload.data.firstResource.resourceId,
          lessonKey: action.payload.data.firstResource.lessonKey,
          isResourceNotFound:
            action.payload.data.firstResource.isResourceNotFound,
        })
        .set('userId', action.payload.data.id);
    case START_TRIAL_FAIL:
      return state.set('loadingTrialStart', false);

    case CHECK_TRIAL_EMAIL:
      return state.set('emailCheckInProgress', true).set('emailError', null);
    case CHECK_TRIAL_EMAIL_SUCCESS:
      return state.set('emailCheckInProgress', false);
    case CHECK_TRIAL_EMAIL_FAIL: {
      return state
        .set('emailCheckInProgress', false)
        .set('emailError', action.payload.error.response.data.errorCode);
    }

    case CHECK_COMPANY_NAME:
      return state.set('companyNameCheck', 'loading');
    case CHECK_COMPANY_NAME_SUCCESS:
      return state.set(
        'companyNameCheck',
        action.payload.data ? 'notFound' : 'found'
      );
    case CHECK_COMPANY_NAME_FAIL:
      return state.set('companyNameCheck', 'error');

    case SET_EMAIL:
      return state.set('email', action.payload.email);
    case SET_RESOURCE_TYPE:
      return state.set('resourceType', action.payload.resourceType);
    case SET_RESOURCE_KEY:
      return state.set('resourceKey', action.payload.key);
    case SET_IS_BEEKEEPER:
      return state.set('isBeekeeper', action.payload.isBeekeeper);
    case SET_COMPANY_NAME:
      return state.set('companyName', action.payload.companyName);
    case SET_ESTIMATED_LEARNERS:
      return state
        .set('estimatedLearners', action.payload.estimatedLearners)
        .set('hasSetLearners', true);
    case SET_FIRST_NAME:
      return state.set('firstName', action.payload.firstName);
    case SET_LAST_NAME:
      return state.set('lastName', action.payload.lastName);
    case SET_INDUSTRY:
      return state.set('industry', action.payload.industry);
    case SET_INTEGRATIONS_USED: {
      return state.set('integrationsUsed', action.payload.integrations);
    }
    case `${SET_PREFERRED_EMAIL}_SUCCESS`:
      return state.set('preferredEmail', action.payload.data);

    case SET_IS_TRIAL_SIGNUP:
      return state.set('isTrialSignup', action.payload.isTrue);

    default: {
      return state;
    }
  }
};

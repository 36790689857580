import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import Header from '../components/core/Header';
import {integrations} from '../enums/integrations';
import * as adminActions from '../state/admin/adminActions';
import * as authActions from '../state/auth/authActions';
import {getVisibleTabs} from '../state/auth/authSelector';
import * as languageActions from '../state/auth/languageActions';
import {getAdminLanguage} from '../state/auth/languageSelector';
import * as headerActions from '../state/header/headerActions';
import * as teamActions from '../state/team/teamActions';
import {
  getSelectedGroup,
  getSelectedTeam,
  getTeams,
} from '../state/team/teamSelectors';
import * as trackingActions from '../state/tracking/trackingActions';
import {injectFeatureFlags} from '../utils/featureFlags';

const connector = connect(
  (state) => {
    const visibleTabs = getVisibleTabs(state);
    const selectedGroup = getSelectedGroup(state);
    const teams = getTeams(state);
    const selectedTeam = getSelectedTeam(state);

    const hasSingleParentIntegration =
      state
        .getIn(['team', 'integrations'])
        .filter(
          (integration) =>
            integration.get('type') === integrations.BEEKEEPER ||
            integration.get('type') === integrations.SPEAKAP
        ).size > 0;
    const allGroupBranding = state.getIn(['settings', 'groupBranding']).toJS();
    const selectedGroupBranding = allGroupBranding[selectedGroup?.toJS().id];

    const brandingLoaded =
      state.getIn(['settings', 'customerBranding', 'hasLoaded']) &&
      !state.getIn(['settings', 'groupBranding', 'isLoading']);

    return {
      company: state.getIn(['auth', 'company']),
      isEduMeAdmin: state.getIn(['auth', 'hidden']),
      selectedGroup: selectedGroup ? selectedGroup.toJS() : null,
      visibleTabs,
      adminPermissions: state.getIn(['auth', 'permissions']),
      loggedInAs: state.getIn(['auth', 'loggedInAs']),
      email: state.getIn(['auth', 'email']),
      showOnboarding: state.getIn(['auth', 'showOnboarding']),
      deleteGroupInProgress: state.getIn(['team', 'deleteGroupInProgress']),
      hasSingleParentIntegration,
      integrationsLoaded: state.getIn(['team', 'integrationsLoaded']),
      teams: teams,
      teamsLoaded: state.getIn(['team', 'teamsLoaded']),
      selectedTeam,
      userCountLoaded: state.getIn(['team', 'userCountLoaded']),
      customerBranding: state.getIn(['settings', 'customerBranding']).toJS(),
      selectedGroupBranding,
      brandingLoaded,
      adminLanguage: getAdminLanguage(state),
    };
  },
  (dispatch) => ({
    ...bindActionCreators(authActions, dispatch),
    ...bindActionCreators(adminActions, dispatch),
    ...bindActionCreators(teamActions, dispatch),
    ...bindActionCreators(trackingActions, dispatch),
    ...bindActionCreators(headerActions, dispatch),
    ...bindActionCreators(languageActions, dispatch),
  })
);

const HeaderView = ({
  adminPermissions,
  visibleTabs,
  company,
  selectedGroup,
  loggedInAs,
  email,
  showOnboarding,
  deleteGroupInProgress,
  isEduMeAdmin,
  logout,
  addAdmin,
  editGroup,
  deleteGroup,
  viewHelpCenter,
  hasSingleParentIntegration,
  integrationsLoaded,
  loadTeams,
  userCountLoaded,
  viewCompanySettings,
  customerBranding,
  selectedGroupBranding,
  brandingLoaded,
  featureFlags,
  setLanguage,
  adminLanguage,
}) => {
  const {isTrial} = company;

  let logoUrl = company.logoUrl;
  let darkLogoUrl = company.darkLogoUrl;

  if (brandingLoaded) {
    logoUrl =
      selectedGroupBranding?.logoUrl ||
      customerBranding?.customerBrandingData?.logoUrl ||
      selectedGroupBranding?.darkLogoUrl ||
      customerBranding?.customerBrandingData?.darkLogoUrl;

    darkLogoUrl =
      selectedGroupBranding?.darkLogoUrl ||
      customerBranding?.customerBrandingData?.darkLogoUrl ||
      selectedGroupBranding?.logoUrl ||
      customerBranding?.customerBrandingData?.logoUrl;
  }

  return (
    <Header
      permissions={
        adminPermissions.toJS() /*TODO check this, sometimes immutable*/
      }
      visibleTabs={visibleTabs}
      loggedInAs={loggedInAs}
      email={email}
      logout={logout}
      addAdmin={addAdmin}
      deleteGroup={deleteGroup}
      deleteGroupInProgress={deleteGroupInProgress}
      logoUrl={logoUrl}
      darkLogoUrl={darkLogoUrl}
      selectedGroup={selectedGroup}
      isTrial={isTrial}
      isEduMeAdmin={isEduMeAdmin}
      showOnboarding={showOnboarding}
      viewHelpCenter={viewHelpCenter}
      isGroupDropdownVisible={!hasSingleParentIntegration}
      integrationsLoaded={integrationsLoaded}
      loadTeams={loadTeams}
      userCountLoaded={userCountLoaded}
      viewCompanySettings={viewCompanySettings}
      editGroup={editGroup}
      guidesInLearningTabEnabled={featureFlags.guidesInLearningTabEnabled}
      setLanguage={setLanguage}
      adminLanguage={adminLanguage}
    />
  );
};

HeaderView.propTypes = {
  //state
  adminPermissions: PropTypes.object.isRequired,
  visibleTabs: PropTypes.array.isRequired,
  company: PropTypes.object,
  selectedGroup: PropTypes.object,
  loggedInAs: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  showOnboarding: PropTypes.bool,
  deleteGroupInProgress: PropTypes.bool.isRequired,
  isEduMeAdmin: PropTypes.bool.isRequired,
  hasSingleParentIntegration: PropTypes.bool.isRequired,
  integrationsLoaded: PropTypes.bool.isRequired,
  loadTeams: PropTypes.func.isRequired,
  userCountLoaded: PropTypes.bool.isRequired,
  customerBranding: PropTypes.object.isRequired,
  selectedGroupBranding: PropTypes.object,
  brandingLoaded: PropTypes.bool.isRequired,
  //actions
  logout: PropTypes.func.isRequired,
  addAdmin: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  // header
  viewHelpCenter: PropTypes.func.isRequired,
  viewCompanySettings: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  adminLanguage: PropTypes.string.isRequired,

  featureFlags: PropTypes.object.isRequired,
};

export default injectFeatureFlags(withRouter(connector(HeaderView)));

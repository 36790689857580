import axios from 'axios';

import {integrations} from './enums/integrations';

export const isInIframe = window.self !== window.top;

const edumeMstClient = axios.create({
  baseURL: '/api',
  headers: {
    platform: 'admin',
    'is-in-iframe': isInIframe,
    'access-method': integrations.MS_TEAMS,
  },
});

export default edumeMstClient;

export const intersect2D = (rect1, rect2) => {
  /*
   * Given 4 points along the horizontal/vertical axis (2 for rect1 + 2 for rect2),
   * we will pick the second and the third point (when sorted) to ensure we are getting the
   * box coordinates that results from the two overlapping boxes (we will for now ignore the cases
   * where there is no overlap)
   *
   *          p1         p3
   *          +---------+
   *          |   p2    |    p4
   *          |    +---------+
   *          |    |    |    |
   *          +---------+    |
   *               |         |
   *               +---------+
   */
  const [, x1, x2] = [
    rect1.x,
    rect1.x + rect1.width,
    rect2.x,
    rect2.x + rect2.width,
  ].sort((a, b) => a - b);
  const [, y1, y2] = [
    rect1.y,
    rect1.y + rect1.height,
    rect2.y,
    rect2.y + rect2.height,
  ].sort((a, b) => a - b);
  const [width, height] = [x2 - x1, y2 - y1];
  return {x: x1, y: y1, width, height};
};

// Compute the smallest rectangle of an aspect ratio which can contain the passed rectangle
export const contain = (width, height, aspectRatio) => {
  if (width / height - aspectRatio > 0.1) {
    return {width, height: parseInt(width / aspectRatio)};
  } else if (width / height - aspectRatio < 0.1) {
    return {width: parseInt(height * aspectRatio), height};
  } else {
    return {width, height};
  }
};

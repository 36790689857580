import {
  CLEAR_VARIANT_OVERRIDE,
  SET_AMPLITUDE_TEST_PROPS,
  SET_TEST_VARIANT,
  SET_VARIANT_OVERRIDE,
} from './multivariantTestActionTypes';

export const setAmplitudeTestProps = () => (dispatch) =>
  dispatch({
    type: SET_AMPLITUDE_TEST_PROPS,
  });

export const setTestVariant = (testName, variantId) => (dispatch) => {
  dispatch({
    type: SET_TEST_VARIANT,
    payload: {
      testName,
      variantId,
    },
  });
};

export const setVariantOverride = (variantId) => (dispatch) =>
  dispatch({
    type: SET_VARIANT_OVERRIDE,
    payload: variantId,
  });

export const clearVariantOverride = () => (dispatch) =>
  dispatch({
    type: CLEAR_VARIANT_OVERRIDE,
  });

export const QUESTION_TYPES = {
  SINGLE_RESPONSE: 'singleResponse',
  MULTIPLE_RESPONSE: 'multipleResponse',
  ORDERING: 'ordering',
};

export const CURRENT_SLIDE_INDEX_ACTION_PROP = 'currentSlideIndex';

const LIMITED = 'LIMITED';

export const EDITING_TYPES = {
  FULL: 'FULL',
  LIMITED,
  LIMITED_MLS: `${LIMITED}_MLS`,
  NONE: 'NONE',
};

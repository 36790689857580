import {fromJS} from 'immutable';

import {
  GET_MATCHED_LEARNER_COUNT_FOR_RULES,
  GET_MATCHED_LEARNER_COUNT_FOR_RULES_FAIL,
  GET_MATCHED_LEARNER_COUNT_FOR_RULES_SUCCESS,
  LOAD_CUSTOMER_TAGS_SUCCESS,
} from './tagActionTypes';

export const initialState = fromJS({
  customerTags: [],
  matchedLearnersCount: null,
});

// eslint-disable-next-line complexity
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CUSTOMER_TAGS_SUCCESS: {
      return state
        .set('loaded', true)
        .set('loading', false)
        .set('customerTags', fromJS(action.payload.data));
    }

    case GET_MATCHED_LEARNER_COUNT_FOR_RULES:
    case GET_MATCHED_LEARNER_COUNT_FOR_RULES_FAIL: {
      return state.set('matchedLearnersCount', null);
    }

    case GET_MATCHED_LEARNER_COUNT_FOR_RULES_SUCCESS: {
      return state.set('matchedLearnersCount', action.payload.data);
    }

    default: {
      return state;
    }
  }
};

import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import useFeatureFlag from '../../../hooks/useFeatureFlags';
import {getHasSingleV2KnowledgeHub} from '../../../state/knowledgeHub/knowledgeHubSelector';
import AddKnowledgeHubView from '../../../views/KnowledgeHubs';
import CreateEditCourse from '../../Course/CreateEditCourse';
import AddNewGuideSlidePanel from '../../Guide/AddNewGuideSlidePanel';
import ChooseContentType from '../ChooseContentType';

/** @typedef {'course' | 'guide' | 'knowledgeHub'} ContentType */

const CreateContentFlow = ({onClose}) => {
  const isGuidesInLearningTabEnabled = useFeatureFlag('guidesInLearningTab');
  const aiGuideGenerationEnabled = useFeatureFlag('aiGuideGeneration');

  const hasSingleV2KnowledgeHub = useSelector(getHasSingleV2KnowledgeHub);
  const isKnowledgeHubsEnabled =
    useFeatureFlag('knowledgeHubs') && !hasSingleV2KnowledgeHub;

  /** @param {ContentType} contentType - The type of content being selected. */
  const onChooseContentType = (contentType) => {
    switch (contentType) {
      case 'course':
        setStep(createCourseStep);
        break;
      case 'guide':
        setStep(createGuideStep);
        break;
      case 'knowledgeHub':
        setStep(createKnowledgeHubStep);
        break;
      default:
        break;
    }
  };

  const onBack = () => setStep(chooseContentTypeStep);

  const chooseContentTypeStep = (
    <ChooseContentType
      onChooseContentType={onChooseContentType}
      onClose={onClose}
      isGuidesInLearningTabEnabled={isGuidesInLearningTabEnabled}
      isKnowledgeHubsEnabled={isKnowledgeHubsEnabled}
    />
  );
  const createCourseStep = (
    <CreateEditCourse onClose={onClose} onBack={onBack} />
  );
  const createGuideStep = (
    <AddNewGuideSlidePanel
      onClosePopover={onClose}
      aiGuideGenerationEnabled={aiGuideGenerationEnabled}
    />
  );
  const createKnowledgeHubStep = (
    <AddKnowledgeHubView onClose={onClose} onBack={onBack} />
  );

  const [step, setStep] = useState(chooseContentTypeStep);

  if (!isGuidesInLearningTabEnabled && !isKnowledgeHubsEnabled) {
    return createCourseStep;
  }

  return step;
};

CreateContentFlow.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreateContentFlow;

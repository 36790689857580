import {fromJS, Map} from 'immutable';

import {MS_TEAMS} from '../../enums/integrations';
import {getWebhookConfigurationName} from '../../utils/webhook';
import {LOGGED_IN} from '../auth/authActionTypes';
import {
  INITIAL_LOAD_TEAMS,
  INITIAL_LOAD_TEAMS_SUCCESS,
} from '../team/teamActionTypes';
import {
  CLOSE_MS_TEAMS_MODAL,
  CREATE_INTEGRATION,
  CREATE_INTEGRATION_FAIL,
  CREATE_INTEGRATION_SUCCESS,
  DELETE_API_KEY_SUCCESS,
  DELETE_INTEGRATION,
  DELETE_INTEGRATION_FAIL,
  DELETE_INTEGRATION_SUCCESS,
  GENERATE_API_KEY,
  GENERATE_API_KEY_FAIL,
  GENERATE_API_KEY_SUCCESS,
  GENERATE_FOUNTAIN_KEYS,
  GENERATE_FOUNTAIN_KEYS_FAIL,
  GENERATE_FOUNTAIN_KEYS_SUCCESS,
  GENERATE_SIGNING_SECRET,
  GENERATE_SIGNING_SECRET_FAIL,
  GENERATE_SIGNING_SECRET_SUCCESS,
  GET_API_KEYS,
  GET_API_KEYS_FAIL,
  GET_API_KEYS_SUCCESS,
  GET_CUSTOMER_BRANDING,
  GET_GROUP_BRANDING,
  GET_INTEGRATION_CREDENTIALS,
  GET_INTEGRATION_CREDENTIALS_FAIL,
  GET_INTEGRATION_CREDENTIALS_SUCCESS,
  GET_SIGNING_SECRETS,
  GET_SIGNING_SECRETS_FAIL,
  GET_SIGNING_SECRETS_SUCCESS,
  GET_WEBHOOK_CONFIGURATIONS,
  GET_WEBHOOK_CONFIGURATIONS_FAIL,
  GET_WEBHOOK_CONFIGURATIONS_SUCCESS,
  GET_WEBHOOK_EVENTS,
  GET_WEBHOOK_EVENTS_FAIL,
  GET_WEBHOOK_EVENTS_SUCCESS,
  OPEN_MS_TEAMS_MODAL,
  RESET_CUSTOMER_BRANDING,
  UPDATE_CUSTOMER_BRANDING,
  UPDATE_FOUNTAIN_KEYS,
  UPDATE_FOUNTAIN_KEYS_FAIL,
  UPDATE_FOUNTAIN_KEYS_SUCCESS,
  UPDATE_GROUP_BRANDING,
  UPDATE_INTEGRATION,
  UPDATE_INTEGRATION_FAIL,
  UPDATE_INTEGRATION_SUCCESS,
} from './settingsActionTypes';

export const initialState = fromJS({
  webhookConfigurations: {
    isLoading: false,
    data: [],
  },
  webhookEvents: {
    isLoading: false,
    data: [],
  },
  signingSecret: {
    key: null,
    isLoading: false,
    hasLoaded: false,
  },
  apiKeys: {
    keys: [],
    isLoading: false,
  },
  integrationCredentials: {
    credentials: {},
    groupIntegrationCredentials: {},
    isLoading: false,
    integrated: false,
    updated: false,
    isMsTeamsSuccessModalVisible: false,
  },
  customerBranding: {
    customerBrandingData: null,
    isLoading: false,
    hasLoaded: false,
  },
  groupBranding: {
    isLoading: true,
  },
});

// eslint-disable-next-line complexity, max-statements
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_API_KEYS:
    case GENERATE_API_KEY: {
      return state.setIn(['apiKeys', 'isLoading'], true);
    }
    case GET_API_KEYS_SUCCESS: {
      const apiKeys = action.payload.data.map((apiKey) =>
        apiKey.groupId
          ? {...apiKey, accessLevel: apiKey.groupName}
          : {...apiKey, accessLevel: 'Company'}
      );
      return state.set('apiKeys', fromJS({keys: apiKeys, isLoading: false}));
    }
    case GENERATE_API_KEY_SUCCESS: {
      const apiKey = action.payload.data;
      const newApiKey = apiKey.groupId
        ? {...apiKey, accessLevel: apiKey.groupName}
        : {...apiKey, accessLevel: 'Company'};

      return state
        .updateIn(['apiKeys', 'keys'], (apiKeys) =>
          apiKeys.push(fromJS(newApiKey))
        )
        .setIn(['apiKeys', 'isLoading'], false);
    }
    case DELETE_API_KEY_SUCCESS: {
      return state.updateIn(['apiKeys', 'keys'], (apiKeys) =>
        apiKeys.filter((apiKey) => apiKey.get('id') !== action.meta.id)
      );
    }
    case GET_API_KEYS_FAIL:
    case GENERATE_API_KEY_FAIL: {
      return state.setIn(['apiKeys', 'isLoading'], false);
    }

    case GET_SIGNING_SECRETS:
    case GENERATE_SIGNING_SECRET: {
      return state.setIn(['signingSecret', 'isLoading'], true);
    }
    case GET_SIGNING_SECRETS_SUCCESS:
    case GENERATE_SIGNING_SECRET_SUCCESS: {
      const {id, key} = action.payload.data;
      return state.set(
        'signingSecret',
        fromJS({id, key, isLoading: false, hasLoaded: true})
      );
    }
    case GET_SIGNING_SECRETS_FAIL:
    case GENERATE_SIGNING_SECRET_FAIL: {
      return state.setIn(['signingSecret', 'isLoading'], false);
    }

    case GET_WEBHOOK_CONFIGURATIONS: {
      return state.setIn(['webhookConfigurations', 'isLoading'], true);
    }

    case GET_WEBHOOK_CONFIGURATIONS_FAIL: {
      return state.setIn(['webhookConfigurations', 'isLoading'], false);
    }

    case GET_WEBHOOK_CONFIGURATIONS_SUCCESS: {
      const {data} = action.payload;

      return state.set(
        'webhookConfigurations',
        fromJS({
          isLoading: false,
          data: data.map((d) => ({
            ...d,
            // TODO: remove this when webhooks.name is no longer nullable
            name: getWebhookConfigurationName(d.name, d.url),
          })),
        })
      );
    }

    case GET_WEBHOOK_EVENTS: {
      return state.setIn(['webhookEvents', 'isLoading'], true);
    }

    case GET_WEBHOOK_EVENTS_FAIL: {
      return state.setIn(['webhookEvents', 'isLoading'], false);
    }

    case GET_WEBHOOK_EVENTS_SUCCESS: {
      const {data} = action.payload;
      return state.set(
        'webhookEvents',
        fromJS({
          isLoading: false,
          data,
        })
      );
    }

    case CREATE_INTEGRATION:
    case UPDATE_INTEGRATION:
    case GET_INTEGRATION_CREDENTIALS:
    case DELETE_INTEGRATION:
    case GENERATE_FOUNTAIN_KEYS:
    case UPDATE_FOUNTAIN_KEYS: {
      return state.set(
        'integrationCredentials',
        fromJS({
          credentials: {},
          groupIntegrationCredentials: {},
          isLoading: true,
        })
      );
    }
    case CREATE_INTEGRATION_SUCCESS:
    case GET_INTEGRATION_CREDENTIALS_SUCCESS: {
      const {integration, integrated} = action.payload.data;
      const {credentials, groupIntegrationCredentials} = integration;
      let isMsTeamsSuccessModalVisible = state.getIn([
        'integrationCredentials',
        'isMsTeamsSuccessModalVisible',
      ]);
      if (integration.credentials.type === MS_TEAMS) {
        isMsTeamsSuccessModalVisible =
          !groupIntegrationCredentials.permissionsGranted;
      }
      return state.set(
        'integrationCredentials',
        fromJS({
          credentials,
          groupIntegrationCredentials: groupIntegrationCredentials || {},
          isLoading: false,
          integrated,
          isMsTeamsSuccessModalVisible,
        })
      );
    }
    case UPDATE_INTEGRATION_SUCCESS: {
      const {integration, updated} = action.payload.data;
      const {credentials, groupIntegrationCredentials} = integration;
      const isMsTeamsSuccessModalVisible = state.getIn([
        'integrationCredentials',
        'isMsTeamsSuccessModalVisible',
      ]);
      return state.set(
        'integrationCredentials',
        fromJS({
          credentials,
          groupIntegrationCredentials: groupIntegrationCredentials || {},
          isLoading: false,
          updated,
          isMsTeamsSuccessModalVisible,
        })
      );
    }
    case CREATE_INTEGRATION_FAIL:
    case UPDATE_INTEGRATION_FAIL:
    case GET_INTEGRATION_CREDENTIALS_FAIL:
    case DELETE_INTEGRATION_FAIL:
    case GENERATE_FOUNTAIN_KEYS_FAIL:
    case UPDATE_FOUNTAIN_KEYS_FAIL: {
      return state.setIn(['integrationCredentials', 'isLoading'], false);
    }
    case DELETE_INTEGRATION_SUCCESS: {
      return state.set(
        'integrationCredentials',
        fromJS({
          credentials: {},
          groupIntegrationCredentials: {},
          isLoading: false,
          integrated: false,
          updated: false,
        })
      );
    }
    case OPEN_MS_TEAMS_MODAL: {
      return state.setIn(
        ['integrationCredentials', 'isMsTeamsSuccessModalVisible'],
        true
      );
    }
    case CLOSE_MS_TEAMS_MODAL: {
      return state.setIn(
        ['integrationCredentials', 'isMsTeamsSuccessModalVisible'],
        false
      );
    }
    case GENERATE_FOUNTAIN_KEYS_SUCCESS: {
      return state.set(
        'integrationCredentials',
        fromJS({
          credentials: action.payload.data.credentials,
          isLoading: false,
          integrated: true,
          updated: true,
        })
      );
    }

    case UPDATE_FOUNTAIN_KEYS_SUCCESS: {
      return state.set(
        'integrationCredentials',
        fromJS({
          credentials: action.payload.data.credentials,
          isLoading: false,
          integrated: true,
          updated: true,
        })
      );
    }
    case GET_CUSTOMER_BRANDING:
      return state.setIn(['customerBranding', 'isLoading'], true);

    case `${GET_CUSTOMER_BRANDING}_SUCCESS`: {
      const branding = action.payload.data;
      delete branding.id;
      return state
        .setIn(['customerBranding', 'isLoading'], false)
        .setIn(['customerBranding', 'customerBrandingData'], branding)
        .setIn(['customerBranding', 'hasLoaded'], true);
    }

    case LOGGED_IN: {
      if (state.getIn(['customerBranding', 'customerBrandingData'])) {
        return state;
      }
      const customisationFields = [
        'logoUrl',
        'darkLogoUrl',
        'colorPrimary',
        'colorPrimaryDark',
        'colorAccent1',
        'colorAccent2',
      ];
      const {company} = action.payload;
      const branding = customisationFields.reduce(
        (prev, field) => ({...prev, [field]: company[field]}),
        state.getIn(['customerBranding', 'customerBrandingData'])
      );
      return state
        .setIn(['customerBranding', 'customerBrandingData'], branding)
        .setIn(['customerBranding', 'hasLoaded'], true);
    }

    case INITIAL_LOAD_TEAMS:
      return state.setIn(['groupBranding', 'isLoading'], true);

    case INITIAL_LOAD_TEAMS_SUCCESS: {
      if (state.get('groupBranding').size > 1) {
        return state;
      }

      const groups = action.payload.data;
      const branding = groups.reduce(
        (prev, group) => prev.set(group.id, group.groupCustomisations),
        state.get('groupBranding')
      );
      return state
        .setIn(['groupBranding'], branding)
        .setIn(['groupBranding', 'isLoading'], false);
    }

    case GET_GROUP_BRANDING:
      return state.setIn(['groupBranding', 'isLoading'], true);

    case `${GET_GROUP_BRANDING}_SUCCESS`: {
      const {groupId} = action.payload.data;
      return state
        .setIn(['groupBranding', groupId], action.payload.data)
        .setIn(['groupBranding', 'isLoading'], true);
    }

    case RESET_CUSTOMER_BRANDING:
      return state.set('branding', fromJS({customerBranding: Map()}));

    case `${GET_CUSTOMER_BRANDING}_FAIL`:
    case `${GET_GROUP_BRANDING}_FAIL`:
    case `${UPDATE_CUSTOMER_BRANDING}_FAIL`:
    case `${UPDATE_GROUP_BRANDING}_FAIL`:
      return state
        .setIn(['customerBranding', 'isLoading'], false)
        .setIn(['groupBranding', 'isLoading'], false);

    default: {
      return state;
    }
  }
};

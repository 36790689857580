import marked from 'marked';

export const createMarkup = (htmlString: string) => ({
  __html: htmlString,
});

// eslint-disable-next-line complexity
export const fixMarkdown = (str: string) => {
  const listRegex = /^ *(\d\.|\* |- )/;

  const swapAsterisksAndUnderlineTags = (text: string) =>
    text
      // Removes any spaces before the `</u>` tag, which causes a random asterisk to be rendered
      .replace(/ +(<\/u>)/g, '$1')
      // Swaps the order so that asterisks come inside the `<u>` tag to prevent random asterisks being rendered
      .replace(/(\*{1,3})(<u>)(.*?)(<\/u>)(\*{1,3})/g, '$2$1$3$5$4');

  const lines = swapAsterisksAndUnderlineTags(str).split('\n');

  // Remove empty lines between paragraphs but not before/after a list
  for (let i = 1; i < lines.length; i++) {
    if (
      lines[i] === '' &&
      lines[i - 1] !== '' &&
      !listRegex.test(lines[i - 1] || '') &&
      (lines[i + 1] !== '' || listRegex.test(lines[i + 1] || ''))
    ) {
      lines.splice(i, 1);
    }
  }

  if (lines.length <= 1) {
    return lines[0];
  }

  //i.e. an asterisk, dash, or number+fullstop (optionally preceded by spaces)
  //also needs to not-detect lines that begin with bold (hence the [^\*])
  //this DOESN'T work for lines beginning in italics (single *) but we're ok with that
  const isListItem = (line: string) => line.match(listRegex);
  let isPrevLineListItem = isListItem(lines[0]);
  let isPrevLineEmpty = lines[0] === '';

  const fixedLines = [lines[0]]; //will be returned

  lines.slice(1).forEach((line: string) => {
    const isThisLineListItem = isListItem(line);

    if (isThisLineListItem && !isPrevLineListItem && !isPrevLineEmpty) {
      // if previous line isn't a list (or empty), want to insert a new line before this first list item
      fixedLines.push('');
    }

    // Whilst user is editing slide, we need to add a space to empty lines otherwise they get
    // collapsed by the markdown renderer
    if (isPrevLineEmpty && line === '') {
      fixedLines.push(' ');
    } else {
      fixedLines.push(line);
    }

    isPrevLineListItem = isThisLineListItem;
    isPrevLineEmpty = line === '';
  });

  return fixedLines.join('\n');
};

//`breaks` causes single line breaks to render as new lines, which isn't normal markdown (it's gfm-only)
export const renderMarkdown = (markdown: string) =>
  marked(fixMarkdown(markdown), {gfm: true, breaks: true});

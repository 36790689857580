import React from 'react';

import Generic, {IndicatorSize, IndicatorType} from './Generic';
import Hexagons from './Hexagons';
import Sparkles from './Sparkles';

export type ContainerStyleProps = 'centerFlex' | 'centerAbsolute' | '';

export type NonGenericIndicatorSize = number | 'small' | 'normal' | 'large';

type CommonLoadingIndicatorProps = {
  color?: 'light' | 'dark';
  containerStyle?: ContainerStyleProps;
  dataAutomation?: string;
  indicator?: 'hexagons' | 'generic' | 'sparkles';
};

type ConditionalSizeProps =
  | {
      indicator: 'hexagons' | 'sparkles';
      size?: NonGenericIndicatorSize;
    }
  | {
      indicator: 'generic';
      size?: IndicatorSize;
      type?: IndicatorType;
    };

type LoadingIndicatorProps = CommonLoadingIndicatorProps & ConditionalSizeProps;

const LoadingIndicator = (props: LoadingIndicatorProps): JSX.Element => {
  // This is a hacky way to apply default props whilst keeping the conditiional type logic above
  const requiredProps = {
    ...props,
    color: props.color || 'light',
    containerStyle: (props.containerStyle || '') as ContainerStyleProps,
    dataAutomation: props.dataAutomation || 'loading-indicator',
  };

  switch (requiredProps.indicator) {
    case 'hexagons':
      return <Hexagons {...requiredProps} />;
    case 'sparkles':
      return <Sparkles {...requiredProps} />;
    default:
      return <Generic {...requiredProps} />;
  }
};

export default LoadingIndicator;

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const isLightColour = (colour: string) => {
  if (!colour) {
    return false;
  }
  const rgb = hexToRgb(colour);
  // NB this currently doesn't work for 3-digit hex codes, so returning false for now
  if (!rgb) {
    return false;
  }
  const brightnessValue = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  return brightnessValue > 130;
};

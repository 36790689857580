import React from 'react';

import {IconProps} from '../types';
import {colourMap, sizesMap} from '../utils/iconProperties';

const CrossSquareSolidIcon = ({
  size = 'small',
  colour = 'textPrimary',
}: IconProps): JSX.Element => (
  <svg
    width={sizesMap[size]}
    height={sizesMap[size]}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='36' height='36' rx='4' fill={colourMap[colour]} />
    <path
      d='M23.2978 13.8696C23.6263 14.1981 23.6306 14.7264 23.3028 15.0542L20.357 18L23.3028 20.9457C23.6285 21.2714 23.6313 21.7967 23.2978 22.1303L22.1303 23.2977C21.8018 23.6262 21.2735 23.6305 20.9458 23.3027L18 20.357L15.0543 23.3027C14.7285 23.6285 14.2032 23.6313 13.8697 23.2977L12.7022 22.1303C12.3738 21.8018 12.3695 21.2735 12.6972 20.9457L15.643 18L12.6972 15.0542C12.3715 14.7285 12.3687 14.2032 12.7022 13.8696L13.8697 12.7022C14.1982 12.3737 14.7265 12.3694 15.0543 12.6972L18.0006 15.6423L20.9458 12.6972C21.2715 12.3714 21.7968 12.3686 22.1303 12.7022L23.2978 13.8696Z'
      fill='white'
    />
  </svg>
);

export default CrossSquareSolidIcon;

import {fromJS} from 'immutable';

import {
  LOAD_LOGIN_PAGE_CONFIG_FAIL,
  LOAD_LOGIN_PAGE_CONFIG_SUCCESS,
} from './loginPageConfigActionTypes';

const urlParams = new URLSearchParams(window.location.search);
const configFromUrl = JSON.parse(urlParams.get('loginPageConfig'));

export const initialState = configFromUrl
  ? fromJS({hasLoaded: true, data: [configFromUrl]})
  : fromJS({hasLoaded: false, data: []});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LOGIN_PAGE_CONFIG_SUCCESS: {
      return state
        .set('data', fromJS(action.payload.data))
        .set('hasLoaded', true);
    }
    case LOAD_LOGIN_PAGE_CONFIG_FAIL: {
      return state.set('hasLoaded', true);
    }
    default: {
      return state;
    }
  }
};

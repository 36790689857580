import {useEffect, useState} from 'react';

const useHasChanges = (currentValues, originalValues) => {
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const hasChanged = currentValues.some(
      (val, i) => val !== originalValues[i]
    );
    setHasChanges(hasChanged);
  }, currentValues);

  return hasChanges;
};

export default useHasChanges;

export const getExtensionFromFileName = (fileName: string) => {
  if (typeof fileName !== 'string') {
    return null;
  }

  const parts = fileName.split('.');
  if (parts.length < 2) {
    return null;
  }

  const extension = parts.pop()?.toLowerCase().trim();

  return extension?.includes(' ') ? null : extension;
};

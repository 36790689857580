import {
  ADD_FORM_ERROR,
  CLOSE_FORM,
  OPEN_FORM,
  RESET_FORM_ERROR,
} from './formActionTypes';

export const openForm = () => (dispatch) => dispatch({type: OPEN_FORM});

export const closeForm = () => (dispatch) => dispatch({type: CLOSE_FORM});

export const addFormErrorFromResponse = (errorResponse) => (dispatch) => {
  //ignore network failure, server error, etc - only show form errors for invalid input

  if (
    !errorResponse.response ||
    errorResponse.response.data.statusCode >= 500
  ) {
    return;
  }

  //BE error responses are in 2 formats; regular errors, and Hapi request validation errors
  const data = errorResponse.response.data;
  const payload =
    data.errorCode || (data.validation ? data.validation.keys[0] : 'generic');
  dispatch({type: ADD_FORM_ERROR, payload});
};

export const resetFormError = () => (dispatch) =>
  dispatch({type: RESET_FORM_ERROR});

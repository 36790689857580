import {useEffect, useState} from 'react';

import throttle from 'lodash/throttle';

/**
 * Custom hook to determine if the user is on a desktop device
 */
export const useIsDesktop = (breakpoint: number) => {
  const [width, setWidth] = useState(() => window.innerWidth);

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setWidth(window.innerWidth);
    }, 200);

    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, [breakpoint]);

  if (!width) {
    return false;
  }

  return window.matchMedia('(hover: hover)').matches && width > breakpoint;
};

import React from 'react';

import PropTypes from 'prop-types';

import {Button, ChevronIcon} from '@edume/magnificent';

import BaseHeader from '../BaseHeader';

const SecondaryHeader = ({onBack, ...propsRest}) => {
  const backButtonContainer = (
    <Button
      type='thirdary'
      dataAutomation='back-button'
      onClick={onBack}
      icon={<ChevronIcon orientation='left' />}
      noMarginTop
    />
  );

  return (
    <BaseHeader backButtonContainer={backButtonContainer} {...propsRest} />
  );
};

SecondaryHeader.propTypes = {
  ...BaseHeader.propTypes,
  onBack: PropTypes.func.isRequired,
};

export default SecondaryHeader;

import {fromJS} from 'immutable';

import {LOAD_ONBOARDING_SUCCESS} from './onboardingActionTypes';

export const initialState = fromJS({
  items: [],
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ONBOARDING_SUCCESS:
      return state.set('items', fromJS(action.payload.data));
    default:
      return state;
  }
};

import React from 'react';

import {OrderedMap} from 'immutable';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {NotificationArea as TerrificNotificationArea} from '@edume/terrific';

import * as notificationActions from '../../state/notification/notificationActions';

const connector = connect(
  (state) => ({
    notifications: state.getIn(['notification', 'notifications']),
  }),
  (dispatch) => ({
    ...bindActionCreators(notificationActions, dispatch),
  })
);

const NotificationArea = ({notifications, deleteNotification}) => {
  const intl = useIntl();

  const parsedNotifications = notifications.toArray().map((notification) => ({
    ...notification,
    headerText:
      notification.headerText &&
      intl.formatMessage({id: notification.headerText}),
    message: intl.formatMessage(
      {id: notification.messageKey},
      notification.data
    ),
    dataAutomation: notification.messageKey.toLowerCase().replaceAll('.', '-'),
  }));

  return (
    <TerrificNotificationArea
      headers={{
        success: intl.formatMessage({id: 'Toast.Success'}),
        error: intl.formatMessage({id: 'Toast.Error'}),
      }}
      notifications={parsedNotifications}
      onDelete={(id) => deleteNotification(id)}
    />
  );
};

NotificationArea.propTypes = {
  notifications: PropTypes.instanceOf(OrderedMap),
  deleteNotification: PropTypes.func.isRequired,
};

export default connector(NotificationArea);

import type {ComponentType, lazy as Lazy} from 'react';

type ImportComponent = () => Promise<{default: ComponentType}>;

const STORAGE_KEY = 'page-has-been-force-refreshed';

export const lazyRetryBuilder =
  (lazy: typeof Lazy) => (importComponent: ImportComponent) =>
    lazy((async () => {
      const hasPageAlreadyBeenForceRefreshed = JSON.parse(
        window.sessionStorage.getItem(STORAGE_KEY) || 'false'
      );

      try {
        const component = await importComponent();
        window.sessionStorage.setItem(STORAGE_KEY, 'false');

        return component;
      } catch (e) {
        if (!hasPageAlreadyBeenForceRefreshed) {
          window.sessionStorage.setItem(STORAGE_KEY, 'true');
          return window.location.reload();
        }

        throw e;
      }
    }) as ImportComponent);

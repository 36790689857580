import {createSelector} from 'reselect';

export const getSelectedGroupStats = (state) => {
  const selectedGroupId = state.getIn(['team', 'groupId']);
  return state.getIn(['stats', 'groupStats', selectedGroupId]);
};

export const getSelectedGroupActivityGraph = (state) => {
  const selectedGroupId = state.getIn(['team', 'groupId']);
  const rollingData = state.getIn([
    'stats',
    'groupStats',
    selectedGroupId,
    'rolling',
  ]);
  return rollingData ? rollingData.toJS() : [];
};

export const getSelectedGroupMonthlyActivity = (state) => {
  const selectedGroupId = state.getIn(['team', 'groupId']);
  const monthlyData = state.getIn([
    'stats',
    'groupStats',
    selectedGroupId,
    'month',
  ]);
  return monthlyData ? monthlyData.toJS() : [];
};

export const getSelectedGroupCacheDate = (state) => {
  const selectedGroupId = state.getIn(['team', 'groupId']);
  const cacheDate = state.getIn([
    'stats',
    'groupStats',
    selectedGroupId,
    'cacheUpdatedAt',
  ]);
  return cacheDate ?? null;
};

export const getSelectedGroupTeamStats = (state) => {
  const selectedGroupId = state.getIn(['team', 'groupId']);
  return state.getIn(['stats', 'teamStats', selectedGroupId, 'stats']);
};

export const getSelectedGroupTeamCacheDate = (state) => {
  const selectedGroupId = state.getIn(['team', 'groupId']);
  return state.getIn(['stats', 'teamStats', selectedGroupId, 'cacheUpdatedAt']);
};

export const getSelectedGroupCourseStats = (state) => {
  const selectedGroupId = state.getIn(['team', 'groupId']);
  return state.getIn(['stats', 'courses', selectedGroupId, 'stats']);
};

export const getSelectedGroupCourseCacheDate = (state) => {
  const selectedGroupId = state.getIn(['team', 'groupId']);
  return state.getIn(['stats', 'courses', selectedGroupId, 'cacheUpdatedAt']);
};

export const getSelectedGroupStatsForCourse = (state, courseId) => {
  const allCourseStats = getSelectedGroupCourseStats(state);
  if (!allCourseStats) {
    return null;
  } else {
    return allCourseStats.find(
      (courseStats) => courseStats.id === parseInt(courseId)
    );
  }
};

export function selectGraphDataForReportByCourseAndGroupId(
  state,
  courseId,
  groupId
) {
  return state.getIn(
    [
      'stats',
      'courseActivityGraphData',
      toCourseGroupKey(courseId, groupId),
      'stats',
    ],
    []
  );
}

export function selectGraphCacheDateForReportByCourseAndGroupId(
  state,
  courseId,
  groupId
) {
  const cacheDate = state.getIn([
    'stats',
    'courseActivityGraphData',
    toCourseGroupKey(courseId, groupId),
    'cacheUpdatedAt',
  ]);
  return cacheDate ?? null;
}

export function toCourseGroupKey(courseId, groupId) {
  return `c${courseId}_g${groupId}`;
}

export function selectActivitiesDataByGroup(state, group) {
  return state.getIn(['stats', 'courseActivityBreakdown', group, 'stats'], []);
}

export function selectActivitiesCacheDateByGroup(state, group) {
  const cacheDate = state.getIn([
    'stats',
    'courseActivityBreakdown',
    group,
    'cacheUpdatedAt',
  ]);
  return cacheDate ?? null;
}

export const selectMonthlyActiveUsers = createSelector(
  [(state) => state.getIn(['stats', 'activeUsers', 'monthly'])],
  (immutableData) => {
    const data = immutableData?.toJS();
    return {
      activeUsersByMonth:
        data?.stats.sort((a, b) => a.year - b.year || a.month - b.month) || [],
      lastUpdatedAt: data?.updatedAt || null,
    };
  }
);

export const selectIsMontlyActiveUsersLoading = (state) =>
  state.getIn(['stats', 'activeUsers', 'isMonthlyLoading']);

export const selectActiveUsersByGroup = createSelector(
  [(state) => state.getIn(['stats', 'activeUsers', 'group'])],
  (immutableData) => {
    const data = immutableData?.toJS();
    return {
      activeUsersByGroup: data?.stats || [],
      lastUpdatedAt: data?.updatedAt || null,
    };
  }
);

export const selectIsActiveUsersByGroupLoading = (state) =>
  state.getIn(['stats', 'activeUsers', 'isGroupLoading']);

export const getCourseOverviewStats = (state) =>
  state.getIn(['stats', 'courseOverviews']).toJS();

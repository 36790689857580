import axios from 'axios';

export const isInIframe = window.self !== window.top;

const edumeClient = axios.create({
  baseURL: '/api',
  headers: {
    platform: 'admin',
    'is-in-iframe': isInIframe,
  },
});

export default edumeClient;

export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAIL = 'CREATE_TAG_FAIL';
export const UPDATE_USER_TAGS = 'UPDATE_USER_TAGS';
export const UPDATE_USER_TAGS_SUCCESS = 'UPDATE_USER_TAGS_SUCCESS';
export const UPDATE_USER_TAGS_FAIL = 'UPDATE_USER_TAGS_FAIL';
export const LOAD_CUSTOMER_TAGS = 'LOAD_CUSTOMER_TAGS';
export const LOAD_CUSTOMER_TAGS_SUCCESS = 'LOAD_CUSTOMER_TAGS_SUCCESS';
export const LOAD_CUSTOMER_TAGS_FAIL = 'LOAD_CUSTOMER_TAGS_FAIL';
export const GET_MATCHED_LEARNER_COUNT_FOR_RULES =
  'GET_MATCHED_LEARNER_COUNT_FOR_RULES';
export const GET_MATCHED_LEARNER_COUNT_FOR_RULES_SUCCESS =
  'GET_MATCHED_LEARNER_COUNT_FOR_RULES_SUCCESS';
export const GET_MATCHED_LEARNER_COUNT_FOR_RULES_FAIL =
  'GET_MATCHED_LEARNER_COUNT_FOR_RULES_FAIL';

export const GET_ACTIVITY_BREAKDOWN = 'GET_ACTIVITY_BREAKDOWN';
export const GET_ACTIVITY_BREAKDOWN_SUCCESS = 'GET_ACTIVITY_BREAKDOWN_SUCCESS';
export const GET_ACTIVITY_BREAKDOWN_FAIL = 'GET_ACTIVITY_BREAKDOWN_FAIL';

export const GET_GUIDE_ACTIVITY_GRAPH = 'GET_GUIDE_ACTIVITY_GRAPH';
export const GET_GUIDE_ACTIVITY_GRAPH_SUCCESS =
  'GET_GUIDE_ACTIVITY_GRAPH_SUCCESS';
export const GET_GUIDE_ACTIVITY_GRAPH_FAIL = 'GET_GUIDE_ACTIVITY_GRAPH_FAIL';
export const GET_GUIDE_STEP_BREAKDOWN = 'GET_GUIDE_STEP_BREAKDOWN';
export const GET_GUIDE_STEP_BREAKDOWN_FAIL = 'GET_GUIDE_STEP_BREAKDOWN_FAIL';
export const GET_GUIDE_STEP_BREAKDOWN_SUCCESS =
  'GET_GUIDE_STEP_BREAKDOWN_SUCCESS';
export const GET_GUIDE_TEAM_BREAKDOWN = 'GET_GUIDE_TEAM_BREAKDOWN';
export const GET_GUIDE_TEAM_BREAKDOWN_FAIL = 'GET_GUIDE_TEAM_BREAKDOWN_FAIL';
export const GET_GUIDE_TEAM_BREAKDOWN_SUCCESS =
  'GET_GUIDE_TEAM_BREAKDOWN_SUCCESS';

export const GET_ACTIVITY_STATS_FOR_GROUP = 'GET_ACTIVITY_STATS_FOR_GROUP';
export const GET_COURSE_ACTIVITY_GRAPH = 'GET_COURSE_ACTIVITY_GRAPH';
export const GET_COURSE_ACTIVITY_GRAPH_SUCCESS =
  'GET_COURSE_ACTIVITY_GRAPH_SUCCESS';
export const GET_TEAM_ACTIVITY_STATS = 'GET_TEAM_ACTIVITY_STATS';
export const GET_TEAM_ACTIVITY_STATS_SUCCESS =
  'GET_TEAM_ACTIVITY_STATS_SUCCESS';
export const GET_TEAM_USERS = 'GET_TEAM_USERS';
export const GET_TEAM_USERS_ERROR = 'GET_TEAM_USERS_ERROR';
export const GET_TEAM_USERS_SUCCESS = 'GET_TEAM_USERS_SUCCESS';
export const GET_STATS_FOR_GROUP = 'GET_STATS_FOR_GROUP';
export const GET_STATS_FOR_GROUP_ERROR = 'GET_STATS_FOR_GROUP_ERROR';
export const GET_STATS_FOR_GROUP_SUCCESS = 'GET_STATS_FOR_GROUP_SUCCESS';
export const GROUP_STATS_LOADED = 'GROUP_STATS_LOADED';
export const REPORT_DOWNLOADED = 'REPORT_DOWNLOADED';
export const RESET_TEAM_USERS = 'RESET_TEAM_USERS';
export const TOGGLE_REPORT_VIEW = 'TOGGLE_REPORT_VIEW';
export const TOGGLE_USER_VIEW = 'TOGGLE_USER_VIEW';
export const USER_REPORT_DOWNLOADED = 'USER_REPORT_DOWNLOADED';
export const GET_ADMIN_COUNT = 'GET_ADMIN_COUNT';
export const GET_COURSE_OVERVIEWS = 'GET_COURSE_OVERVIEWS';
export const GET_COURSE_OVERVIEWS_SUCCESS = 'GET_COURSE_OVERVIEWS_SUCCESS';
export const GET_COURSE_OVERVIEWS_FAIL = 'GET_COURSE_OVERVIEWS_FAIL';
export const CLEAR_COURSE_OVERVIEWS_CACHE = 'CLEAR_COURSE_OVERVIEWS_CACHE';
export const GET_ACTIVE_USERS_BY_MONTH = 'GET_ACTIVE_USERS_BY_MONTH';
export const GET_ACTIVE_USERS_BY_MONTH_SUCCESS =
  'GET_ACTIVE_USERS_BY_MONTH_SUCCESS';
export const GET_ACTIVE_USERS_BY_MONTH_FAIL = 'GET_ACTIVE_USERS_BY_MONTH_FAIL';
export const GET_ACTIVE_USERS_BY_GROUP = 'GET_ACTIVE_USERS_BY_GROUP';
export const GET_ACTIVE_USERS_BY_GROUP_SUCCESS =
  'GET_ACTIVE_USERS_BY_GROUP_SUCCESS';
export const GET_ACTIVE_USERS_BY_GROUP_FAIL = 'GET_ACTIVE_USERS_BY_GROUP_FAIL';
export const SET_PENDING_DOWNLOADS = 'SET_PENDING_DOWNLOADS';
export const CLEAR_PENDING_DOWNLOADS = 'CLEAR_PENDING_DOWNLOADS';

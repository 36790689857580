import * as notificationActions from '../notification/notificationActions';
import * as overviewActionCreators from './overviewActionCreators';
import {
  CLICK_CREATE_CONTENT_BUTTON,
  VIEW_HELP_CENTER_ARTICLE,
} from './overviewActionTypes';

export const loadOverviewStats = (groupId) => (dispatch, getState) => {
  const selectedGroupId = getState().getIn(['team', 'groupId']);
  if (!selectedGroupId) {
    return;
  } //in case in a really old company, or delete the last group
  dispatch(overviewActionCreators.getOverviewStats(groupId)).catch((error) => {
    notificationActions.createErrorNotificationFromResponse(error)(dispatch);
  });
};

export const goToCreateContent = (type) => (dispatch) => {
  dispatch({
    type: CLICK_CREATE_CONTENT_BUTTON,
    contentType: type,
  });
};

export const viewHelpCenterArticle = (articleName) => (dispatch) => {
  dispatch({
    type: VIEW_HELP_CENTER_ARTICLE,
    articleName,
  });
};

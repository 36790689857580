import React from 'react';

import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

export type IndicatorType = (typeof indicatorType)[number];
export const indicatorType = ['scaling', 'bouncing'] as const;

export type IndicatorSize = (typeof indicatorSize)[number];
export const indicatorSize = ['xsmall', 'small', 'normal'];

type GenericLoadingIndicatorProps = {
  color?: 'light' | 'dark';
  size?: IndicatorSize;
  type?: IndicatorType;
  dataAutomation?: string;
};

const GenericLoadingIndicator = ({
  color,
  size = 'normal',
  type = 'scaling',
  dataAutomation,
}: GenericLoadingIndicatorProps): JSX.Element => (
  <div
    styleName={`spinner ${color} ${size} ${type}`}
    data-automation={dataAutomation}
  >
    <div styleName='dot offset1' />
    <div styleName='dot offset2' />
    <div styleName='dot' />
  </div>
);

export default CSSModules(GenericLoadingIndicator, styles, {
  allowMultiple: true,
});

// Retained because this data is referenced in migrations
export const SUBSCRIPTION_STATUS = {ACTIVE: 'active', CANCELLED: 'cancelled'};

export const FREEMIUM = {
  id: 1,
  name: 'Freemium',
  status: SUBSCRIPTION_STATUS.ACTIVE,
  config: {
    features: {guides: true},
    limitations: {admins: 1, publishedGuides: 5},
  },
};

export const PREMIUM = {
  name: 'Premium',
  status: SUBSCRIPTION_STATUS.ACTIVE,
  config: {
    features: {guides: true},
    limitations: {admins: 4, publishedGuides: 10},
  },
};

export const ADD_FEED_ITEM = 'ADD_FEED_ITEM';
export const ADD_FEED_ITEM_FAIL = 'ADD_FEED_ITEM_FAIL';
export const ADD_FEED_ITEM_SUCCESS = 'ADD_FEED_ITEM_SUCCESS';
export const DELETE_FEED_ITEM = 'DELETE_FEED_ITEM';
export const DELETE_FEED_ITEM_SUCCESS = 'DELETE_FEED_ITEM_SUCCESS';
export const EDIT_FEED_ITEM = 'EDIT_FEED_ITEM';
export const EDIT_FEED_ITEM_FAIL = 'EDIT_FEED_ITEM_FAIL';
export const EDIT_FEED_ITEM_SUCCESS = 'EDIT_FEED_ITEM_SUCCESS';
export const LOAD_FEED_ITEMS = 'LOAD_FEED_ITEMS';
export const LOAD_FEED_ITEMS_SUCCESS = 'LOAD_FEED_ITEMS_SUCCESS';
export const VIEW_MESSAGES = 'VIEW_MESSAGES';

import {addMeta, buildRequest} from '../stateUtil';
import {store} from '../store';
import {
  ASSIGN_GUIDE_TO_GROUPS,
  DELETE_GUIDE,
  GET_GUIDE_ASSIGNED_TEAMS,
  GET_GUIDES_NOTIFICATION_DATA,
  GET_PREVIEW_TOKEN,
  LINK_INTEGRATED_GUIDE,
  LOAD_GUIDE,
  LOAD_GUIDES,
  PUBLISH_GUIDE,
  UPDATE_GUIDE,
  UPDATE_INTEGRATED_GUIDE,
} from './guideActionTypes';

// Guide Backend calls
export const getGuide = (guideId, groupId) => {
  const params = `?group_id=${groupId}`;
  return buildRequest(
    LOAD_GUIDE,
    'GET',
    `/contents/guides/${guideId}/${params}&camelCase=true`,
    null,
    'guides'
  );
};

export const getGuides = (
  groupId,
  {pageSize = null, status = null, search = null}
) => {
  const _pageSize =
    pageSize || store.getState().getIn(['guide', 'paginationPageSize']);
  const params =
    `?group_id=${groupId}&page_size=${_pageSize}&camelCase=true` +
    (status ? `&status=${status}` : '') +
    (search ? `&search=${search}` : '');

  return buildRequest(
    LOAD_GUIDES,
    'GET',
    `/contents/guides/${params}`,
    null,
    'guides'
  );
};

export const updateGuide = (guideId, groupId, payload) => {
  const params = `?group_id=${groupId}&camelCase=true`;
  return buildRequest(
    UPDATE_GUIDE,
    'PATCH',
    `/contents/guides/${guideId}/${params}`,
    {
      id: guideId,
      ...payload,
    },
    'guides'
  );
};

export const publishGuide = (guideId, groupId) => {
  const params = `?group_id=${groupId}`;
  return buildRequest(
    PUBLISH_GUIDE,
    'PATCH',
    `/contents/guides/${guideId}/${params}&camelCase=true`,
    {
      id: guideId,
      status: 'published',
    },
    'guides'
  );
};

export const unpublishGuide = (guideId, groupId) => {
  const params = `?group_id=${groupId}&camelCase=true`;
  return buildRequest(
    PUBLISH_GUIDE,
    'PATCH',
    `/contents/guides/${guideId}/${params}`,
    {
      id: guideId,
      status: 'review',
    },
    'guides'
  );
};

export const deleteGuide = (guideId, groupId) => {
  const params = `?group_id=${groupId}`;
  return addMeta(
    buildRequest(
      DELETE_GUIDE,
      'DELETE',
      `/contents/guides/${guideId}/${params}`,
      null,
      'guides'
    ),
    {guideId, groupId}
  );
};

export const getPreviewToken = (guideId, groupId) => {
  const params = `?group_id=${groupId}`;
  return buildRequest(
    GET_PREVIEW_TOKEN,
    'GET',
    `/contents/preview-tokens/${guideId}/${params}`,
    {},
    'guides'
  );
};

// Edume backend calls
export const updateTeamsInGuide = (
  guideId,
  groupId,
  teamIdsToAdd,
  teamIdsToRemove
) => {
  const content = {
    groupId,
    teamIdsToAdd,
    teamIdsToRemove,
  };
  return addMeta(
    buildRequest(
      ASSIGN_GUIDE_TO_GROUPS,
      'POST',
      `/guides/${guideId}/assignment`,
      content
    ),
    {guideId}
  );
};

export const getGuideAssignedTeams = (guideId) =>
  buildRequest(
    GET_GUIDE_ASSIGNED_TEAMS,
    'GET',
    `/guides/${guideId}/assignment`
  );

export const getGuideNotificationDataByGroup = (groupId) =>
  buildRequest(
    GET_GUIDES_NOTIFICATION_DATA,
    'GET',
    `/guides/notificationData/${groupId}`
  );

export const linkIntegrationToGuide = (guideId, payload) =>
  buildRequest(
    LINK_INTEGRATED_GUIDE,
    'POST',
    `/guide/${guideId}/integrations`,
    payload
  );

export const updateGuideIntegration = (guideId, integrationId, payload) =>
  buildRequest(
    UPDATE_INTEGRATED_GUIDE,
    'PUT',
    `/guide/${guideId}/integrations/${integrationId}`,
    payload
  );

// Prop values
export const ADMIN = 'admin';
export const DEFAULT = 'default';
export const ACTIVE = 'active';
export const COMPLETED = 'completed';
export const EMPTY = 'empty';
export const HOME = 'home';
export const SURVEYS = 'surveys';
export const SINGLE = 'single';
export const MULTIPLE = 'multiple';
export const COMPANY = 'company';
export const TEAM = 'team';
export const GROUP = 'group';
export const NONE = 'none';
export const ALPHABETICALLY = 'alphabetically';
export const CHRONOLOGICALLY = 'chronologically';
export const NOT_ACTIVATED = 'not activated';
export const ACTIVATED = 'activated';
export const ALL_USERS = 'all users';
export const SEARCH = 'search';
export const CUSTOM = 'custom';
export const REACTION = 'reaction';
export const MULTIPLE_CHOICE = 'multiple-choice';
export const NPS = 'nps';
export const TEAM_LIST = 'team list';
export const USER_LIST = 'user list';
export const INDIVIDUAL_TEAM = 'individual team';
export const COURSE = 'course';
export const OVERVIEW = 'overview';
export const DETAILED = 'detailed';
export const OVERALL = 'overall';
export const REASSIGNED = 'reassigned';
export const USER = 'user';
export const REPORT_DROPDOWN = 'report dropdown';
export const REPORTS = 'reports';
export const MS_TEAMS = 'ms teams';
export const DIRECT = 'direct';
export const MODULE = 'module';
export const LESSON = 'lesson';
export const KNOWLEDGE_HUB_VIEW_ONLY = 'knowledge hub view only';
export const KNOWLEDGE_HUB_ALL_CONTENT = 'all content';
export const NUMBER_OF_TEAMS_NOTIFIED = 'number of teams notified'; //not changed to eduMe teams following addition of integration because would cause discrepancy between previous and new data
export const NUMBER_OF_BEEKEEPER_TEAMS_ASSIGNED =
  'number of beekeeper teams assigned';
export const NUMBER_OF_BEEKEEPER_TEAMS_NOTIFIED =
  'number of beekeeper teams notified';
export const NUMBER_OF_MS_TEAMS_TEAMS_ASSIGNED =
  'number of ms teams teams assigned';
export const NUMBER_OF_MS_TEAMS_TEAMS_NOTIFIED =
  'number of ms teams teams notified';
export const NUMBER_OF_SPEAKAP_TEAMS_ASSIGNED =
  'number of speakap teams assigned';
export const NUMBER_OF_SPEAKAP_TEAMS_NOTIFIED =
  'number of speakap teams notified';
export const ADDED = 'added';
export const REMOVED = 'removed';
export const CHANGED = 'changed';
export const PUBLISHED = 'published';
export const UNPUBLISHED = 'unpublished';
export const SENT_IMMEDIATELY = 'sent immediately';
export const DASHBOARD = 'dashboard';
export const CONFIRMATION_SCREEN = 'confirmation screen';
export const DELIVERY_AREA = 'delivery area';
export const UNPUBLISHED_WARNING = 'unpublished warning';

// DS-only event props
export const RESOURCE_KEY = 'resource key';

// Event types
export const PUBLISH_COURSE = 'course published';
export const ASSIGN_TEAMS_TO_COURSE = 'updated assigned teams to course';
export const DELETE_COMPONENT = 'deleted component';
export const EDIT_COMPONENT = 'edited component';
export const CREATE_COMPONENT = 'created component';
export const UPLOAD_MEDIA = 'uploaded media';
export const DELETE_GROUP = 'deleted group from company';
export const DELETE_MESSAGE = 'deleted message in group';
export const VIEW_SURVEY_LIST = 'viewed surveys in group';
export const ADD_USER = 'added user in group';
export const EDIT_USER = 'updated user details in group';
export const DELETE_USER = 'deleted user from group';
export const CREATE_LESSON = 'created lesson';
export const CREATE_COURSE = 'created course';
export const CREATE_TEAM = 'created team in group';
export const ADD_GROUP = 'created group in company';
export const DELETE_TEAM = 'deleted team from group';
export const ADD_ADMIN = 'created admin in company';
export const DELETE_ADMIN = 'deleted admin from company';
export const VIEW_TEAM_LIST = 'viewed team list in group';
export const VIEW_USER_LIST = 'viewed user list in group';
export const CREATE_MODULE = 'created module';
export const UPDATE_USERS_ASSIGNED_TEAMS = 'updated users assigned teams';
export const DOWNLOAD_USERS = 'downloaded users in group';
export const VIEW_MESSAGES = 'viewed messages in group';
export const DELETE_SURVEY = 'deleted survey in group';
export const CREATE_SURVEY = 'created survey in group';
export const DOWNLOAD_REPORT = 'downloaded report in group';
export const VIEW_REPORT = 'viewed report in group';
export const CREATE_MESSAGE = 'created message in group';
export const VIEW_HELP_CENTER = 'viewed help centre';
export const DELETE_COURSE = 'deleted course in group';
export const EDITED_BUTTON = 'edited button';
export const EDITED_END_SCREEN = 'edited end screen';
export const EDITED_SLIDE_COLOR = 'edited slide colour';
export const UPDATE_COURSE_SETTINGS = 'updated course settings';
export const GENERATE_MLS_SPREADSHEET = 'generated MLS spreadsheet';
export const EVENT_REPORTING_CHANGED = 'event reporting changed';
export const EVENT_REPORTING_STATUS_CHANGED = 'event reporting status changed';
export const EVENT_REPORTING_NAME_CHANGED = 'event reporting name changed';
export const VIEWED_SEARCH_RESULTS_PAGE = 'viewed search results page';
export const SELECTED_SEARCH_RESULT = 'selected search result';
export const VIEWED_API_KEYS = 'viewed api keys';
export const CREATED_API_KEY = 'created api key';
export const DELETED_API_KEY = 'deleted api key';
export const VIEWED_SIGNING_SECRETS = 'viewed signing secrets';
export const CREATED_SIGNING_SECRET = 'created signing secret';
export const VIEWED_WEBHOOK_CONFIG = 'viewed webhook config';
export const UPDATED_WEBHOOK_CONFIG = 'updated webhook config';
export const UPDATED_WEBHOOK_SUBSCRIBED_EVENTS =
  'updated webhook subscribed events';
export const COPIED_WEBHOOK_SECRET = 'copied webhook secret';
export const DOWNLOAD_USER_REPORT = 'downloaded individual user report';
export const SELECTED_FILTER = 'selected filter in learning tab';
export const VIEWED_PREVIEW_LINK = 'viewed preview link';
export const ID_COPIED = 'id copied';
export const SELECTED_COURSE_TAB = 'selected course tab';
export const VIEWED_LEARNING_PAGE = 'viewed learning page';
export const VIEWED_INTEGRATIONS = 'viewed 3rd party integrations page';
export const UPDATED_LANGUAGE = 'global language changed';
export const UPDATED_COURSE_PRIORITY = 'updated course priority status';
export const VIEWED_COMPANY_SETTINGS = 'viewed company settings page';
export const VIEWED_CONTENT_CREATION_GUIDE = 'viewed content creation guide';
export const VIEWED_COURSE_SCREEN = 'viewed course screen';
export const VIEWED_DASHBOARD = 'viewed dashboard';
export const VIEWED_INDIVIDUAL_INTEGRATION_PAGE =
  'viewed individual integration page';
export const UPDATE_INTEGRATION = 'update integration';
export const DELETE_INTEGRATION = 'delete integration';
export const CREATE_INTEGRATION = 'create integration';
export const SET_COURSE_CARD_DESIGN = 'set course card design';
export const CREATED_KNOWLEDGE_HUB = 'created knowledge hub';
export const CREATED_ACCOUNT = 'created account';
export const SAVED_TEMPLATE = 'saved template';
export const UPDATE_KNOWLEDGE_HUB_CUSTOMISATIONS =
  'updated knowledge hub customisations';
export const SAVED_COMPANY_BRANDING = 'saved company branding';
export const SAVED_GROUP_BRANDING = 'saved group branding';
export const VIEWED_ACCOUNT_UPGRADE = 'viewed account upgrade';
export const COPIED_PUBLISHED_LINK = 'copied published link';
export const CREATED_AI_LESSON = 'created AI lesson';
export const OPENED_COURSE_NOTIFICATIONS_SLIDEPANEL =
  'opened course notifications slidepanel';
export const OPENED_GUIDE_NOTIFICATIONS_SLIDEPANEL =
  'opened guide notifications slidepanel';
export const OPENED_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL =
  'opened knowledge hub notifications slidepanel';
export const SENT_COURSE_NOTIFICATIONS = 'sent course notifications';
export const SENT_GUIDE_NOTIFICATIONS = 'sent guide notifications';
export const SENT_KNOWLEDGE_HUB_NOTIFICATIONS =
  'sent knowledge hub notifications';
export const ASSIGNED_TEAMS_TO_COURSE_VIA_NOTIFICATION_SLIDEOUT =
  'assigned teams to course via notification slideout';
export const ASSIGNED_TEAMS_TO_GUIDE_VIA_NOTIFICATION_SLIDEOUT =
  'assigned teams to guide via notification slideout';
export const VIEWED_COMPANY_LEVEL_REPORT = 'viewed company level report';
export const DATE_RANGE_SELECTED = 'date range selected';
export const MODIFIED_COMPANY_LEVEL_REPORT = 'modified company level report';
export const VIEWED_USAGE_PAGE = 'viewed usage page';
export const ADMIN_SETTINGS = 'admin settings';
export const PEOPLE_TAB = 'people tab';
export const UPGRADED_FROM_LEARNER = 'upgraded from learner';
export const CREATED_COLLECTION = 'created collection';
export const ADDED_COLLECTION_CONTENT = 'added content to collection';
export const DELETE_GUIDE = 'deleted guide in group';
export const PUBLISH_GUIDE = 'publish guide in group';
export const UNPUBLISH_GUIDE = 'unpublish guide in group';
export const UPDATE_GUIDE = 'update guide in group';
export const CREATE_GUIDE = 'create guide';
export const ASSIGN_TEAMS_TO_GUIDE = 'updated assigned teams to guide';
export const REVOKE_ADMIN_PERMISSIONS =
  'revoke all admin permissions from user';
export const CLICKED_CREATE_COURSE =
  'clicked create course button on home dashboard';
export const CLICKED_CREATE_GUIDE =
  'clicked create guide button on home dashboard';
export const CLICKED_ON_SUPPORT_ARTICLE = 'clicked on support article';
export const CUSTOMISED = 'customised';
export const VIEWED_COURSE_DELIVERIES = 'viewed course deliveries';
export const VIEWED_GUIDE_DELIVERIES = 'viewed guide deliveries';
export const VIEWED_KNOWLEDGE_HUB_DELIVERIES =
  'viewed knowledge hub deliveries';
export const CLICKED_CREATE_TEAM = 'clicked create team';
export const SELECTED_TEAM_TYPE = 'selected team type';
export const VIEWED_SMART_TEAM_DETAILS = 'viewed smart team details';
export const VIEWED_TEAM_ACTION = 'viewed team action';
export const SHOW_USERS_IN_TEAM = 'show users in team';
export const CHANGED_STATIC_TEAM_NAME = 'changed static team name';
export const EDIT_SMART_TEAM = 'edit smart team';
export const SAVED_TEAM_UPDATE = 'saved team update';

import React from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {HelpArticles} from '@edume/terrific';

import {viewHelpCenterArticle} from '../../../state/overview/overviewActions';
import OverviewBlock from '../OverviewBlock';

const trialArticles = [
  {
    title: 'Uploading learners',
    tag: 'Support',
    url: 'https://help.edume.com/managing-users/uploading-learners',
  },
  {
    title: 'Requirements for videos and images',
    tag: 'Creating content',
    url: 'https://help.edume.com/creating-content/technical-requirements-for-videos-and-images',
  },
  {
    title: 'Seamless links',
    tag: 'Support',
    url: 'https://help.edume.com/seamless-links',
  },
  {
    title: 'Generating Knowledge Hub links',
    tag: 'Support',
    url: 'https://help.edume.com/generating-knowledge-hub-link',
  },
  {
    title: 'Creating Guides',
    tag: 'Creating content',
    url: 'https://help.edume.com/creating-guides',
  },
  {
    title: 'Supported language',
    tag: 'Creating content',
    url: 'https://help.edume.com/supported-languages',
  },
  {
    title: 'Multi-language support',
    tag: 'Creating content',
    url: 'https://help.edume.com/creating-content/multi-language-support',
  },
  {
    title: 'Branding requirements',
    tag: 'Creating content',
    url: 'https://help.edume.com/getting-started/branding-requirements',
  },
];

const enterpriseArticles = [
  {
    title: 'Uploading Videos and Images',
    tag: 'Creating content',
    url: 'https://help.edume.com/creating-content/technical-requirements-for-videos-and-images',
  },
  {
    title: 'Knowledge Hub Setup Guide',
    tag: 'Managing content',
    url: 'https://help.edume.com/knowledge-hub-setup-guide',
  },
  {
    title: 'Seamless links',
    tag: 'Distribution',
    url: 'https://help.edume.com/seamless-links',
  },
  {
    title: 'Create an AI-Generated Lesson',
    tag: 'Creating content',
    url: 'https://help.edume.com/create-an-ai-generated-lesson',
  },
  {
    title: 'Send Training via SMS and Email',
    tag: 'Delivery',
    url: 'https://help.edume.com/how-to-send-content-via-sms-or-email',
  },
];

const OverviewArticlesWrapper = ({
  withIcon,
  isTrial,
  breakpoint = 'regular',
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const articles = isTrial ? trialArticles : enterpriseArticles;
  const logReduxAction = (title) => dispatch(viewHelpCenterArticle(title));
  return (
    <OverviewBlock>
      <HelpArticles
        withIcon={withIcon}
        articles={articles}
        intl={intl}
        breakpoint={breakpoint}
        logReduxAction={logReduxAction}
      />
    </OverviewBlock>
  );
};

OverviewArticlesWrapper.propTypes = {
  withIcon: PropTypes.bool,
  isTrial: PropTypes.bool,
  breakpoint: PropTypes.oneOf(['regular', 'wide']),
};

export default OverviewArticlesWrapper;

export const getRandomNFromArray = <T>(arr: T[], n: number) => {
  const arrCopy = [...arr];
  const randoms = [];
  for (let i = 0; i < n; i++) {
    const randomIndex = Math.floor(Math.random() * arrCopy.length);
    const randomElement = arrCopy.splice(randomIndex, 1)[0];
    randoms.push(randomElement);
  }
  return randoms;
};

export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL';
export const ADMIN_CREATED = 'ADMIN_CREATED';
export const ADMIN_DELETED = 'ADMIN_DELETED';
export const ADMIN_UPDATED = 'ADMIN_UPDATED';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const GET_COMPANY_ADMINS = 'GET_COMPANY_ADMINS';
export const GET_COMPANY_ADMINS_SUCCESS = 'GET_COMPANY_ADMINS_SUCCESS';
export const LOAD_GROUP_ADMINS = 'LOAD_GROUP_ADMINS';
export const LOAD_GROUP_ADMINS_SUCCESS = 'LOAD_GROUP_ADMINS_SUCCESS';
export const LOAD_TEAM_ADMINS = 'LOAD_TEAM_ADMINS';
export const LOAD_TEAM_ADMINS_SUCCESS = 'LOAD_TEAM_ADMINS_SUCCESS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMIN_FAIL = 'UPDATE_ADMIN_FAIL';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const ADD_REMOVE_ADMIN_FROM_TEAMS = 'ADD_REMOVE_ADMIN_FROM_TEAMS';
export const ADD_REMOVE_ADMIN_FROM_TEAMS_SUCCESS =
  'ADD_REMOVE_ADMIN_FROM_TEAMS_SUCCESS';
export const UPDATE_ADMIN_LEVEL_SUCCESS = 'UPDATE_ADMIN_LEVEL_SUCCESS';

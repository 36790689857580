import {trackReduxAction} from '../tracking/index';

const trackingMiddleware =
  ({getState}) =>
  (next) =>
  (action) => {
    const returnValue = next(action);
    if (action) {
      try {
        trackReduxAction(action, getState());
      } catch (error) {
        console.error(`Amplitude/tracking error: ${error}`);
      }
    }
    return returnValue;
  };

export default trackingMiddleware;

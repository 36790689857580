/* eslint-disable react/no-multi-comp */
import React, {useRef} from 'react';

import {Modal, MultiSelectModal} from '@edume/magnificent';

const getRenderTo = () => {
  const modalContainer = document.getElementById('modalContainer');
  const nestedModalContainer = document.getElementById('nestedModalContainer');
  let renderTo = '#modalContainer';
  //kinda nasty hack to get nested modals working; need a container for each modal.
  //could make a proper Modal hierarchy system but we barely support nested modals anyway so..
  //¯\_(ツ)_/¯

  if (modalContainer.children.length > 0) {
    renderTo = '#nestedModalContainer';
  }
  if (nestedModalContainer.children.length > 0) {
    renderTo = '#nestedNestedModalContainer';
  }
  return renderTo;
};

//use this instead of Modal, so that it can pick up on main React app styles
//e.g. Burmese font, RTL (when we add that), etc
const StyledModal = (props) => {
  const renderTo = useRef(getRenderTo());

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        disableFocusTrap={true} // needed for Appcues builder
        renderTo={renderTo.current}
      />
    </div>
  );
};

const StyledMultiSelectModal = (props) => {
  const renderTo = useRef(getRenderTo());

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MultiSelectModal {...props} renderTo={renderTo.current} />
    </div>
  );
};

export {StyledModal, StyledMultiSelectModal};

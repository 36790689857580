import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import CSSModules from 'react-css-modules';

import Button from '../Button';
import {CrossSquareSolidIcon} from '../icons';
import {ModalButton} from '../Modal';
import Heading from '../TextComponents/Heading';
import Text from '../TextComponents/Text';
import {TextWeights} from '../TextComponents/textUtils';
import UnsavedChangesWarning, {
  type WarningButton,
} from './UnsavedChangesWarning';

import styles from './styles.module.scss';

type SlidePanelProps = {
  onClose: () => void;
  shouldClose?: boolean;
  title: string;
  subtitle?: string;
  children: ReactNode;
  buttons?: [] | [ModalButton] | [ModalButton | null, ModalButton];
  headerContent?: ReactNode;
  hideCloseButton?: boolean;
  darkTheme?: boolean;
  slimPanel?: boolean;
  disableFocusTrap?: boolean;
  overflowScroll?: boolean;
  footerContent?: ReactNode;
  titleWeight?: TextWeights;
};

type WarningInfo = {
  title: string;
  text: string;
  closeButton: WarningButton;
  continueButton: WarningButton;
};

type UnsavedWarningProps =
  | {
      showUnsavedChangesWarning?: false;
      overrideShowWarning?: false;
      overrideShowWarningReset?: () => void;
      overrideShowWarningAction?: () => void;
      warningInfo?: WarningInfo;
    }
  | {
      showUnsavedChangesWarning?: true;
      overrideShowWarning?: false;
      overrideShowWarningReset?: () => void;
      overrideShowWarningAction?: () => void;
      warningInfo: WarningInfo;
    }
  | {
      showUnsavedChangesWarning?: false;
      overrideShowWarning?: true;
      overrideShowWarningReset: () => void;
      overrideShowWarningAction: () => void;
      warningInfo: WarningInfo;
    };
type Props = SlidePanelProps & UnsavedWarningProps;

// eslint-disable-next-line complexity
const SlidePanel = ({
  onClose,
  shouldClose = false,
  title,
  subtitle,
  children,
  buttons,
  overrideShowWarning,
  overrideShowWarningReset,
  overrideShowWarningAction,
  showUnsavedChangesWarning,
  warningInfo,
  headerContent,
  hideCloseButton,
  darkTheme,
  slimPanel,
  disableFocusTrap,
  overflowScroll = true,
  footerContent,
  titleWeight = 'medium',
}: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useLayoutEffect(() => {
    if (isActive) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isActive]);

  useEffect(() => {
    setIsActive(true);
  }, []);

  const onCloseClick = useCallback(() => {
    setIsActive(false);
    setTimeout(onClose, 500);
  }, [onClose]);

  const onCloseButtonClick = () => {
    if (showUnsavedChangesWarning && warningInfo) {
      setShowWarning(true);
    } else {
      onCloseClick();
    }
  };

  useEffect(() => {
    if (shouldClose) {
      onCloseClick();
    }
  }, [shouldClose, onCloseClick]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCloseButtonClick();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  });

  const getFooterContent = () => {
    if (buttons && buttons.length > 0) {
      return buttons.map((button: ModalButton | null, index) =>
        button === null ? (
          <div key={index} />
        ) : (
          <Button
            key={index}
            type={button.type}
            noMarginTop
            noMarginLeft
            disabled={button.disabled}
            icon={button.icon}
            iconUrl={button.iconUrl}
            iconPosition={button.iconPosition}
            dataAutomation={button.dataAutomation}
            {...(button.submit
              ? {submit: button.submit, form: button.form}
              : {onClick: button.onClick || onCloseButtonClick})}
            isLightTheme={darkTheme}
          >
            {button.text}
          </Button>
        )
      );
    }
    if (footerContent) {
      return footerContent;
    }
    return null;
  };

  const footer = getFooterContent();

  const addFocusTrap = (content: ReactNode) => <FocusTrap>{content}</FocusTrap>;

  const slidePanel = (
    <div
      styleName={classNames('panel-backdrop', {
        active: isActive,
        darkTheme,
        slimPanel,
        'overflow-scroll': overflowScroll,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <div styleName='panel-container' data-automation='slide-panel'>
        {(showWarning || overrideShowWarning) && warningInfo ? (
          <div styleName='unsaved-changes-container'>
            <UnsavedChangesWarning
              {...warningInfo}
              onClose={
                overrideShowWarning ? overrideShowWarningAction : onCloseClick
              }
              onContinue={
                overrideShowWarning
                  ? overrideShowWarningReset
                  : () => setShowWarning(false)
              }
            />
          </div>
        ) : (
          <>
            {!hideCloseButton && (
              <button
                data-automation='side-panel-close-button'
                type='button'
                styleName='close-button'
                onClick={onCloseButtonClick}
              >
                <div styleName='close-image'>
                  <CrossSquareSolidIcon size='medium' colour='purple300' />
                </div>
                <div styleName='close-image-hover'>
                  <CrossSquareSolidIcon size='medium' colour='purple500' />
                </div>
              </button>
            )}
            {headerContent}

            <Heading
              as='h3'
              size='sheep'
              weight={titleWeight}
              colour={darkTheme ? 'white' : 'textPrimary'}
            >
              {title}
            </Heading>
            {subtitle && (
              <div styleName='subtitle-wrapper'>
                <Text size='m' colour='textSecondary'>
                  {subtitle}
                </Text>
              </div>
            )}
            {children}
            {footer && <div styleName='actions'>{footer}</div>}
          </>
        )}
      </div>
    </div>
  );

  return disableFocusTrap ? slidePanel : addFocusTrap(slidePanel);
};

export default CSSModules(SlidePanel, styles, {allowMultiple: true});

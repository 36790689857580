import {buildRequest} from '../stateUtil';
import {
  ADD_IMAGE_LIBRARY,
  DELETE_IMAGE,
  GET_IMAGE_LIBRARY,
  TRACK_IMAGE_USE,
} from './imageLibraryActionTypes';

export const addImageToLibrary = (url, filename, groupId, width, height) =>
  buildRequest(ADD_IMAGE_LIBRARY, 'POST', '/imageLibrary', {
    url,
    filename,
    groupId,
    width,
    height,
  });

export const getImagesFromLibrary = (groupId) =>
  buildRequest(GET_IMAGE_LIBRARY, 'GET', `/groups/${groupId}/images`);

export const deleteImageFromLibrary = (groupId, id) =>
  buildRequest(DELETE_IMAGE, 'DELETE', `/groups/${groupId}/images/${id}`);

export const trackImageUse = (groupId, imageId) =>
  buildRequest(TRACK_IMAGE_USE, 'POST', '/trackImageUse', {groupId, imageId});

export const BEEKEEPER = 'beekeeper';
export const SPEAKAP = 'speakap';
export const ITERABLE = 'iterable';
export const BRAZE = 'braze';
export const MS_TEAMS = 'msTeams';
export const ONE_GROUP_INTEGRATIONS = [BEEKEEPER];

// Used for MSTeams trials that do not have a company name
export const MS_TEAMS_DEFAULT_COMPANY_NAME = 'New company';

export const USER_IDENTIFICATION_KEY_BEEKEEPER = 'beekeeper.employeeId';

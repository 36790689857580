import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import SelectGroup from '../components/peopleAndTeams/SelectGroup';
import * as adminActions from '../state/admin/adminActions';
import * as teamActions from '../state/team/teamActions';
import {getSelectedGroup} from '../state/team/teamSelectors';

const mapStateToProps = (state) => {
  const groups = state.getIn(['team', 'groups']).toJS();
  const selected = getSelectedGroup(state);
  const formError = state.getIn(['form', 'errorCode']);
  return {
    groups,
    permissions: state.getIn(['auth', 'permissions']).toJS(),
    selected: selected ? selected.toJS() : null,
    isTrial: state.getIn(['auth', 'company']).isTrial,
    formError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(teamActions, dispatch),
  ...bindActionCreators(adminActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectGroup);

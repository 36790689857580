import * as notificationActions from '../notification/notificationActions';
import {getSelectedGroupId} from '../team/teamSelectors';
import * as collectionsActionCreators from './collectionsActionCreators';

export const getCollectionsForGroup = () => (dispatch, getState) => {
  const selectedGroup = getSelectedGroupId(getState());
  if (selectedGroup) {
    dispatch(
      collectionsActionCreators.getCollectionsForGroup(selectedGroup)
    ).catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });
  }
};

export const createCollection = (name) => (dispatch, getState) => {
  const currentGroup = getSelectedGroupId(getState());

  dispatch(collectionsActionCreators.createCollection(name, currentGroup));
};

export const renameCollection =
  (collectionId, collectionName) => (dispatch, getState) => {
    const currentGroup = getSelectedGroupId(getState());
    dispatch(
      collectionsActionCreators.renameCollection(
        currentGroup,
        collectionId,
        collectionName
      )
    ).catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });
  };

export const deleteCollection = (collectionId) => (dispatch, getState) => {
  const currentGroup = getSelectedGroupId(getState());

  dispatch(
    collectionsActionCreators.deleteCollection(collectionId, currentGroup)
  );
};

export const assignCollectionsToContent =
  (contentId, contentType, collectionIds) => (dispatch, getState) => {
    const selectedGroup = getSelectedGroupId(getState());
    if (selectedGroup) {
      dispatch(
        collectionsActionCreators.assignCollectionsToContent(
          selectedGroup,
          contentId,
          contentType,
          collectionIds
        )
      )
        .then(() => getCollectionsForGroup()(dispatch, getState))
        .then(() => {
          notificationActions.createSuccessNotification(
            'Learning.addCollectionsSuccess'
          )(dispatch);
        })
        .catch((error) => {
          notificationActions.createErrorNotificationFromResponse(error)(
            dispatch
          );
        });
    }
  };

export const updateContentInCollection =
  ({collectionId, contentToAdd, contentToRemove}) =>
  (dispatch, getState) => {
    const currentGroup = getSelectedGroupId(getState());

    dispatch(
      collectionsActionCreators.updateContentInCollection(
        currentGroup,
        collectionId,
        contentToAdd,
        contentToRemove
      )
    );
  };

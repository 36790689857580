import * as Sentry from '@sentry/react';

import {
  ACTIVATE_USER_SUCCESS,
  LOGGED_IN,
  LOGGED_OUT,
  LOGIN_FAIL,
} from '../state/auth/authActionTypes';

const auth = () => (next) => (action) => {
  if (action.type === LOGGED_IN) {
    const {id, name, email, company} = action.payload;

    Sentry.setUser({id});
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    localStorage.setItem('company', JSON.stringify(company));
    localStorage.removeItem('token'); //remove token from localStorage for previously signed in users as we now have a secure cookie
  } else if (action.type === ACTIVATE_USER_SUCCESS) {
    const {company} = action.meta;
    const {id, name, email} = action.payload;

    Sentry.setUser({id});
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    localStorage.setItem('company', JSON.stringify(company));
  } else if (action.type === LOGGED_OUT || action.type === LOGIN_FAIL) {
    Sentry.setUser(null);
    localStorage.removeItem('token'); //remove token from localStorage for previously signed in users as we now have a secure cookie
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('company');
    sessionStorage.removeItem('isLogoutVisible');
    sessionStorage.removeItem('accessMethod');
  }
  return next(action);
};

export default auth;

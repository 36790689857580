export const SEND_COURSE_NOTIFICATIONS_NEW = 'SEND_COURSE_NOTIFICATIONS_NEW';
export const SEND_COURSE_NOTIFICATIONS_NEW_SUCCESS =
  'SEND_COURSE_NOTIFICATIONS_NEW_SUCCESS';
export const SEND_COURSE_NOTIFICATIONS_NEW_FAIL =
  'SEND_COURSE_NOTIFICATIONS_NEW_FAIL';
export const RESET_COURSE_NOTIFICATIONS_NEW = 'RESET_COURSE_NOTIFICATIONS_NEW';

export const SEND_GUIDE_NOTIFICATIONS = 'SEND_GUIDE_NOTIFICATIONS';
export const SEND_GUIDE_NOTIFICATIONS_SUCCESS =
  'SEND_GUIDE_NOTIFICATIONS_SUCCESS';
export const SEND_GUIDE_NOTIFICATIONS_FAIL = 'SEND_GUIDE_NOTIFICATIONS_FAIL';
export const RESET_GUIDE_NOTIFICATIONS = 'RESET_GUIDE_NOTIFICATIONS';

export const SEND_KNOWLEDGE_HUB_NOTIFICATIONS =
  'SEND_KNOWLEDGE_HUB_NOTIFICATIONS';
export const SEND_KNOWLEDGE_HUB_NOTIFICATIONS_SUCCESS =
  'SEND_KNOWLEDGE_HUB_NOTIFICATIONS_SUCCESS';
export const SEND_KNOWLEDGE_HUB_NOTIFICATIONS_FAIL =
  'SEND_KNOWLEDGE_HUB_NOTIFICATIONS_FAIL';
export const RESET_KNOWLEDGE_HUB_NOTIFICATIONS =
  'RESET_KNOWLEDGE_HUB_NOTIFICATIONS';

import {fromJS} from 'immutable';

import {COMPLETED, IN_PROGRESS} from '../../enums/courses';
import {getCourseStartDate, getCourseStatus} from '../../utils/courseUtil';
import {
  CLEAR_COURSE_OVERVIEWS_CACHE,
  CLEAR_PENDING_DOWNLOADS,
  GET_ACTIVE_USERS_BY_GROUP,
  GET_ACTIVE_USERS_BY_GROUP_FAIL,
  GET_ACTIVE_USERS_BY_GROUP_SUCCESS,
  GET_ACTIVE_USERS_BY_MONTH,
  GET_ACTIVE_USERS_BY_MONTH_FAIL,
  GET_ACTIVE_USERS_BY_MONTH_SUCCESS,
  GET_ACTIVITY_BREAKDOWN,
  GET_ACTIVITY_BREAKDOWN_FAIL,
  GET_ACTIVITY_BREAKDOWN_SUCCESS,
  GET_ADMIN_COUNT,
  GET_COURSE_ACTIVITY_GRAPH_SUCCESS,
  GET_COURSE_OVERVIEWS,
  GET_COURSE_OVERVIEWS_FAIL,
  GET_COURSE_OVERVIEWS_SUCCESS,
  GET_GUIDE_ACTIVITY_GRAPH_FAIL,
  GET_GUIDE_ACTIVITY_GRAPH_SUCCESS,
  GET_GUIDE_STEP_BREAKDOWN_FAIL,
  GET_GUIDE_STEP_BREAKDOWN_SUCCESS,
  GET_GUIDE_TEAM_BREAKDOWN_FAIL,
  GET_GUIDE_TEAM_BREAKDOWN_SUCCESS,
  GET_STATS_FOR_GROUP_ERROR,
  GET_STATS_FOR_GROUP_SUCCESS,
  GET_TEAM_ACTIVITY_STATS_SUCCESS,
  GET_TEAM_USERS,
  GET_TEAM_USERS_ERROR,
  GET_TEAM_USERS_SUCCESS,
  GROUP_STATS_LOADED,
  RESET_TEAM_USERS,
  SET_PENDING_DOWNLOADS,
} from './reportingActionTypes';

export const initialState = fromJS({
  error: '',
  courses: {}, //map from group id -> course data
  groupStats: {}, //map from group id -> stats
  /**
   *
   * key is a combination of courseId and groupId -> `c9_g2`;
   * @type Map<string, Array< { daily: number, date: "2020-11-02", monthly: number, weekly: number } >>
   */
  courseActivityGraphData: {},
  courseActivityBreakdown: {},
  courseActivityBreakdownLoaded: false,
  guideActivityStats: {},
  guideStepBreakdown: {},
  guideTeamBreakdown: {},
  teamStats: {},
  users: [],
  loadingUsers: false,
  adminCount: null,
  courseOverviews: {},
  courseOverviewsLoaded: true,
  activeUsers: {},
  pendingDownloads: {},
});

// eslint-disable-next-line complexity, max-statements
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_OVERVIEWS: {
      return state.set('hasCourseOverviewsLoaded', false);
    }

    case GET_COURSE_OVERVIEWS_SUCCESS: {
      const courseOverviewsArray = action.payload.data;
      const courseOverviews = courseOverviewsArray.reduce((obj, course) => {
        const {courseId} = course;
        obj[courseId] = course;
        return obj;
      }, {});
      const currentState = state.get('courseOverviews').toJS();
      return state
        .set('courseOverviews', fromJS({...currentState, ...courseOverviews}))
        .set('hasCourseOverviewsLoaded', true);
    }

    case SET_PENDING_DOWNLOADS: {
      const {groupOrTeamId, range, isPending} = action.payload;
      return state.setIn(
        ['pendingDownloads', groupOrTeamId, range, 'isPending'],
        isPending
      );
    }

    case CLEAR_PENDING_DOWNLOADS: {
      const {groupOrTeamId} = action.payload;

      return state.updateIn(['pendingDownloads', groupOrTeamId], (list) =>
        list?.filter((range) => !!range.get('isPending'))
      );
    }

    case GET_COURSE_OVERVIEWS_FAIL: {
      return state.set('hasCourseOverviewsLoaded', true);
    }

    case CLEAR_COURSE_OVERVIEWS_CACHE: {
      return state.set('courseOverviews', fromJS({}));
    }

    case GET_ACTIVITY_BREAKDOWN: {
      return state
        .set(
          'courseActivityBreakdown',
          initialState.get('courseActivityBreakdown')
        )
        .set('courseActivityBreakdownLoaded', false);
    }

    case GET_ACTIVITY_BREAKDOWN_FAIL: {
      return state.set('courseActivityBreakdownLoaded', true);
    }

    case GET_ACTIVITY_BREAKDOWN_SUCCESS: {
      const {groupId, stats, cacheUpdatedAt} = action.payload.data;
      return state
        .setIn(['courseActivityBreakdown', groupId, 'stats'], stats)
        .setIn(
          ['courseActivityBreakdown', groupId, 'cacheUpdatedAt'],
          cacheUpdatedAt
        )
        .set('courseActivityBreakdownLoaded', true);
    }

    case GET_GUIDE_ACTIVITY_GRAPH_FAIL: {
      const guideId = action.meta.guideId;
      return state.setIn(['guideActivityStats', guideId, 'loaded'], true);
    }

    case GET_GUIDE_ACTIVITY_GRAPH_SUCCESS: {
      const guideId = action.meta.guideId;
      const data = action.payload.data;
      return state
        .setIn(['guideActivityStats', guideId, 'graphStats'], data.graphStats)
        .setIn(['guideActivityStats', guideId, 'eventStats'], data.eventStats)
        .setIn(['guideActivityStats', guideId, 'loaded'], true);
    }

    case GET_GUIDE_STEP_BREAKDOWN_FAIL: {
      const guideId = action.meta.guideId;
      return state.setIn(['guideStepBreakdown', guideId, 'loaded'], true);
    }

    case GET_GUIDE_STEP_BREAKDOWN_SUCCESS: {
      const guideId = action.meta.guideId;
      return state
        .setIn(['guideStepBreakdown', guideId, 'stats'], action.payload.data)
        .setIn(['guideStepBreakdown', guideId, 'loaded'], true);
    }

    case GET_GUIDE_TEAM_BREAKDOWN_FAIL: {
      const guideId = action.meta.guideId;
      return state.setIn(['guideTeamBreakdown', guideId, 'loaded'], true);
    }

    case GET_GUIDE_TEAM_BREAKDOWN_SUCCESS: {
      const guideId = action.meta.guideId;
      const data = action.payload.data;
      const teams = data.teams.map((team) => {
        const completedCount =
          Number(
            team.totalCounts.find(
              (i) => i.key === 'GUIDE_EVENT_TYPE_GUIDE_COMPLETED'
            )?.count
          ) || 0;

        const startedCount =
          Number(
            team.totalCounts.find(
              (i) => i.key === 'GUIDE_EVENT_TYPE_GUIDE_START'
            )?.count
          ) || 0;

        const startedYesterday =
          Number(
            team.yesterdayCounts.find(
              (i) => i.key === 'GUIDE_EVENT_TYPE_GUIDE_START'
            )?.count
          ) || 0;

        const completedYesterday =
          Number(
            team.yesterdayCounts.find(
              (i) => i.key === 'GUIDE_EVENT_TYPE_GUIDE_COMPLETED'
            )?.count
          ) || 0;

        const reactions = team.reactions.reduce(
          (obj, row) => ({
            ...obj,
            [row.reaction]: Number(row.count),
          }),
          {1: 0, 2: 0, 3: 0}
        );

        return {
          ...team,
          completedCount,
          inProgress: startedCount - completedCount,
          notStarted: Number(team.userCount) - startedCount,
          startedYesterday,
          completedYesterday,
          reactions,
        };
      });
      return state
        .setIn(['guideTeamBreakdown', guideId, 'stats'], teams)
        .setIn(['guideTeamBreakdown', guideId, 'loaded'], true);
    }

    case GET_STATS_FOR_GROUP_SUCCESS: {
      const courses = action.payload.data;
      return state
        .setIn(['courses', courses.groupId, 'stats'], courses.stats)
        .setIn(
          ['courses', courses.groupId, 'cacheUpdatedAt'],
          courses.cacheUpdatedAt
        );
    }

    case GET_STATS_FOR_GROUP_ERROR:
      return state.set('error', action.payload.data);

    case GET_COURSE_ACTIVITY_GRAPH_SUCCESS: {
      const courseGroupKey = action.meta.courseGroupKey;
      const data = action.payload.data;
      return state
        .setIn(['courseActivityGraphData', courseGroupKey, 'stats'], data.stats)
        .setIn(
          ['courseActivityGraphData', courseGroupKey, 'cacheUpdatedAt'],
          data.cacheUpdatedAt
        );
    }

    case GROUP_STATS_LOADED: {
      const stats = action.payload;
      const groupMap = state.get('groupStats').set(
        stats.groupId,
        fromJS({
          month: stats.month,
          rolling: stats.rolling,
          cacheUpdatedAt: stats.cacheUpdatedAt,
        })
      );
      return state.set('groupStats', groupMap);
    }

    case GET_TEAM_ACTIVITY_STATS_SUCCESS: {
      const teams = action.payload.data;
      return state
        .setIn(['teamStats', teams.groupId, 'stats'], teams.stats)
        .setIn(
          ['teamStats', teams.groupId, 'cacheUpdatedAt'],
          teams.cacheUpdatedAt
        );
    }

    case GET_TEAM_USERS: {
      return state.set('loadingUsers', true);
    }

    case GET_TEAM_USERS_SUCCESS: {
      const users = action.payload.data;
      let usersWithCourseProgress = [];
      for (let user of users) {
        const {id, firstName, lastName, name} = user;
        const courseResults = [];
        let completed = 0;
        let inProgress = 0;
        let notStarted = 0;
        user.courseResults.forEach((course) => {
          const {
            id: courseId,
            colour,
            icon,
            activityCount,
            title,
            startDate,
            completionDate,
            retakeAssignmentDate,
            latestRetakeStartDate,
            latestRetakeCompletionDate,
          } = course;

          const latestStartDate = getCourseStartDate(
            startDate,
            latestRetakeCompletionDate,
            retakeAssignmentDate,
            latestRetakeStartDate
          );
          const latestCompletionDate =
            latestRetakeCompletionDate || completionDate;
          const courseStatus = getCourseStatus(
            latestStartDate,
            latestCompletionDate,
            retakeAssignmentDate
          );

          if (courseStatus === COMPLETED) {
            completed++;
          } else if (courseStatus === IN_PROGRESS) {
            inProgress++;
          } else {
            notStarted++;
          }
          courseResults.push({
            id: courseId,
            colour,
            icon,
            activityCount,
            title,
            latestStartDate,
            latestCompletionDate,
            retakeAssignmentDate,
            courseStatus,
          });
        });

        user = {id, firstName, lastName, name, courseResults};
        usersWithCourseProgress.push({
          ...user,
          completed,
          inProgress,
          notStarted,
        });
      }
      return state
        .set('users', fromJS(usersWithCourseProgress))
        .set('loadingUsers', false);
    }

    case GET_TEAM_USERS_ERROR: {
      return state.set('error', action.payload.data).set('loadingUsers', false);
    }

    case RESET_TEAM_USERS: {
      return state.set('users', fromJS([]));
    }

    case `${GET_ADMIN_COUNT}_SUCCESS`:
      return state.set('adminCount', action.payload.data);

    case GET_ACTIVE_USERS_BY_MONTH:
      return state.setIn(['activeUsers', 'isMonthlyLoading'], true);

    case GET_ACTIVE_USERS_BY_MONTH_SUCCESS:
      return state
        .setIn(['activeUsers', 'monthly'], fromJS(action.payload.data))
        .setIn(['activeUsers', 'isMonthlyLoading'], false);

    case GET_ACTIVE_USERS_BY_MONTH_FAIL:
      return state.setIn(['activeUsers', 'isMonthlyLoading'], false);

    case GET_ACTIVE_USERS_BY_GROUP:
      return state.setIn(['activeUsers', 'isGroupLoading'], true);

    case GET_ACTIVE_USERS_BY_GROUP_SUCCESS:
      return state
        .setIn(['activeUsers', 'group'], fromJS(action.payload.data))
        .setIn(['activeUsers', 'isGroupLoading'], false);

    case GET_ACTIVE_USERS_BY_GROUP_FAIL:
      return state.setIn(['activeUsers', 'isGroupLoading'], false);

    default:
      return state;
  }
};

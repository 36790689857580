import React, {forwardRef, useEffect} from 'react';

import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList as List} from 'react-window';

import usePrevious from '../../../hooks/usePrevious';

const ContentList = forwardRef(
  ({isFullWidthItem, contentList, getRowHeight, itemWidth}, listRef) => {
    const prevFilterContentListLength = usePrevious(contentList.length);

    useEffect(() => {
      const scrollToTop = () => {
        listRef.current.scrollToItem(0, 'center');
      };
      if (
        listRef?.current &&
        prevFilterContentListLength !== contentList.length
      ) {
        scrollToTop();
      }
    }, [listRef, contentList.length, prevFilterContentListLength]);

    return (
      <AutoSizer>
        {({height, width}) => {
          const itemsPerRow = !isFullWidthItem
            ? Math.floor(width / itemWidth)
            : 1;
          const noOfRows = Math.ceil(contentList.length / itemsPerRow);
          const responsiveWidth = `${100 / itemsPerRow}%`;

          return (
            <List
              className='List'
              width={width}
              height={height}
              itemCount={isFullWidthItem ? noOfRows + 1 : noOfRows}
              itemSize={getRowHeight}
              style={{
                willChange: 'auto',
              }}
              ref={listRef}
              overscanCount={4} // reduces flickering when scrolling fast
            >
              {({index, style}) => {
                const items = [];
                const fromIndex = index * itemsPerRow;
                const toIndex = Math.min(
                  fromIndex + itemsPerRow,
                  contentList.length
                );

                for (let i = fromIndex; i < toIndex; i++) {
                  if (fromIndex < toIndex) {
                    items.push(
                      <div
                        key={i}
                        style={{
                          width: responsiveWidth,
                        }}
                      >
                        {contentList[i]}
                      </div>
                    );
                  }
                }

                return (
                  <div
                    style={{
                      ...style,
                      display: 'flex',
                      marginTop: 10,
                      marginBottom: 30,
                    }}
                  >
                    {items}
                  </div>
                );
              }}
            </List>
          );
        }}
      </AutoSizer>
    );
  }
);

ContentList.displayName = 'ContentList';

ContentList.propTypes = {
  isFullWidthItem: PropTypes.bool,
  contentList: PropTypes.array.isRequired,
  getRowHeight: PropTypes.func.isRequired,
  itemWidth: requiredIf(PropTypes.number, (props) => !props.isFullWidthItem),
};

export default ContentList;

import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {CookiePopup} from '@edume/magnificent';

const mapStateToProps = (state, ownProps) => {
  const {intl} = ownProps;

  return {
    headerText: intl.formatMessage({id: 'Cookie.header'}),
    bodyText: intl.formatMessage({id: 'Cookie.body'}),
    buttonText: intl.formatMessage({id: 'Cookie.button'}),
    padRight: true,
  };
};

export default injectIntl(connect(mapStateToProps)(CookiePopup));

import {EDITING_TYPES} from '../enums/lessons';

export const deriveEditingType = ({isTemplate, published, isMultiLanguage}) => {
  if (isTemplate) {
    return EDITING_TYPES.NONE;
  }

  if (published && isMultiLanguage) {
    return EDITING_TYPES.LIMITED_MLS;
  }

  if (published) {
    return EDITING_TYPES.LIMITED;
  }

  return EDITING_TYPES.FULL;
};

/**
 * Checks if 'universal' editing features are enabled
 * @param {object} args
 * @param {object} [args.course]
 * @param {string} [args.editingType ]
 * @returns {boolean}
 */
export const getIsBasicEditingEnabled = ({course, editingType}) => {
  let editingTypeToCheck = editingType;
  if (course) {
    editingTypeToCheck = deriveEditingType(course);
  }

  return editingTypeToCheck
    ? [EDITING_TYPES.FULL, EDITING_TYPES.LIMITED].includes(editingTypeToCheck)
    : false;
};

import {v4 as uuidv4} from 'uuid';

import config from '../config';
import {COURSE, GUIDE, KNOWLEDGE_HUB, LESSON} from '../enums/contentTypes';
import {storeCoursePreviewToken} from '../state/course/courseActions';
import {storeKnowledgeHubPreviewToken} from '../state/knowledgeHub/knowledgeHubActions';
import {storeLessonPreviewToken} from '../state/moduleActivity/moduleActivityActions';
import {store} from '../state/store';

export const getPreviewLink = async ({
  contentType,
  courseId,
  moduleId,
  activityId,
  knowledgeHubId,
  guideId,
  guidePreviewToken,
}) => {
  const baseUrl = config.lfeRoot || 'https://learn.edume.com';
  const previewToken = uuidv4().split('-')[0];
  let previewLink;
  switch (contentType) {
    case KNOWLEDGE_HUB:
      previewLink = `${baseUrl}/p/kh/${knowledgeHubId}?previewToken=${previewToken}`;
      store.dispatch(
        storeKnowledgeHubPreviewToken(knowledgeHubId, previewToken)
      );
      break;
    case COURSE:
      previewLink = `${baseUrl}/p/m/${moduleId}/${activityId}?previewToken=${previewToken}&isCoursePreview=true`;
      store.dispatch(storeCoursePreviewToken(courseId, previewToken));
      break;
    case LESSON:
      previewLink = `${baseUrl}/p/m/${moduleId}/${activityId}?previewToken=${previewToken}&isCoursePreview=false`;
      store.dispatch(storeLessonPreviewToken(activityId, previewToken));
      break;
    case GUIDE:
      previewLink = `${baseUrl}/p/gd/${guideId}/?previewToken=${guidePreviewToken}`;
      break;
    default:
      break;
  }

  return previewLink;
};

export const getInlinePreviewUrl = (contentType, contentId) => {
  let previewLink;
  switch (contentType) {
    case GUIDE:
      previewLink = `/guides/${contentId}/preview`;
      break;
    default:
      break;
  }

  return previewLink;
};

import React from 'react';

const getStep = (
  intl,
  imageUrl,
  titleKey,
  bodyKey,
  buttonAction,
  buttonKey
) => ({
  imageUrl,
  title: intl.formatMessage({id: titleKey}),
  body: <p>{intl.formatMessage({id: bodyKey})}</p>,
  buttons: [
    {
      type: 'primary',
      text: intl.formatMessage({id: buttonKey}),
      onClick: buttonAction,
    },
  ],
});

export const getUpdatesContent = (
  intl,
  adminLevel,
  buttonAction,
  buttonKey,
  bottomLeftContent
) => ({
  ...getStep(
    intl,
    '/resources/img/onboarding/onboard-messages.png',
    'Onboarding.Updates.title',
    `Onboarding.Updates.body.${adminLevel}`,
    buttonAction,
    buttonKey
  ),
  bottomLeftContent,
});

export const getPeopleContent = (
  intl,
  adminLevel,
  buttonAction,
  buttonKey,
  bottomLeftContent
) => ({
  ...getStep(
    intl,
    '/resources/img/onboarding/onboard-people.png',
    'Onboarding.People.title',
    `Onboarding.People.body.${adminLevel}`,
    buttonAction,
    buttonKey
  ),
  bottomLeftContent,
});

//to be really consistent, we should call this `getContentContent`.... 🤔
//I'm not content with that.
export const getLearningContent = (
  intl,
  adminLevel,
  buttonAction,
  buttonKey,
  bottomLeftContent
) => ({
  ...getStep(
    intl,
    '/resources/img/onboarding/onboard-learning.png',
    'Onboarding.Content.title',
    `Onboarding.Content.body.${adminLevel}`,
    buttonAction,
    buttonKey
  ),
  bottomLeftContent,
});

export const getReportingContent = (
  intl,
  adminLevel,
  buttonAction,
  buttonKey,
  bottomLeftContent
) => ({
  ...getStep(
    intl,
    '/resources/img/onboarding/onboard-reporting.png',
    'Onboarding.Reporting.title',
    `Onboarding.Reporting.body.${adminLevel}`,
    buttonAction,
    buttonKey
  ),
  bottomLeftContent,
});

import {coreSystem, realmPlugin, system} from '@mdxeditor/editor';
import {COMMAND_PRIORITY_HIGH, KEY_TAB_COMMAND} from 'lexical';

// Registers a new empty tab key command so that the editor doesn't
// capture tab key and indent text - avoids focus being trapped in
// the editor for keyboard accessibility, and we don't render
// indents in text created by this editor

const tabControlSystem = system(
  (r, [{createActiveEditorSubscription}]) => {
    r.pub(createActiveEditorSubscription, (editor) =>
      editor.registerCommand(
        KEY_TAB_COMMAND,
        cancelTabKeyAction,
        COMMAND_PRIORITY_HIGH
      )
    );
  },
  [coreSystem]
);

// Return true to stop processing other actions on the tab key
const cancelTabKeyAction = () => true;

const [tabControlPlugin] = realmPlugin({
  id: 'tab-control',
  systemSpec: tabControlSystem,
});

export {tabControlPlugin};

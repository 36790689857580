/* eslint-disable max-statements */
import {fromJS} from 'immutable';
import _ from 'lodash';

import {
  ADD_ADMIN,
  ADD_ADMIN_FAIL,
  ADMIN_CREATED,
  ADMIN_DELETED,
  ADMIN_UPDATED,
  GET_COMPANY_ADMINS_SUCCESS,
  LOAD_GROUP_ADMINS_SUCCESS,
  LOAD_TEAM_ADMINS_SUCCESS,
  UPDATE_ADMIN,
  UPDATE_ADMIN_FAIL,
} from './adminActionTypes';

export const initialState = fromJS({
  companyAdmins: [],
  groupAdmins: [],
  teamAdmins: [],
  editAdminInProgress: false,
  companyAdminsLoaded: false,
  groupAdminsLoaded: false,
  teamAdminsLoaded: false,
});

const getAdminListKey = (isCompanyAdmin, isTeamAdmin) => {
  if (isCompanyAdmin) {
    return 'companyAdmins';
  } else if (isTeamAdmin) {
    return 'teamAdmins';
  } else {
    return 'groupAdmins';
  }
};

const formatLearnerGroups = (learnerGroups) => {
  const parentGroupIds = learnerGroups
    .filter((group) => !group.parentGroupId)
    .map((group) => group.id);
  return parentGroupIds.map((id) => ({
    groupId: id,
    teamIds: learnerGroups
      .filter((group) => group.parentGroupId === id)
      .map((group) => group.id),
  }));
};

// eslint-disable-next-line complexity
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_ADMINS_SUCCESS: {
      const admins = action.payload.data.map((admin) => ({
        ...admin,
        learnerGroups: formatLearnerGroups(admin.learnerGroups),
      }));
      const companyAdmins = fromJS(_.orderBy(admins, 'id', 'desc'));

      return state
        .set('companyAdmins', companyAdmins)
        .set('companyAdminsLoaded', true);
    }

    case LOAD_GROUP_ADMINS_SUCCESS: {
      const admins = action.payload.data.map((admin) => ({
        ...admin,
        learnerGroups: formatLearnerGroups(admin.learnerGroups),
      }));
      const groupAdmins = fromJS(_.orderBy(admins, 'id', 'desc'));
      return state
        .set('groupAdmins', groupAdmins)
        .set('groupAdminsLoaded', true);
    }

    case LOAD_TEAM_ADMINS_SUCCESS: {
      const teamAdmins = fromJS(
        _.orderBy(action.payload.data, 'id', 'desc').map((admin) => ({
          ...admin,
          isTeamAdmin: true,
        }))
      );
      return state.set('teamAdmins', teamAdmins).set('teamAdminsLoaded', true);
    }

    case ADD_ADMIN:
    case UPDATE_ADMIN: {
      return state.set('editAdminInProgress', true);
    }
    case ADD_ADMIN_FAIL:
    case UPDATE_ADMIN_FAIL: {
      return state.set('editAdminInProgress', false);
    }

    case ADMIN_CREATED: {
      const {id, isCompanyAdmin, isTeamAdmin} = action.payload;
      const adminListKey = getAdminListKey(isCompanyAdmin, isTeamAdmin);
      const indexToInsert = state
        .get(adminListKey)
        .findIndex((a) => a.get('id') < id);
      const admin = {
        ...action.payload,
        learnerGroups: formatLearnerGroups(action.payload.learnerGroups),
      };
      return state
        .set('editAdminInProgress', false)
        .updateIn([adminListKey], (admins) =>
          admins.insert(indexToInsert, fromJS(admin))
        );
    }

    case ADMIN_UPDATED: {
      const {id, isCompanyAdmin, isTeamAdmin, isAdmin} = action.payload;
      if (!isAdmin) {
        const wasCompanyAdmin = state
          .get('companyAdmins')
          .find((admin) => admin.get('id') === id);
        const wasTeamAdmin = state
          .get('teamAdmins')
          .find((admin) => admin.get('id') === id);
        const adminList = getAdminListKey(wasCompanyAdmin, wasTeamAdmin);
        const adminIndexToRemove = state
          .get(adminList)
          .findIndex((admin) => admin.get('id') === id);
        const adminsRemoved = state.get(adminList).delete(adminIndexToRemove);
        return state.set(adminList, adminsRemoved);
      }
      const admin = {
        ...action.payload,
        learnerGroups: formatLearnerGroups(action.payload.learnerGroups),
      };
      const adminListKey = getAdminListKey(isCompanyAdmin, isTeamAdmin);
      const indexToUpdate = state
        .get(adminListKey)
        .findIndex((a) => a.get('id') === id);
      return state
        .set('editAdminInProgress', false)
        .updateIn([adminListKey], (admins) =>
          admins.splice(indexToUpdate, 1, fromJS(admin))
        );
    }

    case ADMIN_DELETED: {
      const {adminId, isCompanyAdmin, isTeamAdmin} = action.payload;
      const adminList = getAdminListKey(isCompanyAdmin, isTeamAdmin);
      const adminIndexToRemove = state
        .get(adminList)
        .findIndex((admin) => admin.get('id') === adminId);
      const adminsRemoved = state.get(adminList).delete(adminIndexToRemove);
      return state.set(adminList, adminsRemoved);
    }

    default:
      return state;
  }
};

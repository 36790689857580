export const capitalise = (s) => s[0].toUpperCase() + s.slice(1);

export const getFileFormatFromString = (url) =>
  url ? /.+\.(\w+)$/.exec(url)[1] : '';

export const hashCredentials = (credential) => {
  if (!(credential && credential.length)) {
    return credential;
  }

  const maskedPart =
    credential.length > 12
      ? credential.substring(4, credential.length - 4)
      : credential;
  const mask = '*'.repeat(Math.min(maskedPart.length, 32));
  return credential.replaceAll(maskedPart, mask);
};

import {fromJS} from 'immutable';

import {
  GET_GROUP_SURVEYS,
  GET_GROUP_SURVEYS_SUCCESS,
} from './surveyActionTypes';

export const initialState = fromJS({
  surveysForGroup: [],
  surveysLoaded: false,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_SURVEYS: {
      return state.set('surveysLoaded', false);
    }

    case GET_GROUP_SURVEYS_SUCCESS: {
      return state
        .set('surveysForGroup', action.payload.data)
        .set('surveysLoaded', true);
    }

    default: {
      return state;
    }
  }
};

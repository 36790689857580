import {getSelectedGroupId} from '../team/teamSelectors';

export const getCustomerFeatureFlags = (state) =>
  state.getIn(['featureFlags', 'customerFlags']);

export const getGroupFeatureFlags = (state) => {
  const groupId = getSelectedGroupId(state);
  const groupFlags = state.getIn(['featureFlags', 'groupFlags', groupId]);
  return groupFlags;
};

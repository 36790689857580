// sort-imports-ignore
import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {Notification} from '@edume/magnificent'; // eslint-disable-line workspaces/require-dependency
import styles from './styles.module.scss';

const NotificationArea = ({
  headers,
  notifications,
  onDelete,
  globalSettings,
}) => {
  const ref = useRef(null);
  const [stackingThreshold, setStackingThreshold] = useState();

  if (
    !stackingThreshold &&
    ref.current?.clientHeight > window.innerHeight * 0.7
  ) {
    setStackingThreshold(notifications.length);
  }

  const isStackingEnabled =
    !!stackingThreshold && notifications.length > stackingThreshold;

  return (
    <div styleName={'wrapper'} ref={ref}>
      {notifications.map((notification, index, array) => {
        const headerText =
          notification.headerText ||
          (notification.type === 'SUCCESS' ? headers.success : headers.error);

        const isStacked = isStackingEnabled && index !== array.length - 1;

        return (
          <div key={notification.id} styleName={isStacked ? 'stacked' : ''}>
            <Notification
              headerText={headerText}
              message={notification.message}
              type={notification.type}
              isAutoDismissed={notification.isAutoDismissed}
              duration={4000}
              onDelete={() => onDelete(notification.id)}
              ctaOptions={notification.ctaOptions}
              dataAutomation={`notification-bar-${notification.dataAutomation}`}
              {...globalSettings}
            />
          </div>
        );
      })}
    </div>
  );
};

NotificationArea.propTypes = {
  headers: PropTypes.shape({
    success: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
  }),
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      type: PropTypes.string.isRequired,
      ctaOptions: PropTypes.shape({
        icon: PropTypes.oneOf(['team', 'retry']),
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
      message: PropTypes.string.isRequired,
      isAutoDismissed: PropTypes.bool,
      dataAutomation: PropTypes.string.isRequired,
    })
  ),
  onDelete: PropTypes.func.isRequired,
  globalSettings: PropTypes.shape({
    variant: PropTypes.oneOf(['frontline']),
    isAutoDismissed: PropTypes.bool,
    duration: PropTypes.number,
  }),
};

export default CSSModules(NotificationArea, styles, {allowMultiple: true});

import {addMeta, buildRequest} from '../stateUtil';
import {
  ADD_KNOWLEDGE_HUB,
  ADD_KNOWLEDGE_HUB_SECTION,
  DELETE_KNOWLEDGE_HUB,
  DELETE_KNOWLEDGE_HUB_SECTION,
  EDIT_KNOWLEDGE_HUB,
  EDIT_KNOWLEDGE_HUB_SECTION,
  GET_KNOWLEDGE_HUB_CONTENT,
  GET_KNOWLEDGE_HUBS,
  REORDER_KNOWLEDGE_HUB_SECTIONS,
  STORE_KNOWLEDGE_HUB_PREVIEW_TOKEN,
  UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT,
  UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS,
} from './knowledgeHubActionTypes';

export const addKnowledgeHub = (
  groupId,
  teamIds,
  name,
  showsExternalContent,
  contentAssignment
) =>
  addMeta(
    buildRequest(ADD_KNOWLEDGE_HUB, 'POST', `/knowledge-hubs`, {
      groupId,
      teamIds,
      name,
      showsExternalContent,
      contentAssignment,
    }),
    {
      groupId,
      teamIds,
    }
  );

export const storeKnowledgeHubPreviewToken = (knowledgeHubId, previewToken) =>
  buildRequest(
    STORE_KNOWLEDGE_HUB_PREVIEW_TOKEN,
    'POST',
    `/knowledge-hubs/${knowledgeHubId}/preview`,
    {previewToken}
  );

export const getGroupKnowledgeHubs = (groupId) =>
  buildRequest(GET_KNOWLEDGE_HUBS, 'GET', `/groups/${groupId}/knowledge-hubs`);

export const editKnowledgeHub = (knowledgeHubId, name, showsExternalContent) =>
  addMeta(
    buildRequest(
      EDIT_KNOWLEDGE_HUB,
      'PATCH',
      `/knowledge-hubs/${knowledgeHubId}`,
      {
        name,
        showsExternalContent,
      }
    ),
    {knowledgeHubId}
  );

export const getKnowledgeHubContent = (knowledgeHubId) =>
  addMeta(
    buildRequest(
      GET_KNOWLEDGE_HUB_CONTENT,
      'GET',
      `/knowledge-hubs/${knowledgeHubId}/content`
    ),
    {knowledgeHubId}
  );

export const updateKnowledgeHubSectionContent = (sectionId, content) =>
  addMeta(
    buildRequest(
      UPDATE_KNOWLEDGE_HUB_SECTION_CONTENT,
      'PUT',
      `/knowledge-hubs/sections/${sectionId}/content`,
      {content}
    ),
    {sectionId}
  );

export const deleteKnowledgeHub = (knowledgeHubId) =>
  addMeta(
    buildRequest(
      DELETE_KNOWLEDGE_HUB,
      'DELETE',
      `/knowledge-hubs/${knowledgeHubId}`
    ),
    {knowledgeHubId}
  );

export const addKnowledgeHubSection = (knowledgeHubId, title) =>
  buildRequest(
    ADD_KNOWLEDGE_HUB_SECTION,
    'POST',
    `/knowledge-hubs/${knowledgeHubId}/sections`,
    {title}
  );

export const editKnowledgeHubSection = (knowledgeHubSectionId, title) =>
  addMeta(
    buildRequest(
      EDIT_KNOWLEDGE_HUB_SECTION,
      'PATCH',
      `/knowledge-hubs/sections/${knowledgeHubSectionId}`,
      {title}
    ),
    {knowledgeHubSectionId, title}
  );

export const reorderKnowledgeHubSections = (
  knowledgeHubId,
  oldSectionIds,
  newSectionIds
) =>
  addMeta(
    buildRequest(
      REORDER_KNOWLEDGE_HUB_SECTIONS,
      'PUT',
      `/knowledge-hubs/${knowledgeHubId}/sections/positions`,
      {sectionIds: newSectionIds}
    ),
    {
      oldSectionIds,
    }
  );

export const updateTeamsInKnowledgeHubSection = (
  knowledgeHubSectionId,
  updatedTeamIds
) =>
  addMeta(
    buildRequest(
      UPDATE_KNOWLEDGE_HUB_SECTION_TEAMS,
      'POST',
      `/knowledge-hubs/sections/${knowledgeHubSectionId}/assignTeams`,
      {
        updatedTeamIds,
      }
    ),
    {
      knowledgeHubSectionId,
      updatedTeamIds,
    }
  );

export const deleteKnowledgeHubSection = (knowledgeHubSectionId) =>
  addMeta(
    buildRequest(
      DELETE_KNOWLEDGE_HUB_SECTION,
      'DELETE',
      `/knowledge-hubs/sections/${knowledgeHubSectionId}`
    ),
    {knowledgeHubSectionId}
  );

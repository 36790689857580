import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router/immutable';
import {createBrowserHistory} from 'history';
import {Map} from 'immutable';
import {applyMiddleware, compose, createStore} from 'redux';
import {multiClientMiddleware} from 'redux-axios-middleware';
import {combineReducers} from 'redux-immutable';
import thunk from 'redux-thunk';

import edumeClient from '../edumeClient';
import edumeMstClient from '../edumeMstClient';
import edumeReportsClient from '../edumeReportsClient';
import guidesClient from '../guidesClient';
import analyticsMiddleware from '../middleware/analytics';
import authMiddleware from '../middleware/auth';
import hubspotMiddleware from '../middleware/hubspot';
import trackingMiddleware from '../middleware/tracking';
import * as adminReducer from './admin/adminReducer';
import * as aiReducer from './ai/aiReducer';
import * as authActions from './auth/authActions';
import {LOGGED_OUT} from './auth/authActionTypes';
import * as authReducer from './auth/authReducer';
import * as collectionsReducer from './collections/collectionsReducer';
import * as contentReducer from './content/contentReducer';
import * as courseReducer from './course/courseReducer';
import * as featureFlags from './featureFlags/featureFlagsReducer';
import * as feedReducer from './feed/feedReducer';
import * as formReducer from './form/formReducer';
import * as guideReducer from './guide/guideReducer';
import * as imageLibraryReducer from './imageLibrary/imageLibraryReducer';
import * as knowledgeHubReducer from './knowledgeHub/knowledgeHubReducer';
import * as learningCardReducer from './learningCard/learningCardReducer';
import * as loginPageConfigReducer from './loginPageConfig/loginPageConfigReducer';
import * as moduleActivityReducer from './moduleActivity/moduleActivityReducer';
import * as multivariantTestReducer from './multivariantTests/multivariantTestReducer';
import * as notificationReducer from './notification/notificationReducer';
import * as onboardingReducer from './onboarding/onboardingReducer';
import * as overviewReducer from './overview/overviewReducer';
import * as peopleReducer from './people/peopleReducer';
import * as reportingReducer from './reporting/reportingReducer';
import * as segmentReducer from './segments/segmentReducer';
import * as settingsReducer from './settings/settingsReducer';
import * as surveyReducer from './survey/surveyReducer';
import * as tagReducer from './tags/tagReducer';
import * as teamReducer from './team/teamReducer';
import * as trialReducer from './trial/trialReducer';
import * as unsubscribeReducer from './unsubscribe/unsubscribeReducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    feed: feedReducer.reducer,
    auth: authReducer.reducer,
    admin: adminReducer.reducer,
    overview: overviewReducer.reducer,
    team: teamReducer.reducer,
    people: peopleReducer.reducer,
    onboarding: onboardingReducer.reducer,
    content: contentReducer.reducer,
    course: courseReducer.reducer,
    guide: guideReducer.reducer,
    moduleActivity: moduleActivityReducer.reducer,
    learningCard: learningCardReducer.reducer,
    notification: notificationReducer.reducer,
    stats: reportingReducer.reducer,
    form: formReducer.reducer,
    trial: trialReducer.reducer,
    unsubscribe: unsubscribeReducer.reducer,
    survey: surveyReducer.reducer,
    featureFlags: featureFlags.reducer,
    imageLibrary: imageLibraryReducer.reducer,
    settings: settingsReducer.reducer,
    multivariantTests: multivariantTestReducer.reducer,
    knowledgeHub: knowledgeHubReducer.reducer,
    loginPageConfig: loginPageConfigReducer.reducer,
    tags: tagReducer.reducer,
    segments: segmentReducer.reducer,
    ai: aiReducer.reducer,
    collections: collectionsReducer.reducer,
  });

const initialState = Map({
  router: Map({location: {pathname: '/'}, action: 'POP'}),
  feed: feedReducer.initialState,
  auth: authReducer.initialState,
  admin: adminReducer.initialState,
  overview: overviewReducer.initialState,
  team: teamReducer.initialState,
  people: peopleReducer.initialState,
  onboarding: onboardingReducer.initialState,
  content: contentReducer.initialState,
  course: courseReducer.initialState,
  guide: guideReducer.initialState,
  moduleActivity: moduleActivityReducer.initialState,
  learningCard: learningCardReducer.initialState,
  notification: notificationReducer.initialState,
  stats: reportingReducer.initialState,
  form: formReducer.initialState,
  trial: trialReducer.initialState,
  unsubscribe: unsubscribeReducer.initialState,
  survey: surveyReducer.initialState,
  featureFlags: featureFlags.initialState,
  imageLibrary: imageLibraryReducer.initialState,
  multivariantTests: multivariantTestReducer.initialState,
  knowledgeHub: knowledgeHubReducer.initialState,
  loginPageConfig: loginPageConfigReducer.initialState,
  settings: settingsReducer.initialState,
  tags: tagReducer.initialState,
  segments: segmentReducer.initialState,
  ai: aiReducer.initialState,
  collections: collectionsReducer.initialState,
});

export const history = createBrowserHistory();

const rootReducer = (state, action) => {
  if (action.type === LOGGED_OUT) {
    const resetState = initialState.set(
      'loginPageConfig',
      state.get('loginPageConfig')
    );
    return createRootReducer(history)(resetState, action);
  } else {
    return createRootReducer(history)(state, action);
  }
};

const axiosMiddlewareConfig = {
  isAxiosRequest: (action) => {
    const {payload} = action;
    // check if this is an action created by stateUtil.buildRequest()
    return payload?.client && payload?.request?.method && payload?.request?.url;
  },

  //need to preserve original functionality, documented really badly; I went to the src to figure it out
  onSuccess: ({action, dispatch, response}) => {
    const nextAction = {
      type: `${action.type}_SUCCESS`,
      payload: response,
      meta: {
        previousAction: action, //reproducing original middleware's `meta`
        ...action.meta, //copy original action's meta here for convenience
      },
    };
    dispatch(nextAction);
    return nextAction;
  },

  // eslint-disable-next-line complexity
  onError: (errorResponse) => {
    //replicate the original onError func (dispatch `${type}_FAIL`); payload MUST be original arg
    errorResponse.dispatch({
      type: `${errorResponse.action.type}_FAIL`,
      payload: errorResponse,
    });

    if (errorResponse.error.response) {
      // server error
      const {data} = errorResponse.error.response;
      console.error(data);
      if (data.statusCode === 401) {
        if (data.message === 'Expired token') {
          return authActions.handleExpiredToken()(errorResponse.dispatch);
        } else if (data.message === 'Old token version') {
          return authActions.handleOldTokenVersion()(errorResponse.dispatch);
        } else if (
          data.message === 'Missing authentication' ||
          data.message === 'Invalid token'
        ) {
          return authActions.handleInvalidToken()(errorResponse.dispatch);
        }
      }
      if (data.errorCode === 'deleted_user') {
        return authActions.handleDeletedUser()(errorResponse.dispatch);
      }
    } else {
      // network(/code?) error
      console.error(errorResponse.error.message || errorResponse);
    }

    // trigger .catch and not .then (the default)
    throw errorResponse.error;
  },
};

const clients = {
  default: {client: edumeClient},
  reports: {client: edumeReportsClient},
  guides: {client: guidesClient},
  mst: {client: edumeMstClient},
};

const enhancer = compose(
  applyMiddleware(
    //multiClientMiddleware seems to eat + not return some actions, so make sure it's last in chain (first in list)
    multiClientMiddleware(clients, axiosMiddlewareConfig),
    thunk,
    routerMiddleware(history),
    authMiddleware,
    trackingMiddleware,
    analyticsMiddleware,
    hubspotMiddleware
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
);

export const store = createStore(rootReducer, initialState, enhancer);

import React, {
  AriaAttributes,
  AriaRole,
  MouseEventHandler,
  ReactNode,
  useState,
} from 'react';

import CSSModules from 'react-css-modules';

import {isLightColour} from '../utils/colourUtil';

import styles from './styles.module.scss';

type CommonButtonProps = {
  children?: ReactNode;
  form?: string;
  type: ButtonTypes;
  disabled?: boolean;
  icon?: ReactNode;
  iconUrl?: string;
  darkIconUrl?: string;
  iconPosition?: IconPosition;
  bold?: boolean;
  fullWidth?: boolean;
  noBorder?: boolean;
  noMarginTop?: boolean;
  noMarginLeft?: boolean;
  forceModalStyle?: boolean;
  height?: string;
  width?: string;
  reskinClassName?: string;
  dataAutomation?: string;
  isLightTheme?: boolean;
  dropdownButton?: boolean;
  iconAlt?: string;
  tabIndex?: number;
  role?: AriaRole;
  darkBackground?: boolean;
};

type ConditionalOnClickProps =
  | {
      onClick?: MouseEventHandler<HTMLElement>;
      submit?: never;
    }
  | {
      onClick?: never;
      submit?: true;
    };

type ConditionalHrefProps =
  | {
      as: 'button';
      href?: never;
    }
  | {
      as: 'a';
      href: string;
    }
  | {
      as?: never;
      href?: never;
    };

export type IconPosition = 'start' | 'end';

export type ButtonTypes = (typeof buttonTypes)[number];
export const buttonTypes = [
  'primary',
  'primaryMid',
  'danger',
  'secondary',
  'thirdary',
  'active',
  'custom',
  'tooltip',
  'tooltipDark',
  'invalid',
  'progress',
  'bluePrimary',
  'blueSecondary',
  'text',
] as const;

type ButtonProps = CommonButtonProps &
  AriaAttributes &
  ConditionalHrefProps &
  ConditionalOnClickProps & {
    customColour?: string;
    customDarkColour?: string;
    customFontColour?: string;
    customHoverFontColour?: string;
    isCursorAllowed?: boolean;
  };

type Style = {
  backgroundColor?: string;
  height?: string;
  width?: string;
  cursor?: string;
  opacity?: number;
  color?: string;
};

// eslint-disable-next-line complexity, max-statements
const Button = (
  /* prettier-ignore */ {
    onClick, submit, children, type, form, disabled, icon, iconUrl, darkIconUrl, iconPosition = 'start',
    customColour, customDarkColour, bold, fullWidth, noMarginTop, noMarginLeft, noBorder, forceModalStyle,
    height, width, reskinClassName = '', dataAutomation, isLightTheme, dropdownButton, iconAlt,
    as = 'button', href, tabIndex = 0, customFontColour, customHoverFontColour, role,
    'aria-disabled': ariaDisabled, 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledby, isCursorAllowed = true, darkBackground = false,
  }: ButtonProps
) => {
  const [isHovered, setIsHovered] = useState(false);

  const hoverStyleName =
    as === 'button' || (as === 'a' && !disabled) ? 'hoverColour' : '';
  let styleNames = `${type} ${hoverStyleName} ${reskinClassName}`;
  const optionalStyles = {
    bold,
    fullWidth,
    noMarginTop,
    noMarginLeft,
    noBorder,
    forceModalStyle,
  };
  for (const style of Object.keys(
    optionalStyles
  ) as (keyof typeof optionalStyles)[]) {
    if (optionalStyles[style]) {
      styleNames += ` ${style}`;
    }
  }
  if (!children) {
    styleNames += ' iconOnly';
  }
  if (isLightTheme) {
    styleNames += ' lightTheme';
  }
  if (darkBackground) {
    styleNames += ' darkBackground';
  }

  const displayedIconType = icon ? 'vector' : 'imageUrl';

  const marginClass = children ? iconPosition : '';
  const iconContainer = (
    <span styleName={`iconContainer ${marginClass}`}>
      {displayedIconType === 'vector' ? icon : ''}
      {iconUrl && displayedIconType === 'imageUrl' && (
        <img src={iconUrl} styleName='iconUrl' alt={iconAlt} />
      )}
      {darkIconUrl && displayedIconType === 'imageUrl' && (
        <img src={darkIconUrl} styleName='darkIconUrl' alt={iconAlt} />
      )}
    </span>
  );

  const content =
    !iconUrl && !darkIconUrl && !icon ? (
      children
    ) : (
      <span
        styleName={
          dropdownButton
            ? 'contentWithIconContainer dropdown'
            : 'contentWithIconContainer'
        }
      >
        {iconPosition === 'start' && iconContainer}
        {children && <span>{children}</span>}
        {iconPosition === 'end' && iconContainer}
      </span>
    );

  const buttonStyle: Style = {};
  if (type === 'custom') {
    const showHoverState = isHovered && !disabled;
    const buttonFontColour = showHoverState
      ? customHoverFontColour
      : customFontColour;
    const backgroundColour = showHoverState ? customDarkColour : customColour;
    buttonStyle.backgroundColor = backgroundColour;
    if (buttonFontColour) {
      buttonStyle.color = buttonFontColour;
    } else {
      const fontColourStyleName = isLightColour(backgroundColour || '')
        ? ' textPrimary'
        : ' textWhite';
      styleNames += fontColourStyleName;
    }
  }
  if (height) {
    buttonStyle.height = height;
  }
  if (width) {
    buttonStyle.width = width;
  }

  const linkStyle: Style = {};
  if (disabled) {
    linkStyle.cursor = 'default';
    linkStyle.opacity = 0.3;
  }

  if (!isCursorAllowed) {
    buttonStyle.cursor = 'not-allowed';
  }

  return as === 'a' ? (
    <a
      data-automation={dataAutomation}
      href={disabled ? undefined : href}
      styleName={styleNames}
      style={{...buttonStyle, ...linkStyle}}
      target='_blank'
      rel='noreferrer'
      onClick={disabled ? () => undefined : onClick}
      tabIndex={disabled ? -1 : 0}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role={role}
      aria-disabled={ariaDisabled}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
    >
      {content}
    </a>
  ) : (
    <button
      data-automation={dataAutomation}
      onClick={onClick}
      disabled={disabled}
      tabIndex={disabled ? -1 : tabIndex}
      type={submit ? 'submit' : 'button'}
      form={form}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      styleName={styleNames}
      style={buttonStyle}
      role={role}
      aria-disabled={ariaDisabled}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
    >
      {content}
    </button>
  );
};

export default CSSModules(Button, styles, {allowMultiple: true});
